import React, {useState, useEffect, useContext} from 'react';
import Box from '@mui/material/Box';
import {httpClient} from "../../core/HttpClient";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {PRICES} from "../../core/Static";
import NumberFormat from "react-number-format";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DialogActions from "@mui/material/DialogActions";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import {DateI18n} from "../DateI18n";
import DialogContent from "@mui/material/DialogContent";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import Alert from "@mui/material/Alert";
// import firebase from "../../core/Firebase";
import {Context} from "../../core/Context";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {getDatabase, onValue, ref} from "firebase/database";
import {getAuth, signInAnonymously} from "firebase/auth";
import {getDatabaseInstance} from "../../core/Firebase";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: 'white',
    border: '0px',
    padding: 15,
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#44cc00',
            contrastText: '#ffffff',
        },
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey["300"],
        color: theme.palette.common.black,
        fontWeight: 'bold',
        padding: 8
    },
}));

export default function AccountPlan({data, onUpdate}) {
    const { t } = useTranslation();
    const [context, setContext] = useContext(Context);
    const [account, setAccount] = useState({});
    const [open, setOpen] = useState({redeem: false, upgrade: false});
    const [productQTY, setProductQTY] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalDay, setTotalDay] = useState(30);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [accept, setAccept] = useState(false);
    const [payment, setPayment] = useState({date: new Date()});
    const [qrCodeData, setQrCodeData] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [upgradeLoading, setUpgradeLoading] = useState(false);
    const [qrCodeDataForUpgrade, setQrCodeDataForUpgrade] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);

    useEffect(() => {
        console.log('[AccountPlan]');
        setAccount(data);
    }, [data]);

    const handleClickOpenUpgrade = () => {
        if(account.plan === 'STANDARD'){
            setSelectedPlan('PRO'); // default selection when have one plan.
        }  else {
            setSelectedPlan('');
        }

        setOpen({redeem: false, upgrade: true});
    };

    const handleClose = () => {
        setOpen({redeem: false, upgrade: false});
    };

    const handleSelectPlan = (plan) => {
        setSelectedPlan(plan);
    };

    const handleAccept = () => {
        let _accept = !accept;
        setAccept(_accept);
    };

    const handleIncreaseQTY = () =>{
        if(productQTY > 99){
            return;
        }
        let qty = productQTY + 1;
        setProductQTY(qty);

        let pp = PRICES[account.plan] || 0;
        // let vat = pp * 0.07;
        let vat = 0;
        let totalPrice = (pp * qty)+vat;
        // setVat(vat.toFixed(2));
        setTotalPrice(totalPrice.toFixed(2));
        setTotalDay(qty * 30);
    }

    const handleDecreaseQTY = () =>{
        if(productQTY < 2){
            return;
        }
        let qty = productQTY - 1;
        setProductQTY(qty);

        let pp = PRICES[account.plan] || 0;
        // let vat = pp * 0.07;
        let vat = 0;
        let totalPrice = (pp * qty)+vat;
        // setVat(vat.toFixed(2));
        setTotalPrice(totalPrice.toFixed(2));
        setTotalDay(qty * 30);
    }

    const handlePayment = () => {
        setPayment({date: new Date()});
        setQrCodeData(null);
        setPaymentComplete(false);
        const url = process.env.REACT_APP_API_BASE_URL + '/payments/channels/qr';
        httpClient.post(url, {qty: productQTY, account: account._id})
            .then(res => {
                if(res.data.data){
                    setQrCodeData({orderNo: res.data.orderNo, amount: res.data.amount, qrcodeId: res.data.data.qrcodeId,  base64:res.data.data.qrImage});
                }
            });
        setOpenPayment(true);
    };

    const handleClosePayment = () => {
        setOpenPayment(false);
    };

    const handleClosePaymentComplete = () => {
        if(account._id){
            setOpenPayment(false);
            setPaymentComplete(false);
            setOpen({redeem: false, upgrade: false});
            const url = process.env.REACT_APP_API_BASE_URL + '/accounts/'+account._id;
            httpClient.get(url)
                .then(res => {
                    if(res.data){
                        setAccount(res.data);
                        setSelectedPlan(res.data.plan);
                    }
                });
        }
    };

    const handleConfirm = () => {
        setAccept(false);
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setQrCodeDataForUpgrade(null);
        setOpenConfirm(false);
    };

    const handleSubmit = () => {
        if(upgradeLoading){
            return;
        }

        setUpgradeLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/'+account._id+'/upgrade';
        httpClient.post(url, {plan: selectedPlan})
            .then(res => {
                if(res.data && res.data.data){
                    // render QR Code
                    setQrCodeDataForUpgrade({orderNo: res.data.orderNo, amount: res.data.amount, qrcodeId: res.data.data.qrcodeId,  base64:res.data.data.qrImage});
                    let key = res.data.uid;
                    let app = getDatabaseInstance();
                    const db = getDatabase(app);
                    const auth = getAuth(app);
                    signInAnonymously(auth)
                        .then(() => {
                            const statusRef = ref(db, 'payments/' + key);
                            onValue(statusRef, (snapshot) => {
                                const data = snapshot.val();
                                if(data && data.status === 'success'){
                                    if(account._id){
                                        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/'+account._id;
                                        httpClient.get(url)
                                            .then(res => {
                                                setContext({ ...context, success: true});
                                                if(res.data){
                                                    setAccount(res.data);
                                                    setSelectedPlan(res.data.plan);
                                                    if(onUpdate){
                                                        onUpdate(res.data);
                                                    }
                                                }
                                            });
                                    }
                                    setOpenConfirm(false);
                                    setOpen({redeem: false, upgrade: false});
                                }
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    // Upgrade completed
                    if(account._id){
                        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/'+account._id;
                        httpClient.get(url)
                            .then(res => {
                                setContext({ ...context, success: true});
                                if(res.data){
                                    setAccount(res.data);
                                    setSelectedPlan(res.data.plan);
                                    if(onUpdate){
                                        onUpdate(res.data);
                                    }
                                }
                            });
                    }
                    setOpenConfirm(false);
                    setOpen({redeem: false, upgrade: false});
                }
                setUpgradeLoading(false);
            });
    };

    let MyPackageInfo = () => {
        return <Table aria-label="simple table" style={{border: '1px solid #ebebeb'}}>
            <TableHead>
                <TableRow>
                    {'START' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.start')}</b></StyledTableCell>
                    }
                    {'BASIC' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.basic')}</b></StyledTableCell>
                    }
                    {'STANDARD' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.standard')}</b></StyledTableCell>
                    }
                    {'PRO' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.pro')}</b></StyledTableCell>
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.fee')}</Typography>
                        {'FREE' === account.plan &&
                            <Typography>{t('pricing.price.free')}</Typography>
                        }
                        {'START' === account.plan &&
                            <Typography>{t('pricing.price.start')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{t('pricing.price.basic')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{t('pricing.price.standard')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{t('pricing.price.pro')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{account.type==='RESTAURANT'?t('pricing.menu'):t('pricing.product')}</Typography>
                        {'FREE' === account.plan &&
                            <Typography>{account.planOption.menu?account.planOption.menu:t('pricing.package.free.menu')}</Typography>
                        }
                        {'START' === account.plan &&
                            <Typography>{account.planOption.menu?account.planOption.menu:t('pricing.package.start.menu')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{account.planOption.menu?account.planOption.menu:t('pricing.package.basic.menu')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{account.planOption.menu?account.planOption.menu:t('pricing.package.standard.menu')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{account.planOption.menu?account.planOption.menu:t('pricing.package.pro.menu')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.order')}</Typography>
                        {'FREE' === account.plan &&
                            <Typography>{account.planOption.order?account.planOption.order:t('pricing.package.free.order')}</Typography>
                        }
                        {'START' === account.plan &&
                            <Typography>{account.planOption.order?account.planOption.order:t('pricing.package.start.order')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{account.planOption.order?account.planOption.order:t('pricing.package.basic.order')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{account.planOption.order?account.planOption.order:t('pricing.package.standard.order')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{account.planOption.order?account.planOption.order:t('pricing.package.pro.order')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.table')}</Typography>
                        {'FREE' === account.plan &&
                            <Typography>{account.planOption.table?account.planOption.table:t('pricing.package.free.table')}</Typography>
                        }
                        {'START' === account.plan &&
                            <Typography>{account.planOption.table?account.planOption.table:t('pricing.package.start.table')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{account.planOption.table?account.planOption.table:t('pricing.package.basic.table')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{account.planOption.table?account.planOption.table:t('pricing.package.standard.table')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{account.planOption.table?account.planOption.table:t('pricing.package.pro.table')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.kitchen')}</Typography>
                        {'FREE' === account.plan &&
                            <Typography>{account.planOption.kitchen?account.planOption.kitchen:t('pricing.package.free.kitchen')}</Typography>
                        }
                        {'START' === account.plan &&
                            <Typography>{account.planOption.kitchen?account.planOption.kitchen:t('pricing.package.start.kitchen')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{account.planOption.kitchen?account.planOption.kitchen:t('pricing.package.basic.kitchen')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{account.planOption.kitchen?account.planOption.kitchen:t('pricing.package.standard.kitchen')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{account.planOption.kitchen?account.planOption.kitchen:t('pricing.package.pro.kitchen')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.user')}</Typography>
                        {'FREE' === account.plan &&
                            <Typography>{account.planOption.user?account.planOption.user:t('pricing.package.free.user')}</Typography>
                        }
                        {'START' === account.plan &&
                            <Typography>{account.planOption.user?account.planOption.user:t('pricing.package.start.user')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{account.planOption.user?account.planOption.user:t('pricing.package.basic.user')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{account.planOption.user?account.planOption.user:t('pricing.package.standard.user')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{account.planOption.user?account.planOption.user:t('pricing.package.pro.user')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.delivery')}</Typography>
                        {'FREE' === account.plan &&
                            <Typography>{account.planOption.area?account.planOption.area:t('pricing.package.free.area')}</Typography>
                        }
                        {'START' === account.plan &&
                            <Typography>{account.planOption.area?account.planOption.area:t('pricing.package.start.area')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{account.planOption.area?account.planOption.area:t('pricing.package.basic.area')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{account.planOption.area?account.planOption.area:t('pricing.package.standard.area')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{account.planOption.area?account.planOption.area:t('pricing.package.pro.area')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                {['FREE', 'START', 'BASIC', 'STANDARD'].indexOf(account.plan) > -1 && account.isOwner &&
                <TableRow>
                    <TableCell>
                            <Box>
                                <Button variant="outlined"
                                        color="primary"
                                        fullWidth={true}
                                        size="large"
                                        disableElevation
                                        disabled={account.status === 'denied'}
                                        onClick={handleClickOpenUpgrade}>
                                    {t('pricing.upgradePlan')}
                                </Button>
                            </Box>
                    </TableCell>
                </TableRow>
                }
            </TableBody>
        </Table>;
    }

    return (<Box>
            <Box pb={2}>
                <Box mb={1}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                        <Typography color="inherit">
                            {t('pricing.plan')}
                        </Typography>
                        <Typography color="textSecondary">
                            {'FREE' === account.plan &&
                                t('pricing.free')
                            }
                            {'START' === account.plan &&
                                t('pricing.start')
                            }
                            {'BASIC' === account.plan &&
                                t('pricing.basic')
                            }
                            {'STANDARD' === account.plan &&
                                t('pricing.standard')
                            }
                            {'PRO' === account.plan &&
                                t('pricing.pro')
                            }
                        </Typography>
                    </Box>
                    <MyPackageInfo />
                </Box>
            </Box>
            <Dialog
                fullScreen={true}
                open={open.upgrade}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar variant="dense">
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {t('pricing.selectPlan')}
                        </Typography>
                        <IconButton edge="end" color="primary" aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Box p={1}>
                    <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                        <TableHead>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <StyledTableCell
                                        align="center" style={{
                                        width: '22%',
                                        borderRight: '2px solid white',
                                        fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                    }}
                                        onClick={(e) => handleSelectPlan('START')}>{t('pricing.start')}</StyledTableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <StyledTableCell
                                        align="center" style={{
                                        width: '22%',
                                        borderRight: '2px solid white',
                                        fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                    }}
                                        onClick={(e) => handleSelectPlan('BASIC')}>{t('pricing.basic')}</StyledTableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <StyledTableCell
                                        align="center" style={{
                                        width: '22%',
                                        borderRight: '2px solid white',
                                        fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                    }}
                                        onClick={(e) => handleSelectPlan('STANDARD')}>{t('pricing.standard')}</StyledTableCell>
                                }
                                    <StyledTableCell align="center" style={{
                                        width: '22%',
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'2px solid white':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}
                                       onClick={(e) => handleSelectPlan('PRO')}>{t('pricing.pro')}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{borderBottom: '0px', paddingBottom: '0px'}} colSpan={4} align="center">{t('pricing.fee')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{padding: '10px 0 10px 0'}}
                                               align="center">
                                        <div style={{borderRight: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.start')}</div>
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.price.start')}</div>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{padding: '10px 0 10px 0'}}
                                               align="center">
                                        <div style={{borderRight: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.basic')}</div>
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.price.basic')}</div>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{padding: '10px 0 10px 0'}}
                                        align="center">
                                        <div style={{borderRight: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.standard')}</div>
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.price.standard')}</div>
                                    </TableCell>
                                }
                                <TableCell style={{padding: '10px 0 10px 0'}} align="center" >
                                    <div style={{borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.pro')}</div>
                                    <div style={{
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}>{t('pricing.price.pro')}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom: '0px', paddingBottom: '0px'}} colSpan={4} align="center">{t('pricing.menu')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.menu')}</div>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.menu')}</div>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.menu')}</div>
                                    </TableCell>
                                }
                                <TableCell style={{
                                    padding: '10px 0 10px 0'
                                }} align="center">
                                    <div style={{
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}>{t('pricing.package.pro.menu')}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom: '0px', paddingBottom: '0px'}} colSpan={4} align="center">{t('pricing.order')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.order')}</div>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.order')}</div>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.order')}</div>
                                    </TableCell>
                                }
                                <TableCell style={{
                                    padding: '10px 0 10px 0'
                                }} align="center">
                                    <div style={{
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}>{t('pricing.package.pro.order')}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom: '0px', paddingBottom: '0px'}} colSpan={4} align="center">{t('pricing.table')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.table')}</div>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.table')}</div>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.table')}</div>
                                    </TableCell>
                                }
                                <TableCell style={{
                                    padding: '10px 0 10px 0'
                                }} align="center">
                                    <div style={{
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}>{t('pricing.package.pro.table')}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom: '0px', paddingBottom: '0px'}} colSpan={4} align="center">{t('pricing.kitchen')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.kitchen')}</div>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.kitchen')}</div>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.kitchen')}</div>
                                    </TableCell>
                                }
                                <TableCell style={{
                                    padding: '10px 0 10px 0'
                                }} align="center">
                                    <div style={{
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}>{t('pricing.package.pro.kitchen')}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom: '0px', paddingBottom: '0px'}} colSpan={4} align="center">{t('pricing.user')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.user')}</div>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.user')}</div>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.user')}</div>
                                    </TableCell>
                                }
                                <TableCell style={{
                                    padding: '10px 0 10px 0'
                                }} align="center">
                                    <div style={{
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}>{t('pricing.package.pro.user')}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom: '0px', paddingBottom: '0px'}} colSpan={4} align="center">{t('pricing.delivery')}</TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.area')}</div>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.area')}</div>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <div style={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: selectedPlan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.area')}</div>
                                    </TableCell>
                                }
                                <TableCell style={{
                                    padding: '10px 0 10px 0'
                                }} align="center">
                                    <div style={{
                                        borderLeft: (selectedPlan !== 'PRO' && account.plan !== 'STANDARD')?'1px solid gainsboro':'0px',
                                        fontWeight: selectedPlan === 'PRO'?'bold': 'normal'
                                    }}>{t('pricing.package.pro.area')}</div>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                {['FREE'].indexOf(account.plan) > -1 &&
                                    <TableCell align="center" style={{padding: '8px'}} >
                                        <Button fullWidth={true} variant="contained"
                                                disableElevation
                                                style={{ fontWeight: selectedPlan === 'START'?'bold':'normal' }}
                                                onClick={(e) => handleSelectPlan('START')}
                                                color={selectedPlan === 'START'?'orange':'grey'}>
                                            {t('pricing.price.start')}
                                        </Button>
                                    </TableCell>
                                }
                                {['FREE','START'].indexOf(account.plan) > -1 &&
                                    <TableCell align="center" style={{padding: '8px'}} >
                                        <Button fullWidth={true} variant="contained"
                                                disableElevation
                                                style={{ fontWeight: selectedPlan === 'BASIC'?'bold':'normal' }}
                                                onClick={(e) => handleSelectPlan('BASIC')}
                                                color={selectedPlan === 'BASIC'?'orange':'grey'}>
                                            {t('pricing.price.basic')}
                                        </Button>
                                    </TableCell>
                                }
                                {['FREE','START', 'BASIC'].indexOf(account.plan) > -1 &&
                                    <TableCell align="center" style={{padding: '8px'}}>
                                        <Button fullWidth={true} variant="contained"
                                                  disableElevation
                                                  style={{ fontWeight: selectedPlan === 'STANDARD'?'bold':'normal' }}
                                                  onClick={(e) => handleSelectPlan('STANDARD')}
                                                  color={selectedPlan === 'STANDARD'?'orange':'grey'}>
                                            {t('pricing.price.standard')}
                                        </Button>
                                    </TableCell>
                                }
                                <TableCell align="center" style={{padding: '8px'}}>
                                    <Button fullWidth={true} variant="contained"
                                              disableElevation
                                              onClick={(e) => handleSelectPlan('PRO')}
                                              style={{ fontWeight: selectedPlan === 'PRO'?'bold':'normal' }}
                                              color={selectedPlan === 'PRO'?'orange':'grey'}>
                                        {t('pricing.price.pro')}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Box pl={2} pb={1} mt={2}>
                    <Box>
                        <Typography variant="caption">
                            *{t('common.message.changePlanMessage1')}
                        </Typography>
                    </Box>
                        <Box>
                            <Typography variant="caption">
                                *{t('common.message.changePlanMessage2')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <DialogActions>
                    <Button color="primary"
                            variant="contained"
                            disabled={(account.status === 'denied' || selectedPlan === '')}
                            size="large"
                            fullWidth={true} disableElevation
                            onClick={handleConfirm}>
                        {selectedPlan === ''?t('pricing.selectPlan'):t('pricing.upgradePlan')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen={true}
                open={openConfirm}
                onClose={handleCloseConfirm}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar variant="dense">
                        <IconButton edge="start" color="primary" aria-label="close" onClick={handleCloseConfirm}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {t('pricing.changePlan')}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box>
                        {!qrCodeDataForUpgrade &&
                            <div>
                                <Typography variant="h5">{t('initial.agreement')}</Typography>
                                <p>
                                    &#8226; {t('common.message.changePlanMessage1')}
                                </p>
                                <p>
                                    &#8226; {t('common.message.changePlanMessage2')}
                                </p>
                                <Box mb={1}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={accept} onChange={handleAccept} name="accept" />
                                        }
                                        label={t('initial.agree')}
                                    />
                                </Box>
                                <Button color="primary" variant="contained" size="large" fullWidth={true}
                                        disableElevation
                                        disabled={upgradeLoading || !accept}
                                        onClick={handleSubmit}>
                                    {upgradeLoading &&
                                        <CircularProgress size={20} style={{marginTop: '7px', marginBottom: '7px'}} />
                                    }
                                    {!upgradeLoading &&
                                        <>
                                            {t('common.confirm')}
                                        </>
                                    }
                                </Button>
                            </div>
                        }
                        {qrCodeDataForUpgrade && !upgradeLoading &&
                            <div style={modalStyle} >
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/promptpay_visa_master.png`} alt="payment network"
                                         style={{width: '100%', maxWidth: '200px'}}/>
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                    <img src={`data:image/png;base64,${qrCodeDataForUpgrade.base64}`} alt="pay"
                                         style={{width: '100%', maxWidth: '250px'}}/>
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                                    <Typography variant="body1" color="inherit">{qrCodeDataForUpgrade.qrcodeId}</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1"
                                                color="inherit">{t('pricing.totalAmount')}</Typography>
                                    <Typography variant="body1"
                                                color="inherit"><NumberFormat value={qrCodeDataForUpgrade.amount} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                                </Box>
                                <hr/>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" color="inherit">{t(`pricing.ref1`)}</Typography>
                                    <div>{qrCodeDataForUpgrade && <span>{qrCodeDataForUpgrade.orderNo}</span>}</div>
                                </Box>
                                <hr/>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1" color="inherit">{t(`pricing.paymentDate`)}</Typography>
                                    <div><DateI18n value={payment.date} format="DD MMM YYYY HH:mm" /></div>
                                </Box>
                                <Box mt={2}>
                                    <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                            onClick={handleCloseConfirm}>
                                        {t('common.cancel')}
                                    </Button>
                                </Box>
                            </div>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                fullScreen={true}
                open={open.redeem}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar variant="dense">
                        <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                            {t('pricing.redeem')}
                        </Typography>
                        <IconButton edge="start"color="primary" aria-label="black" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent style={{paddingTop: '5px'}}>
                    <MyPackageInfo />
                </DialogContent>
                <DialogActions>
                    <Box mb={4} style={{width: '100%'}}>
                        <Box display="flex" justifyContent="center" alignItems="center" mb={1} mt={1}>
                            <Typography>{t('common.earn')}</Typography>
                            <Box ml={1}>
                                <Typography>{totalDay} {t('pricing.remainingUnit')}</Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                            <IconButton color="default" aria-label="decrease" component="span" onClick={handleDecreaseQTY}>
                                <RemoveCircleOutlineIcon sx={{ fontSize: 45 }} />
                            </IconButton>
                            <Box sx={{
                                width: 100,
                                height: 45,
                                marginLeft: '10px',
                                marginRight: '10px',
                                border: '1px solid grey',
                            }} display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="h6">{productQTY}</Typography>
                            </Box>
                            <IconButton color="default" aria-label="increase" component="span" onClick={handleIncreaseQTY}>
                                <AddCircleOutlineIcon sx={{ fontSize: 45 }} />
                            </IconButton>
                        </Box>
                        <ThemeProvider theme={theme}>
                            <Button variant="contained" color="primary" size="large" fullWidth={true} disableElevation onClick={handlePayment} >
                                <Box display="flex" justifyContent="space-between" alignItems="center" style={{width: '100%'}}>
                                    <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{t('pricing.payment')}</Typography>
                                    <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}><NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                                </Box>
                            </Button>
                        </ThemeProvider>
                    </Box>
                </DialogActions>
                <Modal
                    open={openPayment}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box style={modalStyle}>
                        {paymentComplete &&
                            <div>
                                <Alert severity="success">{t('payment.complete')}</Alert>
                                <Box mt={2}>
                                    <Button color="default" variant="contained" fullWidth={true} disableElevation
                                            onClick={handleClosePaymentComplete}>
                                        {t('common.ok')}
                                    </Button>
                                </Box>
                            </div>
                        }
                        {!paymentComplete &&
                            <div>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/promptpay_visa_master.png`} alt="payment network"
                                         style={{width: '100%', maxWidth: '200px'}}/>
                                </Box>
                                {!qrCodeData &&
                                    <Box display="flex" justifyContent="center" mt={3} mb={4}>
                                        <CircularProgress size={20}/>
                                    </Box>
                                }
                                {qrCodeData &&
                                    <>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                            <img src={`data:image/png;base64,${qrCodeData.base64}`} alt="pay"
                                                 style={{width: '100%', maxWidth: '250px'}}/>
                                        </Box>
                                        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                                            <Typography variant="body1" color="inherit">{qrCodeData.qrcodeId}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body1"
                                                        color="inherit">{t('pricing.totalAmount')}</Typography>
                                            <Typography variant="body1"
                                                        color="inherit"><NumberFormat value={qrCodeData.amount} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                                        </Box>
                                        <hr/>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body1" color="inherit">{t(`pricing.ref1`)}</Typography>
                                            <div>{qrCodeData && <span>{qrCodeData.orderNo}</span>}</div>
                                        </Box>
                                        <hr/>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body1" color="inherit">{t(`pricing.paymentDate`)}</Typography>
                                            <div><DateI18n value={payment.date} format="DD MMM YYYY HH:mm" /></div>
                                        </Box>
                                    </>
                                }
                                <Box mt={2}>
                                    <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                            onClick={handleClosePayment}>
                                        {t('common.cancel')}
                                    </Button>
                                </Box>
                            </div>
                        }
                    </Box>
                </Modal>
            </Dialog>
        </Box>
    )
}