import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box";
import Store from "../../Store";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    }
}));

export default function MobileOrdering() {
    const {t} = useTranslation();
    const classes = useStyles();

    return (
        <div>
            <form className={classes.root} noValidate autoComplete="off">
                <Box m={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/plugins" className={classes.link}>
                            <Typography variant="h6">
                                {t('plugin.title')}
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}}/>
                <Box m={1}>
                    <Store type="mobile" />
                </Box>
            </form>
        </div>
    )
}