import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {DateI18n} from '../../DateI18n';
import {Link, useParams} from "react-router-dom";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {makeStyles} from "@mui/styles";
import {httpClient} from "../../../core/HttpClient";
import Avatar from "@mui/material/Avatar";
import CustomerOrders from "../CustomerOrders";
import NumberFormat from "react-number-format";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    }
}));

export default function CustomerInfo() {
    const { t } = useTranslation();
    const {id} = useParams();
    const classes = useStyles();
    const [customer, setCustomer] = useState({cif: '', displayName: '', created: null, summary: 0, orders: 0});
    const [error, setError] = useState(false);

    useEffect(() => {
        console.log('[CustomerInfo]');
        let tasks = [
            httpClient.get(process.env.REACT_APP_API_BASE_URL + `/customers/${id}`)
        ];
        Promise.all(tasks).then(rs=>{
            if(rs){
                if(rs[0]){
                    setCustomer(rs[0].data);
                }
            }
        }).catch(e=>{
            setError(true);
        });
    }, [id]);


    return (
        <Box>
            <Box m={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/customers" className={classes.link}>
                        <Typography variant="h6">
                            {t('customer.title')}
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {error &&
                <Box m={1}>
                    <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
                </Box>
            }
            <Box m={1}>
                <Card variant="outlined">
                    <CardContent>
                        <Box display="flex" justifyContent="center" mb={2} mt={1}>
                            {customer._id &&
                                <Avatar sx={{ width: 64, height: 64 }}
                                        src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${customer._id}`} />
                            }
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                {t('customer.cif')}
                            </Box>
                            <Box>
                                {customer.cif}
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                {t('customer.name')}
                            </Box>
                            <Box>
                                {customer.displayName}
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                {t('customer.created')}
                            </Box>
                            <Box>
                                {customer.created &&
                                    <DateI18n value={customer.created} format="DD MMM YYYY HH:mm" />
                                }
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                {t('customer.orders')}
                            </Box>
                            <Box>
                                <NumberFormat value={customer.orders} displayType={'text'} thousandSeparator={true} />
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                {t('customer.summary')}
                            </Box>
                            <Box>
                                <NumberFormat value={customer.summary} displayType={'text'} thousandSeparator={true} prefix={"฿"} />
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                            <Box>
                                {t('reward.point')}
                            </Box>
                            <Box>
                                <NumberFormat value={customer.point} displayType={'text'} thousandSeparator={true} />
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box mt={2}>
                <CustomerOrders customerId={id} />
            </Box>
        </Box>
    );
}
