import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../core/HttpClient";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import {useHistory} from "react-router-dom";
import ListItemIcon from "@mui/material/ListItemIcon";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Chip from "@mui/material/Chip";
import {TableNumber} from "../TableNumber";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));

export default function Tables() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [tables, setTables] = useState([]);

    useEffect(() => {
        console.log('[Tables]');
        const url = process.env.REACT_APP_API_BASE_URL + '/tables';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setTables(res.data);
                }
                setLoading(false);
            });
    }, []);

    const handleClickCreate = () => {
        history.push('/tables/create');
    };

    const handleClickItem = (item) => {
        history.push('/tables/'+item._id);
    };

    return (
        <div className={classes.root}>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {t('table.title')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box display="flex" justifyContent="center" mb={2} m={1}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disableElevation
                    startIcon={<AddIcon/>}
                    onClick={handleClickCreate}>
                    {t('common.add')}
                </Button>
            </Box>
            {!loading && tables.length === 0 &&
                <Box display="flex" justifyContent="center" p={2} m={2} mt={4}>
                    <Typography>
                        {t('common.notFound')}
                    </Typography>
                </Box>
            }
            {!loading && tables.length > 0 &&
                <Box m={1}>
                    <Card variant="outlined">
                    <List className={classes.root} subheader={<li/>}>
                        {tables.map((item, index) => {
                            return(
                                <ListItem key={`item-${item._id}`}
                                          divider={index !== tables.length - 1}
                                          onClick={e=>handleClickItem(item)}>
                                    <ListItemIcon>
                                        <TableNumber value={item.number} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Box display="flex" alignItems="center" sx={{ flexWrap: 'wrap' }}>
                                            {item.tags.map((tag, index) => (
                                                <Box ml={1} key={`tag-${index}`}>
                                                    <Chip label={tag} size="small" />
                                                </Box>

                                            ))}
                                        </Box>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <FiberManualRecordIcon color={item.active?'primary':'disabled'} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                    </List>
                </Card>
                </Box>
            }
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
        </div>
    )
}