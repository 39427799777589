import React, {useRef} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {isDesktop} from "react-device-detect";
import L from "leaflet";
import {TextBirthdate} from "../TextBirthdate";

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: false,
    closePopupOnClick: true,
    dragging: true,
    zoomSnap: true,
    zoomDelta: true,
    trackResize: true,
    touchZoom: true,
    scrollWheelZoom: false,
};

const markerIcon = new L.Icon({
    iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark.png`,
    iconRetinaUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark2.png`,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 44],
    iconAnchor: [15, 44],
    className: 'foodkub-leaflet-div-icon'
});

const LocationMarker = (props) => {
    const markerRef = useRef(null);
    return props.position === null ? null : (
        <Marker icon={markerIcon} position={props.position} ref={markerRef} ></Marker>
    )
};
export default function ShopConfirm({temp, profile}) {
    const { t } = useTranslation();
    return (
        <Box>
            {temp.coordinates &&
                <Box mt={2} style={{height: `300px`}}>
                    <MapContainer center={temp.coordinates}
                                  zoom={temp.zoom?temp.zoom : 15}
                                  {...interactionOptions}
                                  scrollWheelZoom={false}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <LocationMarker effectOn={this}
                                        position={temp.coordinates} />
                    </MapContainer>
                </Box>
            }
            <TableContainer>
                <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                    <TableBody>
                        <TableRow>
                            <TableCell align={"center"}>
                                <Box>
                                    <Typography variant="caption">
                                        {t('shop.name')}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {temp.name}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={"center"}>
                                <Box>
                                    <Typography variant="caption">
                                        {t('shop.address')}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                        {temp.address}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={"center"}>
                                <Box>
                                    <Typography variant="caption">
                                        {t('shop.mobile')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">
                                        <NumberFormat value={temp.phone} displayType={'text'} format="###-###-####" />
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={"center"}>
                                <Box>
                                    <Typography variant="caption">
                                        {t('shop.ownerName')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">
                                        {profile.firstName} {profile.lastName}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={"center"}>
                                <Box>
                                    <Typography variant="caption">
                                        {t('profile.mobile')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">
                                        <NumberFormat value={profile.mobile} displayType={'text'} format="###-###-####" />
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={"center"}>
                                <Box>
                                    <Typography variant="caption">
                                        {t('profile.birthday')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">
                                        <TextBirthdate value={profile.birthday} />
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        {temp.plan && <TableRow>
                            <TableCell align={"center"}>
                                <Box>
                                    <Typography variant="caption">
                                        {t('pricing.plan')}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="subtitle1">
                                        {temp.plan}
                                    </Typography>
                                </Box>
                            </TableCell>
                        </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}