import React, {useState, useEffect, useRef} from 'react';
import {useLocation} from "react-router";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {httpClient} from "../../core/HttpClient";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {PinField} from "react-pin-field";
import './style.css'
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import {LoadingButton} from "@mui/lab";

export default function Passcode(props) {
    const { t } = useTranslation();
    const search = useLocation().search;
    const reset = new URLSearchParams(search).get('reset') || "false";
    const history = useHistory();
    const ref = useRef();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [initialPasscode, setInitialPasscode] = useState('');
    const [initialError, setInitialError] = useState(false);
    const [setupPasscode, setSetupPasscode] = useState(false);
    const [error, setError] = useState(0);
    const [resetPasscodeSuccess, setResetPasscodeSuccess] = useState(reset);
    const [user, setUser] = useState({_id: '', displayName: '', state: 0});

    useEffect(() => {
        console.log('[Passcode]');
        localStorage.removeItem('ticket');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/me';
        httpClient.get(url)
            .then(res => {
                if (res.data) {
                    let data = res.data;
                    if(!data.passcode){
                        // setup passcode
                        setUser({_id: data._id, displayName: data.displayName, state: data.state});
                        setSetupPasscode(true);
                        setLoading(false);
                    } else {
                        if(data.acceptAgreement && data.birthday){
                            setUser({_id: data._id, displayName: data.displayName, state: data.state});
                            setLoading(false);
                        } else {
                            window.location.href = '/user/initial/info';
                        }
                    }

                } else {
                    window.location.href = '/login';
                }
            }).catch(e=>{
                window.location.href = '/login';
            });
    }, []);

    const handleSavePasscode = () => {
        if(initialPasscode.length !== 4){
            return;
        }

        let _passcode = '';
        ref.current.forEach(input => {
            _passcode += input.value;
        });
        if(_passcode.length < 4){
            setInitialError(true);
            return;
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/users/passcode';
        setLoading(true);
        httpClient.put(url, {newPasscode: initialPasscode})
            .then(res => {
                if(res.data && res.data.ticket){
                    localStorage.setItem('ticket', res.data.ticket);
                    window.location.href = '/home';
                }
            });
    }

    const handleCheckPasscode = (value) => {
        setUpdating(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/users/passcode';
        httpClient.post(url, {passcode: value})
            .then(res => {
                setUpdating(false);
                if (res.data && res.data.ticket) {
                    localStorage.setItem('ticket', res.data.ticket);
                    history.push('/home');
                } else {
                    let errorCounter = error;
                    errorCounter++;
                    if (ref && ref.current && ref.current[0]) {
                        ref.current.forEach(input => (input.value = ""));
                        ref.current[0].focus();
                    }
                    setError(errorCounter);
                }
            }).catch(e => {
                let errorCounter = error;
                errorCounter++;
                if (ref && ref.current && ref.current[0]) {
                    ref.current.forEach(input => (input.value = ""));
                    ref.current[0].focus();
                }
                setError(errorCounter);
                setUpdating(false);
            });
    };

    const handleForgetPasscode = () => {
        history.push('/passcode/reset');
    };

    const handleClose = () => {
        setResetPasscodeSuccess(false);
    };

    return (
        <Box>
            <Box mt={6}>
                {loading &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <CircularProgress size={20}/>
                    </Box>
                }
                {!loading &&
                    <>
                    {setupPasscode &&
                        <>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Box>
                                    <Avatar alt={user.displayName}
                                            src={user._id ? `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${user._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.jpg`}
                                            sx={{width: 128, height: 128}}/>
                                    <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                                        <Typography variant="body1">
                                            {user.displayName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={4} mb={2} display="flex" justifyContent="center" alignItems="center">
                                <Typography variant="h6">
                                    {t('profile.setupPasscode')}
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <PinField className={initialError? 'pin-field-error': 'pin-field'}
                                          length={4}
                                          ref={ref}
                                          autoFocus
                                          type="tel"
                                          inputMode="numeric"
                                          validate="0123456789"
                                          onComplete={setInitialPasscode}
                                />
                            </Box>
                            <Box m={4} display="flex" justifyContent="center">
                                <LoadingButton loading={loading}
                                               variant="contained"
                                               color="primary"
                                               disableElevation
                                               size="large"
                                               fullWidth={true}
                                               onClick={(e) => handleSavePasscode()}
                                               disabled={initialPasscode.length !== 4 || loading}>
                                    {t('common.ok')}
                                </LoadingButton>
                            </Box>
                        </>
                    }
                    {!setupPasscode &&
                        <>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Box>
                                    <Avatar alt={user.displayName}
                                            src={user._id ? `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${user._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.jpg`}
                                            sx={{width: 128, height: 128}}/>
                                    <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                                        <Typography variant="body1">
                                            {user.displayName}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {user.state === 9 &&
                                <Box p={2}>
                                    <Alert severity="error" >{t('user.ban')}</Alert>
                                </Box>
                            }
                            {user.state !== 9 &&
                                <>

                                    <Box mt={4} mb={2} display="flex" justifyContent="center" alignItems="center">
                                        <Typography variant="caption">
                                            {t('common.message.enterPasscode')}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <PinField className={error > 0 ? 'pin-field-error': 'pin-field'}
                                                  length={4}
                                                  ref={ref}
                                                  autoFocus
                                                  type="password"
                                                  inputMode="numeric"
                                                  validate="0123456789"
                                                  onComplete={handleCheckPasscode}
                                        />
                                    </Box>
                                    <Box display="flex" justifyContent="center" mt={4}>
                                        <Button onClick={handleForgetPasscode} >
                                            {t('common.message.forgetPasscode')}
                                        </Button>
                                    </Box>
                                </>
                            }
                        </>
                    }
                    </>
                }
            </Box>
            <Snackbar open={resetPasscodeSuccess === 'true'}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t('profile.resetPasscodeSuccess')}
                </Alert>
            </Snackbar>
            <Backdrop
                style={{
                    zIndex: 999,
                    color: '#fff',
                }}
                open={updating}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </Box>
    )
}