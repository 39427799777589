import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {httpClient} from "../../../core/HttpClient";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {PRICES} from "../../../core/Static";
import NumberFormat from "react-number-format";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DialogActions from "@mui/material/DialogActions";
import CircularProgress from "@mui/material/CircularProgress";
import {DateI18n} from "../../DateI18n";
import DialogContent from "@mui/material/DialogContent";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import {getDatabase, goOffline, goOnline, onValue, ref} from "firebase/database";
import {getAuth, signInAnonymously} from "firebase/auth";
import Snackbar from "@mui/material/Snackbar";
import {useIdleTimer} from "react-idle-timer";
import {getDatabaseInstance} from "../../../core/Firebase";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: 'white',
    border: '0px',
    padding: 15,
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#44cc00',
            contrastText: '#ffffff',
        },
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey["300"],
        color: theme.palette.common.black,
        fontWeight: 'bold',
        padding: 8
    },
}));

export default function AccountDayDialog({open, account, callback}) {
    const { t } = useTranslation();
    const [productQTY, setProductQTY] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalDay, setTotalDay] = useState(30);
    const [notifyError, setNotifyError] = useState(0);
    const [payment, setPayment] = useState({date: new Date()});
    const [qrCodeData, setQrCodeData] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            if(document.visibilityState === 'hidden'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    useEffect(() => {
        console.log('[AccountDayDialog]');
        let pp = PRICES[account.plan] || 0;
        setTotalPrice(pp.toFixed(2));
        setProductQTY(1);
    },[account]);


    const handleClose = () => {
        callback('cancel');
    };

    const handleIncreaseQTY = () =>{
        if(productQTY > 99){
            return;
        }
        let qty = productQTY + 1;
        setProductQTY(qty);

        let pp = PRICES[account.plan] || 0;
        // let vat = pp * 0.07;
        let vat = 0;
        let totalPrice = (pp * qty)+vat;
        // setVat(vat.toFixed(2));
        setTotalPrice(totalPrice.toFixed(2));
        setTotalDay(qty * 30);
    }

    const handleDecreaseQTY = () =>{
        if(productQTY < 2){
            return;
        }
        let qty = productQTY - 1;
        setProductQTY(qty);

        let pp = PRICES[account.plan] || 0;
        // let vat = pp * 0.07;
        let vat = 0;
        let totalPrice = (pp * qty)+vat;
        // setVat(vat.toFixed(2));
        setTotalPrice(totalPrice.toFixed(2));
        setTotalDay(qty * 30);
    }


    function validateLimitation() {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_BASE_URL + '/validations/day';
            httpClient.post(url, {qty: productQTY})
                .then(res => {
                    if(res.status === 200){
                        if(res.data.ok === 1){
                            resolve(res.data);
                        } else {
                            reject(res.data);
                        }
                    } else {
                        reject(res.data);
                    }
                })
                .catch(e=>{
                    reject(e);
                })
        })
    }

    const handlePayment = () => {
        validateLimitation().then(r=>{
            setPayment({date: new Date()});
            setQrCodeData(null);
            setPaymentComplete(false);
            const url = process.env.REACT_APP_API_BASE_URL + '/payments/channels/qr';
            httpClient.post(url, {qty: productQTY, account: account._id})
                .then(res => {
                    if(res.data.data){
                        setQrCodeData({orderNo: res.data.orderNo, amount: res.data.amount, qrcodeId: res.data.data.qrcodeId,  base64:res.data.data.qrImage});
                    }

                    let key = res.data.uid;
                    let app = getDatabaseInstance();
                    const db = getDatabase(app);
                    const auth = getAuth(app);
                    signInAnonymously(auth)
                    .then(() => {
                        const statusRef = ref(db, 'payments/' + key);
                        onValue(statusRef, (snapshot) => {
                            const data = snapshot.val();
                            if(data && data.status === 'success'){
                                setPaymentComplete(true);
                            }
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                    });
                });
            setOpenPayment(true);
        }).catch(e=>{
            setNotifyError(1);
        });
    };

    const handleNotifyClose = () => {
        setNotifyError(0);
    };

    const handleClosePayment = () => {
        setOpenPayment(false);
    };

    const handleClosePaymentComplete = () => {
        if(account._id){
            setOpenPayment(false);
            setPaymentComplete(false);
            callback('complete');
        }
    };

    let MyPackageInfo = () => {
        console.log('account', account);
        return <Table aria-label="simple table" style={{border: '1px solid #ebebeb'}}>
            <TableHead>
                <TableRow>
                    {'START' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.start')}</b></StyledTableCell>
                    }
                    {'BASIC' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.basic')}</b></StyledTableCell>
                    }
                    {'STANDARD' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.standard')}</b></StyledTableCell>
                    }
                    {'PRO' === account.plan &&
                        <StyledTableCell
                            align="center"><b>{t('pricing.pro')}</b></StyledTableCell>
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.fee')}</Typography>
                        {'START' === account.plan &&
                            <Typography>{t('pricing.price.start')}</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{t('pricing.price.basic')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{t('pricing.price.standard')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{t('pricing.price.pro')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell align="center">
                        <Typography>{t('pricing.menu')}</Typography>
                        {'START' === account.plan &&
                            <Typography>{account.planOption.menu?account.planOption.menu:t('pricing.package.start.menu')}xxx</Typography>
                        }
                        {'BASIC' === account.plan &&
                            <Typography>{account.planOption.menu?account.planOption.menu:t('pricing.package.basic.menu')}</Typography>
                        }
                        {'STANDARD' === account.plan &&
                            <Typography>{account.planOption.menu?account.planOption.menu:t('pricing.package.standard.menu')}</Typography>
                        }
                        {'PRO' === account.plan &&
                            <Typography variant="h6">{account.planOption.menu?account.planOption.menu:t('pricing.package.pro.menu')}</Typography>
                        }
                    </TableCell>
                </TableRow>
                {'START' !== account.plan &&
                <>
                    <TableRow>
                        <TableCell align="center">
                            <Typography>{t('pricing.table')}</Typography>
                            {'BASIC' === account.plan &&
                                <Typography>{account.planOption.table?account.planOption.table:t('pricing.package.basic.table')}</Typography>
                            }
                            {'STANDARD' === account.plan &&
                                <Typography>{account.planOption.table?account.planOption.table:t('pricing.package.standard.table')}</Typography>
                            }
                            {'PRO' === account.plan &&
                                <Typography variant="h6">{account.planOption.table?account.planOption.table:t('pricing.package.pro.table')}</Typography>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">
                            <Typography>{t('pricing.user')}</Typography>
                            {'BASIC' === account.plan &&
                                <Typography>{account.planOption.user?account.planOption.user:t('pricing.package.basic.user')}</Typography>
                            }
                            {'STANDARD' === account.plan &&
                                <Typography>{account.planOption.user?account.planOption.user:t('pricing.package.standard.user')}</Typography>
                            }
                            {'PRO' === account.plan &&
                                <Typography variant="h6">{account.planOption.user?account.planOption.user:t('pricing.package.pro.user')}</Typography>
                            }
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">
                            <Typography>{t('pricing.delivery')}</Typography>
                            {'BASIC' === account.plan &&
                                <Typography>{account.planOption.area?account.planOption.area:t('pricing.package.basic.area')}</Typography>
                            }
                            {'STANDARD' === account.plan &&
                                <Typography>{account.planOption.area?account.planOption.area:t('pricing.package.standard.area')}</Typography>
                            }
                            {'PRO' === account.plan &&
                                <Typography variant="h6">{account.planOption.area?account.planOption.area:t('pricing.package.pro.area')}</Typography>
                            }
                        </TableCell>
                    </TableRow>
                </>
                }
            </TableBody>
        </Table>;
    }

    return (
        <Dialog
            fullScreen={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                        {t('pricing.redeem')}
                    </Typography>
                    <IconButton edge="start"color="primary" aria-label="black" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent style={{paddingTop: '5px'}}>
                <MyPackageInfo />
            </DialogContent>
            <DialogActions>
                <Box mb={4} style={{width: '100%'}}>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={1} mt={1}>
                        <Typography>{t('common.earn')}</Typography>
                        <Box ml={1}>
                            <Typography><NumberFormat value={totalDay} displayType={'text'} thousandSeparator={true} /> {t('pricing.remainingUnit')}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                        <IconButton color="default" aria-label="decrease" component="span" onClick={handleDecreaseQTY}>
                            <RemoveCircleOutlineIcon sx={{ fontSize: 45 }} />
                        </IconButton>
                        <Box sx={{
                            width: 100,
                            height: 45,
                            marginLeft: '10px',
                            marginRight: '10px',
                            border: '1px solid grey',
                        }} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6">{productQTY}</Typography>
                        </Box>
                        <IconButton color="default" aria-label="increase" component="span" onClick={handleIncreaseQTY}>
                            <AddCircleOutlineIcon sx={{ fontSize: 45 }} />
                        </IconButton>
                    </Box>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" size="large" fullWidth={true} disableElevation onClick={handlePayment} >
                            <Box display="flex" justifyContent="space-between" alignItems="center" style={{width: '100%'}}>
                                <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{t('pricing.payment')}</Typography>
                                <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}><NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                            </Box>
                        </Button>
                    </ThemeProvider>
                </Box>
            </DialogActions>
            <Dialog
                fullScreen={true}
                open={openPayment}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box style={modalStyle}>
                    {paymentComplete &&
                        <div>
                            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                                <CheckCircleOutlineIcon color={"success"} sx={{ fontSize: 60 }} />
                            </Box>
                            <Alert icon={false} severity="success">{t('payment.complete')}</Alert>
                            <Box mt={4}>
                                <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                        onClick={handleClosePaymentComplete}>
                                    {t('common.ok')}
                                </Button>
                            </Box>
                        </div>
                    }
                    {!paymentComplete &&
                        <div>
                            <Box display="flex" justifyContent="center" mt={2} mb={2}>
                                <Typography variant="h5"
                                            color="inherit"><b>{t('payment.scanForPaid')}</b></Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/promptpay_visa_master.png`} alt="payment network"
                                     style={{width: '100%', maxWidth: '200px'}}/>
                            </Box>
                            {!qrCodeData &&
                                <Box display="flex" justifyContent="center" mt={3} mb={4}>
                                    <CircularProgress size={20}/>
                                </Box>
                            }
                            {qrCodeData &&
                                <>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                        <img src={`data:image/png;base64,${qrCodeData.base64}`} alt="pay"
                                             style={{width: '100%', maxWidth: '250px'}}/>
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                                        <Typography variant="body1" color="inherit">{qrCodeData.qrcodeId}</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1"
                                                    color="inherit">{t('pricing.totalAmount')}</Typography>
                                        <Typography variant="body1"
                                                    color="inherit"><NumberFormat value={qrCodeData.amount} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                                    </Box>
                                    <hr/>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" color="inherit">{t(`pricing.ref1`)}</Typography>
                                        <div>{qrCodeData && <span>{qrCodeData.orderNo}</span>}</div>
                                    </Box>
                                    <hr/>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" color="inherit">{t(`pricing.paymentDate`)}</Typography>
                                        <div><DateI18n value={payment.date} format="DD MMM YYYY HH:mm" /></div>
                                    </Box>
                                </>
                            }
                            <Box mt={2}>
                                <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                        onClick={handleClosePayment}>
                                    {t('common.cancel')}
                                </Button>
                            </Box>
                        </div>
                    }
                </Box>
            </Dialog>
            <Snackbar open={notifyError > 0}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={3000} onClose={handleNotifyClose}>
                <Alert onClose={handleNotifyClose} severity="warning">
                    {notifyError === 1 && t('account.limitDay')}
                </Alert>
            </Snackbar>
        </Dialog>
    )
}