import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import "leaflet-editable";
import Card from "@mui/material/Card";
import {ListItem, Switch as SubSwitch} from "@mui/material";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import {httpClient} from "../../core/HttpClient";
import Avatar from "@mui/material/Avatar";
import {Context} from "../../core/Context";

export default function DeliveryPartner() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [context, setContext] = useContext(Context);
    const [deliveryPartner, setDeliveryPartner] = useState({
        grabfood: false,
        lineman: false,
        robinhood: false,
        foodpanda: false,
        shopeefood: false,
        other: false
    });


    console.log('DeliveryPartner');

    useEffect(() => {
        console.log('[DeliveryPartner]');
        const url = process.env.REACT_APP_API_BASE_URL + '/delivery/partners';
        let tasks = [
            httpClient.get(url),
        ];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setDeliveryPartner(rs[0].data);
                }

                setLoading(false);
            });
    }, []);

    const handleSave = () => {

        const url = process.env.REACT_APP_API_BASE_URL + '/delivery/partners';
        let _data = {
            ...deliveryPartner
        }
        setUpdating(true);
        httpClient.post(url, _data)
            .then(res => {
                setUpdating(false);
                setContext({ ...context, success: true});
            });
    };

    const handleSwitchChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        let newData = {...deliveryPartner, ...fieldValue};
        setDeliveryPartner(newData);
    };

    return (
        <div>
            <Box m={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h6">
                        {t('deliveryPartner.title')}
                    </Typography>
                </Box>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <>
                    <Box m={1}>
                        <Card variant="outlined">
                            <List>
                                <ListItem divider key="grabfood">
                                    <ListItemIcon>
                                        <Avatar src="https://cdn-stage.foodkub.com/public/assets/delivery/grabfood.png" alt="grabfood" />
                                    </ListItemIcon>
                                    <ListItemText id='pickup'>
                                        <Box mr={1}>
                                            <Typography variant="h6">
                                                <div>
                                                    GrabFood
                                                </div>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <SubSwitch
                                            checked={deliveryPartner.grabfood}
                                            onChange={(e) => handleSwitchChange(e)}
                                            color="primary"
                                            name="grabfood"
                                            inputProps={{'aria-label': 'open'}}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider key="lineman">
                                    <ListItemIcon>
                                        <Avatar src="https://cdn-stage.foodkub.com/public/assets/delivery/lineman.png" alt="lineman" />
                                    </ListItemIcon>
                                    <ListItemText id='delivery'>
                                        <Box mr={1}>
                                            <Typography variant="h6">
                                                <div>
                                                    Lineman
                                                </div>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <SubSwitch
                                            checked={deliveryPartner.lineman}
                                            onChange={(e) => handleSwitchChange(e)}
                                            color="primary"
                                            name="lineman"
                                            inputProps={{'aria-label': 'open'}}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider key="robinhood">
                                    <ListItemIcon>
                                        <Avatar src="https://cdn-stage.foodkub.com/public/assets/delivery/robinhood.png" alt="robinhood" />
                                    </ListItemIcon>
                                    <ListItemText id='delivery'>
                                        <Box mr={1}>
                                            <Typography variant="h6">
                                                <div>
                                                    Robinhood
                                                </div>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <SubSwitch
                                            checked={deliveryPartner.robinhood}
                                            onChange={(e) => handleSwitchChange(e)}
                                            color="primary"
                                            name="robinhood"
                                            inputProps={{'aria-label': 'open'}}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider key="foodpanda">
                                    <ListItemIcon>
                                        <Avatar src="https://cdn-stage.foodkub.com/public/assets/delivery/foodpanda.png" alt="foodpanda" />
                                    </ListItemIcon>
                                    <ListItemText id='delivery'>
                                        <Box mr={1}>
                                            <Typography variant="h6">
                                                <div>
                                                    Foodpanda
                                                </div>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <SubSwitch
                                            checked={deliveryPartner.foodpanda}
                                            onChange={(e) => handleSwitchChange(e)}
                                            color="primary"
                                            name="foodpanda"
                                            inputProps={{'aria-label': 'open'}}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem divider key="shopeefood">
                                    <ListItemIcon>
                                        <Avatar src="https://cdn-stage.foodkub.com/public/assets/delivery/shopeefood.png" alt="shopeefood" />
                                    </ListItemIcon>
                                    <ListItemText id='delivery'>
                                        <Box mr={1}>
                                            <Typography variant="h6">
                                                <div>
                                                    ShopeeFood
                                                </div>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <SubSwitch
                                            checked={deliveryPartner.shopeefood}
                                            onChange={(e) => handleSwitchChange(e)}
                                            color="primary"
                                            name="shopeefood"
                                            inputProps={{'aria-label': 'open'}}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem key="other">
                                    <ListItemIcon>
                                        <Avatar src="https://cdn-stage.foodkub.com/public/assets/delivery/other.png" alt="other" />
                                    </ListItemIcon>
                                    <ListItemText id='delivery'>
                                        <Box mr={1}>
                                            <Typography variant="h6">
                                                <div>
                                                    {t('common.other')}
                                                </div>
                                            </Typography>
                                        </Box>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <SubSwitch
                                            checked={deliveryPartner.other}
                                            onChange={(e) => handleSwitchChange(e)}
                                            color="primary"
                                            name="other"
                                            inputProps={{'aria-label': 'open'}}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Card>
                    </Box>
                    <Box mb={1}>
                        <Typography variant="caption">{t('')}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" m={1} mt={2} pb={2}>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                disabled={updating}
                                startIcon={<SaveIcon/>}
                                onClick={(e) => handleSave(e)}>
                            {t('common.save')}
                        </Button>
                    </Box>
                </>
            }
        </div>
    )
}