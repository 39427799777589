import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { green } from '@mui/material/colors';
import NumberFormat from 'react-number-format';
import Countdown from 'react-countdown';
import EditIcon from '@mui/icons-material/Edit';
import { DocumentsUpload } from '../DocumentsUpload';
import {httpClient} from "../../core/HttpClient";
import {ImageUpload} from "../ImageUpload";
import {InputAdornment, Radio, RadioGroup} from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {MapContainer, Marker, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {isDesktop} from "react-device-detect";
import L from "leaflet";

const useStyles = makeStyles((theme) => ({
    root: {width: '100%'},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        padding:0,
        listStyle: 'none'
    }
}));

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: false,
    closePopupOnClick: true,
    dragging: true,
    zoomSnap: true,
    zoomDelta: true,
    trackResize: true,
    touchZoom: true,
    scrollWheelZoom: false,
};

const redIcon = new L.Icon({
        iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-red.png`,
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

const LocationMarker = (props) => {
    const [position, setPosition] = useState(props.position);
    const [icon, setIcon] = useState(redIcon);
    const markerRef = useRef(null);
    const map = useMap();
    useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend(e) {
            let center = map.getCenter();
            let zoom = map.getZoom();
            setIcon(redIcon);
            setPosition([center.lat, center.lng]);
            props.callback(center.lat, center.lng, zoom);
        }
    });

    return position === null ? null : (
        <Marker icon={icon} position={position} ref={markerRef} ></Marker>
    )
};

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

const OTPFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="######"
    />);

const MemoizedImagesUpload = React.memo(({value, onChange})=>{
    return(<DocumentsUpload value={value} onChange={ onChange } />)
});

const MemoizedImageUpload = React.memo(({name, value, placeholder, onChange})=>{
    return(<ImageUpload name={name} value={ value }
                        fit="fill"
                        aspect={4/3}
                        button={value==null}
                        secure={true}
                        placeholder={placeholder}
                        onChange={ onChange } />)
});

let tempUploadImage = {step: 0, identificationCard: null, shopOverviewPhoto: null};

export default function AccountVerification() {
    const { t } = useTranslation();
    const classes = useStyles();
    const {id} = useParams();
    const clockRef = useRef();
    const history = useHistory();
    const [somethingWrong, setSomethingWrong] = useState(0);
    const [fail, setFail] = useState(0);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [account, setAccount] = useState({_id: '', name:{i18n:{th: '', en:''}}, lat:0,lng:0, latOrigin:0, lngOrigin:0, phone: '', isOwnerOrAdmin: false, actor: ''});
    const [owner, setOwner] = useState({_id: '', displayName: '', firstName: '', lastName: '', mobile: '', status: '', state: 0});
    const [verification, setVerification] = useState({step: 0, identificationCard: null, selfieWithCard: null, shopOverviewPhoto: null});
    const [moreDocuments, setMoreDocuments] = useState([]);
    const [requestOTP, setRequestOTP] = useState({referenceCode: '', otpId: '', otcId: '', otp: ''});
    const [counter, setCounter] = useState(90);
    const [errors, setErrors] = useState([]);
    const [map, setMap] = useState(null);


    useEffect(() => {
        console.log('[AccountVerification]');
        const url = process.env.REACT_APP_API_BASE_URL + '/verifications/account/' + id;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    let _data = res.data;
                    if(_data.verification){
                        setVerification({
                            step: _data.verification.step,
                            identificationCard: _data.verification.identificationCard,
                            selfieWithCard: _data.verification.selfieWithCard,
                            shopOverviewPhoto: _data.verification.shopOverviewPhoto,
                            requireList: _data.verification.requireList,
                            reason: _data.verification.reason
                        });

                        tempUploadImage = {
                            step: _data.verification.step,
                            identificationCard: _data.verification.identificationCard,
                            selfieWithCard: _data.verification.selfieWithCard,
                            shopOverviewPhoto: _data.verification.shopOverviewPhoto,
                            requireList: _data.verification.requireList,
                            reason: _data.verification.reason
                        };
                    }
                    if(_data.account){
                        let _actor = _data.account.actor || '';
                        setAccount({..._data.account, actor: _actor});
                        if(_data.account.owner){
                            setOwner(_data.account.owner);
                        }
                    }

                }
                setLoading(false);
            }).catch(e=>{
                if(e && e.response && e.response.status === 401){
                    console.log(e.response.data.message);
                    setSomethingWrong(1);
                } else {
                    setSomethingWrong(2);
                }
        });
    }, [id]);

    const handleSave = () => {
        let validateList = [];
        if(!account.name.i18n.th){
            validateList.push('name');
        }
        if(!account.address.i18n.th){
            validateList.push('address');
        }
        if(!account.phone){
            validateList.push('phone');
        }

        if(account.phone){
            let _phone = account.phone.replace(/\D/g,'');
            if(_phone.length !== 10 || !_phone.startsWith('0')){
                validateList.push('phone');
            }
        }

        setErrors(validateList);
        if(validateList.length > 0){
            return;
        }

        setUpdating(true);
        let _account = {...account};
        let _owner = {...owner};
        let _step = 1;
        if(verification.step > _step){
            _step = verification.step;
        }

        let data = {
            accountId: id,
            account: _account,
            owner: _owner,
            step: _step,
            verification: {step: _step, identificationCard: verification.identificationCard, selfieWithCard: verification.selfieWithCard,  shopOverviewPhoto: verification.shopOverviewPhoto}
        };

        const url = process.env.REACT_APP_API_BASE_URL + '/verifications/account';
        httpClient.post(url, data)
            .then(res => {
                setUpdating(false);
                if(res.data) {
                    setVerification({...verification, step: res.data.step});
                    tempUploadImage = {...verification, step: res.data.step};
                }
            });
    };

    const handleSaveMap = () => {
        let _account = {...account};
        let _owner = {...owner};

        let _step = 2;
        if(verification.step > _step){
            _step = verification.step;
        }

        let data = {
            accountId: id,
            account: _account,
            owner: _owner,
            step: _step,
            verification: {step: _step, identificationCard: verification.identificationCard, selfieWithCard: verification.selfieWithCard,  shopOverviewPhoto: verification.shopOverviewPhoto}
        };

        const url = process.env.REACT_APP_API_BASE_URL + '/verifications/account';
        httpClient.post(url, data)
            .then(res => {
                setUpdating(false);
                if(res.data) {
                    setVerification({...verification, step: res.data.step});
                    tempUploadImage = {...verification, step: res.data.step};
                }
            });
    }

    const handleSaveDocument = () => {
        let validateList = [];
        if(owner.firstName && owner.firstName.trim().length === 0){
            validateList.push('firstName');
        }
        if(owner.lastName && owner.lastName.trim().length === 0){
            validateList.push('lastName');
        }
        if(!owner.mobile){
            validateList.push('mobile');
        }
        if(owner.mobile){
            let _phone = owner.mobile.replace(/\D/g,'');
            if(_phone.length !== 10 || !_phone.startsWith('0')){
                validateList.push('mobile');
            }
        }

        if(!account.actor){
            validateList.push('actor');
        }

        if(validateList.length > 0){
            setErrors(validateList);
            return;
        }

        setUpdating(true);
        let _step = 3;
        if(verification.step > _step){
            _step = verification.step;
        }
        let _account = {...account};
        let data = {
            accountId: id,
            account: _account,
            owner: owner,
            step: _step,
            verification: {step: _step, identificationCard: verification.identificationCard, selfieWithCard: verification.selfieWithCard, shopOverviewPhoto: verification.shopOverviewPhoto}
        };

        const url = process.env.REACT_APP_API_BASE_URL + '/verifications/account';
        httpClient.post(url, data)
            .then(res => {
                setUpdating(false);
                if(res.data) {
                    setVerification({...verification, step: res.data.step});
                    tempUploadImage = {...verification, step: res.data.step};
                }
            });
    };

    const handleSaveMoreDocument = () => {
        setUpdating(true);
        let data = {
            accountId: id,
            verification: {moreDocuments: moreDocuments}
        };

        const url = process.env.REACT_APP_API_BASE_URL + '/verifications/account';
        httpClient.put(url, data)
            .then(res => {
                setUpdating(false);
                if(res.data){
                    setVerification({...verification, step: res.data.step});
                    tempUploadImage = {...verification, step: res.data.step};
                }
            });
    };

    const handleChange = ({ target }) => {
        setAccount({ ...account, [target.name]: target.value });
    };

    const handleOwnerChange = ({ target }) => {
        setOwner({ ...owner, [target.name]: target.value });
    };

    const handleOTPChange = ({ target }) => {
        setRequestOTP({ ...requestOTP, [target.name]: target.value });
    };

    const handleVerifyOTP = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/verifications/account/'+id+'/otp';
        setUpdating(true);
        httpClient.post(url, requestOTP)
            .then(res => {
                if(res.data){
                    if(res.data.status === 202){
                        setFail(3);
                    }else{
                        setVerification({...verification, step: res.data.step});
                        tempUploadImage = {...verification, step: res.data.step};
                    }
                }
                setUpdating(false);
            }).catch(e=>{
            setFail(3);
        });
    };

    const handleRequestOTP = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/verifications/account/'+id+'/otp';
        setUpdating(true);
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    if(res.data.status === 202){
                        setFail(2);
                    }else{
                        setRequestOTP({
                            referenceCode: res.data.referenceCode,
                            otpId: res.data.otpId,
                            otcId: res.data.otcId,
                            otp: ''
                        });
                    }

                }
                setUpdating(false);
            }).catch(e=>{
                setFail(2);
                setUpdating(false);
            });
    };

    const handleMapChange = (lat, lng, zoom) => {
        if(map){
            setAccount({...account,zoom: zoom, lat: lat, lng: lng});
        }
    };

    const handleOTPStart = () => {
        console.log('handleOTPStart');
    };

    const handleOTPTick = () => {
        console.log('handleOTPTick');
        let c = counter;
        setCounter(--c);
        if(c===0){
            setCounter(90);
            setRequestOTP({referenceCode: '', otpId: '', otcId: '', otp: ''});
        }
    };

    const handleUploadChange = ({ target }) => {
        if(target && target.name && target.value){
            tempUploadImage[target.name] = target.value;
            setVerification({...tempUploadImage});
        }
    };

    const handleUploadMoreDocumentChange = ({ target }) => {
        if(target && target.value && target.value[0]){
            let _documents = target.value;
            setMoreDocuments(_documents);
        }
    };

    const handleI18nChange = ({ target }) => {
        let value = {
            i18n: {th: target.value, en: target.value}
        };
        setAccount({ ...account, [target.name]: value });
    };

    const handleStepChange = (step) => {
        if(verification.step <= 3){
            if(verification.step > step){
                setVerification({...verification, step: step});
                tempUploadImage = {...verification, step: step};
            }
        }
    };

    const handleClose = () => {
        setFail(0);
    };

    const handleBack = () => {
        history.push('/account/'+id);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" m={1}>
                <IconButton edge="start" color="primary" aria-label="black" onClick={handleBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">
                    {t('verification.title')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px', 'marginBottom': '10px'}} />
            {somethingWrong === 0 &&
            <Box m={1}>
                {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
                }
                {!loading &&
                <Box mt={2} mb={2}>
                    {!account.isOwnerOrAdmin &&
                    <Alert severity="warning">
                        {t('verification.noPermission')}
                    </Alert>
                    }
                    {account.isOwnerOrAdmin &&
                    <Card>
                        {[7,9].indexOf(verification.step) === -1  &&
                        <Box mt={2}>
                            <Stepper alternativeLabel activeStep={verification.step}>
                                <Step key="documents" onClick={e => handleStepChange(0)}>
                                    <StepLabel>{t('verification.step0.subject')}</StepLabel>
                                </Step>
                                <Step key="location" onClick={e => handleStepChange(1)}>
                                    <StepLabel>{t('verification.step1.subject')}</StepLabel>
                                </Step>
                                <Step key="mobile" onClick={e => handleStepChange(2)}>
                                    <StepLabel>{t('verification.step2.subject')}</StepLabel>
                                </Step>
                                <Step key="pending" onClick={e => handleStepChange(3)}>
                                    <StepLabel>{t('verification.step3.subject')}</StepLabel>
                                </Step>
                            </Stepper>
                        </Box>
                        }
                        {verification.step === 0 &&
                        <Box m={2}>
                            <Typography variant="h6">
                                {t('verification.step0.description')}
                            </Typography>
                            <Box>
                                <Box mt={2}>
                                    <TextField label={t('shop.name')} variant="outlined" fullWidth={true}
                                               name="name" value={account.name.i18n['th']}
                                               onChange={handleI18nChange} required
                                               error={errors.indexOf('name') > -1}/>
                                </Box>
                                <Box mt={2}>
                                    <TextField label={t('shop.address')} variant="outlined" fullWidth={true}
                                               name="address" value={account.address.i18n['th']}
                                               multiline rows={2}
                                               onChange={handleI18nChange} required
                                               error={errors.indexOf('address') > -1}/>
                                </Box>
                                <Box mt={2}>
                                    <TextField id="account-phone"
                                               label={t('shop.mobile')}
                                               variant="outlined"
                                               fullWidth={true}
                                               name="phone" type="tel"
                                               value={account.phone}
                                               onChange={handleChange}
                                               InputProps={{
                                                   inputComponent: PhoneFormatCustom,
                                               }}
                                               error={errors.indexOf('phone') > -1}
                                               required/>
                                </Box>
                                <Box mt={1}>
                                    <Typography variant="caption">
                                        *{t('verification.step1.detail1')}
                                    </Typography>
                                </Box>
                                <Box pt={2} display="flex" justifyContent="flex-end">
                                    <Button variant="contained"
                                            color="primary"
                                            disableElevation
                                            size="large"
                                            fullWidth={true}
                                            disabled={updating}
                                            onClick={(e) => handleSave(e)}>
                                        {t('common.next')}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        }
                        {verification.step === 1 &&
                            <Box m={2}>
                                <Typography variant="h6">
                                    {t('verification.step1.description')}
                                </Typography>
                                <Box mt={2} style={{height: `300px`}}>
                                    {!account.lat &&
                                        <Box display="flex" justifyContent="center" mt={4}>
                                            <CircularProgress size={20}/>
                                        </Box>
                                    }
                                    {account.lat &&
                                        <MapContainer center={[account.lat, account.lng]}
                                                      zoom={account.zoom ? account.zoom : 15}
                                                      {...interactionOptions}
                                                      ref={setMap}
                                                      scrollWheelZoom={false}>
                                            <TileLayer
                                                attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                            <LocationMarker effectOn={this}
                                                            position={[account.lat, account.lng]}
                                                            callback={handleMapChange}/>
                                        </MapContainer>
                                    }
                                </Box>
                                <Box mt={1}>
                                    <Typography variant="caption">
                                        *{t('verification.step1.detail1')}
                                    </Typography>
                                </Box>
                                <Box pt={2} display="flex" justifyContent="space-between">
                                    <Button variant="contained"
                                            disableElevation
                                            size="large"
                                            fullWidth={true}
                                            color={"grey"}
                                            onClick={(e) => handleStepChange(0)}>
                                        {t('common.previous')}
                                    </Button>
                                    <div>&nbsp;</div>
                                    <Button variant="contained"
                                            color="primary"
                                            disableElevation
                                            size="large"
                                            fullWidth={true}
                                            onClick={(e) => handleSaveMap(e)}>
                                        {t('common.next')}
                                    </Button>
                                </Box>
                            </Box>
                        }
                        {verification.step === 2 &&
                        <Box m={2}>
                            <Typography variant="h6">
                                {t('verification.step2.description')}
                            </Typography>
                            <Box mt={1}>
                                <Typography variant="body1">
                                    1. {t('verification.step2.detail1')}
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Box mt={1}>
                                    <Box mt={2}>
                                        <TextField id="profile-firstName"
                                                   label={t('profile.firstName')}
                                                   variant="outlined" fullWidth={true}
                                                   name="firstName" value={ owner.firstName }
                                                   error={errors.indexOf('firstName') > -1}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   InputProps={{
                                                       readOnly: owner.status !== 'unverified',
                                                       endAdornment: owner.status !== 'unverified'?(
                                                           <InputAdornment position="end">
                                                               <VerifiedIcon color={owner.status === 'verified'?"info":"default"} />
                                                           </InputAdornment>
                                                       ):null,
                                                   }}
                                                   onChange={ handleOwnerChange } required />
                                    </Box>
                                    <Box mt={2}>
                                        <TextField id="profile-lastName"
                                                   label={t('profile.lastName')}
                                                   variant="outlined" fullWidth={true}
                                                   name="lastName" value={ owner.lastName }
                                                   error={errors.indexOf('lastName') > -1}
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   InputProps={{
                                                       readOnly: owner.status !== 'unverified',
                                                       endAdornment: owner.status !== 'unverified'?(
                                                           <InputAdornment position="end">
                                                               <VerifiedIcon color={owner.status === 'verified'?"info":"default"} />
                                                           </InputAdornment>
                                                       ):null,
                                                   }}
                                                   onChange={ handleOwnerChange } required />
                                    </Box>
                                    <Box mt={2} mb={2}>
                                        <TextField id="verification-mobile"
                                                   label={t('verification.mobile')}
                                                   variant="outlined"
                                                   fullWidth={true}
                                                   name="mobile" type="tel"
                                                   value={owner.mobile}
                                                   error={errors.indexOf('mobile') > -1}
                                                   onChange={ handleOwnerChange }
                                                   InputLabelProps={{
                                                       shrink: true,
                                                   }}
                                                   InputProps={{
                                                       inputComponent: PhoneFormatCustom,
                                                       readOnly: owner.status !== 'unverified',
                                                       endAdornment: owner.status !== 'unverified'?(
                                                           <InputAdornment position="end">
                                                               <VerifiedIcon color={owner.status === 'verified'?"info":"default"} />
                                                           </InputAdornment>
                                                       ):null,
                                                   }}
                                                   required />
                                    </Box>
                                    <Box mt={1}>
                                        <FormControl fullWidth variant="outlined">
                                            <fieldset style={{border: errors.indexOf('actor')>-1?'1px solid red':'1px solid rgba(0, 0, 0, 0.23)',
                                                paddingTop: '0px',
                                                borderRadius: '4px',
                                                fontSize: '12px',
                                                color: 'rgba(0, 0, 0, 0.6)'
                                            }}>
                                                <legend>{t('verification.actor.title')}*</legend>
                                                <Box>
                                                    <RadioGroup
                                                        aria-labelledby="ordering"
                                                        name="actor"
                                                        value={account.actor}
                                                        onChange={handleChange}
                                                    >
                                                        <FormControlLabel value="OWNER" control={<Radio />} label={t('verification.actor.owner')} />
                                                        <FormControlLabel value="DEALER" control={<Radio />} label={t('verification.actor.dealer')} />
                                                    </RadioGroup>
                                                </Box>
                                            </fieldset>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                            <Box mt={1}>
                                <Typography variant="body1">
                                    2. {t('verification.step2.detail2')}
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Box mt={1}>
                                    <MemoizedImageUpload name="identificationCard" value={verification.identificationCard} placeholder="example_id_card.png" onChange={handleUploadChange}/>
                                </Box>
                            </Box>
                            <Box mt={1}>
                                <Typography variant="body1">
                                    3. {t('verification.step2.detail3')}
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Box mt={1}>
                                    <MemoizedImageUpload name="selfieWithCard" value={verification.selfieWithCard} placeholder="example_selfie_id_card.png" onChange={handleUploadChange}/>
                                </Box>
                            </Box>
                            <Box mt={3}>
                                <Typography variant="body1">
                                    4. {t('verification.step2.detail4')}
                                </Typography>
                            </Box>
                            <Box mt={1}>
                                <Box mt={1}>
                                    <MemoizedImageUpload name="shopOverviewPhoto" value={verification.shopOverviewPhoto} placeholder="example_shop.png" onChange={handleUploadChange}/>
                                </Box>
                            </Box>
                            <Box mt={2} pt={2} display="flex" justifyContent="space-between">
                                <Button variant="contained"
                                        disableElevation
                                        size="large"
                                        fullWidth={true}
                                        color={"grey"}
                                        onClick={(e) => handleStepChange(0)}>
                                    {t('common.previous')}
                                </Button>
                                <div>&nbsp;</div>
                                <Button variant="contained"
                                        color="primary"
                                        disableElevation
                                        size="large"
                                        fullWidth={true}
                                        disabled={verification.identificationCard==null
                                            || verification.selfieWithCard==null
                                            || verification.shopOverviewPhoto==null
                                            || owner.firstName.trim().length === 0
                                            || owner.lastName.trim().length === 0
                                            || owner.mobile.replace(/\D/g,'').length !== 10
                                            || ['OWNER','DEALER'].indexOf(account.actor) < 0
                                            || updating}
                                        onClick={(e) => handleSaveDocument(e)}>
                                    {t('common.next')}
                                </Button>
                            </Box>
                        </Box>
                        }
                        {verification.step === 3 &&
                        <Box m={2}>
                            <Typography variant="h6">
                                {t('verification.step3.description')}
                            </Typography>
                            <Box mt={1}>
                                <Typography variant="caption">
                                    {t('verification.step3.detail1')}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Box pt={2} display="flex" alignItems="center">
                                    <Box display="flex" alignItems="center">
                                        <Typography>
                                            {t('verification.otpRequest')}
                                        </Typography>
                                        <Box ml={1}>
                                            <Typography color="primary" onClick={owner.status === 'unverified'?e => handleStepChange(2):null}>
                                                <NumberFormat value={owner.mobile} displayType={'text'}
                                                              format="###-###-####"/>
                                                {owner.status === 'unverified' &&
                                                <EditIcon fontSize={"small"} sx={{marginLeft: '3px'}} />
                                                }
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                {!requestOTP.referenceCode &&
                                <>
                                    <Box mt={1}>
                                        <Button
                                            variant="outlined"
                                            disableElevation
                                            color="default"
                                            component="span"
                                            fullWidth={true}
                                            disabled={updating}
                                            onClick={(e) => handleRequestOTP(e)}>
                                            {t('common.otpRequest')}
                                        </Button>
                                    </Box>
                                    <Box mt={1}>
                                        <Typography variant="caption">
                                            * {t('verification.step3.detail2')}
                                        </Typography>
                                    </Box>
                                </>
                                }
                            </Box>
                            {requestOTP.referenceCode &&
                            <Box pt={2}>
                                <Box mb={1} style={{color: green[500]}}>
                                    <Countdown
                                        ref={clockRef}
                                        date={Date.now() + 1000 * 90}
                                        onStart={handleOTPStart}
                                        onTick={handleOTPTick}
                                        renderer={props => <span>{t('verification.countDown', {counter: counter})}</span>}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <TextField id="otp"
                                               variant="outlined"
                                               fullWidth={true}
                                               name="otp" type="tel"
                                               value={requestOTP.otp}
                                               onChange={handleOTPChange}
                                               error={fail===2}
                                               InputProps={{
                                                   inputComponent: OTPFormatCustom,
                                               }}
                                               required/>
                                </Box>
                                <Button variant="contained"
                                        color="primary"
                                        disableElevation
                                        size="large"
                                        fullWidth={true}
                                        disabled={requestOTP.otp.length !== 6 || updating}
                                        onClick={handleVerifyOTP}>
                                    {t('common.confirm')}
                                </Button>
                            </Box>
                            }
                        </Box>
                        }
                        {verification.step === 4 &&
                        <Box p={2}>
                            <Alert severity="info">
                                {t('verification.completed')}
                            </Alert>
                        </Box>
                        }
                        {verification.step === 6 &&
                        <Box p={2}>
                            {verification.requireList &&
                            <>
                                <Alert severity="warning">
                                    <Box>{t('verification.request')}</Box>
                                    <Box>{t('verification.reason')}: {t(`verification.reasonList.${verification.reason}`)}</Box>
                                    <ul className={classes.list}>
                                        {
                                            verification.requireList.map((item,index) => (
                                                <li key={'require-'+index}>{index+1}. {t(`verification.requireList.${item}`)}</li>
                                            ))
                                        }
                                    </ul>
                                </Alert>
                                <Box mt={2}>
                                    <MemoizedImagesUpload value={moreDocuments} onChange={handleUploadMoreDocumentChange} />
                                </Box>
                                <Box pt={2}>
                                    <Button variant="contained"
                                            color="primary"
                                            disableElevation
                                            size="large"
                                            fullWidth={true}
                                            disabled={moreDocuments.length < verification.requireList.length || updating}
                                            onClick={(e) => handleSaveMoreDocument()}>
                                        {t('common.sentDocument')}
                                    </Button>
                                </Box>
                                <Box mt={1}>
                                    <Typography variant="caption">
                                        * {t('verification.moreDocuments')}
                                    </Typography>
                                </Box>
                            </>
                            }
                        </Box>
                        }
                        {verification.step === 7 &&
                        <Box p={2}>
                            <Alert severity="error">
                                {t('verification.rejected')}
                            </Alert>
                        </Box>
                        }
                        {verification.step === 9 &&
                        <Box p={2}>
                            <Box display="flex" justifyContent="center" m={2}>
                                <VerifiedIcon sx={{ fontSize: 80 }} color={"info"} />
                            </Box>
                            <Alert severity="success">
                                {t('verification.approved')}
                            </Alert>
                        </Box>
                        }
                    </Card>
                    }
                </Box>
                }
                </Box>
            }
            {somethingWrong > 0 &&
                <Box m={1}>
                    {somethingWrong === 1 && <Alert severity="warning">{t('verification.noPermission')}</Alert>}
                    {somethingWrong === 2 && <Alert severity="error">{t('common.message.somethingWrong')}</Alert>}
                </Box>
            }
            <Snackbar open={fail>0}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert variant="filled" severity="error">
                    {fail === 1 && t('common.message.geoLocationFail')}
                    {fail === 2 && t('verification.otpFail')}
                    {fail === 3 && t('verification.otpInvalid')}
                </Alert>
            </Snackbar>
        </div>
    )
}