import React, { useRef } from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import {MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './style.css';
import {TransitionProps} from "@mui/material/transitions";
import L from "leaflet";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const redIcon = new L.Icon({
    iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-red.png`,
    iconSize: [21, 34],
    iconAnchor: [10, 34]
});

const LocationMarker = (props) => {
    const markerRef = useRef(null);

    return props.position === null ? null : (
        <Marker icon={redIcon} position={props.position} ref={markerRef} ></Marker>

    )
};

export default function AddressDeliveryDialog({open, location, onClose}) {
    const { t } = useTranslation();
    console.log('[AddressOriginDialog]');

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={onClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('store.address')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MapContainer center={[location.lat, location.lng]}
                          zoom={16}
                          doubleClickZoom={false}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker effectOn={this}  position={[location.lat, location.lng]} />
            </MapContainer>
        </Dialog>
    )
};