import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ClockPicker } from '@mui/x-date-pickers/ClockPicker';
import dateFormat from 'dateformat';
import './style.css';
const { Box, Button, Dialog, DialogActions, Grid } = require('@mui/material');


const useStyles = makeStyles((theme) => ({
    fieldSet: {
        '&:hover': {
            borderColor: "rgba(0, 0, 0, 0.87)"
        }
    }
}));

export function TimeSelection({id, name, label, value, index, onChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [state, setState] = useState(new Date());
    const [newState, setNewState] = useState(new Date());

    useEffect(() => {
        console.log('[TimeSelection]');
        const date = new Date();
        date.setHours(value.hour, value.minute);
        setState(date);
        setNewState(date);
    },[value]);

    const handleOpen = () => {
        const date = new Date();
        date.setHours(state.getHours(), state.getMinutes());
        setState(date);
        setNewState(date);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        const data = {hour: newState.getHours(), minute: newState.getMinutes()};
        const date = new Date();
        date.setHours(data.hour, data.minute);
        setState(date);
        onChange({target: {name: name, value: data, index: index}});
        setOpen(false);
    };

    return(
        <div>
            <div className="time-selection full-width" onClick={ handleOpen }>
                <label className="time-selection-label" htmlFor="hour" id="closeTime-label">{label}</label>
                <div className="time-selection-content" id="hour">
                    <Box
                        display="flex"
                        alignItems="center">
                        <AccessTimeIcon></AccessTimeIcon>
                        <span style={{'marginLeft': '5px'}}>{ dateFormat(state, "HH:MM")}</span>
                    </Box>
                </div>
                <fieldset aria-hidden="true" className={`${ classes.fieldSet } time-selection-fieldset`}>
                    <legend className="time-selection-legend">
                        <span>{label}</span>
                    </legend>
                </fieldset>
            </div>
            <Dialog
                onClose={ handleClose }
                maxWidth='xs'
                scroll="body"
                PaperProps={{
                    style: {
                        margin: 0,
                        maxWidth: 'none',
                    },
                }}
                open={ open }>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ClockPicker date={newState} onChange={(newDate) => setNewState(newDate)} />
                </LocalizationProvider>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button onClick={ handleSave } fullWidth color='primary' variant="outlined"
                                    size="large">
                                { dateFormat(newState, "HH:MM")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={ handleClose } fullWidth variant="outlined"
                                    size="large">
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>)
}