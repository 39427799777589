import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import {useParams, useHistory, useLocation} from "react-router-dom";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import NumberFormat from 'react-number-format';
import {DateI18n} from "../../DateI18n";
import {httpClient} from "../../../core/HttpClient";
import {getDatabase, goOffline, goOnline, onValue, ref} from "firebase/database";
import {getAuth, signInAnonymously} from "firebase/auth";
import {useIdleTimer} from "react-idle-timer";
import {getDatabaseInstance} from "../../../core/Firebase";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function ConfirmPayment() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const search = useLocation().search;
    const { id } = useParams();
    const date = new Date();
    const qty = new URLSearchParams(search).get('qty');
    const type = new URLSearchParams(search).get('type');
    const ads = new URLSearchParams(search).get('ads');
    const [qrCodeData, setQrCodeData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [paymentComplete, setPaymentComplete] = useState(false);

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            if(document.visibilityState === 'hidden'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    useEffect(() => {
        console.log('[ConfirmPayment]');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/address/'+ads;
        httpClient.get(url)
            .then(res => {
                let address = res.data;
                let data = {
                    productCode: id,
                    address: address,
                    qty: qty,
                    type: type,
                };
                const urlPayment = process.env.REACT_APP_API_BASE_URL + '/users/products/confirm';
                httpClient.post(urlPayment, data)
                    .then(res => {
                        if(res.data.data){
                            setQrCodeData({orderNo: res.data.orderNo, amount: res.data.amount, qrcodeId: res.data.data.qrcodeId,  base64:res.data.data.qrImage});
                            let key = res.data.uid;
                            let app = getDatabaseInstance();
                            const db = getDatabase(app);
                            const auth = getAuth(app);
                            signInAnonymously(auth)
                                .then(() => {
                                const statusRef = ref(db, 'payments/' + key);
                                onValue(statusRef, (snapshot) => {
                                    const data = snapshot.val();
                                    if(data && data.status === 'success'){
                                        setPaymentComplete(true);
                                    }
                                });
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                        }
                        setLoading(false);
                    });
            });
    }, [id, qty, type, ads]);


    const handleCancel = () => {
        history.push(`/store/address/${id}?qty=${qty}&type=${type}&ads=${ads}`);
    };

    const handleHistory = () => {
        history.push('/invoices');
    };

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" m={1}>
                <IconButton edge="start" color="primary" aria-label="black" onClick={handleCancel}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">
                    {t('store.confirmPayment')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            <Box m={1}>
            {paymentComplete &&
            <Box mt={2}>
                <Alert severity="success">{t('payment.complete')}</Alert>
                <Box mt={2}>
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            fullWidth={true}
                            disableElevation
                            disabled={loading}
                            onClick={handleHistory} >
                        {t('store.viewHistory')}
                    </Button>
                </Box>
            </Box>
            }
            {!paymentComplete &&
            <Box>
                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                    <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/promptpay_visa_master.png`} alt="payment network"
                         style={{width: '100%', maxWidth: '200px'}}/>
                </Box>
                {!qrCodeData &&
                <Box display="flex" justifyContent="center" mt={3} mb={4}>
                    <CircularProgress size={20}/>
                </Box>
                }
                {qrCodeData &&
                <>
                    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                        <img src={`data:image/png;base64,${qrCodeData.base64}`} alt="pay"
                             style={{width: '100%', maxWidth: '250px'}}/>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                        <Typography variant="body1" color="inherit">{qrCodeData.qrcodeId}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1"
                                    color="inherit">{t('pricing.totalAmount')}</Typography>
                        <Typography variant="body1"
                                    color="inherit"><NumberFormat value={qrCodeData.amount} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                    </Box>
                    <hr/>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" color="inherit">{t(`pricing.ref1`)}</Typography>
                        <div>{qrCodeData && <span>{qrCodeData.orderNo}</span>}</div>
                    </Box>
                    <hr/>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="body1" color="inherit">{t(`pricing.paymentDate`)}</Typography>
                        <div><DateI18n value={date} format="DD MMM YYYY HH:mm" /></div>
                    </Box>
                </>
                }
                <Box mt={2} pb={2} style={{width: '100%'}}>
                    <Button variant="contained"
                            color="grey"
                            size="large"
                            fullWidth={true}
                            disableElevation
                            disabled={loading}
                            onClick={handleCancel} >
                        {t('common.cancel')}
                    </Button>
                </Box>
            </Box>
            }
            </Box>
        </div>
    )
}