import React from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import {DateI18n} from '../DateI18n';
import Typography from "@mui/material/Typography";
import DeliverIcon from "../Deliver/DeliverIcon";
import OrderIcon from "../OrderIcon";
import UpdateIcon from '@mui/icons-material/Update';
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";


export default function OrderInfo({value}) {
    const { t } = useTranslation();
    return (
        <Box>
            <Card>
                <CardContent>
                    {value.queueNo > 0 &&
                    <Box display="flex" justifyContent="center">
                        <Box mb={2}>
                            <Typography variant={"h5"}>{t('order.queue')} {value.queueNo}</Typography>
                        </Box>
                    </Box>
                    }
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            {t('order.number')}
                        </Box>
                        <Box>
                            {value.transactionNo}
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="space-between">
                        <Box>
                            {t('order.date')}
                        </Box>
                        <Box>
                            <DateI18n value={value.orderDate} format="DD MMM YYYY HH:mm" />
                        </Box>
                    </Box>
                    {value.orderType &&
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                {t('ordering.orderChannel')}
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <OrderIcon foodOrder={value} color={"default"} />
                                {value.tableNumber > 0 && <Box ml={1}>{t(`ordering.channel.table`)} {value.tableNumber}</Box>}
                                {value.tableNumber === 0 && <Box ml={1}>{t(`ordering.channel.${value.orderType}`)}</Box>}
                            </Box>
                        </Box>
                    }
                    {value.deliverType &&
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Box>
                                {t('deliver.method')}
                            </Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                <DeliverIcon foodOrder={value} color={"default"} />
                                <Box ml={1}>{t(value.deliverType)}</Box>
                            </Box>
                        </Box>
                    }
                    {value.preorder &&
                    <Box mt={1}>
                        <Card variant={"outlined"}>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemAvatar>
                                        <UpdateIcon color={"default"} />
                                    </ListItemAvatar>
                                    <ListItemText primary={
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography>
                                                {t('order.preorder')}
                                            </Typography>
                                            <Typography>
                                                <DateI18n value={value.deliverDateTime} format="DD MMM YYYY" />
                                            </Typography>
                                        </Box>
                                        }
                                        secondaryTypographyProps={{ component: 'div' }}
                                        secondary={
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Typography>
                                                {t('deliver.deliverDate')}
                                            </Typography>
                                            <Typography>
                                                <DateI18n value={value.deliverDateTime} format="HH:mm" />
                                            </Typography>
                                        </Box>
                                        }>
                                    </ListItemText>
                                </ListItem>
                            </List>
                        </Card>
                    </Box>
                    }
                </CardContent>
            </Card>
        </Box>
    );
}
