import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NumberFormat from 'react-number-format';
import L from 'leaflet';
import {MapContainer, TileLayer, Marker, useMapEvents, useMap} from 'react-leaflet'
import {isDesktop} from 'react-device-detect';
import ShopConfirm from "../ShopConfirm";
import {DateI18n} from "../DateI18n";
import {httpClient} from "../../core/HttpClient";
import { getDatabase, ref, onValue, goOnline, goOffline} from "firebase/database";
import 'leaflet/dist/leaflet.css';
import './style.css'
import Alert from "@mui/material/Alert";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import Snackbar from "@mui/material/Snackbar";
import {LoadingButton} from "@mui/lab";
import {getAuth, signInAnonymously} from "firebase/auth";
import Dialog from "@mui/material/Dialog";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useIdleTimer } from 'react-idle-timer';
import {getDatabaseInstance} from "../../core/Firebase";

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: false,
    closePopupOnClick: true,
    dragging: true,
    zoomSnap: true,
    zoomDelta: true,
    trackResize: true,
    touchZoom: true,
    scrollWheelZoom: false,
};

const blueIcon = new L.Icon({
        iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-blue.png`,
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    }),
    redIcon = new L.Icon({
        iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-red.png`,
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    tableHead: {
        border: '4px solid #fafafa',
        width: '33%',
        minWidth: '105px',
        background: '#e0e0e0',
        fontWeight: 'bold',
        lineHeight: '1'
    },
    tableHeadSelected: {
        border: '4px solid #fafafa',
        width: '33%',
        minWidth: '105px',
        color: '#fff',
        background: '#ffa600',
        fontWeight: 'bold',
        lineHeight: '1'
    },
    tableContent: {},
    tableContentSelected: {
        color: '#ffa600',
        fontWeight: 'bold'
    },
    tableLastContent: {
        paddingTop: '7px',
        borderBottom: "none",
        paddingBottom: 0
    },
    tableTitle: {
        borderBottom: "none",
        paddingBottom: 0,
        fontWeight: 'bold'
    },
    tableButton: {
        border: '4px solid #fafafa',
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none"
    },
    buttonSuccess: {
        backgroundColor: theme.palette.success.main,
         color: theme.palette.success.contrastText,
        "&:hover":{
            backgroundColor: theme.palette.success.dark
        }
    },
    buttonOrange: {
        backgroundColor: '#ffa600',
        color: '#ffffff',
        "&:hover":{
            backgroundColor: '#ffa600'
        }
    },
    buttonNormal: {

    },
    footerBar: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        padding: '15px'
    },
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        padding: theme.spacing(2, 4, 3),
    }
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#44cc00',
            contrastText: '#ffffff',
        },
    },
});

function getModalStyle() {
    const top = 46;
    const left = 46;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey["300"],
        color: theme.palette.common.black,
        fontWeight: 'bold',
        padding: 8
    },
}));

const LocationMarker = (props) => {
    const [position, setPosition] = useState(props.position);
    const [icon, setIcon] = useState(props.ood?blueIcon:redIcon);
    const markerRef = useRef(null);
    const map = useMap();
    useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend(e) {
            let center = map.getCenter();
            let zoom = map.getZoom();
            setIcon(redIcon);
            setPosition([center.lat, center.lng]);
            props.callback(center.lat, center.lng, zoom);
        }
    });

    return position === null ? null : (
        <Marker icon={icon} position={position} ref={markerRef} ></Marker>
    )
};

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

function renewAccountToken(){

    console.log('renewAccountToken');
    let token = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    if(token && refreshToken){
        const url = process.env.REACT_APP_API_BASE_URL + '/auth/line/refresh-token';
        const payload = {
            token: token,
            refreshToken: refreshToken,
            renew: true
        };
        return httpClient.post(url, payload).then(res => {
            let data = res.data;
            if(data && data.token && data.refreshToken){
                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken);
                window.location.href = '/home';
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
            }
        }).catch(e=>{
            console.log('e', e);
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login';
        });
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
    }
}

export default function AccountNew() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [loading, setLoading] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [state, setState] = useState({step:1});
    const [profile, setProfile] = useState({displayName: '', firstName: '', lastName: '', birthday: '', mobile: ''});
    const [temp, setTemp] = useState({name: '', address: '', phone: '', type: 'RESTAURANT'});
    const [position, setPosition] = useState([13.721163782, 100.523664572]); // Default Bangkok
    const [map, setMap] = useState(null);
    const [notifyError, setNotifyError] = useState(0);
    const [error, setError] = useState({phone: false});
    const [openPayment, setOpenPayment] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [qrCodeData, setQrCodeData] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [paymentCompleteLoading, setPaymentCompleteLoading] = useState(false);
    const [payment, setPayment] = useState({date: new Date(), price: 0});
    const [trial, setTrial] = useState(false);
    const tick = useRef();

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            if(document.visibilityState === 'hidden'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    useEffect(() => {
        console.log('[AccountNew]');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/me';
        httpClient.get(url)
            .then(res => {
                let data = res.data;
                if (data) {
                    if (data.countAccountCreated === 0) {
                        setTrial(true);
                    }

                    let _profile = {displayName: data.displayName || ''
                        , firstName: data.firstName || ''
                        , lastName: data.lastName || ''
                        , birthday: data.birthday || ''
                        , mobile: data.mobile || ''};

                    setProfile(_profile);
                }
            });
    },[]);

    const handleTempChange = ({ target }) => {
        setTemp({...temp,  [target.name]: target.value});
    };

    const handleMapChange = (lat, lng, zoom) => {
        if(map){
            setTemp({...temp,zoom: zoom, coordinates: {lat: lat, lng: lng}});
        }
    };

    const handlePrevious = (previous) => {
        setState({step: previous, acceptAgreement: true});
    };

    const handleClosePayment = () => {
        setOpenPayment(false);
    };

    const handleSelectPlan = (plan) => {
        setTemp({...temp, plan: plan});
    };

    const handleClosePaymentComplete = () => {
        setPaymentCompleteLoading(true);
        renewAccountToken().catch(e=>{
            console.log('e', e);
        });
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNotifyError(0);
    };

    const handleSaveTemp = (next) => {
        if(_validation()) {
            setError({phone: false});
            const url = process.env.REACT_APP_API_BASE_URL + '/users/me/temp';
            setLoading(true);
            httpClient.post(url, temp)
                .then(res => {
                    if(res && res.status === 200){
                            setState({step: next, acceptAgreement: true});
                            setLoading(false);
                        if(!temp.coordinates){
                            try{
                                let processTimer = 0;
                                let processTimout = false;
                                tick.current = setInterval(() => {
                                    processTimer++;
                                    if(processTimer === 9){
                                        console.log('process timout');
                                        processTimout = true;
                                        setShowMap(true);
                                        setNotifyError(1);
                                        clearInterval(tick.current);
                                    }
                                    if(processTimer === 1) {
                                        navigator.geolocation.getCurrentPosition(result => {
                                            if (!processTimout) {
                                                const coordinates = result.coords;
                                                setPosition([coordinates.latitude, coordinates.longitude]);
                                                setTemp({
                                                    ...temp,
                                                    coordinates: {lat: coordinates.latitude, lng: coordinates.longitude}
                                                });
                                                setShowMap(true);
                                            }
                                            clearInterval(tick.current);
                                        }, function (positionError) {
                                            if (!processTimout) {
                                                setShowMap(true);
                                                setNotifyError(1);
                                            }
                                            clearInterval(tick.current);
                                        }, {enableHighAccuracy: true, maximumAge: 10000, timeout: 8000});
                                    }
                                }, 1000);
                            } catch(err){
                                // done
                                console.log('err',err);
                            }
                        }
                    } else {
                        if(res.data && res.data.code === 'DTA_011'){
                            setNotifyError(2);
                        }
                        if(res.data && res.data.code === 'DTA_012'){
                            setNotifyError(3);
                        }
                        setLoading(false);
                    }

                });
        }
    };

    const handleSubmit = () => {
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/temp';
        httpClient.post(url, temp).then(res => {
            if(res.status !== 200) {
                return Promise.reject(res);
            }

            renewAccountToken().catch(e=>{});
        }).catch(e=>{
            console.log('e', e);
        });
    };

    const handleConfirm = () => {
        if(trial){
            handleSubmit();
        } else {
            setPayment({date: new Date(), price: 0});
            setQrCodeData(null);
            setOpenPayment(true);
            let app = getDatabaseInstance();
            const db = getDatabase(app);
            const auth = getAuth(app);
            signInAnonymously(auth)
                .then(() => {
                    console.log('Populate QR Data');
                    const url = process.env.REACT_APP_API_BASE_URL + '/users/me/temp/qr';
                    return httpClient.post(url, {plan: temp.plan});
                }).then(res => {
                    console.log('Set QR Data');
                    if (res.data.data) {
                        setQrCodeData({
                            orderNo: res.data.orderNo,
                            amount: res.data.amount,
                            qrcodeId: res.data.data.qrcodeId,
                            base64: res.data.data.qrImage
                        });
                    }
                    let key = res.data.uid;
                    console.log('key', key);
                    const statusRef = ref(db, 'payments/' + key);
                    onValue(statusRef, (snapshot) => {
                        const data = snapshot.val();
                        if(data && data.status === 'success'){
                            setPaymentComplete(true);
                        }
                    });

                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const validation = () => {
        console.log('validation');
        let _phone = null;
        if(temp.phone){
            _phone = temp.phone.replace(/\D/g,'');
        }
        if(temp.name.trim() && temp.address.trim() &&
            _phone && _phone.length === 10){
            return false;
        }
        return true;
    };

    function _validation() {
        let result = true;
        let error = {phone: false};
        let _phone = null;
        if(temp.phone){
            _phone = temp.phone.replace(/\D/g,'');
        }
        if(!_phone ||
            _phone.length !== 10 ||
            !_phone.startsWith('0')
        ){
            result = false;
            error.phone = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    const handleBack = () => {
        history.push('/accounts');
    };

    return (
        <div>
            {/*<Box>*/}
            {/*    <Button onClick={handleTestOnline}>Online</Button>*/}
            {/*    <Button onClick={handleTestOffline}>Offline</Button>*/}
            {/*</Box>*/}
            {state.step === 1 &&
                <Box>
                    <form noValidate autoComplete="off">
                        <Box display="flex" alignItems="center" m={1}>
                            <IconButton edge="start" color="primary" aria-label="black" onClick={handleBack}
                                        className={classes.link}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {t('shop.create')}
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '10px'}} />
                        <Box m={1}>
                        <Card elevation={1}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField id="outlined-basic" label={t('shop.name')} variant="outlined" fullWidth={true}
                                                   name="name" value={ temp.name } onChange={ handleTempChange } required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="outlined-basic" label={t('shop.address')} variant="outlined" fullWidth={true}
                                                   name="address" value={ temp.address } multiline rows={2} onChange={ handleTempChange } required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="outlined-basic"
                                                   label={t('shop.mobile')}
                                                   variant="outlined"
                                                   fullWidth={true}
                                                   name="phone" type="tel"
                                                   value={temp.phone}
                                                   error={error.phone}
                                                   onChange={ handleTempChange }
                                                   InputProps={{
                                                       inputComponent: PhoneFormatCustom,
                                                   }}
                                                   required />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        </Box>
                    </form>
                    <Box m={1} style={{'marginTop': '20px'}}>
                        <Box display="flex" justifyContent="flex-end">
                            <LoadingButton loading={loading}
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    size="large"
                                    fullWidth={matches}
                                    onClick={(e) => handleSaveTemp(2)}
                                    disabled={ validation() || loading}>
                                {t('common.next')}
                            </LoadingButton>
                        </Box>
                    </Box>
                </Box>
            }
            {state.step === 2 &&
                <Box>
                    <form noValidate autoComplete="off">
                        <Box display="flex" alignItems="center" m={1}>
                            <IconButton edge="start" color="primary" aria-label="black" onClick={(e) => handlePrevious(1)}
                                        className={classes.link}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="h6">
                                {t('shop.location')}
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '10px'}} />
                        <Box m={1}>
                            <Box mt={2} style={{height: `300px`}}>
                                {!showMap &&
                                    <Box display="flex" justifyContent="center" mt={4}>
                                        <CircularProgress size={20}/>
                                    </Box>
                                }
                                {showMap &&
                                    <MapContainer center={temp.coordinates ? temp.coordinates : position}
                                                  zoom={temp.zoom ? temp.zoom : 15}
                                                  {...interactionOptions}
                                                  ref={setMap}
                                                  scrollWheelZoom={false}>
                                        <TileLayer
                                            attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        {position[0] &&
                                            <LocationMarker effectOn={this}
                                                            ood={temp.coordinates ? false : true}
                                                            position={temp.coordinates ? temp.coordinates : position}
                                                            callback={handleMapChange}/>
                                        }
                                    </MapContainer>
                                }
                            </Box>
                        </Box>
                    </form>
                    <Box m={1} style={{'marginTop': '20px'}}>
                        <Box display="flex" justifyContent="space-between">
                            <Button variant="contained"
                                    disableElevation
                                    size="large"
                                    color={"grey"}
                                    fullWidth={matches}
                                    onClick={(e) => handlePrevious(1)}>
                                {t('common.previous')}
                            </Button>
                            <div>&nbsp;</div>
                            <Button variant="contained"
                                    color="primary"
                                    disableElevation
                                    size="large"
                                    fullWidth={matches}
                                    onClick={(e) => handleSaveTemp(3)}
                                    disabled={!temp.coordinates}>
                                {t('common.next')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            }
            {state.step === 3 &&
                <Box>
                    <Box display="flex" alignItems="center" m={1}>
                        <IconButton edge="start" color="primary" aria-label="black" onClick={(e) => handlePrevious(2)}
                                    className={classes.link}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {t('pricing.selectPlan')}
                        </Typography>
                    </Box>
                    <Divider style={{'marginBottom': '10px'}} />
                    <Box m={1}>
                    <Box mt={2}>
                        <TableContainer>
                            <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell
                                            align="center" style={{
                                            borderRight: '2px solid white',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }} onClick={(e) => handleSelectPlan('START')}>{t('pricing.start')}</StyledTableCell>
                                        <StyledTableCell
                                            align="center" style={{
                                            borderRight: '2px solid white',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }} onClick={(e) => handleSelectPlan('BASIC')}>{t('pricing.basic')}</StyledTableCell>
                                        <StyledTableCell align="center" style={{
                                            borderLeft: '2px solid white',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }} onClick={(e) => handleSelectPlan('STANDARD')}>{t('pricing.standard')}</StyledTableCell>
                                        <StyledTableCell align="center" style={{
                                            borderLeft: '2px solid white',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }} onClick={(e) => handleSelectPlan('PRO')}>{t('pricing.pro')}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={4} align="center">{t('pricing.fee')}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{padding: '10px 0 10px 0'}}
                                                   align="center">
                                            <Box sx={{borderRight: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.start')}</Box>
                                            <Box sx={{
                                                borderRight: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'START'?'bold': 'normal'
                                            }}>{t('pricing.price.start')}</Box>
                                        </TableCell>
                                        <TableCell style={{padding: '10px 0 10px 0'}}
                                                   align="center">
                                            <Box sx={{borderRight: '0px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.basic')}</Box>
                                            <Box sx={{
                                                borderRight: '0px solid gainsboro',
                                                fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                            }}>{t('pricing.price.basic')}</Box>
                                        </TableCell>
                                        <TableCell style={{padding: '10px 0 10px 0'}} align="center" >
                                            <Box sx={{borderLeft: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.standard')}</Box>
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                            }}>{t('pricing.price.standard')}</Box>
                                        </TableCell>
                                        <TableCell style={{padding: '10px 0 10px 0'}} align="center" >
                                            <Box sx={{borderLeft: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.pro')}</Box>
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                            }}>{t('pricing.price.pro')}</Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={4} align="center">
                                            {temp.type==='RESTAURANT'?t('pricing.menu'):t('pricing.product')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'START'?'bold': 'normal'
                                            }}>{t('pricing.package.start.menu')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '0px solid gainsboro',
                                                fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                            }}>{t('pricing.package.basic.menu')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                            }}>{t('pricing.package.standard.menu')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                            }}>{t('pricing.package.pro.menu')}</Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={4} align="center">
                                            {t('pricing.order')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'START'?'bold': 'normal'
                                            }}>{t('pricing.package.start.order')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '0px solid gainsboro',
                                                fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                            }}>{t('pricing.package.basic.order')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                            }}>{t('pricing.package.standard.order')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                            }}>{t('pricing.package.pro.order')}</Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={4} align="center">
                                            {temp.type==='RESTAURANT'?t('pricing.table'):t('pricing.table')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'START'?'bold': 'normal'
                                            }}>{t('pricing.package.start.table')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '0px solid gainsboro',
                                                fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                            }}>{t('pricing.package.basic.table')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                            }}>{t('pricing.package.standard.table')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                            }}>{t('pricing.package.pro.table')}</Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={4} align="center">
                                            {temp.type==='RESTAURANT'?t('pricing.kitchen'):t('pricing.kitchen')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'START'?'bold': 'normal'
                                            }}>{t('pricing.package.start.kitchen')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '0px solid gainsboro',
                                                fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                            }}>{t('pricing.package.basic.kitchen')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                            }}>{t('pricing.package.standard.kitchen')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                            }}>{t('pricing.package.pro.kitchen')}</Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={4} align="center">
                                            {t('pricing.user')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'START'?'bold': 'normal'
                                            }}>{t('pricing.package.start.user')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '0px solid gainsboro',
                                                fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                            }}>{t('pricing.package.basic.user')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                            }}>{t('pricing.package.standard.user')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                            }}>{t('pricing.package.pro.user')}</Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={4} align="center">
                                            {t('pricing.delivery')}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'START'?'bold': 'normal'
                                            }}>{t('pricing.package.start.area')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderRight: '0px solid gainsboro',
                                                fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                            }}>{t('pricing.package.basic.area')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                            }}>{t('pricing.package.standard.area')}</Box>
                                        </TableCell>
                                        <TableCell style={{
                                            padding: '10px 0 10px 0'
                                        }} align="center">
                                            <Box sx={{
                                                borderLeft: '1px solid gainsboro',
                                                fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                            }}>{t('pricing.package.pro.area')}</Box>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell align="center">
                                            <Button fullWidth={true} variant="contained"
                                                    disableElevation
                                                    style={{ fontWeight: temp.plan === 'START'?'bold':'normal' }}
                                                    onClick={(e) => handleSelectPlan('START')}
                                                    color={temp.plan === 'START'?'orange':'grey'}>
                                                {t('pricing.price.start')}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button fullWidth={true} variant="contained"
                                                    disableElevation
                                                    style={{ fontWeight: temp.plan === 'BASIC'?'bold':'normal' }}
                                                    onClick={(e) => handleSelectPlan('BASIC')}
                                                    color={temp.plan === 'BASIC'?'orange':'grey'}>
                                                {t('pricing.price.basic')}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button fullWidth={true} variant="contained"
                                                    disableElevation
                                                    onClick={(e) => handleSelectPlan('STANDARD')}
                                                    style={{ fontWeight: temp.plan === 'STANDARD'?'bold':'normal' }}
                                                    color={temp.plan === 'STANDARD'?'orange':'grey'}>
                                                {t('pricing.price.standard')}
                                            </Button>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Button fullWidth={true} variant="contained"
                                                    disableElevation
                                                    onClick={(e) => handleSelectPlan('PRO')}
                                                    style={{ fontWeight: temp.plan === 'PRO'?'bold':'normal' }}
                                                    color={temp.plan === 'PRO'?'orange':'grey'}>
                                                {t('pricing.price.pro')}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box>
                            <Typography variant={"caption"}>
                                *{t('common.message.selectPlanMessage')}
                            </Typography>
                        </Box>
                        <div style={{'marginTop': '10px', 'paddingBottom': '1rem'}}>
                            <Box display="flex" justifyContent="space-between">
                                <Button variant="contained"
                                        disableElevation
                                        size="large"
                                        color={"grey"}
                                        fullWidth={matches}
                                        onClick={(e) => handlePrevious(2)}>
                                    {t('common.previous')}
                                </Button>
                                <div>&nbsp;</div>
                                <Button variant="contained"
                                        style={{ fontWeight: (temp.plan)?'bold':'normal' }}
                                        disableElevation
                                        size="large"
                                        fullWidth={matches}
                                        onClick={(e) => handleSaveTemp(4)}
                                        color="primary"
                                        disabled={!temp.plan || loading}>
                                    {t('common.next')}
                                </Button>
                            </Box>
                        </div>
                    </Box>
                </Box>
                </Box>
            }
            {state.step === 4 &&
            <Box>
                <form noValidate autoComplete="off">
                    <Box display="flex" alignItems="center" m={1}>
                        <IconButton edge="start" color="primary" aria-label="black" onClick={(e) => handlePrevious(3)}
                                    className={classes.link}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6">
                            {t('shop.confirm')}
                        </Typography>
                    </Box>
                    <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                    <Box mt={2} m={1}>
                        <ShopConfirm temp={temp} profile={profile} />
                    </Box>
                </form>
                <Box mt={4} m={1}>
                    {trial &&
                        <Box mb={2}>
                            <Alert icon={<CardGiftcardIcon sx={{ fontSize: 40 }} />} severity="success">
                                {t('shop.trial.message')}
                            </Alert>
                        </Box>
                    }
                    <ThemeProvider theme={theme}>
                        <Button variant="contained"
                                disableElevation
                                color={"primary"}
                                size="large"
                                fullWidth={true}
                                onClick={(e) => handleConfirm()}
                                disabled={!temp.coordinates}>
                            {trial && loading &&
                                <CircularProgress size={20} style={{marginTop: '7px', marginBottom: '7px'}} />
                            }
                            {trial && !loading &&
                                <>
                                    {t('common.confirm')}
                                </>
                            }
                            {!trial &&
                                <Box display="flex" justifyContent="space-between" alignItems="center" style={{width: '100%'}}>
                                    <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{t('pricing.payment')}</Typography>
                                    <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{temp.plan === 'START'?t('pricing.price.start'):temp.plan === 'BASIC'?t('pricing.price.basic'):temp.plan === 'STANDARD'?t('pricing.price.standard'):t('pricing.price.pro')}</Typography>
                                </Box>
                            }
                        </Button>
                    </ThemeProvider>
                </Box>
                <Box mt={2} m={1}>
                    <Button variant="contained"
                            disableElevation
                            color="grey"
                            size="large"
                            fullWidth={true}
                            onClick={(e) => handlePrevious(3)}>
                        {t('common.edit')}
                    </Button>
                </Box>
            </Box>
            }
            <Dialog
                fullScreen={true}
                open={openPayment}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper}>
                    {paymentComplete &&
                        <div>
                            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                                <CheckCircleOutlineIcon color={"success"} sx={{ fontSize: 60 }} />
                            </Box>
                            <Alert icon={false} severity="success">{t('payment.complete')}</Alert>
                            <Box mt={2}>
                                <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                        disabled={paymentCompleteLoading}
                                        onClick={handleClosePaymentComplete}>
                                    {t('common.ok')}
                                </Button>
                            </Box>
                        </div>
                    }
                    {!paymentComplete &&
                        <div>
                            <Box display="flex" justifyContent="center" mt={2} mb={2}>
                                <Typography variant="h5"
                                            color="inherit"><b>{t('payment.scanForPaid')}</b></Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img
                                    src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/promptpay_visa_master.png`}
                                    alt="payment network"
                                    style={{width: '100%', maxWidth: '200px'}}/>
                            </Box>
                            {!qrCodeData &&
                                <Box display="flex" justifyContent="center" mt={3} mb={4}>
                                    <CircularProgress size={20}/>
                                </Box>
                            }
                            {qrCodeData &&
                                <>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                        <img src={`data:image/png;base64,${qrCodeData.base64}`} alt="pay"
                                             style={{width: '100%', maxWidth: '250px'}}/>
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                                        <Typography variant="body1" color="inherit">{qrCodeData.qrcodeId}</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1"
                                                    color="inherit">{t('pricing.totalAmount')}</Typography>
                                        <Typography variant="body1"
                                                    color="inherit"><NumberFormat value={qrCodeData.amount}
                                                                                  displayType={'text'}
                                                                                  thousandSeparator={true}/> {t('pricing.baht')}
                                        </Typography>
                                    </Box>
                                    <hr/>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" color="inherit">{t(`pricing.ref1`)}</Typography>
                                        <div>{qrCodeData && <span>{qrCodeData.orderNo}</span>}</div>
                                    </Box>
                                    <hr/>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1"
                                                    color="inherit">{t(`pricing.paymentDate`)}</Typography>
                                        <div><DateI18n value={payment.date} format="DD MMM YYYY HH:mm"/></div>
                                    </Box>
                                </>
                            }
                            <Box mt={2}>
                                <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                        onClick={handleClosePayment}>
                                    {t('common.cancel')}
                                </Button>
                            </Box>
                        </div>
                    }
                </div>
            </Dialog>
            <Snackbar open={notifyError > 0}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {notifyError === 1 && t('common.message.geoLocationFail')}
                    {notifyError === 2 && t('account.limitCreation')}
                    {notifyError === 3 && t('account.frequencyCreation')}
                </Alert>
            </Snackbar>
        </div>
    )
}