import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {httpClient} from "../../../core/HttpClient";
import {DateI18n} from "../../DateI18n";
import NumberFormat from "react-number-format";
import {ListItem} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeliverIcon from "../../Deliver/DeliverIcon";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
}));


function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.mode==='query'?false:action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:null,
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(customerId, query, dispatch) {
    dispatch({ type: "FETCH_START" });
    try {
        let results = [];
        let mode = 'all';

        const url = process.env.REACT_APP_API_BASE_URL + `/customers/${customerId}/history`;
        const result = await httpClient(
            `${url}?c=${query.cursor}`
        );

        if(result.data && result.data){
            results = result.data;
        }

        dispatch({ type: "FETCH_SUCCESS", payload: results, cursor: query.cursor, mode: mode })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

export default function CustomerOrders({customerId}) {
    const { t } = useTranslation();
    const [{ hits, hasNext, hasError, isLoading, cursor}, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null
    });
    const [query, setQuery] = useState({cursor: ''});
    const classes = useStyles();

    const debouncedFetchHits = useRef(
        _.debounce(
            (customerId, query, cursor) => fetchHits(customerId, query, dispatch),
            200
        )
    ).current;

    useEffect(() => {
        console.log('[CustomerOrders]');
        const timeOutId = setTimeout(() => debouncedFetchHits(customerId, {cursor: '', query: ''}), 10);
        return () => clearTimeout(timeOutId);
    }, [customerId, debouncedFetchHits]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData', cursor);
        let newValue = {...query, cursor: cursor};
        setQuery(newValue);
        const cancelToken = httpClient.CancelToken.source();
        debouncedFetchHits(customerId, newValue, cancelToken.token).then(r=>{});
    };

    return (
        <div className={classes.root}>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {t('customer.history')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {hasError &&
            <Box m={2}>
                <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
            </Box>
            }
            {!isLoading && hits.length === 0 && !hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2} mt={4}>
                <Typography>
                    {t('common.notFound')}
                </Typography>
            </Box>
            }
            {hits.length > 0 &&
            <Box>
                <InfiniteScroll
                    dataLength={hits.length} //This is important field to render the next data
                    next={e=>handleFetchData(cursor)}
                    hasMore={hasNext}
                >
                    <List className={classes.root} subheader={<li/>}>
                        {hits.map((item) => (
                            <ListItem divider key={`item-${item._id}`}>
                                <ListItemIcon><DeliverIcon foodOrder={item} /></ListItemIcon>
                                <ListItemText>
                                    <div>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography noWrap={true}>
                                                        <span>
                                                            {item.transactionNo}
                                                        </span>
                                            </Typography>
                                            <div>
                                                        <span style={{color: ['cancel', 'reject'].indexOf(item.status) > -1?'red':'green', fontSize: '1rem'}}>
                                                            <NumberFormat value={item.grandTotal} displayType={'text'}
                                                                          thousandSeparator={true} prefix={'฿'}/>
                                                        </span>
                                            </div>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography noWrap={true}>
                                                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                                                            <DateI18n format="DD MMM YY, HH:mm" value={item.orderDate} />
                                                        </span>
                                            </Typography>
                                            <div>
                                                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                                                            {t(`transactionStatus.${item.status}`)}
                                                        </span>
                                            </div>
                                        </Box>
                                    </div>
                                </ListItemText>
                            </ListItem>
                        ))}
                    </List>
                </InfiniteScroll>
            </Box>
            }
            {isLoading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
        </div>
    )
}