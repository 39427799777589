import React, {useEffect, useRef} from 'react';
import { useParams, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../../core/HttpClient";
import liff from "@line/liff";

// const liff = window.liff;
const lineAuthentication = async(cb) => {
    const liffId = process.env.REACT_APP_LINE_SIGNIN_LIFF_ID;
    await liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});
    if (liff.isLoggedIn()) {
        const accessToken = await liff.getAccessToken();
        return cb(accessToken);
    }else{
        // liff.login(); //external browser can not use liff.login(); for verify token
        return cb(null);
    }
};

export default function LandingHome(props) {
    const history = useHistory();
    const { accountId, dateCode, uid } = useParams();
    const tick = useRef();

    useEffect(() => {
        console.log('[LandingHome]');
        try {
            let processTimer = 0;
            tick.current = setInterval(() => {
                processTimer++;
                if (processTimer === 20) {
                    console.log('process timout');
                    clearInterval(tick.current);
                    window.location.reload(true);
                }
                if(processTimer === 1) {
                    lineAuthentication(accessToken => {
                        clearInterval(tick.current);
                        if (accessToken) {

                            const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected/line';
                            let data = {
                                lineAccessToken: accessToken
                            };
                            httpClient.put(url, data)
                                .then(res => {
                                    if (res.data && res.data.token) {
                                        localStorage.setItem('token', res.data.token);
                                        localStorage.setItem('refreshToken', res.data.refreshToken);
                                        history.push(`/passcode`);
                                    } else {
                                        localStorage.clear();
                                        window.location.href = '/signin';
                                    }
                                }).catch(e => {
                                console.log('error', e);
                                localStorage.clear();
                                window.location.href = '/login';
                            });

                        } else {
                            localStorage.clear();
                            window.location.href = '/login';
                        }
                    }).then(r =>{
                        console.log('done');
                    });
                }
            }, 1000);
        } catch(err){
            // done
            console.log('err',err);
        }

    }, [ accountId, dateCode, uid, history]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    )
}