import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FaceIcon from '@mui/icons-material/Face';
import StoreIcon from '@mui/icons-material/Store';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import PinDropIcon from '@mui/icons-material/PinDrop';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PaymentIcon from '@mui/icons-material/Payment';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import ShopIcon from '@mui/icons-material/Shop';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import {ListItemButton} from "@mui/material";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
// import CardMembershipIcon from '@mui/icons-material/CardMembership';
// import CampaignIcon from '@mui/icons-material/Campaign';
// import HandshakeIcon from '@mui/icons-material/Handshake';

const useStyles = makeStyles({
    list: {
        width: 200,
    },
    fullList: {
        width: 'auto',
    },
    icon: {
        minWidth: '35px'
    }
});

const SideMenu = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [role] = React.useState(props.role);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setOpen(open);
    };

    useImperativeHandle(ref, () => ({
        toggleDrawer(open) {
            setOpen(open);
        }

    }));

    const list = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}>
            <List>
                <ListItemButton component={Link} to="/shop">
                    <ListItemIcon sx={{minWidth: '35px'}}><StoreIcon/></ListItemIcon>
                    <ListItemText primary={t('shop.title')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/products">
                    <ListItemIcon sx={{minWidth: '35px'}}><MenuBookIcon/></ListItemIcon>
                    <ListItemText primary={t('product.title')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/categories">
                    <ListItemIcon sx={{minWidth: '35px'}}><FastfoodIcon/></ListItemIcon>
                    <ListItemText primary={t('productCategory.title')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/kitchens">
                    <ListItemIcon sx={{minWidth: '35px'}}><SoupKitchenIcon/></ListItemIcon>
                    <ListItemText primary={t('kitchen.title')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/tables">
                    <ListItemIcon sx={{minWidth: '35px'}}><TableRestaurantIcon/></ListItemIcon>
                    <ListItemText primary={t('table.title')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/payments">
                    <ListItemIcon sx={{minWidth: '35px'}}><PaymentIcon/></ListItemIcon>
                    <ListItemText primary={t('payment.title')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/delivery/partners">
                    <ListItemIcon sx={{minWidth: '35px'}}><DeliveryDiningIcon/></ListItemIcon>
                    <ListItemText primary={t('deliveryPartner.title')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/deliver">
                    <ListItemIcon sx={{minWidth: '35px'}}><PinDropIcon/></ListItemIcon>
                    <ListItemText primary={t('qr.delivery')}/>
                </ListItemButton>
                <ListItemButton component={Link} to="/qrcode">
                    <ListItemIcon sx={{minWidth: '35px'}}><SmartphoneIcon/></ListItemIcon>
                    <ListItemText primary={t('qr.mobile')}/>
                </ListItemButton>
                {/*<ListItemButton component={Link} to="/mobile/deliver">*/}
                {/*    <ListItemIcon sx={{minWidth: '35px'}}><PhoneAndroidIcon/></ListItemIcon>*/}
                {/*    <ListItemText primary={t('mobileOrdering.title')}/>*/}
                {/*</ListItemButton>*/}
                <ListItemButton component={Link} to="/discounts">
                    <ListItemIcon sx={{minWidth: '35px'}}><LocalOfferIcon/></ListItemIcon>
                    <ListItemText primary={t('discount.title')}/>
                </ListItemButton>
                {/*<ListItemButton component={Link} to="/campaigns">*/}
                {/*    <ListItemIcon sx={{minWidth: '35px'}}><CampaignIcon/></ListItemIcon>*/}
                {/*    <ListItemText primary={t('campaigns.title')}/>*/}
                {/*</ListItemButton>*/}
                {/*<ListItemButton component={Link} to="/rewards">*/}
                {/*    <ListItemIcon sx={{minWidth: '35px'}}><CardMembershipIcon/></ListItemIcon>*/}
                {/*    <ListItemText primary={t('reward.title')}/>*/}
                {/*</ListItemButton>*/}
                <ListItemButton component={Link} to="/customers">
                    <ListItemIcon sx={{minWidth: '35px'}}><FaceIcon /></ListItemIcon>
                    <ListItemText primary={t('customer.title')} />
                </ListItemButton>
                <ListItemButton component={Link} to="/users">
                    <ListItemIcon sx={{minWidth: '35px'}}><SupervisorAccountIcon/></ListItemIcon>
                    <ListItemText primary={t('user.title')}/>
                </ListItemButton>
                {/*<ListItemButton component={Link} to="/partners">*/}
                {/*    <ListItemIcon sx={{minWidth: '35px'}}><HandshakeIcon/></ListItemIcon>*/}
                {/*    <ListItemText primary={t('partner.title')}/>*/}
                {/*</ListItemButton>*/}
                {/*<ListItemButton component={Link} to="/market">*/}
                {/*    <ListItemIcon sx={{minWidth: '35px'}}><ShopIcon /></ListItemIcon>*/}
                {/*    <ListItemText primary={t('market.title')} />*/}
                {/*</ListItemButton>*/}
                <ListItemButton component={Link} to="/plugins">
                    <ListItemIcon sx={{minWidth: '35px'}}><ShopIcon/></ListItemIcon>
                    <ListItemText primary={t('plugin.title')}/>
                </ListItemButton>
            </List>
        </div>
    );

    return (
        <div>
            {['OWNER', 'ADMIN'].indexOf(role) > -1 &&
                <Drawer anchor='left' open={open} onClose={toggleDrawer(false)}>
                    {list()}
                </Drawer>
            }
        </div>
    )
});

export default SideMenu;