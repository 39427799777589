import React, { useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Box from '@mui/material/Box';
import liff from "@line/liff";
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../../core/HttpClient";

// const liff = window.liff;
const lineAuthentication = async(cb) => {
    const liffId = process.env.REACT_APP_LINE_SIGNIN_LIFF_ID;
    await liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});
    if (liff.isLoggedIn()) {
        const accessToken = await liff.getAccessToken();
        return cb(accessToken);
    }else{
        // liff.login(); //external browser can not use liff.login(); for verify token
        return cb(null);
    }
};

export default function LandingTransaction(props) {
    const history = useHistory();
    const { accountId, dateCode, uid } = useParams();

    useEffect(() => {
        console.log('[LandingTransaction]');
        lineAuthentication(accessToken=> {
            if (accessToken) {

                const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected/line';
                let data = {
                    account: accountId,
                    lineAccessToken: accessToken
                };
                httpClient.put(url, data)
                    .then(res => {
                        if(res.data){
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('refreshToken', res.data.refreshToken);
                            history.push(`/orders/action/${uid}`);
                        }
                    }).catch(e=>{
                        console.log(e);
                    });

            }
        });
    }, [accountId, dateCode, uid, history]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    )
}