import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import AddIcon from '@mui/icons-material/Add';
import arrayMove from 'array-move';
import {httpClient} from "../../core/HttpClient";
import {TextI18n, getTextI18n} from "../TextI18n";
import './style.css';
import {ListItemButton} from "@mui/material";
import {Context} from "../../core/Context";
import LinesEllipsis from "react-lines-ellipsis";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToParentElement} from "@dnd-kit/modifiers";
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging
    } = useSortable({id: props.id});

    const style = {
        position: "relative",
        zIndex: isDragging ? 1 : undefined,
        transform: CSS.Transform.toString(transform),
    };

    return (
        <div ref={setNodeRef} style={style}>
            <ListItemButton divider={props.divider} onClick={e=>props.onClick(props.value)}>
                <ListItemAvatar style={{marginRight: '10px'}} >
                    <Badge color={props.value.active?'primary':'error'} variant="dot">
                        <img src={
                            props.value.images[0]?
                                `${process.env.REACT_APP_CDN_BASE_URL}/${props.value.images[0].permission}/merchant/m/${props.value.images[0].name}`: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo_16_9.png`
                        }
                             alt={getTextI18n(props.value.name)}
                             style={{width: '100px'}}>
                        </img>
                    </Badge>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Box pr={1}>
                            <LinesEllipsis
                                text={getTextI18n(props.value.name)}
                                maxLine='2'
                                ellipsis='...'
                                trimRight
                            />
                            {props.value.recommend && <Chip label={props.t('product.recommend')} size="small" sx={{borderRadius: '3px', height: 'auto'}} color={"secondary"} />}
                        </Box>
                    }
                    />
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="move" style={{touchAction: "none"}} {...attributes} {...listeners} ><OpenWithIcon style={{cursor: 'grab'}}></OpenWithIcon></IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
        </div>
    );
}

const MySortableList = ({items, t, onClick}) => {
    return (
        <div>
            {items.map((item, index1) => {
                const itemIds = item.products.map((item) => item._id);
                return (
                    <Box mb={2}
                         key={`item-${index1}`}>
                        <Card variant="outlined">
                            {item.systemData === 0 &&
                                <Box display="flex"
                                     sx={{
                                         padding: '10px',
                                         backgroundColor: '#eff0f0',
                                     }}>
                                    <Typography style={{fontWeight: 'bold'}}>
                                        <TextI18n value={item.name}></TextI18n>
                                    </Typography>
                                </Box>
                            }
                    <List className="SortableList">
                        <SortableContext
                            items={itemIds}
                            strategy={verticalListSortingStrategy}
                        >
                            <List className="SortableList" >
                                {item.products.map((value, index2) => <SortableItem key={`item-${index1}-${index2}`}
                                                                                    id={value._id}
                                                                                    divider={index2 !== item.products.length-1}
                                                                                    onClick={onClick}
                                                                                    t={t}
                                                                                    index={index2} value={value} />)}
                            </List>
                        </SortableContext>
                    </List>
                </Card>
            </Box>
            )
        })}
    </div>
    );
};


export default function Products() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [context] = useContext(Context);
    const [loading, setLoading] = useState(true);
    const [productGroups, setProductGroups] = useState([]);
    const [total, setTotal] = useState(0);
    const [plan, setPlan] = useState({limited: false, menu: 3, account: ''});
    const [showLimited, setShowLimited] = useState(false);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    console.log('Products');
    useEffect(() => {
        console.log('[Products]');
        const productURL = process.env.REACT_APP_API_BASE_URL + '/products';
        const accountUrl = process.env.REACT_APP_API_BASE_URL + '/accounts/me/limited';
        let tasks = [httpClient.get(productURL), httpClient.get(accountUrl)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setProductGroups(rs[0].data);
                    let total = 0;
                    rs[0].data.forEach(pg=>{
                        total += pg.products.length;
                    });
                    setTotal(total);
                }
                if(rs[1] && rs[1].data){
                    let size = rs[1].data.menu;
                    let limited = rs[1].data.productLimit;
                    setPlan({limited: limited, menu: size, account: rs[1].data.account});

                }
                setLoading(false);
            })
            .catch(e=>{
                console.log('error', e);
            });
    }, []);

    const handleClickNewProduct = () => {
        if(!plan.limited){
            history.push('/product');
        } else {
            setShowLimited(true);
        }
    };

    const handleChangePlan = (event, reason) => {
        localStorage.setItem('SHOW_PLAN', '1');
        history.push('/account/'+plan.account);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowLimited(false);
    };

    function handleClick(value){
        history.push('/product/' + value._id);
    }

    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {
            let newProductGroup = [];
            let oldIndex = -1;
            let newIndex = -1;
            productGroups.forEach(pg=>{
                oldIndex = pg.products.findIndex(item => item._id === active.id);
                newIndex = pg.products.findIndex(item => item._id === over.id);
                if(oldIndex > -1 && newIndex > -1){
                    let newProducts = arrayMove(pg.products, oldIndex, newIndex);
                    pg.products = newProducts;
                    newProductGroup.push(pg);
                    const url = process.env.REACT_APP_API_BASE_URL + '/products/sort';
                    httpClient.put(url, {collection: pg._id, oldIndex, newIndex})
                        .then(res => {
                            // done
                        });
                } else {
                    newProductGroup.push(pg);
                }
            });
            setProductGroups(newProductGroup);


        }
    }

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {context.accountType==='RESTAURANT'?t('product.title'):t('product.shopTitle')}
                </Typography>
                <Typography variant="body1">
                    {t('product.totalMenu', {menu: total})}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
                }
                {!loading &&
                    <Box display="flex" justifyContent="center" mb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            disableElevation
                            startIcon={<AddIcon/>}
                            onClick={handleClickNewProduct}>
                            {t('common.add')}
                        </Button>
                    </Box>
                }
                {!loading && productGroups.length === 0 &&
                    <Box display="flex" justifyContent="center" p={2} m={2} mt={4}>
                        <Typography>
                            {t('common.notFound')}
                        </Typography>
                    </Box>
                }
                {!loading && productGroups.length > 0 &&
                <div style={{marginBottom: '10px', paddingBottom: '10px'}}>

                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        modifiers={[restrictToParentElement]}
                        onDragEnd={handleDragEnd}
                    >
                        <MySortableList numberTitle={t('product.number')}
                                        items={productGroups}
                                        t={t}
                                        onClick={handleClick} />
                    </DndContext>
                </div>
                }
            </Box>
            <Snackbar open={showLimited}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    <Box>
                        <Button variant="outlined" color="primary" disableElevation onClick={handleChangePlan}>
                            <Box>
                                <div style={{marginBottom: '10px'}}>{t('product.planLimited', {menu: plan.menu})}</div>
                                <div>{t('common.message.changePlan')}</div>
                            </Box>
                        </Button>
                    </Box>
                </Alert>
            </Snackbar>
        </div>
    )
}