import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import {makeStyles, withStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import OnlineStatus from '../OnlineStatus'

import './style.css';
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import StoreIcon from "@mui/icons-material/Store";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {httpClient} from "../../core/HttpClient";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import {ShopLink} from "../ShopLink";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const GreenSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#44cc00',
        },
        '&$checked + $track': {
            backgroundColor: '#44cc00',
        },
    },
    checked: {},
    track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function Home() {
    const { t } = useTranslation();
    const [confirm, setConfirm] = useState(null);
    const [account, setAccount] = useState({active: false,});
    const [loading, setLoading] = useState(true);
    const classes = useStyles();

    useEffect(() => {
        console.log('[Home]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    let _data = res.data;
                    setAccount(_data);
                }
                setLoading(false);
            });
    }, []);

    const handleShopStatus = () => {
        setAccount({ ...account, 'active': confirm });
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id;
        httpClient.post(url, {'active': confirm})
            .then(res => {
                setLoading(false);
            });
        setConfirm(null);
    }

    const handleSwitchChange = ({ target }) => {
        setConfirm(target.checked);
    };

    const handleClose = () => {
        setConfirm(null);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('dashboard.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box mt={2} m={1}>
                <Card variant="outlined">
                    <Box m={2}>
                        {loading &&
                            <Box display="flex" justifyContent="center" m={2} pt={2}>
                                <Chip label={t('common.loading')} icon={<CircularProgress size={20}/>}/>
                            </Box>
                        }
                        {!loading &&
                            <Box display="flex" justifyContent="center" m={2} pt={2}>
                                {!account.active &&
                                    <Chip color="secondary" label={t('shop.status.close')} icon={<StoreIcon/>}/>
                                }
                                {account.active &&
                                    <Chip color="primary" label={t('shop.status.open')} icon={<StoreIcon/>}/>
                                }
                            </Box>
                        }
                        <Box display="flex" justifyContent="center">
                            <GreenSwitch
                                name="active"
                                disabled={loading}
                                checked={ account.active }
                                onChange={ handleSwitchChange }
                                color="primary"
                                inputProps={{ 'aria-label': 'active' }}
                            />
                        </Box>
                        <Box mt={2}>
                            <ShopLink cid={account.cid} name="link" type="o" share={true}/>
                        </Box>
                    </Box>
                </Card>
            </Box>
            <Box mt={2} m={1}>
                <OnlineStatus plan={account.plan} />
            </Box>

            <Dialog
                open={confirm !== null}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('common.message.confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {confirm?t('shop.statusConfirm.open'):t('shop.statusConfirm.close')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            onClick={handleClose}
                            fullWidth={true}
                            size="large"
                            color={"grey"}
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained"
                            size="large"
                            color={"primary"}
                            disableElevation
                            fullWidth={true}
                            onClick={ handleShopStatus }>
                        {t('common.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}