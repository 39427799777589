import {useTranslation} from "react-i18next";

export function TextBirthdate({value}) {
    const { t } = useTranslation();

    if(value && value.length === 8){
        let d = value.substring(0, 2);
        let m = value.substring(2, 4);
        let y = value.substring(4, 8);
        let day = parseInt(d,10);
        let month = parseInt(m,10);

        return (<span>
            {day} {t(`common.months.${month}.name`)} {y}
        </span>);
    } else {
        return (<span></span>);
    }
}