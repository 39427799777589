import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {httpClient} from "../../core/HttpClient";
import liff from "@line/liff";
import Avatar from "@mui/material/Avatar";
import {PinField} from "react-pin-field";
import './style.css'
import {useParams} from "react-router-dom";
import {useLocation} from "react-router";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";

// const liff = window.liff;
const lineAuthentication = async(ref, cb) => {
    const liffId = process.env.REACT_APP_LINE_SIGNIN_QR_LIFF_ID;
    await liff.init({ liffId: liffId, withLoginOnExternalBrowser: false }).catch(err=>{throw err});
    if (liff.isLoggedIn()) {
        const accessToken = liff.getAccessToken();
        return cb(accessToken);
    }else{
        if(ref){
            liff.login({redirectUri: `${process.env.REACT_APP_BASE_URL}/sign/qr/auth/${ref}`});
        }
        return cb(null);
    }
};

export default function SignInQrCode(props) {
    const { t } = useTranslation();
    const search = useLocation().search;
    const reset = new URLSearchParams(search).get('reset') || "false";
    const {ref} = useParams();
    const refPIN = useRef();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [error, setError] = useState(0);
    const [resetPasscodeSuccess, setResetPasscodeSuccess] = useState(reset);
    const [device, setDevice] = useState({});

    useEffect(() => {
        console.log('[SignInQrCode]');
        lineAuthentication(ref,accessToken => {
            console.log('lineAuthentication accessToken', accessToken);
            if(ref && accessToken){
                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({accessToken: accessToken})
                };
                const url = process.env.REACT_APP_API_BASE_URL + '/auth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if (data.token) {
                            localStorage.setItem('token', data.token);
                            localStorage.setItem('refreshToken', data.refreshToken);

                            const userUrl = process.env.REACT_APP_API_BASE_URL + '/users/me';
                            const qrCodeUrl = process.env.REACT_APP_API_BASE_URL + '/sign/qr/ref/' + ref;
                            let tasks = [httpClient.get(userUrl), httpClient.get(qrCodeUrl)];
                            Promise.all(tasks)
                                .then(rs=>{

                                    if(rs[1].status === 200 && rs[1].data){
                                        let userState = 9;
                                        let userId = '';
                                        if(rs[0].status === 200 && rs[0].data){
                                            userState = rs[0].data.state;
                                            userId = rs[0].data._id;
                                        }
                                        setDevice({...rs[1].data, userId: userId, userState: userState, userName: rs[0].data.displayName});
                                    }

                                    setLoading(false);
                                })
                                .catch(e=>{
                                    console.log('error', e);
                                    setLoading(false);
                                });
                        }
                    }).catch(e=>{console.log(e)})
            }
        }).catch(e => {console.log(e)});
    }, [ref]);


    const handleCheckPasscode = (value) => {
        setUpdating(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/users/passcode/qr';
        httpClient.post(url, {passcode: value, ref: ref})
            .then(res => {
                setUpdating(false);
                if (res.data && res.data.ticket) {
                    setLoggedIn(true);
                } else {
                    let errorCounter = error;
                    errorCounter++;
                    if (refPIN && ref.current && refPIN.current[0]) {
                        refPIN.current.forEach(input => (input.value = ""));
                        refPIN.current[0].focus();
                    }
                    setError(errorCounter);
                }
            }).catch(e => {
            let errorCounter = error;
            errorCounter++;
            if (refPIN && refPIN.current && refPIN.current[0]) {
                refPIN.current.forEach(input => (input.value = ""));
                refPIN.current[0].focus();
            }
            setError(errorCounter);
            setUpdating(false);
        });
    };

    const handleClose = () => {
        setResetPasscodeSuccess(false);
    };

    return (
        <Box>
            <Box mt={6}>
                {loading &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <CircularProgress size={20}/>
                    </Box>
                }
                {!loading && device.ref &&
                    <>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Avatar alt={device.userId}
                                    src={device.userId ? `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${device.userId}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.jpg`}
                                    sx={{width: 128, height: 128}}/>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={4}>
                            <Typography gutterBottom  variant="h6" component="h3">
                                {device.userName}
                            </Typography>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                            <Box>
                                <Typography variant="h6" component="h3">
                                    {t('login.qrSignIn')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" mt={1} mb={4}>
                            <Box>
                                <Typography gutterBottom  variant="caption" component="h2">
                                    {device.deviceName}
                                </Typography>
                            </Box>
                        </Box>
                        {device.userState === 9 &&
                            <Box p={2}>
                                <Alert severity="error" >{t('user.ban')}</Alert>
                            </Box>
                        }
                        {device.userState !== 9 && !loggedIn &&
                            <>
                                <Box mt={4} mb={2} display="flex" justifyContent="center" alignItems="center">
                                    <Typography variant="caption">
                                        {t('common.message.enterPasscode')}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <PinField className={error > 0 ? 'pin-field-error': 'pin-field'}
                                              length={4}
                                              ref={refPIN}
                                              autoFocus
                                              type="password"
                                              inputMode="numeric"
                                              validate="0123456789"
                                              onComplete={handleCheckPasscode}
                                    />
                                </Box>
                            </>
                        }
                        {device.userState !== 9 && loggedIn &&
                            <Box m={4}>
                                <Alert severity="success">{t('login.qrSignInSuccess')}</Alert>
                            </Box>
                        }
                    </>
                }
                {!loading && !device.ref &&
                    <Box m={4}>
                        <Alert severity="warning">{t('common.message.qrcodeExpired')}</Alert>
                    </Box>
                }
            </Box>
            <Snackbar open={resetPasscodeSuccess === 'true'}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t('profile.resetPasscodeSuccess')}
                </Alert>
            </Snackbar>
            <Backdrop
                style={{
                    zIndex: 999,
                    color: '#fff',
                }}
                open={updating}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </Box>
    )



    // const handleCheckPasscode = (value) => {
    //     setUpdating(true);
    //     const url = process.env.REACT_APP_API_BASE_URL + '/users/passcode/qr';
    //     httpClient.post(url, {passcode: value, ref: ref})
    //         .then(res => {
    //             setUpdating(false);
    //             if (res.data && res.data.ticket) {
    //                 console.log('success ', res.data.ticket);
    //             } else {
    //                 let errorCounter = error;
    //                 errorCounter++;
    //                 if (refPIN && refPIN.current && refPIN.current[0]) {
    //                     refPIN.current.forEach(input => (input.value = ""));
    //                     refPIN.current[0].focus();
    //                 }
    //                 setError(errorCounter);
    //             }
    //         }).catch(e => {
    //         let errorCounter = error;
    //         errorCounter++;
    //         if (refPIN && ref.current && refPIN.current[0]) {
    //             refPIN.current.forEach(input => (input.value = ""));
    //             refPIN.current[0].focus();
    //         }
    //         setError(errorCounter);
    //         setUpdating(false);
    //     });
    // };

    // return (
    //     <Box>
    //         {loading &&
    //         <Box display="flex" justifyContent="center" mt={4}>
    //             <CircularProgress size={20}/>
    //         </Box>
    //         }
    //         <Box display="flex" justifyContent="center" p={2} mt={2}>
    //         {!loading &&
    //             <>
    //                 {device &&
    //                 <Card className={classes.card}>
    //                     <CardContent>
    //                         {loggedIn &&
    //                             <>
    //                                 <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
    //                                     <Avatar alt={device.userId}
    //                                             src={device.userId ? `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${device.userId}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.jpg`}
    //                                             sx={{width: 128, height: 128}}/>
    //                                 </Box>
    //                                 <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={4}>
    //                                     <Typography gutterBottom  variant="h6" component="h3">
    //                                         {t('login.qrSignIn', {deviceName: device.deviceName})}
    //                                     </Typography>
    //                                 </Box>
    //                                 <Box display="flex" justifyContent="center" alignItems="center">
    //                                     <PinField className={error > 0 ? 'pin-field-error': 'pin-field'}
    //                                               length={4}
    //                                               ref={refPIN}
    //                                               autoFocus
    //                                               type="password"
    //                                               inputMode="numeric"
    //                                               validate="0123456789"
    //                                               onComplete={handleCheckPasscode}
    //                                     />
    //                                 </Box>
    //                             </>
    //                         }
    //                     </CardContent>
    //                 </Card>
    //                 }
    //                 {!device &&
    //                 <Alert severity="warning">{t('common.message.qrcodeExpired')}</Alert>
    //                 }
    //             </>
    //         }
    //         </Box>
    //     </Box>
    // )
}