import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {httpClient} from "../../core/HttpClient";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import {getDatabase, goOffline, goOnline} from "firebase/database";
import { useIdleTimer } from 'react-idle-timer';
import {getDatabaseInstance} from "../../core/Firebase";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

export default function AccountPlanCurrent() {
    const { t } = useTranslation();
    const history = useHistory();
    const [accountLimit, setAccountLimit] = useState({orderLimit: 0, orderCurrent: 0, account: '', plan: ''});

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            if(document.visibilityState === 'hidden'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    useEffect(() => {
        console.log('[AccountPlanCurrent]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/order/limited';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAccountLimit({
                        orderCurrent: res.data.orderCurrent || 0,
                        orderLimit: res.data.order || 0,
                        account: res.data.account,
                        plan: res.data.type
                    });
                }
            });
    }, []);

    return (<Card variant="outlined">
        <Box m={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color="textSecondary">
                    {t('account.currentPlan')}
                </Typography>
                {accountLimit.plan !== 'PRO' &&
                    <Box>
                        <Button variant="text"
                                sx={{padding: 0, minWidth: 'auto'}}
                                onClick={()=>{history.push(`/account/${accountLimit.account}`)}}>{t('pricing.upgradePlan')}</Button>
                    </Box>
                }
            </Box>
            <Box display="flex" justifyContent="center">
                <Typography variant="h4">
                    {accountLimit.plan}
                </Typography>
            </Box>
        </Box>
    </Card>
    )
}