import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";
import {makeStyles, withStyles} from '@mui/styles';
import NumberFormat from 'react-number-format';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {httpClient} from '../../core/HttpClient'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import SaveIcon from "@mui/icons-material/Save";
import {Context} from "../../core/Context";
import Switch from "@mui/material/Switch";
import ListItemIcon from "@mui/material/ListItemIcon";
import Dialog from "@mui/material/Dialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import {Radio, RadioGroup} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import liff from "@line/liff";
import {QRCodePayment} from "../QRCodePayment";
import CardMedia from "@mui/material/CardMedia";
import Snackbar from "@mui/material/Snackbar";
import CreditCardIcon from "@mui/icons-material/CreditCard";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    BBL: {backgroundColor: '#264091', padding: '5px', borderRadius: '6px'},
    KBANK: {backgroundColor: '#039029', padding: '5px', borderRadius: '6px'},
    KTB: {backgroundColor: '#06a4e7', padding: '5px', borderRadius: '6px'},
    TTB: {backgroundColor: '#ffffff', padding: '5px', borderRadius: '6px'},
    SCB: {backgroundColor: '#462279', padding: '5px', borderRadius: '6px'},
    BAY: {backgroundColor: '#fec523', padding: '5px', borderRadius: '6px'},
    KKP: {backgroundColor: '#635f98', padding: '5px', borderRadius: '6px'},
    CIMBT: {backgroundColor: '#ff0000', padding: '5px', borderRadius: '6px'},
    TISCO: {backgroundColor: '#1369b0', padding: '5px', borderRadius: '6px'},
    UOBT: {backgroundColor: '#07367a', padding: '5px', borderRadius: '6px'},
    GHB: {backgroundColor: '#ff3a00', padding: '5px', borderRadius: '6px'},
    GSB: {backgroundColor: '#eb077e', padding: '5px', borderRadius: '6px'},
    ISBT: {backgroundColor: '#073e04', padding: '5px', borderRadius: '6px'},
}));

const bankAccountList = [
    {code: 'BBL'},
    {code: 'KBANK'},
    {code: 'KTB'},
    {code: 'TTB'},
    {code: 'SCB'},
    {code: 'BAY'},
    {code: 'KKP'},
    {code: 'CIMBT'},
    {code: 'TISCO'},
    {code: 'UOBT'},
    {code: 'GHB'},
    {code: 'GSB'},
    {code: 'ISBT'}
];

const GreenSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#44cc00',
        },
        '&$checked + $track': {
            backgroundColor: '#44cc00',
        },
    },
    checked: {},
    track: {},
})(Switch);

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);
const PersonalIDFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="# #### ##### ## #"
    />);

const BillPaymentIDFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###############"
    />);

const BankAccountFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-#-#####-#####"
    />);


export default function Payment() {
    const {t} = useTranslation();
    const classes = useStyles();
    const {id} = useParams();
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const [requirePaymentMethod, setRequirePaymentMethod] = React.useState(false);
    const [invalidQrPayment, setInvalidQrPayment] = React.useState(false);
    const [loading, setLoading] = useState({init: true, update: false});
    const [payment, setPayment] = useState({cash: false, creditCard: false, fundsTransfer: false, fundsTransferList:[{bank: 'BBL', accountNumber: ''}], qrPayment: false, qrPaymentType: 'promptPayAccount', qrPaymentTag: '', qrPaymentRef: '', qrPaymentAccountName: '', promptPayAccountName: '', ownerStatus: ''});
    const [errors, setErrors] = React.useState([]);
    const [account, setAccount] = useState({});
    const [whiteList, setWhiteList] = useState([]);

    useEffect(() => {
        console.log('[Payment]');
        if (id) {
            const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me';
            const paymentUrl = process.env.REACT_APP_API_BASE_URL + '/payments';
            const whitelistUrl = process.env.REACT_APP_API_BASE_URL + '/whitelist/banks';
            let tasks = [httpClient.get(url), httpClient.get(paymentUrl), httpClient.get(whitelistUrl)];
            Promise.all(tasks)
                .then(rs=>{
                    if(rs[0] && rs[0].data){
                        setAccount(rs[0].data);
                    }
                    if(rs[1] && rs[1].data){
                        let _payment = rs[1].data;
                        let defaultAccountName = '';
                        if(rs[2] && rs[2].data && rs[2].data.length > 0){
                            defaultAccountName = rs[2].data[0];
                        }

                        if(_payment.fundsTransferList){
                            if(_payment.fundsTransferList.length === 0){
                                _payment.fundsTransferList = [{bank: 'BBL', accountNumber: '', accountName: defaultAccountName}];
                            } else {
                                let data = _payment.fundsTransferList[0];
                                if(rs[2] && rs[2].data && rs[2].data.length > 0){
                                    if(rs[2].data.indexOf(data.accountName) < 0){
                                        _payment.fundsTransferList[0].accountName = rs[2].data[0];
                                    }
                                }
                            }
                        } else {
                            _payment.fundsTransferList = [{bank: 'BBL', accountNumber: '', accountName: defaultAccountName}];
                        }

                        if(rs[2] && rs[2].data && rs[2].data.length > 0){
                            if(rs[2].data.indexOf(_payment.promptPayAccountName) < 0){
                                _payment.promptPayAccountName = rs[2].data[0];
                            }
                            if(rs[2].data.indexOf(_payment.qrPaymentAccountName) < 0){
                                _payment.qrPaymentAccountName = rs[2].data[0];
                            }
                        }


                        setPayment(_payment);
                    }
                    if(rs[2] && rs[2].data){
                        setWhiteList(rs[2].data);
                    }

                    setLoading({init: false, update: false});
                });
        }
    }, [id]);

    const handleSave = () => {
        if(_validation()){
            loading.update = true;
            const url = process.env.REACT_APP_API_BASE_URL + '/payments';
            httpClient.put(url, payment)
                .then(res => {
                    setLoading({init: false, update: false});
                    setContext({ ...context, success: true});
                    history.push('/payments');
                });
        }

    };

    const _validation = () => {
        let errors = [];
        // if(account.orderingType !== 'none' || account.mobileOrderingType !== 'none'){
            if(payment.fundsTransfer || payment.qrPayment || payment.cash){
                setRequirePaymentMethod(false);
            } else {
                setRequirePaymentMethod(true);
                return;
            }
        // }

        if(id === 'transfer') {
            if (payment.fundsTransfer) {
                if (payment.fundsTransferList.length === 0) {
                    errors.push('accountNumber');
                } else {
                    let data = payment.fundsTransferList[0];
                    if(data.accountNumber.replace(/\D/g,'').trim().length < 10){
                        errors.push('accountNumber');
                    }

                    if(data.accountName){
                        if(data.accountName.length === 0){
                            errors.push('accountName');
                        }

                        if(whiteList.indexOf(data.accountName) < 0){
                            errors.push('accountName');
                        }
                    } else {
                        errors.push('accountName');
                    }
                }

            }
        }

        if(id === 'qrcode'){
            if(payment.qrPayment){
                if(payment.qrPaymentType === 'promptPayAccount'){
                    if(payment){
                        if(!payment[payment.promptPayType]){
                            errors.push(payment.promptPayType);
                        }

                        if(whiteList.indexOf(payment.promptPayAccountName) < 0){
                            errors.push('promptPayAccountName');
                        }

                        if(payment.promptPayType === 'promptPayTypeMobile'){
                            let _phone = payment.promptPayTypeMobile;
                            if(_phone){
                                _phone = _phone.replace(/\D/g,'');
                            }
                            if(!_phone ||
                                _phone.length !== 10 ||
                                !_phone.startsWith('0')
                            ){
                                errors.push('promptPayTypeMobile');
                            }
                        } else if(payment.promptPayType === 'promptPayTypePersonalID'){
                            let _personalID = payment.promptPayTypePersonalID;
                            if(_personalID){
                                _personalID = _personalID.replace(/\D/g,'');
                            }
                            if(!_personalID ||
                                _personalID.length !== 13
                            ){
                                errors.push('promptPayTypePersonalID');
                            }
                        }

                        if(payment.promptPayAccountName){
                            if(payment.promptPayAccountName.length === 0){
                                errors.push('promptPayAccountName');
                            }
                        } else {
                            errors.push('promptPayAccountName');
                        }
                    }
                } else if(payment.qrPaymentType === 'qrPaymentAccount'){
                    if(!(payment.qrPaymentRef && payment.qrPaymentTag)){
                        errors.push('qrPaymentTag');
                    }

                    if(payment.qrPaymentAccountName){
                        if(payment.qrPaymentAccountName.length === 0){
                            errors.push('qrPaymentAccountName');
                        }
                    } else {
                        errors.push('qrPaymentAccountName');
                    }
                }
            }
        }

        setErrors(errors);

        return errors.length < 1;
    }

    const handleTextChange = ({ target }) => {
        if(payment.fundsTransferList.length>0){
            let data = payment.fundsTransferList[0];
            data[target.name] = target.value;
            setPayment({...payment, fundsTransferList: [data]})
        }
    };

    const handleSelectChange = ({ target }) => {
        if(target.name === 'promptPayAccountName'){
            setPayment({...payment, promptPayAccountName: target.value})
        } else if(target.name === 'qrPaymentAccountName'){
            setPayment({...payment, qrPaymentAccountName: target.value})
        } else if(target.name === 'accountName'){
            if(payment.fundsTransferList.length>0){
                let data = payment.fundsTransferList[0];
                data[target.name] = target.value;
                setPayment({...payment, fundsTransferList: [data]})
            }
        } else if(target.name === 'bank'){
            if(payment.fundsTransferList.length>0){
                let data = payment.fundsTransferList[0];
                data[target.name] = target.value;
                setPayment({...payment, fundsTransferList: [data]})
            }
        } else if(target.name === 'qrPaymentType'){
            let fieldValue = {};
            fieldValue[target.name] = target.value;
            setPayment({...payment, ...fieldValue});
        }

    };

    const handlePaymentChange = ({ target }) => {
        setPayment({ ...payment, [target.name]: target.value });
    };

    const handleScanQRCode = async () => {
        try {
            const result = await liff.scanCodeV2();
            if(result && result.value){
                const url = process.env.REACT_APP_API_BASE_URL + '/payments/qrpayment/validate';
                let payload = {
                    qrPaymentTag: result.value
                };
                httpClient.post(url, payload)
                .then(res => {
                    if(res.status === 200){
                        if(res.data && res.data.qrPaymentRef){
                            let qrPaymentRef = res.data.qrPaymentRef;
                            setPayment({...payment, qrPaymentTag: result.value, qrPaymentRef: qrPaymentRef});
                            setErrors([]);
                        } else {
                            setInvalidQrPayment(true);
                        }
                    } else {
                        setInvalidQrPayment(true);
                    }
                }).catch(e=>{
                    setInvalidQrPayment(true);
                });
            }
        } catch (error) {
            setInvalidQrPayment(true);
        }
    };

    const handleClose = (event) => {

        setInvalidQrPayment(false);
    };

    function renderPromptPayTypeElement(){
        if(payment.promptPayType === 'promptPayTypeMobile'){
            return <Box mt={2}>
                <TextField id="promptPayTypeMobile"
                           label={t('payment.promptPayTypeMobile')}
                           variant="outlined"
                           fullWidth={true}
                           name="promptPayTypeMobile" type="tel"
                           value={payment.promptPayTypeMobile}
                           error={errors.indexOf('promptPayTypeMobile') > -1}
                           onChange={ handlePaymentChange }
                           InputLabelProps={{
                               shrink: true,
                           }}
                           InputProps={{
                               inputComponent: PhoneFormatCustom,
                           }}
                           required />
            </Box>;
        } else if(payment.promptPayType === 'promptPayTypePersonalID') {
            return <Box mt={2}>
                <TextField id="promptPayTypePersonalID"
                           label={t('payment.promptPayTypePersonalID')}
                           variant="outlined"
                           fullWidth={true}
                           name="promptPayTypePersonalID" type="tel"
                           value={payment.promptPayTypePersonalID}
                           error={errors.indexOf('promptPayTypePersonalID') > -1}
                           onChange={ handlePaymentChange }
                           InputLabelProps={{
                               shrink: true,
                           }}
                           InputProps={{
                               inputComponent: PersonalIDFormatCustom,
                           }}
                           required />
            </Box>;
        } else if(payment.promptPayType === 'promptPayTypeBillPayment'){
            return <Box mt={2}>
                <TextField id="promptPayTypeBillPayment"
                           label={t('payment.promptPayTypeBillPaymentID')}
                           variant="outlined"
                           fullWidth={true}
                           name="promptPayTypeBillPayment"
                           value={payment.promptPayTypeBillPayment}
                           error={errors.indexOf('promptPayTypeBillPayment') > -1}
                           onChange={ handlePaymentChange }
                           InputLabelProps={{
                               shrink: true,
                           }}
                           onInput={e => {
                               e.target.value = ("" + e.target.value).toUpperCase();
                           }}
                           InputProps={{
                               inputComponent: BillPaymentIDFormatCustom,
                           }}
                           required />
            </Box>;
        } else {
            return null;
        }
    }

    function renderElement(){


        const handleSwitchChange = ({ target }) => {
            setPayment({ ...payment, [target.name]: target.checked });
        };

        if(id === 'cash'){
            return <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Box display="flex"
                         justifyContent="space-between"
                         alignItems="center">
                        <Box display="flex"
                             alignItems="center">
                            <ListItemIcon>
                                <MoneyIcon />
                            </ListItemIcon>
                            <Box mr={1}>
                                <Typography variant="h6">
                                    <div>
                                        {t('payment.channels.cash.title')}
                                    </div>
                                    <div style={{fontSize: '1rem'}}>
                                        {t('payment.channels.cash.subtitle')}
                                    </div>
                                </Typography>
                            </Box>
                        </Box>
                        <GreenSwitch
                            name="cash"
                            checked={ payment.cash }
                            onChange={ handleSwitchChange }
                            color="primary"
                            inputProps={{ 'aria-label': 'active' }}
                        />
                    </Box>
                </CardContent>
            </Card>;
        } else if(id === 'creditCard'){
            return <>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Box display="flex"
                             justifyContent="space-between"
                             alignItems="center">
                            <Box display="flex"
                                 alignItems="center">
                                <ListItemIcon>
                                    <CreditCardIcon />
                                </ListItemIcon>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('payment.channels.creditCard.title')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('payment.channels.creditCard.subtitle')}
                                        </div>
                                    </Typography>
                                </Box>
                            </Box>
                            <GreenSwitch
                                name="creditCard"
                                checked={ payment.creditCard }
                                onChange={ handleSwitchChange }
                                color="primary"
                                inputProps={{ 'aria-label': 'active' }}
                            />
                        </Box>
                    </CardContent>
                </Card>
                <Box mt={1}>
                    <Typography variant="caption">
                        * {t('payment.channels.creditCard.remark')}
                    </Typography>
                </Box>
            </>;
        } else if(id === 'transfer'){
            return <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Box display="flex"
                         justifyContent="space-between"
                         alignItems="center">
                        <Box display="flex"
                             alignItems="center">
                            <ListItemIcon>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <Box ml={1} mr={1}>
                                <Typography variant="h6">
                                    <div>
                                        {t('payment.channels.fundstransfer.title')}
                                    </div>
                                    <div style={{fontSize: '1rem'}}>
                                        {t('payment.channels.fundstransfer.subtitle')}
                                    </div>
                                </Typography>
                            </Box>
                        </Box>
                        <GreenSwitch
                            name="fundsTransfer"
                            checked={ payment.fundsTransfer }
                            onChange={ handleSwitchChange }
                            color="primary"
                            inputProps={{ 'aria-label': 'active' }}
                        />
                    </Box>
                    <Collapse in={payment.fundsTransfer}>
                    <>
                        <Divider style={{'marginBottom': '20px', 'marginTop': '20px'}} />
                        {payment.fundsTransferList.length > 0 &&
                            <Box>
                                <Box mb={2}>
                                    <Box mt={1}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}>
                                            <InputLabel id="time-slip-select-filled-label">{t('payment.bank')}</InputLabel>
                                            <Select
                                                native
                                                labelId="time-slip-select-filled-label"
                                                label={t('payment.bank')}
                                                name="bank"
                                                value={'' + payment.fundsTransferList[0].bank}
                                                onChange={handleSelectChange}>
                                                {
                                                    bankAccountList.map((item) => (
                                                        <option key={item.code} value={item.code}>
                                                            {t(`banks.${item.code}`)}
                                                        </option>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box mb={2}>
                                    <TextField label={t('payment.accountNumber')} variant="outlined" fullWidth={true}
                                               name="accountNumber"
                                               type="tel"
                                               value={payment.fundsTransferList[0].accountNumber}
                                               InputProps={{
                                                   inputComponent: BankAccountFormatCustom,
                                               }}
                                               error={errors.indexOf('accountNumber') > -1}
                                               onChange={handleTextChange}/>
                                </Box>
                                <Box mb={2}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}>
                                        <InputLabel id="account-name-select-filled-label" style={{backgroundColor: 'white'}}
                                                    shrink={true}>{t('payment.accountName')}</InputLabel>
                                        <Select
                                            native
                                            labelId="account-name-select-filled-label"
                                            label={t('payment.accountName')}
                                            name="accountName"
                                            value={payment.fundsTransferList[0].accountName}
                                            error={errors.indexOf('accountName') > -1}
                                            onChange={handleSelectChange}>
                                            {
                                                whiteList.map((item, index) => (
                                                    <option key={`name-${index}`} value={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                {whiteList.length === 0 &&
                                    <Box>
                                        <Typography color={"red"} variant={"caption"}>
                                            *{t('payment.accountNameNotFound')}
                                        </Typography>
                                    </Box>
                                }
                                {whiteList.length > 0 &&
                                    <Box>
                                        <Alert severity="info">{t('payment.accountNameRemark')}</Alert>
                                    </Box>
                                }
                            </Box>
                        }
                    </>
                    </Collapse>
                </CardContent>
            </Card>;
        } else if(id === 'qrcode'){
            return <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Box display="flex"
                         justifyContent="space-between"
                         alignItems="center">
                        <Box display="flex"
                             alignItems="center">
                            <ListItemIcon>
                                <QrCodeScannerIcon />
                            </ListItemIcon>
                            <Box mr={1}>
                                <Typography variant="h6">
                                    <div>
                                        {t('payment.channels.qrPayment.title')}
                                    </div>
                                    <div style={{fontSize: '1rem'}}>
                                        {t('payment.channels.qrPayment.subtitle')}
                                    </div>
                                </Typography>
                            </Box>
                        </Box>
                        <GreenSwitch
                            name="qrPayment"
                            checked={ payment.qrPayment }
                            onChange={ handleSwitchChange }
                            color="primary"
                            inputProps={{ 'aria-label': 'active' }}
                        />
                    </Box>
                    <Collapse in={payment.qrPayment}>
                    <>
                        <Divider style={{'marginBottom': '20px', 'marginTop': '20px'}} />
                        <Card variant={"outlined"}>
                            <CardContent>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="qrPaymentType"
                                        name="qrPaymentType"
                                        defaultValue={"promptPayAccount"}
                                        value={payment.qrPaymentType}
                                        onChange={handleSelectChange}
                                    >
                                        <FormControlLabel value="promptPayAccount" control={<Radio/>}
                                                          label={t('payment.promptPayAccount')}/>
                                        <FormControlLabel value="qrPaymentAccount" control={<Radio/>}
                                                          label={t('payment.qrPaymentAccount')}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                        {payment.qrPaymentType === 'promptPayAccount' &&
                            <Box>
                                <Box mt={4}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}>
                                        <InputLabel
                                            id="time-slip-select-filled-label">{t('payment.promptPayType')}</InputLabel>
                                        <Select
                                            native
                                            labelId="time-slip-select-filled-label"
                                            label={t('payment.promptPayType')}
                                            name="promptPayType"
                                            value={payment.promptPayType}
                                            onChange={handlePaymentChange}>
                                            <option key="mobile" value="promptPayTypeMobile">
                                                {t('payment.promptPayTypeMobile')}
                                            </option>
                                            <option key="personalID" value="promptPayTypePersonalID">
                                                {t('payment.promptPayTypePersonalID')}
                                            </option>
                                            {/*<option key="billPaymentID" value="promptPayTypeBillPayment">*/}
                                            {/*    {t('payment.promptPayTypeBillPayment')}*/}
                                            {/*</option>*/}
                                        </Select>
                                    </FormControl>
                                </Box>
                                {renderPromptPayTypeElement()}
                                <Box mt={2} mb={2}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}>
                                        <InputLabel id="prompt-account-name-select-filled-label" style={{backgroundColor: 'white'}}
                                                    shrink={true}>{t('payment.promptPayAccountName')}</InputLabel>
                                        <Select
                                            native
                                            labelId="prompt-account-name-select-filled-label"
                                            label={t('payment.promptPayAccountName')}
                                            name="promptPayAccountName"
                                            value={payment.promptPayAccountName}
                                            error={errors.indexOf('promptPayAccountName') > -1}
                                            onChange={handleSelectChange}>
                                            {
                                                whiteList.map((item, index) => (
                                                    <option key={`name-${index}`} value={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        }
                        {payment.qrPaymentType === 'qrPaymentAccount' &&
                            <Box>
                                <Box m={2}>
                                    {payment.qrPaymentTag &&
                                        <Card>
                                            <CardMedia
                                                component="img"
                                                alt="thai qr payment"
                                                image={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/thai_qr_code_payment_header.png`}
                                            />
                                            <CardContent style={{paddingTop: 0}}>
                                                <Box display="flex" justifyContent="center" flexDirection={"column"} alignItems={"center"}>
                                                    <QRCodePayment value={payment.qrPaymentTag} />
                                                    <Typography variant={"caption"}>
                                                        {t('payment.qrPaymentAccountReference')} : {payment.qrPaymentRef}
                                                    </Typography>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    }
                                </Box>
                                {errors.indexOf("qrPaymentTag") > -1 &&
                                    <Box mb={1}>
                                        <Alert severity="error">{t('payment.qrPaymentTagRequire')}</Alert>
                                    </Box>
                                }
                                <Button variant="outlined"
                                        startIcon={<QrCodeScannerIcon />}
                                        color="primary"
                                        size="large"
                                        fullWidth={true}
                                        disableElevation
                                        onClick={handleScanQRCode}>
                                    {t('payment.scanQRCodeShop')}
                                </Button>
                                <Box mb={3}>
                                    <Typography variant={"caption"}>
                                        *{t('payment.qrPaymentAccountRemark')}
                                    </Typography>
                                </Box>
                                <Box mt={2} mb={2}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}>
                                        <InputLabel id="qrpayment-account-name-select-filled-label" style={{backgroundColor: 'white'}}
                                                    shrink={true}>{t('payment.qrPaymentAccountName')}</InputLabel>
                                        <Select
                                            native
                                            labelId="qrpayment-account-name-select-filled-label"
                                            label={t('payment.qrPaymentAccountName')}
                                            name="qrPaymentAccountName"
                                            value={payment.qrPaymentAccountName}
                                            error={errors.indexOf('qrPaymentAccountName') > -1}
                                            onChange={handleSelectChange}>
                                            {
                                                whiteList.map((item, index) => (
                                                    <option key={`name-${index}`} value={item}>
                                                        {item}
                                                    </option>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        }
                        {whiteList.length === 0 &&
                            <Box>
                                <Typography color={"red"} variant={"caption"}>
                                    *{t('payment.accountNameNotFound')} <Button variant="text" onClick={()=>{history.push(`/account/${account._id}/verification`)}}>({t('common.message.verify')})</Button>
                                </Typography>
                            </Box>
                        }
                        {whiteList.length > 0 &&
                            <Box>
                                <Alert severity="info">{t('payment.accountNameRemark')}</Alert>
                            </Box>
                        }
                    </>
                </Collapse>
                </CardContent>
            </Card>;
        }

        return null;
    }

    return (
        <div>
            <form className={classes.root} noValidate autoComplete="off">
                <Box m={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/payments" className={classes.link}>
                            <Typography variant="h6">
                                {t('order.payment')}
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}}/>
                <Box m={1} pb={2}>
                    <Paper className={classes.paper} elevation={0}>
                        { renderElement(id, t, payment) }
                    </Paper>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button variant="contained"
                                startIcon={<SaveIcon />}
                                color="primary"
                                size="large"
                                fullWidth={true}
                                disableElevation
                                disabled={loading.update}
                                onClick={(e) => handleSave(e)}>
                            {t('common.save')}
                        </Button>
                    </Box>
                </Box>
            </form>
            <Dialog
                open={requirePaymentMethod}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <Box p={2} pb={1}>
                    <Alert severity="info">
                        {t('payment.requirePaymentChannel')}
                    </Alert>
                </Box>
                <DialogActions>
                    <Box pl={1} pr={1} pb={1} sx={{width: '100%'}}>
                        <Button variant="outlined" size={"large"} fullWidth={true} onClick={e=>setRequirePaymentMethod(false)}>
                            {t('common.iSee')}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Snackbar open={invalidQrPayment}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {t('common.message.invalidQRPayment')}
                </Alert>
            </Snackbar>
        </div>
    )
}