import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import {httpClient} from "../../../core/HttpClient";
import {Context} from "../../../core/Context";

import Card from "@mui/material/Card";
import {ListItem, Switch as SubSwitch} from "@mui/material";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import {Link, useHistory, useLocation} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#00B300',
            dark: '#00C900',
            contrastText: '#ffffff',
        },
    },
});

export default function Pickup() {
    const { t } = useTranslation();
    const search = useLocation().search;
    const orderingType = new URLSearchParams(search).get('orderingType');
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const [context, setContext] = useContext(Context);
    const [account, setAccount] = useState({zoom: 15,
        deliveryStartPrice: 0,
        deliveryPrice: 0,
        deliveryStartAt: 1,
        delivery: false,
        pickup: false,
        orderingAcceptation: 'accept',
        deliveryType: 'immediately',
        deliveryTimes: []
    });

    console.log('Pickup');

    useEffect(() => {
        console.log('[Pickup]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me';
        let tasks = [httpClient.get(url)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setAccount(rs[0].data);
                }
            });
    }, []);

    const handleSave = () => {

        let _data = {
            _id: account._id,
            orderingType: orderingType,
            pickup: account.pickup
        };

        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id + '/pickup';
        httpClient.post(url, _data)
            .then(res => {
                setLoading(false);
                setContext({ ...context, success: true});
                history.push('/deliver');
            });
    };

    const handleSwitchChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        setAccount({...account, ...fieldValue});
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/deliver" className={classes.link}>
                        <Typography variant="h6">
                            {t('delivery.title')}
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                <Card className={classes.root} variant="outlined">
                    <List className={classes.root}>
                        <ListItem key="pickup">
                            <ListItemIcon>
                                <ShoppingBagIcon/>
                            </ListItemIcon>
                            <ListItemText id='pickup'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('deliver.pickup')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('deliver.pickupDescription')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <SubSwitch
                                    checked={account.pickup}
                                    onChange={ (e) => handleSwitchChange(e) }
                                    color="primary"
                                    name="pickup"
                                    inputProps={{ 'aria-label': 'open' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Card>
                <Box display="flex" justifyContent="center" mt={2} pb={2}>
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            disabled={loading.update}
                            startIcon={<SaveIcon/>}
                            onClick={(e) => handleSave(e)}>
                        {t('common.save')}
                    </Button>
                </Box>
            </Box>
        </div>
    )
}