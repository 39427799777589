import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {httpClient} from "../../../core/HttpClient";

import Card from "@mui/material/Card";
import {Radio, RadioGroup} from "@mui/material";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Collapse from "@mui/material/Collapse";
import SaveIcon from "@mui/icons-material/Save";
import {Context} from "../../../core/Context";
import {Link} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {SelectionRate} from "../../SelectionRate";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

const MemoizedSelectionRate = React.memo(({label, name, value, min, minLabel='', prefix='', onChange})=>{
    return(<SelectionRate label={label} name={name} value={value} minLabel={minLabel} onChange={ onChange } min={min} prefix={prefix} />)
});

export default function DiscountDeliver() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [context, setContext] = useContext(Context);
    const [discount, setDiscount] = useState({
        delivery: 'none',
        food: 'none',
        deliveryRates: [
            {price: 1, discount: 1}
        ],
        foodRates: [
            {price: 1, discount: 1}
        ]
    });

    console.log('DiscountDeliver');
    useEffect(() => {
        console.log('[DiscountDeliver]');
        const url = process.env.REACT_APP_API_BASE_URL + '/discounts';
        let tasks = [
            httpClient.get(url),
        ];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setDiscount(rs[0].data);
                }
                setLoading(false);
            });
    }, []);

    const handleChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.value;
        setDiscount({...discount, ...fieldValue});
    };

    const handleRateChange = (e) => {
        let _rate = discount.deliveryRates[0];
        _rate[e.target.name] = e.target.value;
        setDiscount({...discount, deliveryRates: [_rate]})
    };

    const handleSave = () => {

        const url = process.env.REACT_APP_API_BASE_URL + '/discounts';
        setUpdating(true);
        httpClient.post(url, discount)
            .then(res => {
                setUpdating(false);
                setContext({ ...context, success: true});
            });
    };


    return (
        <div className={classes.root}>
            <Box m={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/discounts" className={classes.link}>
                        <Typography variant="h6">
                            {t('discount.delivery')}
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <>
                    <Box m={1}>
                        <Box>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <Typography>
                                    {t('discount.setting')}
                                </Typography>
                            </Box>
                        </Box>
                        <Card variant={"outlined"}>
                            <CardContent>
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="delivery"
                                        defaultValue="none"
                                        name="delivery"
                                        value={discount.delivery}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel value="none" control={<Radio/>} label={t('discount.none')}/>
                                        <FormControlLabel value="discount" control={<Radio/>}
                                                          label={t('discount.discount')}/>
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Box>
                    <Collapse in={['discount','free'].indexOf(discount.delivery) > -1}>
                        <Box m={1} mt={2}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Typography>
                                    {t('discount.rate')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box m={1}>
                                <Box>
                                    <MemoizedSelectionRate
                                        label={t('discount.minimumTotalPrice')}
                                        name="price"
                                        value={discount.deliveryRates[0].price}
                                        min={1}
                                        prefix={"฿"}
                                        onChange={handleRateChange}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <MemoizedSelectionRate
                                        label={t('discount.discountDeliveryAmount')}
                                        name="discount"
                                        value={discount.deliveryRates[0].discount}
                                        min={0}
                                        minLabel={t('discount.free')}
                                        prefix={"฿"}
                                        onChange={handleRateChange}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                    <Box display="flex" justifyContent="center" m={1} mt={2} pb={2}>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                disabled={updating}
                                startIcon={<SaveIcon/>}
                                onClick={(e) => handleSave(e)}>
                            {t('common.save')}
                        </Button>
                    </Box>
                </>
            }
        </div>
    )
}