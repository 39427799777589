import React, { forwardRef } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIcon from '@mui/icons-material/Assignment';

const useStyles = makeStyles({
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#efefef'
    },
});

const FooterMenu = forwardRef((props, ref) => {
    const pathname = window.location.pathname;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <footer>
            <BottomNavigation
                value={pathname}
                style={{zIndex: 999, height: '75px', backgroundColor: '#f7f7f7'}}
                showLabels
                className={classes.stickToBottom}>
                <BottomNavigationAction label={t('dashboard.title')}
                                        icon={<HomeIcon/>}
                                        component={Link}
                                        to='/home'
                                        value='/home' />
                <BottomNavigationAction label={t('order.title')}
                                        icon={<AssignmentIcon/>}
                                        component={Link}
                                        to="/transactions"
                                        value="/transactions"/>
            </BottomNavigation>
        </footer>
    )
});

export default FooterMenu;