import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import liff from "@line/liff";
import {httpClient} from "../../core/HttpClient";

// const liff = window.liff;
const lineAuthentication = async(cb) => {
    const liffId = process.env.REACT_APP_LINE_SIGNIN_LIFF_ID;
    await liff.init({ liffId: liffId, withLoginOnExternalBrowser: true }).catch(err=>{throw err});
    if (liff.isLoggedIn()) {
        const accessToken = await liff.getAccessToken();
        return cb(accessToken);
    }else{
        liff.login(); //external browser can not use liff.login(); for verify token
    }
};

export default function SignIn() {
    const history = useHistory();
    const { mode, key } = useParams();

    useEffect(() => {
        console.log('[SignIn]');
        lineAuthentication(accessToken=>{
            if(accessToken){

                const requestOptions = {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({accessToken: accessToken})
                };
                const url = process.env.REACT_APP_API_BASE_URL + '/auth/line/token';
                fetch(url, requestOptions)
                    .then(results => results.json())
                    .then(data => {
                        if (data.token) {
                            localStorage.setItem('token', data.token);
                            localStorage.setItem('refreshToken', data.refreshToken);

                            if(mode==='invite'){
                                const inviteUrl = process.env.REACT_APP_API_BASE_URL + '/users/register/account';
                                httpClient.post(inviteUrl, {key: key})
                                    .then(res => {
                                        fetch(url, requestOptions)
                                            .then(results => results.json())
                                            .then(data => {
                                                if (data.token) {
                                                    localStorage.setItem('token', data.token);
                                                    localStorage.setItem('refreshToken', data.refreshToken);

                                                    history.push('/passcode');
                                                } else {
                                                    localStorage.removeItem('token');
                                                    localStorage.removeItem('refreshToken');
                                                    history.push('/login');
                                                }
                                            });
                                    });

                            } else {
                                if (data.accountId) {
                                    history.push('/passcode');
                                    // history.push('/home');
                                } else {
                                    history.push('/init');
                                }
                            }
                        } else {
                            localStorage.removeItem('token');
                            localStorage.removeItem('refreshToken');
                            history.push('/login');
                        }

                    });
            }
        });
    }, [history, mode, key]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20} />
            </Box>
        </div>
    )
}