import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {httpClient} from "../../core/HttpClient";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardActions from "@mui/material/CardActions";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {styled, createTheme, ThemeProvider} from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import {DateI18n} from "../DateI18n";
import Dialog from "@mui/material/Dialog";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {getDatabase, goOffline, goOnline, onValue, ref} from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import {useHistory} from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import { useIdleTimer } from 'react-idle-timer';
import {getDatabaseInstance} from "../../core/Firebase";

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    background: 'white',
    border: '0px',
    padding: 15,
};

const theme = createTheme({
    palette: {
        primary: {
            main: '#44cc00',
            contrastText: '#ffffff',
        },
    },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey["300"],
        color: theme.palette.common.black,
        fontWeight: 'bold',
        padding: 8
    },
}));

let PRICE = 29;
let CREDIT = 100;

export default function AccountCredit() {
    const { t } = useTranslation();
    const history = useHistory();
    const [accountCredit, setAccountCredit] = useState({});
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [qrCodeData, setQrCodeData] = useState(null);
    const [paymentComplete, setPaymentComplete] = useState(false);
    const [payment, setPayment] = useState({date: new Date()});
    const [productQTY, setProductQTY] = useState(1);
    const [notifyError, setNotifyError] = useState(0);
    const [totalPrice, setTotalPrice] = useState(PRICE);
    const [totalCredit, setTotalCredit] = useState(CREDIT);

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            if(document.visibilityState === 'hidden'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    useEffect(() => {
        console.log('[AccountCredit]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/credit';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAccountCredit(res.data);
                }
            });
    }, []);

    const handleClickOpenRedeem = () => {
        setProductQTY(1);
        setTotalPrice(PRICE);
        setTotalCredit(CREDIT);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleNotifyClose = () => {
        setNotifyError(0);
    };

    const handleIncreaseQTY = () =>{
        if(productQTY > 99){
            return;
        }
        let qty = productQTY + 1;
        setProductQTY(qty);

        let pp = PRICE;
        // let vat = pp * 0.07;
        let vat = 0;
        let totalPrice = (pp * qty)+vat;
        // setVat(vat.toFixed(2));
        setTotalPrice(totalPrice.toFixed(2));
        setTotalCredit(qty * CREDIT);
    }

    const handleDecreaseQTY = () =>{
        if(productQTY < 2){
            return;
        }
        let qty = productQTY - 1;
        setProductQTY(qty);

        let pp = PRICE;
        // let vat = pp * 0.07;
        let vat = 0;
        let totalPrice = (pp * qty)+vat;
        // setVat(vat.toFixed(2));
        setTotalPrice(totalPrice.toFixed(2));
        setTotalCredit(qty * CREDIT);
    }

    function validateLimitation() {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_BASE_URL + '/validations/credit';
            httpClient.post(url, {qty: productQTY})
                .then(res => {
                    if(res.status === 200){
                        if(res.data.ok === 1){
                            resolve(res.data);
                        } else {
                            reject(res.data);
                        }
                    } else {
                        reject(res.data);
                    }
                })
                .catch(e=>{
                    reject(e);
                })
        })
    }

    const handlePayment = () => {
        validateLimitation().then(r=>{
            setPayment({date: new Date()});
            setQrCodeData(null);
            setPaymentComplete(false);
            const url = process.env.REACT_APP_API_BASE_URL + '/payments/channels/qr';
            httpClient.post(url, {qty: productQTY, account: accountCredit._account, pcode: 'CREDIT'})
                .then(res => {
                    if(res.data.data){
                        setQrCodeData({orderNo: res.data.orderNo, amount: res.data.amount, qrcodeId: res.data.data.qrcodeId,  base64:res.data.data.qrImage});
                    }

                    let key = res.data.uid;
                    let app = getDatabaseInstance();
                    const db = getDatabase(app);
                    const auth = getAuth(app);
                    signInAnonymously(auth)
                        .then(() => {
                            const statusRef = ref(db, 'payments/' + key);
                            onValue(statusRef, (snapshot) => {
                                const data = snapshot.val();
                                if(data && data.status === 'success'){
                                    setPaymentComplete(true);
                                }
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                        });

                });
            setOpenPayment(true);
        }).catch(e=>{
            setNotifyError(1);
        });

    };

    const handleClosePayment = () => {
        setOpenPayment(false);
    };

    const handleClosePaymentComplete = () => {
        if(accountCredit._id){
            setOpenPayment(false);
            setPaymentComplete(false);
            setOpen(false);
            const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/credit';
            httpClient.get(url)
                .then(res => {
                    if(res.data){
                        setAccountCredit(res.data);
                    }
                });
        }
    };

    const handleViewHistory = () => {
        history.push('/history/credit');
    };

    return (<Card variant="outlined">
        <Box m={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color="textSecondary">
                    {t('accountCredit.creditRemaining')}
                </Typography>
                <Button variant="text"
                        onClick={handleViewHistory}
                        sx={{padding: 0, minWidth: 'auto'}}>
                    {t('common.viewHistory')}
                </Button>
            </Box>
            <Box display="flex" justifyContent="center">
                <Typography variant="h4">
                    <NumberFormat value={accountCredit.remaining} displayType={'text'} thousandSeparator={true} />
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <Typography>
                    {t('accountCredit.credit')}
                </Typography>
            </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
            <Button variant="text"
                    size={"small"}
                    color={"default"}
                    onClick={e=>setOpenInfo(true)}
                    startIcon={<InfoIcon />}>
                <Typography variant={"caption"}>
                    {t('common.info')}
                </Typography>
            </Button>
        </Box>
        <CardActions>
            <Button variant="outlined"
                    color="primary"
                    fullWidth={true}
                    size="large"
                    disableElevation
                    onClick={handleClickOpenRedeem}>
                {t('accountCredit.redeem')}
            </Button>
        </CardActions>
        <Dialog
            fullScreen={true}
            open={openInfo}
            aria-labelledby="info-modal-title"
            aria-describedby="info-modal-description"
            onClose={e=>setOpenInfo(false)}
        >
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                        {t('common.info')}
                    </Typography>
                    <IconButton edge="start"color="primary" aria-label="black" onClick={e=>setOpenInfo(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Box mb={1}>
                    <Typography>{t('usage.creditInfo.title')}</Typography>
                </Box>
                <Box>
                    <Typography>{t('usage.creditInfo.1')}</Typography>
                </Box>
                <Box>
                    <Typography>{t('usage.creditInfo.2')}</Typography>
                </Box>
                <Box>
                    <Typography>{t('usage.creditInfo.3')}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant={"caption"}>{t('usage.creditInfo.remark')}</Typography>
                </Box>
            </DialogContent>
        </Dialog>
        <Dialog
            fullScreen={true}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                        {t('accountCredit.redeem')}
                    </Typography>
                    <IconButton edge="start"color="primary" aria-label="black" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <DialogContent style={{paddingTop: '5px'}}>
                <Table aria-label="credit table" style={{border: '1px solid #ebebeb'}}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">{t('common.package')}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <Typography>{t('accountCredit.credit')}</Typography>
                                <Typography variant="h6">{CREDIT}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center">
                                <Typography>{t('accountCredit.pricing')}</Typography>
                                <Typography>{t('accountCredit.price')}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Box mb={4} style={{width: '100%'}}>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={1} mt={1}>
                        <Typography>{t('common.earn')}</Typography>
                        <Box ml={1}>
                            <Typography><NumberFormat value={totalCredit} displayType={'text'} thousandSeparator={true} /> {t('accountCredit.credit')}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                        <IconButton color="default" aria-label="decrease" component="span" onClick={handleDecreaseQTY}>
                            <RemoveCircleOutlineIcon sx={{ fontSize: 45 }} />
                        </IconButton>
                        <Box sx={{
                            width: 100,
                            height: 45,
                            marginLeft: '10px',
                            marginRight: '10px',
                            border: '1px solid grey',
                        }} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant="h6">{productQTY}</Typography>
                        </Box>
                        <IconButton color="default" aria-label="increase" component="span" onClick={handleIncreaseQTY}>
                            <AddCircleOutlineIcon sx={{ fontSize: 45 }} />
                        </IconButton>
                    </Box>
                    <ThemeProvider theme={theme}>
                        <Button variant="contained" color="primary" size="large" fullWidth={true} disableElevation onClick={handlePayment} >
                            <Box display="flex" justifyContent="space-between" alignItems="center" style={{width: '100%'}}>
                                <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}>{t('pricing.payment')}</Typography>
                                <Typography variant="h6" color="inherit" style={{fontWeight: 'bold', fontSize: '1.2rem'}}><NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                            </Box>
                        </Button>
                    </ThemeProvider>
                </Box>
            </DialogActions>
            <Dialog
                fullScreen={true}
                open={openPayment}
                aria-labelledby="payment-modal-title"
                aria-describedby="payment-modal-description"
            >
                <Box style={modalStyle}>
                    {paymentComplete &&
                        <div>
                            <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                                <CheckCircleOutlineIcon color={"success"} sx={{ fontSize: 60 }} />
                            </Box>
                            <Alert icon={false} severity="success">{t('payment.complete')}</Alert>
                            <Box mt={2}>
                                <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                        onClick={handleClosePaymentComplete}>
                                    {t('common.ok')}
                                </Button>
                            </Box>
                        </div>
                    }
                    {!paymentComplete &&
                        <div>
                            <Box display="flex" justifyContent="center" mt={2} mb={2}>
                                <Typography variant="h5"
                                            color="inherit"><b>{t('payment.scanForPaid')}</b></Typography>
                            </Box>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/promptpay_visa_master.png`} alt="payment network"
                                     style={{width: '100%', maxWidth: '200px'}}/>
                            </Box>
                            {!qrCodeData &&
                                <Box display="flex" justifyContent="center" mt={3} mb={4}>
                                    <CircularProgress size={20}/>
                                </Box>
                            }
                            {qrCodeData &&
                                <>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                        <img src={`data:image/png;base64,${qrCodeData.base64}`} alt="pay"
                                             style={{width: '100%', maxWidth: '250px'}}/>
                                    </Box>
                                    <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                                        <Typography variant="body1" color="inherit">{qrCodeData.qrcodeId}</Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1"
                                                    color="inherit">{t('pricing.totalAmount')}</Typography>
                                        <Typography variant="body1"
                                                    color="inherit"><NumberFormat value={qrCodeData.amount} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                                    </Box>
                                    <hr/>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" color="inherit">{t(`pricing.ref1`)}</Typography>
                                        <div>{qrCodeData && <span>{qrCodeData.orderNo}</span>}</div>
                                    </Box>
                                    <hr/>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography variant="body1" color="inherit">{t(`pricing.paymentDate`)}</Typography>
                                        <div><DateI18n value={payment.date} format="DD MMM YYYY HH:mm" /></div>
                                    </Box>
                                </>
                            }
                            <Box mt={2}>
                                <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                        onClick={handleClosePayment}>
                                    {t('common.cancel')}
                                </Button>
                            </Box>
                        </div>
                    }
                </Box>
            </Dialog>
        </Dialog>
        <Snackbar open={notifyError > 0}
                  anchorOrigin={{vertical:'top', horizontal:'center'}}
                  autoHideDuration={3000} onClose={handleNotifyClose}>
            <Alert onClose={handleNotifyClose} severity="warning">
                {notifyError === 1 && t('account.limitCredit')}
            </Alert>
        </Snackbar>
    </Card>
    )
}