import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
        cursor: 'pointer'
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
}));

export default function NotificationsInfo() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = useParams();
    const history = useHistory();

    useEffect(() => {
        console.log('[NotificationsInfo]');
    }, [id]);

    const handleBack = () => {
        history.push('/notifications');
    };

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" m={1}>
                <IconButton edge="start" color="primary" aria-label="black" onClick={handleBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">
                    {t('notification.info')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />

        </div>
    )
}
