import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SaveIcon from '@mui/icons-material/Save';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {httpClient} from '../../core/HttpClient'
import {Context} from "../../core/Context";
import {makeStyles} from "@mui/styles";
import {Slider} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="###"
            isNumericString
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function TableSetup() {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [context, setContext] = useContext(Context);
    const [loading, setLoading] = useState({init: false, update: false});
    const [limit, setLimit] = useState(10);
    const [createTable, setCreateTable] = useState({
        count: 0,
    });

    console.log('TableSetup');
    useEffect(() => {
        console.log('[TableSetup]');
        const accountLimitURL = process.env.REACT_APP_API_BASE_URL + '/accounts/me/limited';
        const setupURL = process.env.REACT_APP_API_BASE_URL + '/tables/setup/count';
        let tasks = [httpClient.get(accountLimitURL), httpClient.get(setupURL)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs && rs[0] && rs[0].data){
                    setLimit(rs[0].data.table);
                }
                if(rs && rs[1] && rs[1].data){
                    setCreateTable(rs[1].data);
                }

                setLoading({init: false, update: false});
            })
            .catch(e=>{
                console.log('error', e);
                setLoading({init: false, update: false});
            });
    }, [id]);

    const handleSave = () => {
        setLoading({init: false, update: true});
        const url = process.env.REACT_APP_API_BASE_URL + '/tables/generate';
        httpClient.post(url, createTable)
            .then(res => {
                setContext({ ...context, success: true});
                setLoading({init: false, update: false});
                history.push('/tables');
            });
    };

    const handleCancel = () => {
        history.push('/tables');
    };

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setCreateTable({count: newValue});
    };

    return (
        <div>
            <form noValidate autoComplete="off">
                <Box m={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/tables" className={classes.link}>
                            <Typography variant="h6">
                                {t('table.title')}
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                <Box m={1}>
                    <Paper elevation={0}>
                        <Card elevation={1}>
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1">
                                        {t('table.countTable')}
                                    </Typography>
                                    <Typography variant="body1">
                                        {createTable.count}
                                    </Typography>
                                </Box>
                                <Box mt={2}>
                                    <Slider key={`slider-${createTable.count}`}
                                            defaultValue={createTable.count}
                                            max={limit}
                                            aria-label="Default"
                                            onChangeCommitted={handleSliderChange}
                                            valueLabelDisplay="auto" />
                                </Box>
                            </CardContent>
                        </Card>
                    </Paper>
                    <Box mt={2}>
                    <Box mb={2}>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                disabled={loading.update}
                                startIcon={<SaveIcon />}
                                onClick={(e) => handleSave(e)}>
                            {t('common.save')}
                        </Button>
                    </Box>
                    <Box mb={2}>
                        <Button variant="contained"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                disabled={loading.update}
                                color={"grey"}
                                onClick={handleCancel}>
                            {t('common.cancel')}
                        </Button>
                    </Box>
                </Box>
                </Box>
            </form>
        </div>
    )
}