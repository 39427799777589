import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import MoneyIcon from '@mui/icons-material/Money';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import {httpClient} from "../../core/HttpClient";
import Card from "@mui/material/Card";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import {useHistory} from "react-router-dom";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Payments() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [payment, setPayment] = useState({cash: false, fundsTransfer: false, fundsTransferList:[], qrPayment: false, promptPayQrCodeImage: '', creditCard: false});

    useEffect(() => {
        console.log('[Payments]');
        const paymentUrl = process.env.REACT_APP_API_BASE_URL + '/payments';
        let tasks = [httpClient.get(paymentUrl)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setPayment(rs[0].data);
                }
            })
            .catch(e=>{
                console.log('error', e);
            });
    }, []);

    const handleClick = (key) => () => {
        history.push('/payments/' + key);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('order.payment')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                <Card variant="outlined">
                    <List className={classes.root}>
                        <ListItem divider key="cash" role={undefined} button onClick={handleClick('cash')}>
                            <ListItemIcon>
                                <MoneyIcon />
                            </ListItemIcon>
                            <ListItemText id='cash'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('payment.channels.cash.title')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('payment.channels.cash.subtitle')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction onClick={handleClick('cash')}>
                                <FiberManualRecordIcon color={payment.cash?'primary':'disabled'} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider key="creditCard" dense role={undefined} button onClick={handleClick('creditCard')}>
                            <ListItemIcon>
                                <CreditCardIcon />
                            </ListItemIcon>
                            <ListItemText id='creditCard'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('payment.channels.creditCard.title')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('payment.channels.creditCard.subtitle')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction onClick={handleClick('creditCard')}>
                                <FiberManualRecordIcon color={payment.creditCard?'primary':'disabled'} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem divider  key="qrcode" role={undefined} button onClick={handleClick('qrcode')}>
                            <ListItemIcon>
                                <QrCodeScannerIcon />
                            </ListItemIcon>
                            <ListItemText id='qrcode'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('payment.channels.qrPayment.title')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('payment.channels.qrPayment.subtitle')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction onClick={handleClick('qrcode')}>
                                <FiberManualRecordIcon color={payment.qrPayment?'primary':'disabled'} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem key="fundsTransfer" role={undefined} button onClick={handleClick('transfer')}>
                            <ListItemIcon>
                                <AccountBalanceIcon />
                            </ListItemIcon>
                            <ListItemText id='fundsTransfer'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('payment.channels.fundstransfer.title')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('payment.channels.fundstransfer.subtitle')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction onClick={handleClick('transfer')}>
                                <FiberManualRecordIcon color={payment.fundsTransfer?'primary':'disabled'} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Card>
            </Box>
        </div>
    )
}