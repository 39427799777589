import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import {createTheme, useTheme} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {httpClient} from '../../core/HttpClient'
import {Context} from "../../core/Context";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    actionControl: {
        marginTop: '1rem'
    },
    paper: {
        marginBottom: '10px'
    },
    boxLeft: {
        padding: theme.spacing(1),
        textAlign: 'left'
    },
    boxRight: {
        padding: theme.spacing(1),
        textAlign: 'right'
    },
    itemSave: {
        order: 1,
        [theme.breakpoints.up('sm')]: {
            order: 2,
        },
    },
    itemCancel: {
        order: 2,
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputBrowse: {
        display: 'none',
    },
}));

function NumberFormatCustom(props) {
    const {inputRef, onChange, ...other} = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix={props.prefix}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function ProductGroup() {
    const {t} = useTranslation();
    const classes = useStyles();
    const {id} = useParams();
    const theme = useTheme();
    const defaultTheme = createTheme();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(false);
    const [context, setContext] = useContext(Context);
    const [loading, setLoading] = useState({init: false, update: false});
    const [errors, setErrors] = useState([]);
    const [productGroup, setProductGroup] = useState({
        _id: '',
        name: {i18n: {en: '', th: ''}},
        products: [],
        systemData: 0
    });

    Object.assign(defaultTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    border: "1px solid #c4c4c4",
                    borderRadius: 4,
                    padding: 12
                },
                editor: {
                    minHeight: "6rem"
                },
                editorContainer: {
                    minHeight: "6rem"
                }
            }
        }
    });

    useEffect(() => {
        console.log('[ProductGroup]');
        if (id) {
            const url = process.env.REACT_APP_API_BASE_URL + '/groups/' + id;
            httpClient.get(url)
                .then(res => {
                    if (res.data) {
                        setProductGroup(res.data);
                    }
                });
        }
    }, [id]);

    const validation = () => {
        let errors = [];
        if (!productGroup.name.i18n.th) {
            errors.push('name');
        }
        if(errors.length>0){
            setErrors(errors);
        }
        return errors.length>0;
    };

    const handleI18nChange = ({target}) => {
        let value = {
            i18n: {th: target.value, en: target.value}
        };
        setProductGroup({...productGroup, [target.name]: value});
    };

    const handleSave = () => {
        if (!validation()) {
            loading.update = true;
            const url = process.env.REACT_APP_API_BASE_URL + '/groups';
            httpClient.post(url, productGroup)
                .then(res => {
                    setContext({...context, success: true});
                    setLoading({init: false, update: false});
                    history.push('/categories');
                });
        } else {
            setContext({...context, fail: true});
        }
    };

    const handleDelete = () => {
        loading.update = true;
        const id = productGroup._id;
        const url = process.env.REACT_APP_API_BASE_URL + '/groups/' + id;
        httpClient.delete(url)
            .then(res => {
                setContext({...context, success: true});
                setLoading({init: false, update: false});
                history.push('/categories');
            });
    };

    const handleCancel = () => {
        history.push('/categories');
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <form className={classes.root} noValidate autoComplete="off">
                <Box m={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/categories" className={classes.link}>
                            <Typography variant="h6">
                                {context.accountType==='RESTAURANT'?t('productCategory.title'):t('productCategory.shopTitle')}
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}}/>
                <Box m={1}>
                    <Paper className={classes.paper} elevation={0}>
                        <Card elevation={1}>
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField label={t('productCategory.name')} variant="outlined" fullWidth={true}
                                                   name="name" value={productGroup.name.i18n['th']}
                                                   onChange={handleI18nChange} required
                                                   error={errors.indexOf('name') > -1}/>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Paper>
                    <div className={classes.actionControl}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={6} className={classes.itemCancel}>
                            <Box className={classes.boxRight}>
                                {productGroup._id &&
                                    <Button variant="contained"
                                            color="secondary"
                                            size="large"
                                            disableElevation
                                            fullWidth={matches}
                                            disabled={loading.update || productGroup.systemData === 1}
                                            startIcon={<DeleteIcon/>}
                                            onClick={(e) => handleClickOpen(e)}>
                                        {t('common.delete')}
                                    </Button>
                                }
                                {!productGroup._id &&
                                <Button variant="contained"
                                        size="large"
                                        disableElevation
                                        fullWidth={matches}
                                        disabled={loading.update}
                                        color={"grey"}
                                        onClick={(e) => handleCancel(e)}>
                                    {t('common.cancel')}
                                </Button>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.itemSave}>
                            <Box className={classes.boxLeft}>
                                <Button variant="contained"
                                        color="primary"
                                        size="large"
                                        disableElevation
                                        fullWidth={matches}
                                        disabled={loading.update}
                                        startIcon={<SaveIcon/>}
                                        onClick={(e) => handleSave(e)}>
                                    {t('common.save')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                </Box>
            </form>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('common.message.confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('common.message.confirmDelete')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            onClick={handleClose}
                            fullWidth={matches}
                            size="large"
                            color={"grey"}
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained"
                            color="secondary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            startIcon={<DeleteForeverIcon/>}
                            onClick={(e) => handleDelete(e)}>
                        {t('common.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}