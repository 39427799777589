import React, { useState, useRef, useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NumberFormat from 'react-number-format';
import L from 'leaflet';
import {MapContainer, TileLayer, Marker} from 'react-leaflet'
import {isDesktop} from 'react-device-detect';
import {httpClient} from '../../core/HttpClient'
import { Context } from "../../core/Context";
import { TimeSelection } from "../TimeSelection"
import {TextI18n} from "../TextI18n";
import 'leaflet/dist/leaflet.css';
import './style.css'
import DeleteIcon from "@mui/icons-material/Delete";
import {ImagesUploadSlideShow} from "../ImagesUploadSlideShow";
import AddressOriginDialog from "../AddressOriginDialog";
import {ImageUpload} from "../ImageUpload";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";


const markerIcon = new L.Icon({
    iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark.png`,
    iconRetinaUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark2.png`,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 44],
    iconAnchor: [15, 44],
    className: 'foodkub-leaflet-div-icon'
});

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: false,
    closePopupOnClick: true,
    dragging: true,
    zoomSnap: true,
    zoomDelta: true,
    trackResize: true,
    touchZoom: true,
    scrollWheelZoom: false,
};

export { markerIcon };

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8',

    },
    tabPanels: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    actionControl: {
        marginTop: '1rem',
        paddingBottom: '1rem'
    },
    boxLeft: {
        padding: theme.spacing(1),
        textAlign: 'left'
    },
    boxRight: {
        padding: theme.spacing(1),
        textAlign: 'right'
    },
    itemSave: {
        order: 1,
        [theme.breakpoints.up('sm')]: {
            order: 2,
        },
    },
    itemCancel: {
        order: 2,
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
    },
    cardContent: {
        padding: 15,
        "&:last-child": {
            paddingBottom: 15
        },
        "& canvas": {
            width: '100%'
        }
    }
}));

function TabPanel(props) {
    const {children, value, index, classes, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

const MemoizedImageUploadSlideShow = React.memo(({name, value, label, onChange})=>{
    return(<ImagesUploadSlideShow name={name} value={ value } max={4} aspect={1.91/1} label={label} onChange={ onChange } />)
});

const MemoizedImageAvatar = React.memo(({name, value, onChange})=>{
    return(<ImageUpload name={name} value={ value } fit="cover" aspect={1/1} onChange={ onChange } />)
});

const MemoizedTimeSelection = React.memo(({id, name, label, value, index, onChange})=>{
    return(<TimeSelection  id={id}
                    name={name}
                    label={label}
                    value={value}
                    index={index}
                    onChange={ onChange } />)
});

const MyMapContainer = ({position, setMap, zoom}) => {
    console.log('[MyMapContainer]');
    return(
        <MapContainer center={position}
                      zoom={zoom}
                      ref={setMap}
                      {...interactionOptions}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LocationMarker effectOn={this} position={position} center={position} />
        </MapContainer>
    )
};

const LocationMarker = (props) => {
    const markerRef = useRef(null);

    return props.position === null ? null : (
        <Marker icon={markerIcon} position={props.position} ref={markerRef} ></Marker>
    )
};

export default function Shop(params) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [context, setContext] = useContext(Context);
    const [position, setPosition] = useState([13.721163782, 100.523664572]); // Default Bangkok
    const [map, setMap] = useState(null); // Default Bangkok
    const [tabIndex, setTabIndex] = useState(0);
    const [openEditLocation, setOpenEditLocation] = useState(false);
    const [account, setAccount] = useState({
        _id: '',
        cid: '',
        name: {i18n: {en: '', th: ''}},
        address: {i18n: {en: '', th: ''}},
        phone: '',
        email: '',
        active: false,
        orderingType: 'inorder',
        image: {},
        images: [],
        times: [{
            weekdays: [],
            open: {hour: '00', minute: '00'},
            close: {hour: '00', minute: '00'}
        }]
    });
    const [loading, setLoading] = useState({init: false, update: false});

    console.log('Shop');
    useEffect(() => {
        console.log('[Shop]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me';
        httpClient.get(url)
        .then(res => {
            if(res.data){
                let _data = res.data;
                if(res.data.image){
                    _data.image = res.data.image;
                }

                if(res.data.lat && res.data.lng){
                    // set by user location
                    setPosition([res.data.lat, res.data.lng]);
                }

                setAccount(_data);
            }
        });
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleTimeChange = ({ target }) => {
        let updatedData = {...account.times[target.index]};
        updatedData[target.name] = {...target.value};
        account.times[target.index] = updatedData;
    };

    const handleImageChange = ({ target }) => {
        setAccount({ ...account, [target.name]: target.value });
        loading.update = true;
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id;
        httpClient.post(url, {[target.name]: target.value})
            .then(res => {
                setLoading({init: false, update: false});
            });
    };

    const handleSave = () => {
        loading.update = true;
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id;
        httpClient.post(url, account)
            .then(res => {
                setLoading({init: false, update: false});
                setContext({ ...context, success: true});
            });

    };

    const handleWeekdays = (day, idx) => {
        let index = account.times[idx].weekdays.indexOf(day);
        if(index > -1){
            account.times[idx].weekdays.splice(index, 1);
        } else {
            account.times[idx].weekdays.push(day);
        }
        setAccount({ ...account });
    };

    const handleDeleteTimes = (index) => {
        account.times.splice(index, 1);
        setAccount({ ...account });
    };

    const handleCloseEditLocation = () => {
        setOpenEditLocation(false);
    };

    const handleSaveOriginLocation = (value) => {
        if(value){
            setAccount({ ...account, lat: value.lat, lng: value.lng});
            loading.update = true;
            const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id;
            httpClient.post(url, {lat: value.lat, lng: value.lng})
                .then(res => {
                    setLoading({init: false, update: false});
                });
            setOpenEditLocation(false);
            if(map){
                setPosition([value.lat, value.lng]);
                map.setView(value, value.zoom);
            }
        }
    };

    return (
        <div>
            <form className={classes.root} noValidate autoComplete="off">
                <Box m={1} mb={0}>
                    <Typography variant="h6">
                        {t('shop.title')}
                    </Typography>
                </Box>
                <Tabs value={tabIndex}
                      className={classes.tabs}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={handleTabChange}
                      aria-label="info tab">
                    <Tab label={t('shop.information')} />
                    <Tab label={t('shop.businessDays')} />
                </Tabs>
                <TabPanel className={classes.tabPanels} value={tabIndex} index={0}>
                    <Box sx={{marginBottom: '100px'}} m={1}>
                        <Box style={{marginTop: '20px'}}>
                            <Card elevation={1}>
                                <CardContent>
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                        <MemoizedImageAvatar name="image" value={ account.image } onChange={ handleImageChange } />
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant="caption">
                                            {t('shop.name')}
                                        </Typography>
                                        <Typography>
                                            <strong><TextI18n value={account.name}></TextI18n></strong>
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant="caption">
                                            {t('shop.address')}
                                        </Typography>
                                        <Typography>
                                            <strong><TextI18n value={account.address}></TextI18n></strong>
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <Typography variant="caption">
                                            {t('shop.mobile')}
                                        </Typography>
                                        <Typography>
                                            <strong><NumberFormat value={account.phone} displayType={'text'} format="###-###-####" /></strong>
                                        </Typography>
                                    </Box>
                                    <Box mt={2}>
                                        <Alert severity="info">{t('shop.changeShopInfoRemark')}</Alert>
                                    </Box>
                                    <Divider style={{marginBottom: '10px', 'marginTop': '10px'}} />
                                    <Box>
                                        <Box mb={1}>
                                            <Typography variant="caption">
                                                {t('shop.coverImages')}
                                            </Typography>
                                        </Box>
                                        <MemoizedImageUploadSlideShow name="images" value={ account.images } label={t('shop.addCoverImages')} onChange={ handleImageChange } />
                                    </Box>
                                    <Box style={{marginTop: '1rem', marginBottom: '1rem', height: `300px`}}>
                                        <Box mb={1}>
                                            <Typography variant="caption">
                                                {t('shop.location')}
                                            </Typography>
                                        </Box>
                                        {account.zoom &&
                                        <MyMapContainer
                                            position={position}
                                            setMap={setMap}
                                            zoom={account.zoom}
                                            doubleClickZoom={false}
                                        />
                                        }
                                    </Box>
                                    <Box mt={6}>
                                        <Button color={"default"}
                                                variant={"outlined"}
                                                fullWidth={true}
                                                size={"large"}
                                                onClick={e=>{setOpenEditLocation(true);}}
                                        >{t('shop.editLocation')}</Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Box mt={2} sx={{marginBottom: '100px'}} m={1}>
                        <Card elevation={1}>
                            <Box m={2} mb={0}>
                                <Typography>
                                    {t('shop.businessDaysSetting')}
                                </Typography>
                            </Box>
                            <CardContent>
                                {account.times.map((value, index) => (
                                <Box key={`times-${index}`}>
                                    <Box mb={4}>
                                        <ButtonGroup disableElevation size="small" fullWidth aria-label="Business days">
                                            <Button color={account.times[index].weekdays.indexOf(1)>-1?'primary':'inherit'}
                                                    onClick={(e) => handleWeekdays(1, index)}
                                                    variant="contained">{t('common.weekdays.mon')}</Button>
                                            <Button color={account.times[index].weekdays.indexOf(2)>-1?'primary':'inherit'}
                                                    onClick={(e) => handleWeekdays(2, index)}
                                                    variant="contained">{t('common.weekdays.tue')}</Button>
                                            <Button color={account.times[index].weekdays.indexOf(3)>-1?'primary':'inherit'}
                                                    onClick={(e) => handleWeekdays(3, index)}
                                                    variant="contained">{t('common.weekdays.wed')}</Button>
                                            <Button color={account.times[index].weekdays.indexOf(4)>-1?'primary':'inherit'}
                                                    onClick={(e) => handleWeekdays(4, index)}
                                                    variant="contained">{t('common.weekdays.thu')}</Button>
                                            <Button color={account.times[index].weekdays.indexOf(5)>-1?'primary':'inherit'}
                                                    onClick={(e) => handleWeekdays(5, index)}
                                                    variant="contained">{t('common.weekdays.fri')}</Button>
                                            <Button color={account.times[index].weekdays.indexOf(6)>-1?'primary':'inherit'}
                                                    onClick={(e) => handleWeekdays(6, index)}
                                                    variant="contained">{t('common.weekdays.sat')}</Button>
                                            <Button color={account.times[index].weekdays.indexOf(0)>-1?'primary':'inherit'}
                                                    onClick={(e) => handleWeekdays(0, index)}
                                                    variant="contained">{t('common.weekdays.sun')}</Button>
                                        </ButtonGroup>
                                    </Box>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            <MemoizedTimeSelection id="open"
                                                                   name="open"
                                                                   label={t('shop.timeToOpen')}
                                                                   value={account.times[index].open}
                                                                   index={index}
                                                                   onChange={ handleTimeChange } />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <MemoizedTimeSelection id="close"
                                                                   name="close"
                                                                   label={t('shop.timeToClose')}
                                                                   value={account.times[index].close}
                                                                   index={index}
                                                                   onChange={ handleTimeChange } />
                                        </Grid>
                                    </Grid>
                                    {index > 0 && <Box display="flex" justifyContent="flex-center" alignItems="center" mt={2}>
                                        <Button variant="contained"
                                                color="grey"
                                                disableElevation
                                                fullWidth={true}
                                                onClick={() => handleDeleteTimes(index)}>
                                            <DeleteIcon />
                                        </Button>
                                    </Box>}
                                    {/*<Box><Divider style={{marginBottom: '30px', 'marginTop': '30px'}} /></Box>*/}
                                </Box>
                                ))}
                                {/*<Box mt={2}>*/}
                                {/*    <Button variant="outlined"*/}
                                {/*            color="default"*/}
                                {/*            disableElevation*/}
                                {/*            startIcon={<AddCircleOutlineIcon/>}*/}
                                {/*            fullWidth={true}*/}
                                {/*            onClick={(e) => handleTimesOptional(e)}>*/}
                                {/*        {t('common.addTimes')}*/}
                                {/*    </Button>*/}
                                {/*</Box>*/}
                            </CardContent>
                        </Card>
                        <div className={classes.actionControl}>
                            <Box display="flex" justifyContent="center">
                                <Button variant="contained"
                                        startIcon={<SaveIcon />}
                                        color="primary"
                                        size="large"
                                        disableElevation
                                        fullWidth={matches}
                                        disabled={loading.update}
                                        onClick={(e) => handleSave(e)}>
                                    {t('common.save')}
                                </Button>
                            </Box>
                        </div>
                    </Box>
                </TabPanel>
            </form>
            <AddressOriginDialog
                open={openEditLocation}
                shop={account}
                onClose={handleCloseEditLocation}
                onSave={handleSaveOriginLocation}
            />
        </div>
    )
}