import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import {httpClient} from "../../../core/HttpClient";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import {PinField} from "react-pin-field";
import '../style.css'
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import {NativeSelect} from "@mui/material";
import {styled} from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const BootstrapInputError = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid red',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const DATES = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
const MONTHS = [1,2,3,4,5,6,7,8,9,10,11,12];
const YEARS = [];

let currentYear = new Date().getFullYear() + 543;
currentYear -= 18;
for(let i = currentYear; i>currentYear-118;i--){
    YEARS.push(i);
}

export default function PasscodeReset(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const ref = useRef();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(0);
    const [step, setStep] = useState(0);
    const [user, setUser] = useState({_id: '', displayName: ''});
    const [birthday, setBirthday] = useState({day: '', month: '', year: ''});
    const [passcode, setPasscode] = useState('');

    useEffect(() => {
        console.log('[PasscodeReset]');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/me';
        httpClient.get(url)
            .then(res => {
                if (res.data) {
                    setUser({_id: res.data._id, displayName: res.data.displayName});
                    if(!res.data.birthday){
                        setStep(1);
                    }
                } else {
                    window.location.href = '/login';
                }
                setLoading(false);
            }).catch(e=>{
                window.location.href = '/login';
            });
    }, []);


    const handleCheckBirthday = () => {

        let d = ("0" + birthday.day).slice(-2);
        let m = ("0" + birthday.month).slice(-2);
        let y = birthday.year;
        let bd = d+m+y;

        const url = process.env.REACT_APP_API_BASE_URL + '/users/profile/birthday';
        httpClient.post(url, {birthday: bd})
            .then(res => {
                if (res.data && res.data.verify === 'success') {
                    setStep(1);
                } else {
                    let errorCounter = error;
                    errorCounter++;
                    setError(errorCounter);
                }
            }).catch(e => {
                let errorCounter = error;
                errorCounter++;
                setError(errorCounter);
            });
    };

    const handlePasscodeChange = (value) => {
        setPasscode(value);
    };

    const handleBirthdayChange = ({ target }) => {
        setBirthday({...birthday, [target.name]: target.value});
    };

    const handleUpdatePasscode = () => {

        let d = ("0" + birthday.day).slice(-2);
        let m = ("0" + birthday.month).slice(-2);
        let y = birthday.year;
        let bd = d+m+y;

        console.log('handleUpdatePasscode');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/passcode';
        httpClient.put(url, {birthday: bd, newPasscode: passcode})
            .then(res => {
                if(res.data && res.data.ticket){
                    localStorage.setItem('ticket', res.data.ticket);
                    history.push('/passcode?reset=true');
                }
            }).catch(e => {
            let errorCounter = error;
            errorCounter++;
            setError(errorCounter);
        });
    };

    const handleCancel = () => {
        history.push('/passcode');
    };

    return (
        <Box>
            <Box mt={6}>
                {loading &&
                    <Box display="flex" justifyContent="center" mt={4}>
                        <CircularProgress size={20}/>
                    </Box>
                }
                {!loading &&
                    <>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Box>
                                <Avatar alt={user.displayName}
                                        src={user._id ? `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${user._id}` : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.jpg`}
                                        sx={{width: 128, height: 128}}/>
                                <Box mt={1} display="flex" justifyContent="center" alignItems="center">
                                    <Typography variant="body1">
                                        {user.displayName}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        {step === 0 &&
                            <>
                                <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                                    <Typography variant="caption">
                                        {t('profile.questionBirthday')}
                                    </Typography>
                                </Box>
                                <Box p={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <FormControl variant="outlined"
                                                         fullWidth={true}>
                                                <NativeSelect
                                                    name="day"
                                                    value={birthday.day}
                                                    onChange={handleBirthdayChange}
                                                    input={error > 0 ?<BootstrapInputError/>:<BootstrapInput/>}>
                                                    {birthday.day === '' &&
                                                        <option value="">{t('common.duration.day')}</option>}
                                                    {DATES.map((value, i) => {
                                                        return (<option value={value} key={`d-${i}`}>{value}</option>)
                                                    })}
                                                </NativeSelect>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <FormControl variant="outlined"
                                                         fullWidth={true}>
                                                <NativeSelect
                                                    name="month"
                                                    value={birthday.month}
                                                    onChange={handleBirthdayChange}
                                                    input={error > 0 ?<BootstrapInputError/>:<BootstrapInput/>}>
                                                    {birthday.month === '' &&
                                                        <option value="">{t('common.duration.month')}</option>}
                                                    {MONTHS.map((value, i) => {
                                                        return (<option value={value}
                                                                        key={`m-${i}`}>{t(`common.months.${value}.name`)}</option>)
                                                    })}
                                                </NativeSelect>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <FormControl variant="outlined"
                                                         fullWidth={true}>
                                                <NativeSelect
                                                    name="year"
                                                    value={birthday.year}
                                                    onChange={handleBirthdayChange}
                                                    input={error > 0 ?<BootstrapInputError/>:<BootstrapInput/>}>
                                                    {birthday.year === '' &&
                                                        <option value="">{t('common.duration.year')}</option>}
                                                    {YEARS.map((value, i) => {
                                                        return (<option value={value} key={`y-${i}`}>{value}</option>)
                                                    })}
                                                </NativeSelect>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box display="flex" justifyContent="center" mt={2} pl={2} pr={2}>
                                    <Button variant={"contained"}
                                            size={"large"}
                                            fullWidth={true}
                                            disableElevation={true}
                                            disabled={loading
                                                || birthday.day === ''
                                                || birthday.month === ''
                                                || birthday.year === ''}
                                            onClick={handleCheckBirthday}>
                                        {t('common.next')}
                                    </Button>
                                </Box>
                            </>
                        }
                        {step === 1 &&
                            <>
                                <Box mt={2} p={2} display="flex" justifyContent="center" alignItems="center">
                                    <Typography variant="caption">
                                        {t('profile.resetPasscode')}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <PinField length={4}
                                              className="pin-field"
                                              ref={ref}
                                              autoFocus
                                              type="number"
                                              inputMode="numeric"
                                              validate="0123456789"
                                              onChange={handlePasscodeChange}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="center" mt={4} pl={2} pr={2}>
                                    <Button variant={"contained"}
                                            size={"large"}
                                            disabled={passcode.length < 4}
                                            fullWidth={true}
                                            disableElevation={true}
                                            onClick={handleUpdatePasscode}>
                                        {t('common.confirm')}
                                    </Button>
                                </Box>
                            </>
                        }
                    </>
                }
            </Box>
            <Box display="flex" justifyContent="center" p={2}>
                <Button variant={"contained"}
                        color={"grey"}
                        size={"large"}
                        disableElevation={true}
                        fullWidth={true}
                        onClick={handleCancel} >
                    {t('common.cancel')}
                </Button>
            </Box>
        </Box>
    )
}