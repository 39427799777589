import React from 'react';
import { makeStyles } from '@mui/styles';
import FollowingCustomer from "../FollowingCustomer";
import Box from "@mui/material/Box";
import AccountCredit from "../AccountCredit";
import AccountDay from "../AccountDay";
import AccountOrder from "../AccountOrder";
import AccountPlanCurrent from "../AccountPlanCurrent";

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        background: '#ececec',
        padding: '5px'
    }
}));

export default function OnlineStatus({plan}) {
    const classes = useStyles();
    return (
        <Box className={classes.root} pb={2}>
            <Box mb={2}>
                <FollowingCustomer />
            </Box>
            <Box mb={2}>
                <AccountPlanCurrent />
            </Box>
            <Box mb={2}>
                <AccountOrder />
            </Box>
            {plan !== 'FREE' &&
                <Box>
                    <AccountDay />
                </Box>
            }
            <Box mb={2}>
                <AccountCredit />
            </Box>
        </Box>
    )
}