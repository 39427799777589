import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {httpClient} from "../../core/HttpClient";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import {NFormatter} from "../NFormatter";

export default function FollowingCustomer() {
    const { t } = useTranslation();
    const [reaches, setReaches] = useState(0);
    const [views, setViews] = useState(0);

    useEffect(() => {
        console.log('[FollowingCustomer]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/counter';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setReaches(res.data.reaches);
                    setViews(res.data.views);
                }
            });
    }, []);

    return (<Card variant="outlined">
        <Box m={2}>
            <Grid container spacing={2}>
            <Grid item xs={6} sm={6}>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h3">
                        <NFormatter value={views} />
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography>
                        {t('onlineStatus.viewer')}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
                <Box display="flex" justifyContent="center">
                    <Typography variant="h3">
                        <NFormatter value={reaches} />
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="center">
                    <Typography>
                        {t('onlineStatus.reach')}
                    </Typography>
                </Box>
            </Grid>
        </Grid>
        </Box>
        </Card>
    )
}