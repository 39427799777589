import React from 'react';
import { useTranslation } from "react-i18next";
import Alert from '@mui/material/Alert';
import MicrowaveIcon from '@mui/icons-material/Microwave';
import Box from '@mui/material/Box';
import AlertTitle from "@mui/material/AlertTitle";


export default function OrderStatus({value}) {
    const { t } = useTranslation();
    return (
        <Box>
            {value.status === 'cancel' &&
            <Box mb={2}>
                <Alert severity="error">
                    <AlertTitle><b>{t('transactionStatus.cancel')}</b></AlertTitle>
                    {!value.cancellationReasonCode &&
                        <Box>
                            {t('order.canceled')}
                        </Box>
                    }
                    {value.cancellationReasonCode &&
                        <Box>
                            {t(`cancelReasonCode.${value.cancellationReasonCode}`)}
                        </Box>
                    }
                </Alert>
            </Box>
            }
            {value.status === 'reject' &&
                <Box mb={2}>
                    <Alert severity="error">
                        <AlertTitle><b>{t('transactionStatus.reject')}</b></AlertTitle>
                        {!value.cancellationReasonCode &&
                            <Box>
                                {t('order.rejected')}
                            </Box>
                        }
                        {value.cancellationReasonCode &&
                            <Box>
                                {t(`cancelReasonCode.${value.cancellationReasonCode}`)}
                            </Box>
                        }
                    </Alert>
                </Box>
            }
            {value.status === 'completed' &&
            <Box mb={2}>
                <Alert severity="success">
                    <AlertTitle><b>{t('transactionStatus.completed')}</b></AlertTitle>
                    {t('order.orderCompleted')}
                </Alert>
            </Box>
            }
            {['pending', 'confirmed', 'deliver'].indexOf(value.status) > -1 &&
                <Box mb={2}>
                    <Alert color={"warning"} icon={<MicrowaveIcon />} >
                        <AlertTitle><b>{t('transactionStatus.pending')}</b></AlertTitle>
                        {t('order.pending')}
                    </Alert>
                </Box>
            }
        </Box>
    )
};