import React, {useState, useContext, useEffect, useMemo} from 'react';
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import useMediaQuery from '@mui/material/useMediaQuery';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { createTheme } from '@mui/material/styles';
import { useTheme } from '@mui/styles';
import { withStyles, makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Switch from '@mui/material/Switch';
import {httpClient} from '../../core/HttpClient'
import { ImagesUpload } from '../ImagesUpload';
import {Context} from "../../core/Context";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {SelectionRate} from "../SelectionRate";
import OpenWithIcon from "@mui/icons-material/OpenWith";
import arrayMove from "array-move";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToParentElement} from "@dnd-kit/modifiers";
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {ClickAwayListener, InputAdornment, Tooltip} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CardHeader from "@mui/material/CardHeader";
import {getTextI18n} from "../TextI18n";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";


const GreenSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#44cc00',
        },
        '&$checked + $track': {
            backgroundColor: '#44cc00',
        },
    },
    checked: {},
    track: {},
})(Switch);

function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging,
    } = useSortable({id: props.id});

    const style = {
        position: "relative",
        zIndex: isDragging ? 1 : undefined,
        transform: CSS.Transform.toString(transform),
        backgroundColor: 'white'
    };

    return (
        <div ref={setNodeRef} style={style}>
            <Box key={'option_'+props.optionIndex} >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <b>
                        {props.optional.name.i18n['th']}
                    </b>
                    <Box display="flex">
                        <IconButton edge="start" aria-label="move" style={{touchAction: "none"}} {...attributes} {...listeners} ><OpenWithIcon style={{cursor: 'grab'}}></OpenWithIcon></IconButton>
                        <IconButton edge="end" aria-label="delete"
                                    onClick={() => props.handleDelete(props.optionIndex)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </Box>
                <List style={{paddingTop: '0'}}
                      onClick={() => props.handleEdit(props.optionIndex)}>
                    {props.optional.items.map((optionalItem, itemIndex) => (
                        <ListItem key={'option_'+props.optionIndex+'_'+itemIndex}
                                        style={{ padding: '0' }}>
                            <ListItemIcon style={{ minWidth: '30px' }}>
                                {props.optional.multipleSelect &&
                                    <CheckBoxOutlineBlankIcon />
                                }
                                {!props.optional.multipleSelect &&
                                    <RadioButtonUncheckedIcon />
                                }
                            </ListItemIcon>
                            <ListItemText style={{wordWrap: 'break-word'}}
                                          primary={optionalItem.name.i18n['th']}>
                            </ListItemText>
                            {props.optional.showPrice &&
                                <Box style={{minWidth: '60px', textAlign: 'right'}}>
                                    <Typography variant="body1"
                                                color="inherit"><NumberFormat value={optionalItem.price || 0} displayType={'text'} thousandSeparator={true} /> {props.t('pricing.baht')}</Typography>
                                </Box>
                            }
                        </ListItem>
                    ))
                    }
                </List>
                {(props.optional.optionalRequire || (props.optional.multipleSelect && props.optional.maxSelect > 0)) &&
                    <Box mb={1}>*
                        {props.optional.optionalRequire && <Typography variant={"caption"}>{props.t('product.optionalRequire')}</Typography>}
                        {(props.optional.optionalRequire && (props.optional.multipleSelect && props.optional.maxSelect > 0)) && <Typography variant={"caption"}>, </Typography>}
                        {(props.optional.multipleSelect && props.optional.maxSelect > 0) && <Typography variant={"caption"}>{props.t('product.maxSelectInfo', {max: props.optional.maxSelect})}</Typography>}
                    </Box>
                }
                <Divider light />
            </Box>
        </div>
    );
}

const MySortableList = ({items, t, handleEdit, handleDelete}) => {
    const itemIds = useMemo(() => items.map((item) => `${item.key}${item._id}`), [items]);
    return (
        <SortableContext
            items={itemIds}
            strategy={verticalListSortingStrategy}
        >
            <List className="SortableList" >
                {items.map((value, index) => (
                    <SortableItem key={`item-${index}`} id={`${value.key}${value._id}`} index={index} optionIndex={index} optional={value} t={t} handleEdit={handleEdit} handleDelete={handleDelete} />
                ))}
            </List>
        </SortableContext>
    );
};

const MemoizedSelectionRate = React.memo(({label, name, value, min, prefix='', onChange})=>{
    return(<SelectionRate label={label} name={name} value={value} onChange={ onChange } min={min} minLabel={'common.notLimit'} prefix={prefix} />)
});

const MemoizedMultipleOptionalItemLimit = React.memo(({label, name, value, min, max, onChange})=>{
    return(<SelectionRate label={label} name={name} value={value} onChange={ onChange } max={max} min={min} minLabel={'common.notLimit'} />)
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    actionControl: {
        marginTop: '1rem'
    },
    paper: {
        marginBottom: '10px'
    },
    boxLeft: {
        padding: theme.spacing(1),
        textAlign: 'left'
    },
    boxRight: {
        padding: theme.spacing(1),
        textAlign: 'right'
    },
    itemSave: {
        order: 1,
        [theme.breakpoints.up('sm')]: {
            order: 2,
        },
    },
    itemCancel: {
        order: 2,
        [theme.breakpoints.up('sm')]: {
            order: 1,
        },
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    inputBrowse: {
        display: 'none',
    },
}));

let EDIT_OPTIONAL_ITEM_INDEX = -1;
let DELETE_MODE_INDEX = -1;
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="###"
            isNumericString
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const MemoizedImagesUpload = React.memo(({value, limit=0, onChange})=>{
    return(<ImagesUpload name="images" value={value} limit={limit} onChange={ onChange } />)
});

const MemoizedOptionItems = React.memo(({classes, t, list, onChange, onClose})=>{
    const [checked, setChecked] = useState([]);
    const [items, setItems] = useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value._id);
        const newChecked = [...checked];
        const newItems = [...items];

        if (currentIndex === -1) {
            newChecked.push(value._id);
            newItems.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
            newItems.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        setItems(newItems);
    };

    const handleChange = () => {
        onChange(items);
        setChecked([]);
        setItems([]);
    };

    const handleClose = () => {
        onClose();
        setChecked([]);
        setItems([]);
    };

    return(
        <>
        <List className={classes.root}>
        {list.map((value) => {
            const labelId = `checkbox-list-label-${value.name}`;

            return (
                <ListItem key={value._id} role={undefined} dense button onClick={handleToggle(value)} divider>
                    <ListItemIcon style={{ minWidth: '30px' }}>
                        <Checkbox
                            edge="start"
                            checked={checked.indexOf(value._id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            color="primary"
                            inputProps={{ 'aria-labelledby': labelId }}
                        />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={value._id} style={{wordWrap: 'break-word'}}/>
                    <ListItemSecondaryAction>
                        <NumberFormat value={value.price || 0} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                    </ListItemSecondaryAction>
                </ListItem>
            );
        })}
    </List>
    <DialogActions>
        <Grid container spacing={0}>
            <Grid item xs={12} sm={6} className={classes.itemCancel}>
                <Box className={classes.boxRight}>
                    <Button variant="contained"
                            onClick={handleClose}
                            fullWidth={true}
                            color={"grey"}
                            size="large"
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.itemSave}>
                <Box className={classes.boxLeft}>
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={true}
                            onClick={handleChange}>
                        {t('common.ok')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    </DialogActions>
    </>)
});

const MemoizedOptionList = React.memo(({classes, t, list, onChange, onClose})=>{
    const [checked, setChecked] = useState([]);
    const [items, setItems] = useState([]);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value._id);
        const newChecked = [...checked];
        const newItems = [...items];

        if (currentIndex === -1) {
            newChecked.push(value._id);
            newItems.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
            newItems.splice(currentIndex, 1);
        }

        setChecked(newChecked);
        setItems(newItems);
    };

    const handleChange = () => {
        onChange(items);
        setChecked([]);
        setItems([]);
    };

    const handleClose = () => {
        onClose();
        setChecked([]);
        setItems([]);
    };

    return(
        <>
            <List className={classes.root}>
                {list.map((optional, index) => {
                    const labelId = `checkbox-list-label-${optional.name}`;

                    return (
                        <ListItem key={optional._id} role={undefined} dense button onClick={handleToggle(optional)} divider>
                            <ListItemIcon style={{ minWidth: '30px' }}>
                                <FormControlLabel
                                    sx={{ alignItems: 'flex-start' }}
                                    control={
                                        <Checkbox
                                            checked={checked.indexOf(optional._id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                            inputProps={{ 'aria-labelledby': labelId }}
                                        />
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId}
                                          primary={<Box mb={1}><b>{optional._id}</b></Box>}
                              disableTypography
                              secondary={
                                  <Box>
                                      <Box key={'option_'+index}>
                                          <List style={{paddingTop: '0'}}>
                                              {optional.items.map((optionalItem, itemIndex) => (
                                                  <ListItem key={'option_'+index+'_'+itemIndex} style={{ padding: '0' }}>
                                                      <ListItemIcon style={{ minWidth: '30px' }}>
                                                          {optional.multipleSelect &&
                                                              <CheckBoxOutlineBlankIcon />
                                                          }
                                                          {!optional.multipleSelect &&
                                                              <RadioButtonUncheckedIcon />
                                                          }
                                                      </ListItemIcon>
                                                      <ListItemText style={{wordWrap: 'break-word'}}
                                                                    primary={optionalItem.name.i18n['th']}>
                                                      </ListItemText>
                                                      {optional.showPrice &&
                                                          <Box style={{minWidth: '60px', textAlign: 'right'}}>
                                                              <Typography variant="body1"
                                                                          color="inherit"><NumberFormat value={optionalItem.price || 0} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                                                          </Box>
                                                      }
                                                  </ListItem>
                                              ))
                                              }
                                          </List>
                                      </Box>
                                  </Box>
                              }
                              style={{wordWrap: 'break-word'}}
                            />
                        </ListItem>
                    );
                })}
            </List>
            <DialogActions>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={6} className={classes.itemCancel}>
                        <Box className={classes.boxRight}>
                            <Button variant="contained"
                                    onClick={handleClose}
                                    fullWidth={true}
                                    color={"grey"}
                                    size="large"
                                    disableElevation>
                                {t('common.cancel')}
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.itemSave}>
                        <Box className={classes.boxLeft}>
                            <Button variant="contained"
                                    color="primary"
                                    size="large"
                                    disableElevation
                                    fullWidth={true}
                                    onClick={handleChange}>
                                {t('common.ok')}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogActions>
        </>)
});

export default function Product() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = useParams();
    const theme = useTheme();
    const defaultTheme = createTheme();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = React.useState(0);
    const [openOptional, setOpenOptional] = React.useState(false);
    const [openProductGroup, setOpenProductGroup] = React.useState(false);
    const [openOptionalList, setOpenOptionalList] = React.useState(false);
    const [openKitchen, setOpenKitchen] = React.useState(false);
    const [showKitchenLimit, setShowKitchenLimit] = React.useState(false);
    const [planLimit, setPlanLimit] = React.useState({});
    const [context, setContext] = useContext(Context);
    const [loading, setLoading] = useState({init: true, update: false});
    const [errors, setErrors] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState('');
    const [checkError, setCheckError] = useState(false);
    const [checkOptionsItemsError, setCheckOptionsItemsError] = useState(false);
    const [selectingOptionsItems, setSelectingOptionsItems] = useState(false);
    const [listOptionsItems, setListOptionsItems] = useState([]);
    const [listOptions, setListOptions] = useState([]);
    const [productGroup, setProductGroup] = useState({
        _id: '',
        name: {i18n: {en: '', th: ''}},
        products: [],
        systemData: 0
    });
    const [kitchen, setKitchen] = useState({
        _id: '',
        name: {i18n: {en: '', th: ''}},
        products: [],
        systemData: 0
    });
    const [productGroups, setProductGroups] = useState([]);
    const [kitchens, setKitchens] = useState([]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const [productOptional, setProductOptional] = useState({
        name: {i18n: {en: '', th: ''}},
        optionalRequire: false,
        multipleSelect: false,
        maxSelect: 0,
        showPrice: false,
        items: [{
            name: {i18n: {en: '', th: ''}},
            price: ''
        }]
    });
    const [product, setProduct] = useState({
        _id: '',
        name: {i18n: {en: '', th: ''}},
        description: {i18n: {en: '', th: ''}},
        detail: {i18n: {en: '', th: ''}},
        productCode: '',
        productGroup: null,
        kitchen: null,
        price: '',
        deliverPrice: 0,
        deliverLimit: 0,
        stock: -1,
        stockReset: 'no',
        recommend: false,
        shortcut: false,
        timeSlip: 'day',
        images: [],
        options: [],
        active: true
    });

    Object.assign(defaultTheme, {
        overrides: {
            MUIRichTextEditor: {
                root: {
                    border: "1px solid #c4c4c4",
                    borderRadius: 4,
                    padding: 12
                },
                editor: {
                    minHeight: "6rem"
                },
                editorContainer: {
                    minHeight: "6rem"
                }
            }
        }
    });

    useEffect(() => {
        const productGroupURL = process.env.REACT_APP_API_BASE_URL + '/groups?system=1';
        const accountLimitURL = process.env.REACT_APP_API_BASE_URL + '/accounts/me/limited';
        const productOptionItemsURL = process.env.REACT_APP_API_BASE_URL + '/products/options/items';
        const productOptionListURL = process.env.REACT_APP_API_BASE_URL + '/products/options/list';
        const kitchenURL = process.env.REACT_APP_API_BASE_URL + '/kitchens?system=1';
        let tasks = [
            httpClient.get(productGroupURL),
            httpClient.get(accountLimitURL),
            httpClient.get(productOptionItemsURL),
            httpClient.get(productOptionListURL),
            httpClient.get(kitchenURL),
        ];
        if(id) {
            const productURL = process.env.REACT_APP_API_BASE_URL + '/products/' + id;
            tasks.push(httpClient.get(productURL));
        }
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0].data){
                    setProductGroups(rs[0].data);
                }
                if(rs[1].data){
                    if(!id) {
                        let planLimit = rs[1].data;
                        if(planLimit.productLimit){
                            history.push('/products');
                        }
                    }

                    setPlanLimit(rs[1].data);
                }

                if(rs[2].data){
                    setListOptionsItems(rs[2].data);
                }

                if(rs[3].data){
                    setListOptions(rs[3].data);
                }

                if(rs[5] && rs[5].data){
                    let data = {...rs[5].data};
                    setProduct(data);
                } else {
                    if(rs[0].data && rs[0].data[0]){
                        setProduct(s => ({...s, productGroup: rs[0].data[0]._id}));
                    }
                }

                if(rs[4].data){
                    setKitchens(rs[4].data);
                }

                setLoading({init: false, update: false});
            })
            .catch(e=>{
                console.log('error', e);
            });
    }, [id, history]);

    const validation = () => {
        let errors = [];
        if(!product.name.i18n.th){
            errors.push('name');
        }
        if(!product.price){
            errors.push('price');
        }
        if(product.price < 1){
            errors.push('price');
        }
        return errors;
    };

    const handleI18nChange = ({ target }) => {
        let value = {
            i18n: {th: target.value, en: target.value}
        };
        setProduct({ ...product, [target.name]: value });
    };

    const handleProductGroupI18nChange = ({target}) => {
        let value = {
            i18n: {th: target.value, en: target.value}
        };
        setProductGroup({...productGroup, [target.name]: value});
    };

    const handleKitchenI18nChange = ({target}) => {
        let value = {
            i18n: {th: target.value, en: target.value}
        };
        setKitchen({...kitchen, [target.name]: value});
    };

    const handleChange = ({ target }) => {
        setProduct({ ...product, [target.name]: target.value });
    };

    const handleNumberChange = ({ target }) => {

        let found = target.value.search(/^(\d*\.?\d*)$/);
        if(found > -1) {
            setProduct({...product, [target.name]: target.value});
        }

    };

    const handleSelectProductGroup = (value) =>{
        setProduct({...product, productGroup: value});
        setProductGroup({
            _id: '',
            name: {i18n: {en: '', th: ''}},
            products: [],
            systemData: 0
        });
        setOpenProductGroup(false);
    }

    const handleSelectKitchen = (value) =>{
        setProduct({...product, kitchen: value});
        setKitchen({
            _id: '',
            name: {i18n: {en: '', th: ''}},
            products: [],
            systemData: 0
        });
        setOpenKitchen(false);
    }

    const handleChangePlan = (event, reason) => {
        history.push('/account/'+planLimit.account);
    };

    const handleKitchenLimitClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowKitchenLimit(false);
    };

    const handleSave = () => {
        let errors = validation();
        if(errors.length < 1) {
            setLoading({...loading, update: true});
            const url = process.env.REACT_APP_API_BASE_URL + '/products';
            httpClient.post(url, product)
            .then(res => {
                setContext({ ...context, success: true});
                setLoading({init: false, update: false});
                history.push('/products');
            });
        } else {
            setErrors(errors);
            setContext({ ...context, fail: true});
        }
    };

    const handleAddProductGroup = () => {
        if (productGroup.name.i18n.th && productGroup.name.i18n.th.trim().length > 0) {
            // loading.update = true;
            const url = process.env.REACT_APP_API_BASE_URL + '/groups';
            httpClient.post(url, productGroup)
                .then(res => {
                    if(res.status === 200){
                        setProduct({...product, productGroup: res.data});
                        setProductGroup({
                            _id: '',
                            name: {i18n: {en: '', th: ''}},
                            products: [],
                            systemData: 0
                        });
                        setProductGroups(productGroups.concat(res.data));
                        setOpenProductGroup(false);
                    } else {
                        setContext({...context, fail: true});
                    }
                });
        } else {
            setContext({...context, fail: true});
        }
    };

    const handleAddKitchen = () => {
        if(planLimit.kitchenLimit){
            setShowKitchenLimit(true);
        } else {
            if (kitchen.name.i18n.th && kitchen.name.i18n.th.trim().length > 0) {
                // loading.update = true;
                const url = process.env.REACT_APP_API_BASE_URL + '/kitchens';
                httpClient.post(url, kitchen)
                    .then(res => {
                        if(res.status === 200){
                            setProduct({...product, kitchen: res.data});
                            setKitchen({
                                _id: '',
                                name: {i18n: {en: '', th: ''}},
                                products: [],
                                systemData: 0
                            });
                            setKitchens(kitchens.concat(res.data));
                            setOpenKitchen(false);
                        } else {
                            setContext({...context, fail: true});
                        }
                    });
            } else {
                setContext({...context, fail: true});
            }
        }

    };

    const handleDelete = () => {
        if(DELETE_MODE_INDEX > -1){
            product.options.splice(DELETE_MODE_INDEX, 1);
            let newValue = {...product};
            setProduct(newValue);
            DELETE_MODE_INDEX = -1;
            setOpen(0);
        } else {
            setLoading({...loading, update: true});
            const id = product._id;
            const url = process.env.REACT_APP_API_BASE_URL + '/products/' + id;
            httpClient.delete(url)
                .then(res => {
                    setContext({ ...context, success: true});
                    setLoading({init: false, update: false});
                    history.push('/products');
                });
        }

    };

    const handleDeleteOptionItem = (index) => {
        productOptional.items.splice(index, 1);
        let newValue = {...productOptional};
        setProductOptional(newValue);
    };

    const handleMultipleOptionalChange = (event) => {
        let newValue = {...productOptional};
        newValue.multipleSelect = event.target.checked;
        setProductOptional(newValue);
    };

    const handleOptionalRequireChange = (event) => {
        let newValue = {...productOptional};
        newValue.optionalRequire = event.target.checked;
        setProductOptional(newValue);
    };

    const handleMaxOptionalChange = (event) => {
        let newValue = {...productOptional};
        newValue.maxSelect = event.target.value;
        setProductOptional(newValue);
    };

    const handleCheckBoxChange = (e) => {
        let newValue = {...product};
        newValue[e.target.name] = e.target.checked;
        setProduct(newValue);
    };

    const handleAddOptional = () => {
        setCheckError(true);
        if(!productOptional.name.i18n.th){
            setContext({ ...context, fail: true});
            return;
        }

        let newValueOptional = {...productOptional};

        if(newValueOptional.items.length === 0){
            setCheckOptionsItemsError(true);
            setContext({ ...context, fail: true});
            return;
        }

        let showPrice = false;
        let validateItemName = false;
        newValueOptional.items.forEach(item=>{
            if(!item.name.i18n.th){
                validateItemName = true;
            }
            if(item.price && item.price !== 0){
                showPrice = true;
            }
        });
        newValueOptional.showPrice = showPrice;
        if(validateItemName){
            setContext({ ...context, fail: true});
            return;
        }

        let newValueProduct = {...product};
        if(EDIT_OPTIONAL_ITEM_INDEX>-1){
            newValueProduct.options[EDIT_OPTIONAL_ITEM_INDEX] = newValueOptional;
        } else {
            newValueProduct.options.push(newValueOptional);
        }

        if(newValueProduct.options){
            newValueProduct.options.forEach(opts=>{
                opts.items.sort((a,b) => (parseInt(a.price || 0) > parseInt(b.price || 0)) ? 1 : ((parseInt(b.price || 0) > parseInt(a.price || 0)) ? -1 : 0));
            });
            newValueProduct.options.sort((a,b) => (a.multipleSelect > b.multipleSelect) ? 1 : ((b.multipleSelect > a.multipleSelect) ? -1 : 0));
        }

        setProduct(newValueProduct);
        setOpenOptional(false);
        setCheckError(false);
        setCheckOptionsItemsError(false);
    };

    const handleAddMoreItem = () => {
        let newValue = {...productOptional};
        newValue.items.push({
            name: {i18n: {en: '', th: ''}},
            price: ''
        });
        setProductOptional(newValue);
        setCheckOptionsItemsError(false);
    };

    const handleSelectMoreItem = () => {
        setSelectingOptionsItems(true);
    };

    const handleSelectMoreOption = () => {
        setOpenOptionalList(true);
    };

    const handleI18nOptionalChange = ({ target }) => {
        let value = {
            i18n: {th: target.value, en: target.value}
        };
        setProductOptional({ ...productOptional, [target.name]: value });
    };

    const handleI18nOptionalItemChange = (e, index) => {
        let newValue = {...productOptional};
        newValue.items[index].name = {
            i18n: {th: e.target.value, en: e.target.value}
        };
        setProductOptional(newValue);
    };

    const handleOptionalItemChange = (e, index) => {
        let found = e.target.value.search(/^(\d*\.?\d*)$/);
        if(found > -1) {
            let newValue = {...productOptional};
            newValue.items[index].price = e.target.value;
            setProductOptional(newValue);
        }
    };

    const handleOptionalItemSelected = (values) => {
        let newValue = {...productOptional};
        values.forEach(value=>{
            newValue.items.push({
                name: {i18n: {en: value._id, th: value._id}},
                price: value.price
            });
        });

        setProductOptional(newValue);
        setCheckOptionsItemsError(false);
        setSelectingOptionsItems(false);
    };

    const handleOptionalSelected = (values) => {
        let newValue = {...product};
        values.forEach((value,index)=>{
            let option = {
                key: ''+index,
                name: value.name,
                items: value.items,
                multipleSelect: value.multipleSelect,
                showPrice: value.showPrice
            };
            newValue.options.push(option);
        });

        setProduct(newValue);
        setOpenOptionalList(false);
    };

    const handleEditOption = (index) => {
        let productOption = product.options[index];
        let items = [];
        productOption.items.forEach(item=>{
           items.push({name: item.name, price: item.price || 0});
        });
        let newValue = {...productOption, items: items};
        setProductOptional(newValue);
        EDIT_OPTIONAL_ITEM_INDEX = index;
        setOpenOptional(true);
    };

    const handleDeleteOption = (index) => {
        DELETE_MODE_INDEX = index;
        setOpen(2);
    };

    const handleCancel = () => {
        history.push('/products');
    };

    const handleClickOpen = () => {
        DELETE_MODE_INDEX = -1;
        setOpen(1);
    };

    const handleClose = () => {
        setOpen(0);
    };

    const handleClickOpenOptional = () => {
        EDIT_OPTIONAL_ITEM_INDEX = -1;
        const items = [];
        if(listOptionsItems.length === 0){
            items.push({
                name: {i18n: {en: '', th: ''}},
                price: ''
            });
        }
        setProductOptional({
            name: {i18n: {en: '', th: ''}},
            multipleSelect: false,
            maxSelect: 0,
            showPrice: false,
            items: items
        });
        setOpenOptional(true);
    };

    const handleTooltipClose = () => {
        setTooltipOpen('');
    };

    const handleCloseProductGroup = () => {
        setOpenProductGroup(false);
    };

    const handleCloseKitchen = () => {
        setOpenKitchen(false);
    };

    const handleCloseOptional = () => {
        setOpenOptional(false);
    };

    const handleCloseOptionalList = () => {
        setOpenOptionalList(false);
    };

    const handleCloseSelectingOptional = () => {
        setSelectingOptionsItems(false);
    };

    const handleSwitchChange = ({ target }) => {
        setProduct({ ...product, [target.name]: target.checked });
    };

    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {
            const oldIndex = product.options.findIndex(item => `${item.key}${item._id}` === active.id);
            const newIndex = product.options.findIndex(item => `${item.key}${item._id}` === over.id);
            let newValue = arrayMove(product.options, oldIndex, newIndex);
            setProduct({...product, options: newValue});
        }
    }

    return (
        <div>
            <form className={classes.root} noValidate autoComplete="off">
                <Box m={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/products" className={classes.link}>
                            <Typography variant="h6">
                                {t('product.title')}
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                    <Box m={1}>
                        {loading.init &&
                            <Box display="flex" justifyContent="center" mt={4}>
                                <CircularProgress size={20}/>
                            </Box>
                        }
                        {!loading.init &&
                        <Box>
                            <Paper className={classes.paper} elevation={0}>
                                <Card elevation={1}>
                                    <CardContent>
                                        <Box mb={2}>
                                            <Typography>
                                                {t('product.info')}
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField label={t('product.name')} variant="outlined" fullWidth={true}
                                                           name="name" value={product.name.i18n['th']}
                                                           onChange={handleI18nChange} required
                                                           error={errors.indexOf('name') > -1}/>
                                            </Grid>
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <TextField label={t('product.description')} variant="outlined"*/}
                                            {/*               fullWidth={true}*/}
                                            {/*               name="description" value={product.description.i18n['th']}*/}
                                            {/*               onChange={handleI18nChange}/>*/}
                                            {/*</Grid>*/}
                                            <Grid item xs={12}>
                                                <TextField label={t('product.detail')} variant="outlined" fullWidth={true}
                                                           name="detail" value={product.detail.i18n['th']}
                                                           multiline
                                                           rows={5}
                                                           onChange={handleI18nChange}/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl variant="outlined"
                                                             fullWidth={true}>
                                                    <fieldset style={{borderWidth: '1px',
                                                        borderRadius: '4px',
                                                        borderColor: '#d8d8d8'
                                                        }}>
                                                        <legend>
                                                            <Typography variant={"caption"}>
                                                                {t('productCategory.title')}
                                                            </Typography>
                                                        </legend>
                                                        <Box display="flex" justifyContent="space-between" alignItems={"center"}
                                                             onClick={(e)=>{setOpenProductGroup(true);}}>
                                                            <Box>
                                                                <Typography variant={"body1"} mb={1}>
                                                                    {product.productGroup._id?getTextI18n(product.productGroup.name):t('common.notSpecify')}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <ArrowForwardIosRoundedIcon color={"default"} />
                                                            </Box>
                                                        </Box>
                                                    </fieldset>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={product.recommend}
                                                                onChange={handleCheckBoxChange}
                                                                name="recommend"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={t('product.recommend')}
                                                    />
                                                </Box>
                                                <Box display={"flex"} alignItems={"baseline"}>
                                                    <FormControlLabel
                                                        sx={{marginRight: 0}}
                                                        control={
                                                            <Checkbox
                                                                checked={product.shortcut || false}
                                                                onChange={handleCheckBoxChange}
                                                                name="shortcut"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={t('product.shortcut')}
                                                    />
                                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                                        <Tooltip title={t('product.shortcutDescription')}
                                                                 PopperProps={{
                                                                     disablePortal: true,
                                                                 }}
                                                                 onClose={handleTooltipClose}
                                                                 open={tooltipOpen === 'shortcut'}
                                                                 disableFocusListener
                                                                 disableHoverListener
                                                                 disableTouchListener
                                                                 arrow>
                                                            <IconButton onClick={e=>{setTooltipOpen("shortcut");}}>
                                                                <HelpOutlineRoundedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ClickAwayListener>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Paper>
                            <Paper className={classes.paper} elevation={0}>
                                <Card elevation={1}>
                                    <CardContent>
                                        <Box mb={2}>
                                            <Typography>
                                                {t('kitchen.title')}
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <FormControl variant="outlined"
                                                             fullWidth={true}>
                                                    <fieldset style={{borderWidth: '1px',
                                                        borderRadius: '4px',
                                                        borderColor: '#d8d8d8'
                                                    }}>
                                                        <Box display="flex" justifyContent="space-between" alignItems={"center"}
                                                             onClick={(e)=>{setOpenKitchen(true);}}>
                                                            <Box>
                                                                <Typography variant={"body1"} mb={1} mt={1}>
                                                                    {product.kitchen?getTextI18n(product.kitchen.name):t('common.notSpecify')}
                                                                </Typography>
                                                            </Box>
                                                            <Box style={{paddingTop: '5px'}}>
                                                                <ArrowForwardIosRoundedIcon color={"default"} />
                                                            </Box>
                                                        </Box>
                                                    </fieldset>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Paper>
                            <Paper className={classes.paper} elevation={0}>
                                <Card elevation={1}>
                                    <CardContent>
                                        <Box mb={2}>
                                            <Typography>
                                                {t('product.priceAlt')} *
                                            </Typography>
                                        </Box>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined" fullWidth={true}
                                                    name="price"
                                                    type="tel"
                                                    placeholder={"0"}
                                                    value={product.price}
                                                    onChange={handleNumberChange}
                                                    required
                                                    error={errors.indexOf('price') > -1}
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">฿</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <MemoizedSelectionRate*/}
                                            {/*        label={t('product.additionalPrice')}*/}
                                            {/*        name="deliverPrice"*/}
                                            {/*        prefix={"฿"}*/}
                                            {/*        value={product.deliverPrice}*/}
                                            {/*        onChange={handleChange}*/}
                                            {/*        min={1}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                            {/*<Grid item xs={12}>*/}
                                            {/*    <MemoizedSelectionRate*/}
                                            {/*        label={t('product.additionalPerUnit')}*/}
                                            {/*        name="deliverLimit"*/}
                                            {/*        value={product.deliverLimit}*/}
                                            {/*        onChange={handleChange}*/}
                                            {/*        min={1}*/}
                                            {/*    />*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Paper>
                            <Paper className={classes.paper} elevation={0}>
                                <Card elevation={1}>
                                    <CardContent>
                                        <Box mb={2}>
                                            <Typography>
                                                {t('product.quantity')}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box>
                                                <MemoizedSelectionRate
                                                    label={t('product.quantityDescription')}
                                                    name="stock"
                                                    value={product.stock}
                                                    min={-1}
                                                    onChange={handleChange}
                                                />
                                            </Box>
                                            <Box>
                                                <Collapse in={product.stock > -1}>
                                                    <Box mt={2}>
                                                        <FormControl variant="outlined"
                                                                     fullWidth={true}>
                                                            <InputLabel
                                                                id="quantity-reset-label">{t('product.quantityReset')}</InputLabel>
                                                            <Select
                                                                native
                                                                labelId="quantity-reset-label"
                                                                label={t('product.quantityReset')}
                                                                name="stockReset"
                                                                value={product.stockReset}
                                                                onChange={handleChange}>
                                                                <option key="no" value="no">
                                                                    {t('product.quantityResetNotSpecify')}
                                                                </option>
                                                                <option key="daily" value="daily">
                                                                    {t('product.quantityResetDaily')}
                                                                </option>
                                                                <option key="weekly" value="weekly">
                                                                    {t('product.quantityResetWeekly')}
                                                                </option>
                                                                <option key="monthly" value="monthly">
                                                                    {t('product.quantityResetMonthly')}
                                                                </option>
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                                </Collapse>
                                            </Box>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Paper>
                            <Paper className={classes.paper} elevation={0}>
                                <Card elevation={1}>
                                    <CardContent>
                                        <Box mb={1}>
                                            <Typography>
                                                {t('product.options')}
                                            </Typography>
                                        </Box>
                                        {product.options && product.options.length > 0 &&
                                            <Box mb={1}>
                                                <DndContext
                                                    sensors={sensors}
                                                    collisionDetection={closestCenter}
                                                    modifiers={[restrictToParentElement]}
                                                    onDragEnd={handleDragEnd}
                                                >
                                                    <MySortableList items={product.options}
                                                                    t={t}
                                                                    handleEdit={handleEditOption}
                                                                    handleDelete={handleDeleteOption}
                                                                    helperClass="SortableHelper"/>
                                                </DndContext>
                                            </Box>
                                        }
                                        <Box>
                                            <ButtonGroup variant="outlined" aria-label="options" fullWidth={true}>
                                                <Button variant="outlined"
                                                        color="default"
                                                        disableElevation
                                                        startIcon={<AddCircleOutlineIcon/>}
                                                        fullWidth={true}
                                                        onClick={(e) => handleClickOpenOptional(e)}>
                                                    {t('common.add')}
                                                </Button>
                                                {listOptions.length > 0 &&
                                                    <Button variant="outlined"
                                                            color="default"
                                                            onClick={handleSelectMoreOption}
                                                            fullWidth={true}
                                                            startIcon={<PlaylistAddIcon/>}
                                                            disableElevation>
                                                        {t('common.select')}
                                                    </Button>
                                                }
                                            </ButtonGroup>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Paper>
                            <Paper className={classes.paper} elevation={0}>
                                <Card elevation={1}>
                                    <Box m={2}>
                                        <Typography id="continuous-slider">
                                            {t('product.pictures')}
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <MemoizedImagesUpload value={product.images} limit={4} onChange={handleChange}/>
                                    </Box>
                                </Card>
                            </Paper>
                            <Card elevation={1}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" m={2}>
                                    <Typography>
                                        {t('product.active')}
                                    </Typography>
                                    <GreenSwitch
                                        name="active"
                                        checked={product.active}
                                        onChange={handleSwitchChange}
                                        color="primary"
                                        inputProps={{'aria-label': 'active'}}
                                    />
                                </Box>
                            </Card>
                            <div className={classes.actionControl}>
                                <Grid container spacing={0}>
                                    <Grid item xs={12} sm={6} className={classes.itemCancel}>
                                        <Box className={classes.boxRight}>
                                            {product._id &&
                                                <Button variant="contained"
                                                        color="secondary"
                                                        size="large"
                                                        disableElevation
                                                        fullWidth={matches}
                                                        disabled={loading.update}
                                                        startIcon={<DeleteIcon/>}
                                                        onClick={(e) => handleClickOpen(e)}>
                                                    {t('common.delete')}
                                                </Button>
                                            }
                                            {!product._id &&
                                                <Button variant="contained"
                                                        size="large"
                                                        disableElevation
                                                        fullWidth={matches}
                                                        disabled={loading.update}
                                                        color={"grey"}
                                                        onClick={(e) => handleCancel(e)}>
                                                    {t('common.cancel')}
                                                </Button>
                                            }
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} className={classes.itemSave}>
                                        <Box className={classes.boxLeft}>
                                            <Button variant="contained"
                                                    color="primary"
                                                    size="large"
                                                    disableElevation
                                                    fullWidth={matches}
                                                    disabled={loading.update}
                                                    startIcon={<SaveIcon/>}
                                                    onClick={(e) => handleSave(e)}>
                                                {t('common.save')}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                        }
                    </Box>
            </form>
            <Dialog
                open={openOptional}
                TransitionComponent={Transition}
                keepMounted
                fullScreen
                onClose={handleCloseOptional}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"h6"}>
                            {t('product.createOptionalItems')}
                        </Typography>
                        <IconButton edge={"end"} onClick={handleCloseOptional}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Box p={1} pt={0}>
                    <Box ml={1} mr={1}>
                        <TextField label={t('product.optionalName')} variant="outlined" fullWidth={true}
                                   name="name" value={productOptional.name.i18n['th']}
                                   error={checkError && !productOptional.name.i18n['th']}
                                   onChange={ handleI18nOptionalChange } required />
                    </Box>
                    <Box mt={2} ml={1} mr={1}>
                        <Box mb={2}>
                            <Typography>
                                {t('product.optionalItems')} * {checkOptionsItemsError?<small style={{color:'red'}}>({t('product.optionalItemsError')})</small>:''}
                            </Typography>
                        </Box>
                        {productOptional.items.map((item, index) => (
                            <Box key={''+index+'_'+item._id}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={9}>
                                        <TextField label={t('product.optionalItemName')} variant="outlined" fullWidth={true}
                                                   name="optionalName" value={item.name.i18n['th']}
                                                   error={checkError && !item.name.i18n['th']}
                                                   onChange={ (e) => handleI18nOptionalItemChange(e, index) } required />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField
                                            label={t('product.optionalItemPrice')} variant="outlined" fullWidth={true}
                                            name="price"
                                            type="tel"
                                            value={item.price}
                                            placeholder={"0"}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={ (e) => handleOptionalItemChange(e, index) }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Button variant="contained"
                                                color="grey"
                                                size="small"
                                                disableElevation
                                                fullWidth={true}
                                                startIcon={<DeleteIcon />}
                                                onClick={(e) => handleDeleteOptionItem(index)}>
                                            {t('common.delete')}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Divider style={{'marginBottom': '20px', 'marginTop': '20px'}} />
                            </Box>
                        ))
                        }
                        <ButtonGroup color={checkOptionsItemsError?'secondary':'default'} variant="outlined" aria-label="options" fullWidth={true}>
                            <Button variant="outlined"
                                    onClick={handleAddMoreItem}
                                    fullWidth={true}
                                    startIcon={<AddCircleOutlineIcon/>}
                                    disableElevation>
                                {t('common.add')}
                            </Button>
                            {listOptionsItems.length > 0 &&
                            <Button variant="outlined"
                                    onClick={handleSelectMoreItem}
                                    fullWidth={true}
                                    startIcon={<PlaylistAddIcon />}
                                    disableElevation>
                                {t('common.select')}
                            </Button>
                            }
                        </ButtonGroup>
                    </Box>
                    <Box mt={2} ml={1} mr={1} mb={1}>
                        <Card variant={"outlined"}>
                            <CardHeader subheader={t('product.optionalSetting')} />
                            <Box ml={2} mr={2} mb={2}>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={productOptional.optionalRequire || false}
                                                onChange={handleOptionalRequireChange}
                                                name="optionalRequire"
                                                color="primary"
                                            />
                                        }
                                        label={t('product.optionalRequire')}
                                    />
                                </Box>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={productOptional.multipleSelect || false}
                                                onChange={handleMultipleOptionalChange}
                                                name="multipleSelect"
                                                color="primary"
                                            />
                                        }
                                        label={t('product.optionalMultiple')}
                                    />
                                </Box>
                                <Collapse in={productOptional.multipleSelect}>
                                    <Box m={2} mt={1}>
                                        <Box>
                                            <MemoizedMultipleOptionalItemLimit
                                                label={t('product.maxSelect')}
                                                name="maxSelect"
                                                value={productOptional.maxSelect}
                                                min={0}
                                                max={productOptional.items.length}
                                                onChange={handleMaxOptionalChange}
                                            />
                                        </Box>
                                        {/*<FormControl fullWidth>*/}
                                        {/*    <NativeSelect*/}
                                        {/*        name="maxSelect"*/}
                                        {/*        value={productOptional.maxSelect}*/}
                                        {/*        onChange={handleMaxOptionalChange}>*/}

                                        {/*        {Array(productOptional.items.length+1).fill(0).map((value, i) => {*/}
                                        {/*            if(i === 0){*/}
                                        {/*                return <option key={`max-${i}`} value={i}>{t('product.unlimited')}</option>;*/}
                                        {/*            } else {*/}
                                        {/*                return <option value={i} key={`max-${i}`}>{i}</option>*/}
                                        {/*            }*/}
                                        {/*        })}*/}
                                        {/*    </NativeSelect>*/}
                                        {/*</FormControl>*/}
                                    </Box>
                                </Collapse>
                            </Box>
                        </Card>
                    </Box>
                    <Box>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6} className={classes.itemCancel}>
                                <Box className={classes.boxRight}>
                                    <Button variant="contained"
                                            onClick={handleCloseOptional}
                                            fullWidth={matches}
                                            size="large"
                                            color={"grey"}
                                            disableElevation>
                                        {t('common.cancel')}
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} className={classes.itemSave}>
                                <Box className={classes.boxLeft}>
                                    <Button variant="contained"
                                            color="primary"
                                            size="large"
                                            disableElevation
                                            fullWidth={matches}
                                            onClick={(e) => handleAddOptional(e)}>
                                        {t('common.save')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                open={openProductGroup}
                TransitionComponent={Transition}
                keepMounted
                fullScreen
                onClose={handleCloseProductGroup}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"h6"}>
                            {t('productCategory.title')}
                        </Typography>
                        <IconButton edge={"end"} onClick={handleCloseProductGroup}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Card variant={"outlined"}>
                            <List>
                                {productGroups.map((value, index) => {
                                    const labelId = `checkbox-list-label-${index}`;
                                    return (
                                        <ListItem key={value._id} role={undefined} dense divider={index !== productGroups.length-1}>
                                            <ListItemText id={labelId} primary={getTextI18n(value.name)} style={{wordWrap: 'break-word'}}/>
                                            <Box mt={1} mb={1}>
                                                <Button variant="contained" disableElevation={true} onClick={e=>handleSelectProductGroup(value)} >
                                                    {t('common.select')}
                                                </Button>
                                            </Box>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Card>
                    </Box>
                    <Box mt={4}>
                        <TextField label={t('productCategory.title')} variant="outlined" fullWidth={true}
                                   name="name" value={productGroup.name.i18n['th']}
                                   onChange={handleProductGroupI18nChange} required
                                   error={errors.indexOf('name') > -1}/>
                    </Box>
                    <Box mt={2}>
                        <Box>
                            <Button variant="contained"
                                    color="orange"
                                    size="large"
                                    disableElevation
                                    fullWidth={matches}
                                    disabled={loading.update || productGroup.name.i18n['th'].length === 0}
                                    onClick={(e) => handleAddProductGroup(e)}>
                                {t('common.add')}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openKitchen}
                TransitionComponent={Transition}
                keepMounted
                fullScreen
                onClose={handleCloseKitchen}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"h6"}>
                            {t('kitchen.title')}
                        </Typography>
                        <IconButton edge={"end"} onClick={handleCloseKitchen}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Card variant={"outlined"}>
                            <List>
                                <ListItem key="notSpecify" role={undefined} dense divider={kitchens.length > 0}>
                                    <ListItemText id="notSpecify" primary={t('common.notSpecify')} style={{wordWrap: 'break-word'}}/>
                                    <Box mt={1} mb={1}>
                                        <Button variant="contained" disableElevation={true} onClick={e=>handleSelectKitchen(null)} >
                                            {t('common.select')}
                                        </Button>
                                    </Box>
                                </ListItem>
                                {kitchens.map((value, index) => {
                                    const labelId = `checkbox-list-label-${index}`;
                                    return (
                                        <ListItem key={value._id} role={undefined} dense divider={index !== kitchens.length-1}>
                                            <ListItemText id={labelId} primary={getTextI18n(value.name)} style={{wordWrap: 'break-word'}}/>
                                            <Box mt={1} mb={1}>
                                                <Button variant="contained" disableElevation={true} onClick={e=>handleSelectKitchen(value)} >
                                                    {t('common.select')}
                                                </Button>
                                            </Box>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Card>
                    </Box>
                    <Box mt={4}>
                        <TextField label={t('kitchen.addMore')} variant="outlined" fullWidth={true}
                                   name="name" value={kitchen.name.i18n['th']}
                                   onChange={handleKitchenI18nChange} required
                                   error={errors.indexOf('name') > -1}/>
                    </Box>
                    <Box mt={2}>
                        <Box>
                            <Button variant="contained"
                                    color="orange"
                                    size="large"
                                    disableElevation
                                    fullWidth={matches}
                                    disabled={loading.update || kitchen.name.i18n['th'].length === 0}
                                    onClick={(e) => handleAddKitchen(e)}>
                                {t('common.add')}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                open={open>0}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('common.message.confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('common.message.confirmDelete')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                        onClick={handleClose}
                        fullWidth={matches}
                        color="grey"
                        size="large"
                        disableElevation>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained"
                            color="secondary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            startIcon={<DeleteForeverIcon />}
                            onClick={(e) => handleDelete(e)}>
                        {t('common.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={selectingOptionsItems}
                TransitionComponent={Transition}
                keepMounted
                fullScreen
                onClose={handleCloseSelectingOptional}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"h6"}>
                            {t('product.selectOptionalItems')}
                        </Typography>
                        <IconButton edge={"end"} onClick={handleCloseSelectingOptional}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <MemoizedOptionItems classes={classes} t={t} list={listOptionsItems} onChange={handleOptionalItemSelected} onClose={handleCloseSelectingOptional}/>
            </Dialog>
            <Dialog
                open={openOptionalList}
                TransitionComponent={Transition}
                keepMounted
                fullScreen
                onClose={handleCloseOptionalList}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography variant={"h6"}>
                            {t('product.selectOptionalItems')}
                        </Typography>
                        <IconButton edge={"end"} onClick={handleCloseOptionalList}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <MemoizedOptionList classes={classes} t={t} list={listOptions} onChange={handleOptionalSelected} onClose={handleCloseOptionalList}/>
            </Dialog>
            <Snackbar open={showKitchenLimit}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={5000} onClose={handleKitchenLimitClose}>
                <Alert onClose={handleKitchenLimitClose} severity="warning">
                    <Box>
                        <Button variant="outlined" color="primary" disableElevation onClick={handleChangePlan}>
                            <Box>
                                <div style={{marginBottom: '10px'}}>
                                    {planLimit.kitchen > 0 && t('kitchen.planLimited', {limit: planLimit.kitchen})}
                                    {planLimit.kitchen <= 0 && t('kitchen.notPermitted')}
                                </div>
                                <div>{t('common.message.changePlan')}</div>
                            </Box>
                        </Button>
                    </Box>
                </Alert>
            </Snackbar>
        </div>
    )
}