import React, {useEffect} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import jwt_decode from "jwt-decode";
import Grid from "@mui/material/Grid";

export default function Application() {
    const { t } = useTranslation();

    function checkRole(){
        const token = localStorage.getItem('token');
        if (token) {
            var decoded = jwt_decode(token);
            if (['OWNER','ADMIN'].indexOf(decoded.role) > -1) {
                window.location.href = '/home';
            }
        }
    }

    useEffect(() => {
        console.log('[Application]');
        checkRole();
    }, []);

    const handleGoToAppStore = () => {
        console.log('handleGoToAppStore');
        window.location.href = 'https://apps.apple.com/th/app/foodkub-merchant/id6447616328';
    };
    const handleGoToPlayStore = () => {
        console.log('handleGoToPlayStore');
        window.location.href = 'https://play.google.com/store/apps/details?id=com.foodkub.radish';
    };

    return (
        <div>
            <Box display="flex" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('application.title')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px', 'marginBottom': '10px'}} />
            <Box m={2}>
                <Box p={1}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Box onClick={handleGoToPlayStore} mb={1} ml={1} mr={1}>
                                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/google_play.webp`} alt={"Google Play Store"} style={{width: '100%'}} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box onClick={handleGoToAppStore} mb={1} ml={1} mr={1}>
                                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/web/app_store.webp`} alt={"IOS Apple Store"} style={{width: '100%'}} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box display="flex" alignItems="center" mt={1}>
                    <Typography variant="caption">
                        * {t('application.detail')}
                    </Typography>
                </Box>
            </Box>
        </div>
    )
}