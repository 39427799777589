import React, {useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import {httpClient} from "../../core/HttpClient";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {getRemainingDay} from "../RemainingDay";
import NumberFormat from "react-number-format";
import AccountDayDialog from "./AccountDayDialog";
import {useHistory} from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

export default function AccountDay() {
    const { t } = useTranslation();
    const history = useHistory();
    const [accountCredit, setAccountCredit] = useState({});
    const [openInfo, setOpenInfo] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        console.log('[AccountDay]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/credit';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAccountCredit(res.data);
                }
            });
    }, []);

    const handleClickOpenRedeem = () => {
        setOpen(true);
    };

    const handleClosePaymentComplete = (value) => {
        if(value === 'complete'){
            const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/credit';
            httpClient.get(url)
                .then(res => {
                    if(res.data){
                        setAccountCredit(res.data);
                    }
                });
        }
        setOpen(false);
    };

    const handleViewHistory = () => {
        history.push('/history/day');
    };


    return (<Box>
            <Box>
                <Box mb={2}>
                    <Card variant="outlined">
                        <Box m={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                <Typography color="textSecondary">
                                    {t('pricing.remainingDay')}
                                </Typography>
                                <Button variant="text"
                                        onClick={handleViewHistory}
                                        sx={{padding: 0, minWidth: 'auto'}}>
                                    {t('common.viewHistory')}
                                </Button>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Typography variant="h4">
                                    <NumberFormat value={getRemainingDay(accountCredit.expired)} displayType={'text'} thousandSeparator={true} />
                                </Typography>
                            </Box>
                            <Box display="flex" justifyContent="center">
                                <Typography>
                                    {t('pricing.remainingUnit')}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                            <Button variant="text"
                                    size={"small"}
                                    color={"default"}
                                    onClick={e=>setOpenInfo(true)}
                                    startIcon={<InfoIcon />}>
                                <Typography variant={"caption"}>
                                    {t('common.info')}
                                </Typography>
                            </Button>
                        </Box>
                        <CardActions>
                            <Button variant="outlined"
                                    color="primary"
                                    fullWidth={true}
                                    size="large"
                                    disableElevation
                                    onClick={handleClickOpenRedeem}>
                                {t('pricing.redeem')}
                            </Button>
                        </CardActions>
                    </Card>
                </Box>
            </Box>
            <Dialog
                fullScreen={true}
                open={openInfo}
                aria-labelledby="info-modal-title"
                aria-describedby="info-modal-description"
                onClose={e=>setOpenInfo(false)}
            >
                <AppBar position="static" color="transparent" elevation={0}>
                    <Toolbar variant="dense">
                        <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                            {t('common.info')}
                        </Typography>
                        <IconButton edge="start"color="primary" aria-label="black" onClick={e=>setOpenInfo(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Divider />
                <DialogContent>
                    <Box>
                        <Typography>{t('usage.dayInfo.1')}</Typography>
                    </Box>
                    <Box>
                        <Typography>{t('usage.dayInfo.2')}</Typography>
                    </Box>
                    <Box mt={1}>
                        <Typography variant={"caption"}>{t('usage.dayInfo.remark')}</Typography>
                    </Box>
                </DialogContent>
            </Dialog>
            {accountCredit._account &&
                <AccountDayDialog open={open} account={{_id: accountCredit._account, plan: accountCredit.plan, planOption: accountCredit.planOption}} callback={handleClosePaymentComplete} />
            }
        </Box>
    )
}