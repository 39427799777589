import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {Context} from "../../core/Context";

const useStyles = makeStyles((theme) => ({
    root: {},
}));

export default function Setting() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [context, setContext] = useContext(Context);
    const [state, setState] = useState({});
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        console.log('[Setting]');
        setState({language: i18n.language});
    }, [i18n]);

    const handleChange = ({ target }) => {
        setState({ [target.name]: target.value });
    };

    const handleSave = () => {
        i18n.changeLanguage(state.language);
        setContext({ ...context, success: true});
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('changeLanguage')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                <Card elevation={0}>
                    <Box display="flex" pt={2}>
                        <FormControl variant="outlined"
                                     fullWidth={true}>
                            <InputLabel id="time-slip-select-filled-label">{t('setting.language')}</InputLabel>
                            <Select
                                native
                                labelId="time-slip-select-filled-label"
                                label={t('setting.language')}
                                name="language"
                                value={ state.language }
                                onChange={ handleChange }>
                                <option value="en-US">{t('languages.english')}</option>
                                <option value="th-TH">{t('languages.thai')}</option>
                            </Select>
                        </FormControl>
                    </Box>
                </Card>
                <Box display="flex" justifyContent="center" pt={2}>
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            onClick={(e) => handleSave(e)}>
                        {t('common.save')}
                    </Button>
                </Box>
            </Box>
        </div>
    )
}