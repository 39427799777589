import React, {useState, useRef, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import {MapContainer, TileLayer, Marker} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './style.css';
import {TransitionProps} from "@mui/material/transitions";
import L from "leaflet";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import {area, kinks, polygon} from "@turf/turf";
import ReactLeafletEditable from "react-leaflet-editable";
import {isDesktop} from "react-device-detect";
import {markerIcon} from "../Deliver/Delivery";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

const MemoizedMapContainer = React.memo(({setMap, map, deliveryAreas, maxAreas, editRef, position, zoom, t, disabled, onMapChanged, onSave})=>{
    console.log('MyMapContainer');
    useEffect(() => {
        console.log('[MyMapContainer]');
        if(map){
            if(deliveryAreas && deliveryAreas[0]){
                let line2 = L.polygon(deliveryAreas[0]).addTo(map);
                line2.enableEdit();
            }
        }
    }, [deliveryAreas, map]);

    const handleEnable = (e) => {
        e.layer.setStyle({color: '#1abc9c'});
    };


    const handleDrawingMove = (e) => {
        let deliveryArea = [];
        if(e.vertex.latlngs){
            e.vertex.latlngs.forEach(latLng=>{
                deliveryArea.push([latLng.lat, latLng.lng]);
            });
        }

        let sm = area(e.layer.toGeoJSON());
        let skm = Math.floor(sm/1000000 * 100) / 100;

        let _areas = [];
        deliveryArea.forEach(e=>{
            _areas.push([e[1],e[0]]);
        });
        _areas.push(_areas[0]);
        let _polygon = polygon([_areas]);
        let _kinks = kinks(_polygon);

        if(skm > maxAreas || _kinks.features.length > 0){
            e.layer.setStyle({color: '#ff0000'});
        } else {
            e.layer.setStyle({color: '#1abc9c'});
        }

    };

    const handleVertexDeleted = (e) => {
        console.log('handleVertexDeleted');
        let deliveryAreas = [];
        if(e.vertex.latlngs){
            e.vertex.latlngs.forEach(latLng=>{
                deliveryAreas.push([latLng.lat, latLng.lng]);
            });
        }

        let _areas = [];
        deliveryAreas.forEach(e=>{
            _areas.push([e[1],e[0]]);
        });
        _areas.push(_areas[0]);
        let _polygon = polygon([_areas]);
        let _kinks = kinks(_polygon);

        let sm = area(e.layer.toGeoJSON());
        let skm = Math.floor(sm/1000000 * 100) / 100;

        if(skm > maxAreas){
            e.layer.setStyle({color: '#ff0000'});
        } else {
            e.layer.setStyle({color: '#1abc9c'});
        }

        onMapChanged(deliveryAreas, skm, _kinks.features.length);
    };

    const handleVertexMarkerDragEnd = (e) => {
        console.log('handleVertexMarkerDragEnd');
        let deliveryAreas = [];
        if(e.vertex.latlngs){
            e.vertex.latlngs.forEach(latLng=>{
                deliveryAreas.push([latLng.lat, latLng.lng]);
            });
        }

        let _areas = [];
        deliveryAreas.forEach(e=>{
            _areas.push([e[1],e[0]]);
        });
        _areas.push(_areas[0]);
        let _polygon = polygon([_areas]);
        let _kinks = kinks(_polygon);

        let sm = area(e.layer.toGeoJSON());
        let skm = Math.floor(sm/1000000 * 100) / 100;
        onMapChanged(deliveryAreas, skm, _kinks.features.length);
    };

    const handleSave = ()=>{
        onSave();
    };

    return(
        <ReactLeafletEditable ref={editRef} map={map}
                              onEnable={handleEnable}
                              onDrawingMove={handleDrawingMove}
                              onVertexMarkerDragEnd={handleVertexMarkerDragEnd}
                              onVertexDeleted={handleVertexDeleted}>
            {position &&
                <MapContainer center={position}
                              bounds={deliveryAreas}
                              {...interactionOptions}
                              ref={setMap}
                              editable={true}
                              scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LocationMarker effectOn={this} position={position} />
                    <Box>
                        <Fab color={"primary"} variant="extended"
                             disabled={disabled}
                             sx={{
                                 width: '100%',
                                 bottom: '15px',
                                 position: 'absolute'
                             }} onClick={handleSave}>
                            {t('common.save')}
                        </Fab>
                    </Box>
                </MapContainer>
            }
        </ReactLeafletEditable>
    );
});

const LocationMarker = (props) => {
    const [position] = useState(props.position);
    const markerRef = useRef(null);

    return position === null ? null : (
        <Marker icon={markerIcon} position={position} ref={markerRef} ></Marker>

    )
};

export default function DeliveryLocationDialog({open, shop, maxAreas, onClose, onSave}) {
    const { t } = useTranslation();
    const editRef = useRef();
    const [map, setMap] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [deliveryAreasSize, setDeliveryAreasSize] = useState(0);
    const [deliveryArea, setDeliveryArea] = useState([]);

    console.log('DeliveryLocationDialog');
    useEffect(() => {
        console.log('[DeliveryLocationDialog]');
        setDeliveryAreasSize(shop.deliveryAreasSize);
    }, [shop]);

    const handleSave = ()=>{
        onSave(deliveryArea, deliveryAreasSize);

    };

    const handleMapChange = (deliveryArea, skm, kinks) => {
        setDeliveryArea(deliveryArea);
        setDeliveryAreasSize(skm);
        setDisabled(skm > maxAreas || kinks > 0);
    };

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={onClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('delivery.specify')} {deliveryAreasSize} {t('delivery.areaUnit')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MemoizedMapContainer
                map={map}
                setMap={setMap}
                deliveryAreas={shop.deliveryAreas}
                maxAreas={maxAreas}
                editRef={editRef}
                position={[shop.lat, shop.lng]}
                zoom={shop.zoom}
                t={t}
                disabled={disabled}
                onSave={handleSave}
                onMapChanged={handleMapChange}
            />
        </Dialog>
    )
};