import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Dialog from '@mui/material/Dialog';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../core/CropImage'
import {httpClient} from "../../core/HttpClient";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import {styled} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inputBrowse: {
        display: 'none',
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
        cursor: 'pointer'
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.background.paper}`,
}));

export function ImageUpload({name, value, fit, aspect, button, secure=false, placeholder='empty.png', onChange}) {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const classes = useStyles();
    const [image, setImage] = useState({});
    const [loading, setLoading] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropDialog, setCropDialog] = useState(false);
    const [cropImage, setCropImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const token = localStorage.getItem('token');

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        console.log('[ImageUpload]');
        if(value){
            setImage(value);
        } else {
            setImage({});
        }
    },[value]);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                cropImage,
                croppedAreaPixels,
                0
            )
            setCropDialog(false);
            setLoading(true);
            const data = new FormData();
            data.append('file', croppedImage);
            let url = process.env.REACT_APP_API_BASE_URL + '/upload/image';
            if(secure){
                url = process.env.REACT_APP_API_BASE_URL + '/upload/secure/account';
            }
            httpClient.post(url, data).then(res => {
                if(res.data){
                    setImage(res.data);
                    let myTarget = {
                        target: {
                            name: name,
                            value: { ...res.data }
                        }
                    };
                    onChange(myTarget);
                }
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        }
    }, [cropImage, croppedAreaPixels, name, secure, onChange]);

    const handleUpload = ({ target }) => {
        if(target.files[0]){
            const reader = new FileReader();
            reader.onloadend = () => {
                setCropImage(reader.result);
            };
            reader.readAsDataURL(target.files[0]);
            target.value = ''; // fix same file select
            setCropDialog(true);
        }
    };

    const handleClose = () =>{
      setCropDialog(false);
    };

    const handleUploadClick = () => {
        inputRef.current.click();
    };

    return(
        <>
            {fit !== 'cover' &&
                <label htmlFor={`contained-button-file-${name}}`} style={{textAlign: 'center'}}>
                    {image.key && secure &&
                        <img alt="account" src={`${process.env.REACT_APP_API_BASE_URL}/upload/secure/${image.key}?token=${token}`} style={{width: '100%'}} />

                    }
                    {image.name && !secure &&
                        <img alt="account" src={`${process.env.REACT_APP_CDN_BASE_URL}/${image.permission}/merchant/o/${image.name}`} style={{width: '100%'}} />
                    }
                    {!image.name && !image.key &&
                        <CardMedia
                            className={classes.media}
                            image={loading?`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/loading2.gif`:`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/${placeholder}`}
                        />
                    }
                </label>
            }
            {fit === 'cover' &&
                <label htmlFor={`contained-button-file-${name}}`}>
                    <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={loading?<SmallAvatar alt="Upload"><CircularProgress size={20} color={"grey"} /></SmallAvatar> : <SmallAvatar alt="Upload"><PhotoCameraIcon /></SmallAvatar>}
                    >
                        <Avatar sx={{ width: 168, height: 168 }}
                                alt="Profile"
                                variant={"square"}
                                src={(image && image.name)?`${process.env.REACT_APP_CDN_BASE_URL}/${image.permission}/merchant/o/${image.name}` : loading?`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/loading2.gif`:`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/${placeholder}`} />
                    </Badge>
                </label>
            }
            <input
                ref={inputRef}
                accept="image/*"
                className={classes.inputBrowse}
                id={`contained-button-file-${name}}`}
                name={`contained-button-file-${name}}`}
                onChange={handleUpload}
                type="file"
            />
            {button &&
            <Box mt={1}>
                <Button variant="outlined"
                        fullWidth={true}
                        color="default"
                        startIcon={<PhotoCameraIcon />}
                        onClick={handleUploadClick}>
                    {t('common.upload')}
                </Button>
            </Box>
            }
            {cropDialog &&
            <Dialog
                onClose={ handleClose }
                fullScreen={true}
                open={ cropDialog }>
                <DialogContent>
                    <Cropper
                        image={cropImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button color='primary'
                                    variant="contained"
                                    onClick={showCroppedImage}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.ok')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained"
                                    onClick={handleClose}
                                    fullWidth={true}
                                    size="large"
                                    color={"grey"}
                                    disableElevation>
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            }
        </>
    )
}