import React from 'react';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LanguageIcon from '@mui/icons-material/Language';

export default function OrderIcon({foodOrder, color}) {
    if(foodOrder.orderType === 'direct'){
        if(foodOrder.tableNumber > 0){
            return (<TableRestaurantIcon color={color} />);
        } else {
            return (<PointOfSaleIcon color={color} />);
        }
    } else if(foodOrder.orderType === 'mobile'){
        return (<PhoneAndroidIcon color={color} />);
    } else if(foodOrder.orderType === 'online'){
        return (<LanguageIcon color={color} />);
    } else {
        return (<ReceiptIcon color={color} />);
    }

};