import React from 'react';
import {Route, Router} from 'react-router';
import {Redirect, Switch} from 'react-router-dom';
import Account from './components/Account';
import AccountNew from './components/AccountNew';
import AccountVerification from './components/AccountVerification';
import Accounts from './components/Accounts';
import Deliver from './components/Deliver';
import Home from './components/Home';
import Shop from './components/Shop';
import Product from './components/Product';
import Products from './components/Products';
import Plugins from './components/Plugins';
import Setting from './components/Setting';
import SignIn from './components/SignIn';
import Login from './components/Login';
import Initial from './components/Initial';
import ConsoleLayoutRoute from "./layouts/Console";
import DashboardLayoutRoute from "./layouts/Dashboard";
import BlankLayoutRoute from "./layouts/Blank";
import ProductGroups from "./components/ProductGroups";
import ProductGroup from "./components/ProductGroup";
import Payments from "./components/Payments";
import Payment from "./components/Payment";
import Profile from "./components/Profile";
import Ordering from "./components/Ordering";
import OrderView from "./components/OrderView";
import User from "./components/User";
import Users from "./components/Users";
import UserInvite from "./components/UserInvite";
import UserList from "./components/UserList";
import SelectProduct from "./components/Store/SelectProduct";
import SelectAddress from "./components/Store/SelectAddress";
import ConfirmPayment from "./components/Store/ConfirmPayment";
import Customers from "./components/Customers";
import Invoice from "./components/Invoice";
import Transactions from "./components/Transactions";
import EMenu from "./components/Plugins/EMenu";
import Notifications from "./components/Notifications";
import NotificationsInfo from "./components/NotificationsInfo";
import LandingTransaction from "./components/Landing/LandingTransaction";
import LandingHome from "./components/Landing/LandingHome";
import OnlineOrdering from "./components/Plugins/OnlineOrdering";
import Delivery from "./components/Deliver/Delivery";
import Pickup from "./components/Deliver/Pickup";
import Parcel from "./components/Deliver/Parcel";
import Application from "./components/Application";
import Passcode from "./components/Passcode";
import Table from "./components/Table";
import Tables from "./components/Tables";
import TableSetup from "./components/TableSetup";
import PasscodeReset from "./components/Passcode/PasscodeReset";
import FoodKubLayoutRoute from "./layouts/Foodkub";
import Terms from "./components/Terms";
import AccountCreditHistory from "./components/AccountCredit/AccountCreditHistory";
import AccountDayHistory from "./components/AccountDay/AccountDayHistory";
import MobileOrdering from "./components/Plugins/MobileOrdering";
import Discounts from "./components/Discount";
import DiscountDeliver from "./components/Discount/Delivery";
import DiscountProduct from "./components/Discount/Product";
import Partners from "./components/Partners";
import PartnerInfo from "./components/Partners/info";
import PartnerPayment from "./components/Partners/payment";
import PartnerConfirmPayment from "./components/Partners/confirmPayment";
import CustomerInfo from "./components/Customers/CustomerInfo";
import Refresh from "./components/Refresh";
import QRCodeList from "./components/QRCodeList";
import CompleteStoreOrder from "./components/Store/CompleteStoreOrder";
import Reward from "./components/Rewards/Reward";
import SignInQrCode from "./components/SignInQrCode";
import Kitchens from "./components/Kitchens";
import Kitchen from "./components/Kitchen";
import DeliveryPartner from "./components/DeliveryPartner";

const Routes = (props) => (
    <Router {...props}>
        {/*<Suspense fallback={<div>Loading...</div>}>*/}
            <Switch>
                <ConsoleLayoutRoute path="/account/:id/verification" component={AccountVerification} />
                <ConsoleLayoutRoute path="/account/:id" component={Account} />
                <ConsoleLayoutRoute path="/account" component={Account} />
                <ConsoleLayoutRoute path="/accounts/new"  component={AccountNew} />
                <ConsoleLayoutRoute path="/accounts" component={Accounts} />
                {/*<DashboardLayoutRoute path="/campaigns/digitalcoin" component={CampaignInfo} roles={['OWNER','ADMIN']} />*/}
                {/*<DashboardLayoutRoute path="/campaigns" component={Campaigns} roles={['OWNER','ADMIN']} />*/}
                <DashboardLayoutRoute path="/category/:id" component={ProductGroup} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/category" component={ProductGroup} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/categories" component={ProductGroups} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/customers/:id" component={CustomerInfo} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/customers" component={Customers} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/delivery/partners" component={DeliveryPartner} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/deliver/pickup" component={Pickup} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/deliver/delivery" component={Delivery} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/deliver/parcel" component={Parcel} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/deliver" component={Deliver} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/discounts/food" component={DiscountProduct} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/discounts/deliver" component={DiscountDeliver} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/discounts" component={Discounts} roles={['OWNER','ADMIN']} />
                {/*<DashboardLayoutRoute path="/mobile/deliver" component={MobileOrder} roles={['OWNER','ADMIN']} />*/}
                <DashboardLayoutRoute path="/home" component={Home} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/kitchen/:id" component={Kitchen} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/kitchen" component={Kitchen} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/kitchens" component={Kitchens} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/application" component={Application} />
                <ConsoleLayoutRoute path="/invoices" component={Invoice} />
                <DashboardLayoutRoute path="/store/select/:id" component={SelectProduct} />
                <DashboardLayoutRoute path="/store/address/:id" component={SelectAddress} />
                <DashboardLayoutRoute path="/store/payment/:id" component={ConfirmPayment} />
                <DashboardLayoutRoute path="/store/complete/free" component={CompleteStoreOrder} />
                {/*<DashboardLayoutRoute path="/store/history" component={StoreHistory} />*/}
                {/*<DashboardLayoutRoute path="/store" component={Store} />*/}
                {/*<DashboardLayoutRoute path="/notify" component={LineNotify} roles={['OWNER','ADMIN']} />*/}
                <DashboardLayoutRoute path="/notifications/:id" component={NotificationsInfo} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/notifications" component={Notifications} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/ordering" component={Ordering} />
                <DashboardLayoutRoute path="/orders/view/:uid" component={OrderView} />
                {/*<DashboardLayoutRoute path="/orders/action/:uid" component={OrderAction} />*/}
                {/*<DashboardLayoutRoute path="/orders/active" component={OrderListActive} />*/}
                {/*<DashboardLayoutRoute path="/orders/delivering/:uid" component={OrderRiderAction} />*/}
                {/*<DashboardLayoutRoute path="/orders/delivering" component={OrderListDelivering} />*/}
                <DashboardLayoutRoute path="/product/:id" component={Product} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/product" component={Product} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/products" component={Products} roles={['OWNER','ADMIN']} />
                <ConsoleLayoutRoute path="/profile" component={Profile} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/partners/1/payment/confirm" component={PartnerConfirmPayment} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/partners/1/payment" component={PartnerPayment} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/partners/1" component={PartnerInfo} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/partners" component={Partners} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/payments/:id" component={Payment} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/payments" component={Payments} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/plugins/menu" component={EMenu} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/plugins/online" component={OnlineOrdering} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/plugins/mobile" component={MobileOrdering} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/plugins" component={Plugins} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/qrcode" component={QRCodeList} roles={['OWNER','ADMIN']} />
                {/*<DashboardLayoutRoute path="/rewards/generate" component={GenerateQRCodePoint} roles={['OWNER','ADMIN','STAFF']} />*/}
                {/*<DashboardLayoutRoute path="/rewards/history" component={History} roles={['OWNER','ADMIN','STAFF']} />*/}
                <DashboardLayoutRoute path="/rewards" component={Reward} roles={['OWNER','ADMIN']} />
                {/*<DashboardLayoutRoute path="/rider" component={Rider} roles={['OWNER','ADMIN']} />*/}
                {/*<DashboardLayoutRoute path="/riders/:id" component={RiderView} roles={['OWNER','ADMIN']} />*/}
                {/*<DashboardLayoutRoute path="/riders" component={Riders} roles={['OWNER','ADMIN']} />*/}
                <ConsoleLayoutRoute path="/setting" component={Setting} />
                <DashboardLayoutRoute path="/shop" component={Shop} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/tables/create" component={TableSetup} />
                <DashboardLayoutRoute path="/tables/:id" component={Table} />
                <DashboardLayoutRoute path="/tables" component={Tables} />
                <DashboardLayoutRoute path="/transactions" component={Transactions} />
                <DashboardLayoutRoute path="/history/credit" component={AccountCreditHistory} />
                <DashboardLayoutRoute path="/history/day" component={AccountDayHistory} />
                <FoodKubLayoutRoute path="/user/initial/info" component={Initial} />
                <DashboardLayoutRoute path="/user/:id" component={User} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/user" component={User} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/users/:id" component={UserList} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/users" component={Users} roles={['OWNER','ADMIN']} />
                <DashboardLayoutRoute path="/landing/home" component={Home} />
                <ConsoleLayoutRoute path="/landing/account/:id/verification" component={AccountVerification} />
                <Route path="/landing/transaction/:accountId/:dateCode/:uid" component={LandingTransaction} />
                <Route path="/landing/user/invite/:key" component={UserInvite} />
                <Route path="/landing" component={LandingHome} />
                <FoodKubLayoutRoute path="/passcode/reset" component={PasscodeReset} />
                <FoodKubLayoutRoute path="/passcode" component={Passcode} />
                <Route path="/signin/user/invite/:key" component={UserInvite} />
                <Route path="/signin/:mode/:key" component={SignIn} />
                <Route path="/signin" component={SignIn} />
                <Route path="/sign/qr/auth/:ref" component={SignInQrCode} />
                <Route path="/sign/qr/auth" component={SignInQrCode} />
                <Route path="/login" component={Login} />
                <Route path="/terms" component={Terms} />
                <BlankLayoutRoute path="/init" component={Initial} />
                <Route path="/refresh" component={Refresh} />
                <Redirect to="/login" />
            </Switch>
        {/*</Suspense>*/}
    </Router>
);

export default Routes;