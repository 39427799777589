import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {useHistory} from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export default function CompleteStoreOrder() {
    const { t } = useTranslation();
    const history = useHistory();

    const handleBack = () => {
        history.push('/plugins');
    };

    return (
        <Box>
            <Box display="flex" alignItems="center" m={1}>
                <IconButton edge="start" color="primary" aria-label="black" onClick={handleBack}>
                    <ArrowBackIcon/>
                </IconButton>
                <Typography variant="h6">
                    {t('store.completeFreeTitle')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}}/>
            <Box m={1}>
                <Alert icon={false} severity="success" >
                    <AlertTitle>{t('store.completeFreeTitle')}</AlertTitle>
                    {t('store.completeFreeSubtitle')}
                </Alert>
            </Box>
        </Box>
    )
}