import React, {useState, useEffect, useContext, useRef} from 'react';
import { useTranslation } from "react-i18next";
import {makeStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import "leaflet-editable";
import Card from "@mui/material/Card";
import {InputAdornment, Link, ListItem, Switch as SubSwitch} from "@mui/material";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import {useHistory} from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Collapse from "@mui/material/Collapse";
import SaveIcon from "@mui/icons-material/Save";
import {Context} from "../../core/Context";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ButtonGroup from "@mui/material/ButtonGroup";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NumberFormat from "react-number-format";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import TextField from "@mui/material/TextField";
import {MapContainer, Marker, Polygon, TileLayer} from "react-leaflet";
import {markerIcon} from "./Delivery";
import {isDesktop} from "react-device-detect";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {ClockPicker} from "@mui/x-date-pickers/ClockPicker";
import Grid from "@mui/material/Grid";
import dateFormat from "dateformat";
import DeliveryLocationDialog from "../DeliveryLocationDialog";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import {httpClient} from "../../core/HttpClient";
import Checkbox from "@mui/material/Checkbox";
import CardContent from "@mui/material/CardContent";
import InfoIcon from "@mui/icons-material/Info";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8',

    },
    tabPanels: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
}));

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

const MemoizedMapViewContainer = React.memo(({setMap, shop, position})=>{

    if(position){
        return <MapContainer center={[shop.lat, shop.lng]}
                             bounds={shop.deliveryAreas}
                             {...interactionOptions}
                             whenCreated={setMap}
                             scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {shop && shop.lat && shop.lng &&
                <LocationMarker effectOn={this} position={position} />
            }
            {shop && shop.delivery && shop.deliveryAreas &&
                <Polygon
                    positions={shop.deliveryAreas}
                    color="#1abc9c"
                    fillColor="#1abc9c" />
            }
        </MapContainer>;
    } else {
        return;
    }

});

const LocationMarker = (props) => {
    console.log('[LocationMarker]');
    const [position] = useState(props.position);
    const markerRef = useRef(null);

    return position === null ? null : (
        <>
            <Marker icon={markerIcon} position={position} ref={markerRef} ></Marker>
        </>
    )
};

let TIME_INDEX = -1;

export default function Deliver() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [context, setContext] = useContext(Context);
    const [account, setAccount] = useState({zoom: 15,
        deliveryStartPrice: 0,
        deliveryAdditionalPrice: 0,
        deliveryStartAt: 1,
        deliveryAdditionalAt: 1,
        deliveryPostpaid: false,
        deliveryQrPayment: false,
        deliveryFundsTransfer: false,
        delivery: false,
        pickup: false,
        parcel: false,
        orderingType: '',
        orderingAcceptation: 'accept',
        deliveryType: 'immediately',
        deliveryDays: [],
        deliveryTimes: [],
        deliveryAreasSize: 0,
        times: [{weekdays: []}]
    });
    const [errors, setErrors] = useState([]);
    const [deliveryError, setDeliveryError] = useState(0);
    const [deliveryPrices, setDeliveryPrices] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openDeliveryLocation, setOpenDeliveryLocation] = useState(false);
    const [time, setTime] = useState(new Date(new Date().setHours(0, 0)));
    const [position, setPosition] = useState(null);
    const [maxAreas, setMaxAreas] = useState(0);
    const [payment, setPayment] = useState({cash: false, fundsTransfer: false, qrPayment: false, creditCard: false});
    const [setMap] = useState(null);

    console.log('Deliver');

    useEffect(() => {
        console.log('[Deliver]');
        const fields = "cid,orderingType,lat,lng,zoom,deliveryStartPrice,deliveryAdditionalPrice,deliveryStartAt,deliveryAdditionalAt," +
            "delivery,pickup,orderingAcceptation,deliveryType,deliveryDays,deliveryTimes,deliveryAreas,deliveryAreasSize," +
            "deliveryPostpaid,deliveryQrPayment,deliveryFundsTransfer,times";
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me?fields='+fields;
        const paymentUrl = process.env.REACT_APP_API_BASE_URL + '/payments';
        let tasks = [
            httpClient.get(url),
            httpClient.get(paymentUrl),
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/accounts/me/limited')
        ];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setAccount(rs[0].data);
                    let _distancePrices = [];
                    setPosition([rs[0].data.lat, rs[0].data.lng]);
                    setDeliveryPrices(_distancePrices);
                }
                if(rs[1] && rs[1].data){
                    setPayment(rs[1].data);
                }
                if(rs[2] && rs[2].data){
                    setMaxAreas(rs[2].data.areas);
                }
                setLoading(false);
            });
    }, []);

    const handleGotoPaymentSetting = () => {
        history.push('/payments');
    };

    const handleChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.value;
        setAccount({...account, ...fieldValue});
    };

    const handleSave = () => {

        let _errors = [];
        if(['inorder','preorder'].indexOf(account.orderingType) > -1){
            if(!account.delivery && !account.pickup){
                _errors.push('deliver');
            }
        }
        if(account.deliveryType === 'round'){
            if(account.deliveryTimes.length === 0){
                _errors.push('times');
            }
        }

        const filteredArray = account.times[0].weekdays.filter(value => account.deliveryDays.includes(value));
        if(account.orderingType === 'preorder'
            && account.deliveryType === 'round'
            && filteredArray.length === 0){
            _errors.push('deliveryDays');
        }


        if(!account.deliveryPostpaid && !account.deliveryQrPayment && !account.deliveryFundsTransfer){
            _errors.push('payments');
        }

        if(_errors.length > 0){
            setContext({ ...context, fail: true});
            setErrors(_errors);
            return;
        } else {
            setErrors(_errors);
        }

        let _data = {
            _id: account._id,
            orderingType: account.orderingType,
            pickup: account.pickup,
            delivery: account.delivery,
            orderingAcceptation: account.orderingAcceptation,
            deliveryType: account.deliveryType,
            deliveryDays: account.deliveryDays,
            deliveryTimes: account.deliveryTimes,
            deliveryAreas: account.deliveryAreas,
            deliveryAreasSize: account.deliveryAreasSize,
            deliveryPrices: deliveryPrices,
            deliveryStartPrice: account.deliveryStartPrice,
            deliveryAdditionalPrice: account.deliveryAdditionalPrice,
            deliveryStartAt: account.deliveryStartAt,
            deliveryAdditionalAt: account.deliveryAdditionalAt,
            deliverPriceCalculation: account.deliverPriceCalculation,
            deliveryPostpaid: account.deliveryPostpaid,
            deliveryQrPayment: account.deliveryQrPayment,
            deliveryFundsTransfer: account.deliveryFundsTransfer
        };

        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id + '/deliver';
        setUpdating(true);
        httpClient.post(url, _data)
            .then(res => {
                console.log('res',res.status, res.data);
                if(res.status === 200){
                    setContext({ ...context, success: true});
                } else {
                    if(res.data.code === 'LIMIT_002'){
                        // area limit
                        setErrors(['deliveryArea']);
                    }
                    setContext({ ...context, fail: true});
                }
                setUpdating(false);

            });
    };

    const handleSwitchOnlineOrderingChange = (e) => {

        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        if(e.target.checked){
            setAccount({...account, orderingType: 'inorder'});
        } else {
            setAccount({...account, orderingType: 'none'});
        }
    };

    const handleSwitchChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        setAccount({...account, ...fieldValue});
    };

    const handleAddRound = (e) => {
        const data = {hour: time.getHours(), minute: time.getMinutes()};
        // validate duplicate
        let duplicateResult = false;
        let duplicateIndex = -1;
        let _hourStr = ''+data.hour;
        let _minuteStr = ''+data.minute;
        account.deliveryTimes.forEach((dt,index)=>{
            if(''+dt.hour === _hourStr && ''+dt.minute === _minuteStr){
                duplicateResult = true;
                duplicateIndex = index;
            }
        });
        if(TIME_INDEX < 0){
            // add
            if(duplicateResult){
                setDeliveryError(1);
            } else {
                account.deliveryTimes.push(data);
            }
        } else {
            // edit
            if(duplicateResult && TIME_INDEX !== duplicateIndex){
                setDeliveryError(1);
            } else {
                account.deliveryTimes[TIME_INDEX] = data;
            }
        }
        let _deliveryTimes = account.deliveryTimes.sort((a, b) => ((parseInt(a.hour)*60)+parseInt(a.minute)) > ((parseInt(b.hour)*60)+parseInt(b.minute)) ? 1 : -1);
        setAccount({...account, deliveryTimes: _deliveryTimes});
        let _errors = errors;
        _errors.splice(_errors.indexOf('times'), 1);
        setErrors(_errors);
        setOpen(false);
    };

    const handleWeekdays = (day) => {
        let index = account.deliveryDays.indexOf(day);
        if(index > -1){
            account.deliveryDays.splice(index, 1);
        } else {
            account.deliveryDays.push(day);
        }
        setAccount({ ...account });
        let _errors = errors;
        _errors.splice(_errors.indexOf('deliveryDays'), 1);
        setErrors(_errors);
    };

    const handleEditTime = (index) => {
        let _time = new Date(new Date().setHours(account.deliveryTimes[index].hour, account.deliveryTimes[index].minute));
        setTime(_time);
        TIME_INDEX = index;
        setOpen(true);
    };

    const handleDeleteTime = (index) => {
        account.deliveryTimes.splice(index, 1);
        setAccount({...account});
    };

    const handleOpen = () => {
        TIME_INDEX = -1;
        setTime(new Date(new Date().setHours(0, 0)));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDeliveryTimeError = () => {
        setDeliveryError(0);
    };

    const handleCloseDeliveryLocation = () => {
        setOpenDeliveryLocation(false);
    };

    const handleSaveDeliveryLocation = (deliveryAreas, deliveryAreasSize) => {
        if(deliveryAreas && deliveryAreas.length > 2 && deliveryAreasSize){
            setAccount({ ...account, deliveryAreas: [deliveryAreas], deliveryAreasSize: deliveryAreasSize});
        }
        setOpenDeliveryLocation(false);
    };

    const handleCheck = (event) => {
        setAccount({ ...account, [event.target.name]: event.target.checked });
    };

    return (
        <div className={classes.root}>
            <Box m={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h6">
                        {t('ordering.title')}
                    </Typography>
                </Box>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <>
                    <Box m={1} mt={2}>
                        <Card variant={"outlined"}>
                            <Box m={2}>
                                <Box display="flex"
                                     justifyContent="space-between"
                                     alignItems="center">
                                    <Box display="flex"
                                         alignItems="center">
                                        <Box mr={1}>
                                            <Typography variant="h6">
                                                <div>
                                                    {t('ordering.inorder')}
                                                </div>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <SubSwitch
                                        name="orderingType"
                                        checked={ account.orderingType === 'inorder' }
                                        onChange={ handleSwitchOnlineOrderingChange }
                                        color="primary"
                                        inputProps={{ 'aria-label': 'active' }}
                                    />
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                    <Collapse in={['inorder', 'preorder'].indexOf(account.orderingType) > -1}>
                        <Box m={1} mt={2}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Typography>
                                    {t('delivery.payment')}
                                </Typography>
                                {errors.indexOf('payments') > -1 &&
                                    <Box ml={1}>
                                        <Typography color={"secondary"}>
                                            ({t('deliver.requirePaymentMethod')})
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                            <Card variant="outlined">
                                <CardContent>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={account.deliveryPostpaid}
                                                    onChange={handleCheck}
                                                    name="deliveryPostpaid"
                                                    color="primary"
                                                />
                                            }
                                            label={t('delivery.postpaid')}
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={account.deliveryQrPayment}
                                                    onChange={handleCheck}
                                                    name="deliveryQrPayment"
                                                    color="primary"
                                                    disabled={!payment.qrPayment}
                                                />
                                            }
                                            label={t('delivery.qrPayment')}
                                        />
                                    </Box>
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={account.deliveryFundsTransfer}
                                                    onChange={handleCheck}
                                                    name="deliveryFundsTransfer"
                                                    color="primary"
                                                    disabled={!payment.fundsTransfer}
                                                />
                                            }
                                            label={t('delivery.fundsTransfer')}
                                        />
                                    </Box>
                                    {!payment.fundsTransfer && !payment.qrPayment && <Box mt={1}>
                                        <Button variant="text"
                                                size={"small"}
                                                onClick={e=>handleGotoPaymentSetting()}
                                                startIcon={<InfoIcon />}>
                                            <Typography variant={"caption"}>
                                                {t('deliver.morePaymentMethod')}
                                            </Typography>
                                        </Button>
                                    </Box>
                                    }
                                </CardContent>
                            </Card>
                        </Box>
                        <Box m={1} mt={2}>
                            <Box display="flex" alignItems="center" mb={1}>
                                <Typography>
                                    {t('delivery.title')}
                                </Typography>
                                {errors.indexOf('deliver') > -1 &&
                                    <Box ml={1}>
                                        <Typography color={"secondary"}>
                                            ({t('deliver.requireDeliverMethod')})
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                        </Box>
                        <Box m={1}>
                            <Card variant="outlined">
                                <List className={classes.root}>
                                    <ListItem divider key="pickup">
                                        <ListItemIcon>
                                            <ShoppingBagIcon/>
                                        </ListItemIcon>
                                        <ListItemText id='pickup'>
                                            <Box mr={1}>
                                                <Typography variant="h6">
                                                    <div>
                                                        {t('deliver.pickup')}
                                                    </div>
                                                    <div style={{fontSize: '1rem'}}>
                                                        {t('deliver.pickupDescription')}
                                                    </div>
                                                </Typography>
                                            </Box>
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <SubSwitch
                                                checked={account.pickup}
                                                onChange={(e) => handleSwitchChange(e)}
                                                color="primary"
                                                name="pickup"
                                                inputProps={{'aria-label': 'open'}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem key="delivery">
                                        <ListItemIcon>
                                            <TwoWheelerIcon/>
                                        </ListItemIcon>
                                        <ListItemText id='delivery'>
                                            <Box mr={1}>
                                                <Typography variant="h6">
                                                    <div>
                                                        {t('deliver.delivery')}
                                                    </div>
                                                    <div style={{fontSize: '1rem'}}>
                                                        {t('deliver.deliveryDescription')}
                                                    </div>
                                                </Typography>
                                            </Box>
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <SubSwitch
                                                checked={account.delivery}
                                                onChange={(e) => handleSwitchChange(e)}
                                                color="primary"
                                                name="delivery"
                                                inputProps={{'aria-label': 'open'}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                                <Collapse in={account.delivery}>
                                    <Divider style={{'marginRight': '20px', 'marginLeft': '20px'}}/>
                                    <Box m={2}>
                                        <Box mt={2}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography>
                                                    {t('delivery.specify')}
                                                </Typography>
                                                <Typography
                                                    variant={"caption"}>{account.deliveryAreasSize} {t('delivery.areaUnit')}</Typography>
                                            </Box>
                                            <Box mt={1} style={{height: `300px`, border: errors.indexOf("deliveryArea")>-1?'2px solid red': '0px'}}>
                                                <MemoizedMapViewContainer setMap={setMap} shop={account}
                                                                          position={position}/>
                                            </Box>
                                            <Box mt={2}>
                                                <Button color={"default"}
                                                        variant={"outlined"}
                                                        fullWidth={true}
                                                        size={"large"}
                                                        onClick={e => {
                                                            setOpenDeliveryLocation(true);
                                                        }}
                                                >{t('delivery.editDeliveryLocation')}</Button>
                                            </Box>
                                            <Box mt={2}>
                                                <Box mb={2}>
                                                    <Typography>
                                                        {t('deliver.method')}
                                                    </Typography>
                                                </Box>
                                                <FormControl variant="outlined"
                                                             fullWidth={true}>
                                                    <InputLabel
                                                        id="delivery-type-label">{t('deliver.type')}</InputLabel>
                                                    <Select
                                                        native
                                                        labelId="delivery-type-label"
                                                        label={t('deliver.type')}
                                                        name="deliveryType"
                                                        value={'' + account.deliveryType}
                                                        onChange={handleChange}>
                                                        <option key="auto_accept" value="immediately">
                                                            {t('ordering.deliveryNow')}
                                                        </option>
                                                        <option key="auto_reject" value="round">
                                                            {t('ordering.deliveryRound')}
                                                        </option>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            <Collapse in={account.deliveryType === 'round'}>
                                                {account.orderingType === 'preorder' &&
                                                    <>
                                                        <Box mt={2} mb={2}>
                                                            <Typography>
                                                                {t('deliver.deliveryDates')}
                                                            </Typography>
                                                        </Box>
                                                        <Box mt={2} mb={2}>
                                                            <ButtonGroup variant="outlined" disableElevation size="small" fullWidth
                                                                         aria-label="Business days" sx={errors.indexOf('deliveryDays')>-1?{border: '1px solid red'} : {}} >
                                                                <Button
                                                                    disabled={account.times[0].weekdays.indexOf(1)===-1}
                                                                    color={account.deliveryDays.indexOf(1) > -1 ? 'primary' : 'inherit'}
                                                                    onClick={(e) => handleWeekdays(1)}
                                                                    variant="contained">{t('common.weekdays.mon')}</Button>
                                                                <Button
                                                                    disabled={account.times[0].weekdays.indexOf(2)===-1}
                                                                    color={account.deliveryDays.indexOf(2) > -1 ? 'primary' : 'inherit'}
                                                                    onClick={(e) => handleWeekdays(2)}
                                                                    variant="contained">{t('common.weekdays.tue')}</Button>
                                                                <Button
                                                                    disabled={account.times[0].weekdays.indexOf(3)===-1}
                                                                    color={account.deliveryDays.indexOf(3) > -1 ? 'primary' : 'inherit'}
                                                                    onClick={(e) => handleWeekdays(3)}
                                                                    variant="contained">{t('common.weekdays.wed')}</Button>
                                                                <Button
                                                                    disabled={account.times[0].weekdays.indexOf(4)===-1}
                                                                    color={account.deliveryDays.indexOf(4) > -1 ? 'primary' : 'inherit'}
                                                                    onClick={(e) => handleWeekdays(4)}
                                                                    variant="contained">{t('common.weekdays.thu')}</Button>
                                                                <Button
                                                                    disabled={account.times[0].weekdays.indexOf(5)===-1}
                                                                    color={account.deliveryDays.indexOf(5) > -1 ? 'primary' : 'inherit'}
                                                                    onClick={(e) => handleWeekdays(5)}
                                                                    variant="contained">{t('common.weekdays.fri')}</Button>
                                                                <Button
                                                                    disabled={account.times[0].weekdays.indexOf(6)===-1}
                                                                    color={account.deliveryDays.indexOf(6) > -1 ? 'primary' : 'inherit'}
                                                                    onClick={(e) => handleWeekdays(6)}
                                                                    variant="contained">{t('common.weekdays.sat')}</Button>
                                                                <Button
                                                                    disabled={account.times[0].weekdays.indexOf(0)===-1}
                                                                    color={account.deliveryDays.indexOf(0) > -1 ? 'primary' : 'inherit'}
                                                                    onClick={(e) => handleWeekdays(0)}
                                                                    variant="contained">{t('common.weekdays.sun')}</Button>
                                                            </ButtonGroup>
                                                            <Box mt={1}>
                                                                <Typography variant={"caption"}>
                                                                    *{t('deliver.deliveryDatesRemark')}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </>
                                                }
                                                <Box mt={2}>
                                                    <Typography>
                                                        {t('deliver.deliveryTimes')}
                                                    </Typography>
                                                </Box>
                                                <List>
                                                    {account.deliveryTimes.map((item, index) => (
                                                        <Box key={`times-${index}`}>
                                                            <ListItem dense key={item.bank} role={undefined}
                                                                      onClick={() => handleEditTime(index)} sx={{
                                                                border: '1px solid gainsboro',
                                                                marginTop: '10px'
                                                            }}>
                                                                <ListItemIcon sx={{minWidth: '35px'}}>
                                                                    <AccessTimeIcon/>
                                                                </ListItemIcon>
                                                                <ListItemText id={`times-${index}`}>
                                                                    <Typography variant="h6" noWrap={true}>
                                                                        <NumberFormat value={item.hour}
                                                                                      displayType={'text'}
                                                                                      format={(val) => {
                                                                                          return val < 10 ? '0' + val : val
                                                                                      }}/>
                                                                        <span>:</span>
                                                                        <NumberFormat value={item.minute}
                                                                                      displayType={'text'}
                                                                                      format={(val) => {
                                                                                          return val < 10 ? '0' + val : val
                                                                                      }}/>
                                                                    </Typography>
                                                                </ListItemText>
                                                                {index !== 0 &&
                                                                    <ListItemSecondaryAction>
                                                                        <IconButton edge="end" aria-label="add"
                                                                                    onClick={(e) => handleDeleteTime(index)}>
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </ListItemSecondaryAction>
                                                                }
                                                            </ListItem>
                                                        </Box>
                                                    ))
                                                    }
                                                </List>
                                                <Box mt={1}>
                                                    {errors.indexOf('times') > -1 &&
                                                        <Typography variant="caption" color="secondary">
                                                            *{t('ordering.errorTimes')}
                                                        </Typography>
                                                    }
                                                    <Button variant="outlined"
                                                            disableElevation
                                                            fullWidth={true}
                                                            disabled={loading.update}
                                                            style={{justifyContent: "center"}}
                                                            onClick={() => handleOpen()}>
                                                        <AddCircleOutlineIcon/><Box
                                                        ml={1}>{t('ordering.addDeliveryRounds')}</Box>
                                                    </Button>
                                                </Box>
                                            </Collapse>
                                            <Box mt={2}>
                                                <Typography id="continuous-slider">
                                                    {t('delivery.startPrice')}
                                                </Typography>
                                                <Box key={`item-start`} mt={3}>
                                                    <FormControl fullWidth variant="outlined">
                                                        <TextField
                                                            key={`input-start`}
                                                            label={t('delivery.deliverPriceStart')}
                                                            variant="outlined"
                                                            type={"tel"}
                                                            name={'deliveryStartPrice'}
                                                            value={account.deliveryStartPrice}
                                                            placeholder={"0"}
                                                            aria-readonly={true}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                readOnly: true,
                                                                startAdornment: <InputAdornment
                                                                    position="start">฿</InputAdornment>,
                                                            }}
                                                        />
                                                    </FormControl>
                                                </Box>
                                                {/*<Box mt={3}>*/}
                                                {/*    <FormControl fullWidth variant="outlined">*/}
                                                {/*        <fieldset style={{border: '1px solid rgba(0, 0, 0, 0.23)',*/}
                                                {/*            paddingTop: '0px',*/}
                                                {/*            margin: '0px',*/}
                                                {/*            borderRadius: '4px',*/}
                                                {/*            fontSize: '12px',*/}
                                                {/*            color: 'rgba(0, 0, 0, 0.6)'*/}
                                                {/*        }}>*/}
                                                {/*            <legend>{t('delivery.startPriceCalculate')}</legend>*/}
                                                {/*            <Box display="flex" justifyContent="space-between" alignItems="center">*/}
                                                {/*                <IconButton aria-label="decrement" onClick={e=>handleDeliveryStart(-1)}>*/}
                                                {/*                    <IndeterminateCheckBoxIcon fontSize="large" />*/}
                                                {/*                </IconButton>*/}
                                                {/*                <Box>*/}
                                                {/*                    <Typography variant={"h6"}>*/}
                                                {/*                        {account.deliveryStartAt}*/}
                                                {/*                    </Typography>*/}
                                                {/*                </Box>*/}
                                                {/*                <IconButton aria-label="increment" onClick={e=>handleDeliveryStart(1)}>*/}
                                                {/*                    <AddBoxIcon fontSize="large" />*/}
                                                {/*                </IconButton>*/}
                                                {/*            </Box>*/}
                                                {/*        </fieldset>*/}
                                                {/*    </FormControl>*/}
                                                {/*</Box>*/}
                                            </Box>
                                            <Box mt={3}>
                                                <FormControl fullWidth variant="outlined">
                                                    <TextField
                                                        label={t('delivery.additionalPriceUnit')}
                                                        variant="outlined"
                                                        name={'deliveryAdditionalPrice'}
                                                        type={"tel"}
                                                        value={account.deliveryAdditionalPrice}
                                                        placeholder={"0"}
                                                        aria-readonly={true}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            readOnly: true,
                                                            startAdornment: <InputAdornment
                                                                position="start">฿</InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Box>
                                            {/*<Box mt={3}>*/}
                                            {/*    <FormControl fullWidth variant="outlined">*/}
                                            {/*        <fieldset style={{border: '1px solid rgba(0, 0, 0, 0.23)',*/}
                                            {/*            paddingTop: '0px',*/}
                                            {/*            margin: '0px',*/}
                                            {/*            borderRadius: '4px',*/}
                                            {/*            fontSize: '12px',*/}
                                            {/*            color: 'rgba(0, 0, 0, 0.6)'*/}
                                            {/*        }}>*/}
                                            {/*            <legend>{t('delivery.additionalPriceCalculate')}</legend>*/}
                                            {/*            <Box display="flex" justifyContent="space-between" alignItems="center">*/}
                                            {/*                <IconButton aria-label="decrement" onClick={e=>handleDeliveryAdditional(-1)}>*/}
                                            {/*                    <IndeterminateCheckBoxIcon fontSize="large" />*/}
                                            {/*                </IconButton>*/}
                                            {/*                <Box>*/}
                                            {/*                    <Typography variant={"h6"}>*/}
                                            {/*                        {account.deliveryAdditional}*/}
                                            {/*                    </Typography>*/}
                                            {/*                </Box>*/}
                                            {/*                <IconButton aria-label="increment" onClick={e=>handleDeliveryAdditional(1)}>*/}
                                            {/*                    <AddBoxIcon fontSize="large" />*/}
                                            {/*                </IconButton>*/}
                                            {/*            </Box>*/}
                                            {/*        </fieldset>*/}
                                            {/*    </FormControl>*/}
                                            {/*</Box>*/}
                                        </Box>
                                        <Box mt={1}>
                                            <Box>
                                                <Typography variant={"caption"}>
                                                    *{t('delivery.pricingRateMessage')}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant={"caption"}>
                                                    *{t('delivery.discountRateMessage')}
                                                    <Link href="/discounts" style={{marginLeft: '3px'}}>{t('discount.delivery')}</Link>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Collapse>
                            </Card>
                        </Box>
                    </Collapse>
                    <Box display="flex" justifyContent="center" m={1} mt={2} pb={2}>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                disabled={updating}
                                startIcon={<SaveIcon/>}
                                onClick={(e) => handleSave(e)}>
                            {t('common.save')}
                        </Button>
                    </Box>
                </>
            }
            <Dialog
                onClose={ handleClose }
                maxWidth='xs'
                scroll="body"
                PaperProps={{
                    style: {
                        margin: 0,
                        maxWidth: 'none',
                    },
                }}
                open={ open }>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ClockPicker date={time} onChange={(newDate) => setTime(newDate)} />
                </LocalizationProvider>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button onClick={ handleAddRound } fullWidth color='primary' variant="outlined"
                                    size="large">
                                { dateFormat(time, "HH:MM")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={ handleClose } fullWidth variant="outlined"
                                    size="large">
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <DeliveryLocationDialog
                open={openDeliveryLocation}
                shop={account}
                maxAreas={maxAreas}
                onClose={handleCloseDeliveryLocation}
                onSave={handleSaveDeliveryLocation}
            />
            <Snackbar open={deliveryError>0}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={3000} onClose={handleCloseDeliveryTimeError}>
                <Alert onClose={handleCloseDeliveryTimeError} severity="warning">
                    {deliveryError === 1 && t('deliver.deliveryTimesDuplicate')}
                    {deliveryError === 2 && t('deliver.deliveryDatesRequire')}
                </Alert>
            </Snackbar>
        </div>
    )
}