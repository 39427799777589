import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import {getTextI18n} from "../TextI18n";
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import {httpClient} from "../../core/HttpClient";
import CircularProgress from "@mui/material/CircularProgress";
import {ListItemButton} from "@mui/material";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function Store() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState([]);
    const [condition, setCondition] = useState({});

    useEffect(() => {
        console.log('[Store]');
        const productUrl = process.env.REACT_APP_API_BASE_URL + '/stores/products';
        const conditionUrl = process.env.REACT_APP_API_BASE_URL + '/stores/free/condition';
        let tasks = [httpClient.get(productUrl), httpClient.get(conditionUrl)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setProducts(rs[0].data);
                }
                if(rs[1] && rs[1].data){
                    setCondition(rs[1].data);
                }
                setLoading(false);
            })
            .catch(e=>{
                console.log('error', e);
            });

    }, []);

    return (
        <div className={classes.root}>
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <Box pb={2}>
                    <Card variant={"outlined"}>
                        <List>
                            {products.map((product, index) => (
                            <ListItemButton key={`item-${index}`} divider={index!==products.length-1}
                                      component={Link} to={`/store/select/${product.code}`}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="square"
                                        src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/products/${product.code}_S.jpg`}></Avatar>
                                </ListItemAvatar>
                                <ListItemText>
                                    <div>
                                        <Box>
                                            <Typography variant="body1">
                                                <strong>{getTextI18n(product.name)}</strong>
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1">
                                                <span>{getTextI18n(product.description)}</span>
                                            </Typography>
                                        </Box>
                                        {product.price < 1 &&
                                            <Box display="flex" justifyContent="space-between" mt={1}>
                                                <Typography variant="body1">
                                                    <span style={{marginRight: '5px'}}>
                                                        {t('store.code')}:
                                                    </span>
                                                    <span>{product.code}</span>
                                                </Typography>
                                                <Box display={"flex"} alignItems={"center"}>
                                                    <CardGiftcardIcon color={condition.freeItems && condition.freeItems.indexOf("F1") > -1?"disabled":"success"} />
                                                    <Typography noWrap={true} ml={1}>
                                                        <b>{t('store.campaign.standee1.free')}</b>
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                        {product.price > 0 &&
                                            <Box display="flex" justifyContent="space-between" mt={1}>
                                                <Typography variant="body1">
                                                <span style={{marginRight: '5px'}}>
                                                    {t('store.code')}:
                                                </span>
                                                    <span>{product.code}</span>
                                                </Typography>
                                                <Typography noWrap={true}>
                                                    <b style={{marginRight: '5px'}}>
                                                        {t('store.price')}
                                                    </b>
                                                    <b>{product.price} {t('pricing.baht')}</b>
                                                </Typography>
                                            </Box>
                                        }
                                    </div>
                                </ListItemText>
                            </ListItemButton>
                            ))}
                        </List>
                    </Card>
                </Box>
            }
        </div>
    )
}