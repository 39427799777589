import React  from 'react';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

export default function Partners() {
    const { t } = useTranslation();
    const history = useHistory();

    const handleClick = () => {
        history.push('/partners/1');
    };

    return (
        <div>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {t('partner.title')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                <Card sx={{ display: 'flex' }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 150 }}
                        image="https://mui.com/static/images/cards/live-from-space.jpg"
                        alt="Live from space album cover"
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }} m={1}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography component="div" variant="h6">
                                {t('partner.partners.1.name')}
                            </Typography>
                            <Typography variant="subtitle1" color="text.secondary" component="div">
                                {t('partner.partners.1.description')}
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                            <Button variant="outlined"
                                    fullWidth={true}
                                    onClick={handleClick} >
                                {t('partner.moreInfo')}
                            </Button>
                        </Box>
                    </Box>
                </Card>
            </Box>
        </div>
    )
}