import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import OrderItems from "../OrderItems";
import ContactInfo from "../ContactInfo";
import AddressStatic from '../AddressStatic'
import {httpClient} from "../../core/HttpClient";
import OrderStatus  from "../OrderStatus";
import OrderInfo from "../OrderInfo";
import AddressDeliveryDialog from "../AddressDeliveryDialog";
import CircularProgress from "@mui/material/CircularProgress";
import OrderPayment from "../OrderPayment";
import List from "@mui/material/List";
import {ListItem} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import PhoneIcon from '@mui/icons-material/Phone';
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import {DateI18n} from "../DateI18n";
import {getUserName} from "../TextUserName";
import NumberFormat from "react-number-format";

let DEFAULT_ZOOM = 17;
let DEFAULT_LOCATION = {accuracy: 0, lat: 13.724168703054607, lng: 100.52801370653471, zoom: DEFAULT_ZOOM};

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function OrderView() {
    const { t } = useTranslation();
    const classes = useStyles();
    const { uid } = useParams();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [foodOrder, setFoodOrder] = useState({customer: {}, items: [], timeLine: {}, location: DEFAULT_LOCATION});

    useEffect(() => {
        console.log('[OrderView]');
        let tasks = [];
        if(uid) {
            const url = process.env.REACT_APP_API_BASE_URL + '/transactions/' + uid;
            tasks.push(httpClient.get(url));
        }
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0].data){
                    setFoodOrder(rs[0].data);
                }
                setLoading(false);
            })
            .catch(e=>{
                console.log('error', e);
                setLoading(false);
            });
    }, [uid]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleTel = (phone) => {
        if(phone){
            const link = document.createElement('a');
            link.id = 'phone';
            link.href = 'tel:'+phone;
            link.click();
        }
    }

    return (
        <div className={classes.root}>
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <>
                <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/transactions" className={classes.link}>
                            <Typography variant="h6">
                                {t('order.all')}
                            </Typography>
                        </Link>
                        <Typography variant="h6">
                            {foodOrder.transactionNo}
                        </Typography>
                    </Breadcrumbs>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}}/>
                <Box m={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <OrderStatus value={foodOrder}/>
                            <OrderInfo value={foodOrder}/>
                            {foodOrder.customer &&
                                <Box mt={1}>
                                    <Card elevation={1}>
                                        <CardHeader style={{paddingBottom: '0', textAlign: 'center'}}
                                                    title={<Typography variant="h6">
                                                        {t('customer.title')}
                                                    </Typography>}/>
                                        <ContactInfo value={foodOrder}/>
                                        {foodOrder.deliverType === 'DELIVERY' &&
                                            <Box m={2} mt={0}>
                                                <Box style={{
                                                    border: '1px solid #e7e7e7',
                                                    borderRadius: '5px'
                                                }}>
                                                    <ButtonBase onClick={handleClick}
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    height: '120px',
                                                                    width: '100%'
                                                                }}>
                                                        <AddressStatic location={foodOrder.contactProfile}/>
                                                    </ButtonBase>
                                                </Box>
                                            </Box>
                                        }
                                    </Card>
                                </Box>
                            }
                            <Box mt={1}>
                                <Card elevation={1}>
                                    <OrderItems value={foodOrder}/>
                                </Card>
                            </Box>
                            <Box mt={1}>
                                <Card elevation={1}>
                                    <OrderPayment value={foodOrder} />
                                </Card>
                            </Box>
                            {foodOrder.rider &&
                                <Box mt={1}>
                                    <Card elevation={1}>
                                        <CardHeader style={{paddingBottom: '0', textAlign: 'center'}}
                                                    title={<Typography variant="h6">
                                                        {t('rider.title')}
                                                    </Typography>}/>
                                        <List>
                                            <ListItem alignItems="flex-start"
                                                      secondaryAction={
                                                          <Box display={"flex"}>
                                                              <IconButton color={"primary"} edge="end" aria-label="tel" onClick={e=>handleTel(foodOrder.rider.mobile)} >
                                                                  <Avatar sx={{background: "#1abc9c", width: 32, height: 32 }}>
                                                                    <PhoneIcon />
                                                                  </Avatar>
                                                              </IconButton>
                                                              {/*<OrdersChat to={"rider"} type={"buttonIcon"} />*/}
                                                          </Box>
                                                      }>
                                                <ListItemAvatar>
                                                    <Avatar alt="Rider" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${foodOrder.rider._id}`} />
                                                </ListItemAvatar>
                                                <ListItemText primary={getUserName(foodOrder.rider)} secondary={
                                                    <Typography variant="body2" noWrap={true} component="div" style={{flexGrow: 1}}>
                                                        {foodOrder.rider.mobile &&
                                                            <a href={`tel:${foodOrder.rider.mobile}`}><NumberFormat value={foodOrder.rider.mobile} displayType={'text'} format="###-###-####" /></a>
                                                        }
                                                    </Typography>
                                                }>
                                                </ListItemText>
                                            </ListItem>
                                        </List>
                                        {foodOrder.deliver && foodOrder.deliver.attachment &&
                                            <Box m={2} mt={0} style={{textAlign: 'center'}}>
                                                <Box display="flex" justifyContent="space-between" mb={1}>
                                                    <Typography variant="body1">
                                                        {t('deliver.deliverDate')}
                                                    </Typography>
                                                    <Typography variant="body1">
                                                        <DateI18n value={foodOrder.deliver.dateTime}
                                                                  format="DD MMM YYYY HH:mm"></DateI18n>
                                                    </Typography>
                                                </Box>
                                                <img
                                                    style={{width: '100%'}}
                                                    alt={foodOrder.deliver.attachment.originName}
                                                    src={`${process.env.REACT_APP_CDN_BASE_URL}/${foodOrder.deliver.attachment.key}`}
                                                />
                                            </Box>
                                        }
                                    </Card>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
                {foodOrder.contactProfile &&
                    <AddressDeliveryDialog
                        open={open}
                        location={foodOrder.contactProfile}
                        onClose={e=>setOpen(false)}
                    />
                }
                <Box style={{height: '2rem'}}></Box>
                </>
            }
        </div>
    )
}