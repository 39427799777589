import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {Link, useHistory, useParams} from "react-router-dom";
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LogoutIcon from '@mui/icons-material/Logout';
import { red } from '@mui/material/colors';
import {httpClient} from "../../core/HttpClient";
import {getTextI18n} from "../TextI18n";
import dayjs from 'dayjs';
import AccountPlan from "../AccountPlan";
import AccountDayDialog from "../AccountDay/AccountDayDialog";
import NumberFormat from "react-number-format";
import {getRemainingDay} from "../RemainingDay";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    card: {
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
    },
    tableHead: {
        border: '4px solid #fafafa',
        width: '33%',
        minWidth: '105px',
        background: '#e0e0e0',
        fontWeight: 'bold'
    },
    tableHeadSelected: {
        border: '4px solid #fafafa',
        width: '33%',
        minWidth: '105px',
        color: '#fff',
        background: theme.palette.orange.main,
        fontWeight: 'bold'
    },
    tableContent: {},
    tableContentSelected: {
        color: theme.palette.orange.main,
        fontWeight: 'bold'
    },
    tableLastContent: {
        paddingTop: '7px',
        borderBottom: "none",
        paddingBottom: 0
    },
    tableTitle: {
        borderBottom: "none",
        paddingBottom: 0,
        fontWeight: 'bold'
    },
    tableButton: {
        border: '4px solid #fafafa',
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none"
    },
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    noSelected: {
        color: "#8c8c8c"
    }
}));

let MyCardMedia = ({value, className}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo_16_9.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/o/${value.image.name}`;
    }
    return (<CardMedia
        className={className}
        image={imageUrl}
        title={name}
    />)
};

export default function Account() {
    const { t } = useTranslation();
    const classes = useStyles();
    const {id} = useParams();
    const history = useHistory();
    const [openRedeem, setOpenRedeem] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [openLeft, setOpenLeft] = useState(false);
    const [loading, setLoading] = useState(true);
    const [account, setAccount] = useState({plan: 'STANDARD', planOption: {}, image: {}, name:{i18n:{en:''}}});
    const [expiring, setExpiring] = useState(0);
    const [confirmDeleteText, setConfirmDeleteText] = useState('');

    useEffect(() => {
        console.log('[Account]');
        if(id){
            const token = localStorage.getItem('token');
            if (token) {
                const url = process.env.REACT_APP_API_BASE_URL + '/accounts/'+id;
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            setAccount(res.data);

                            // expiring
                            if(res.data.expired) {
                                if(res.data.plan !== 'FREE'){
                                    let remainingHour = dayjs(res.data.expired).diff(new Date(), 'hour');
                                    if(remainingHour < 1) {
                                        setExpiring(2);
                                    } else if(remainingHour < 96 && remainingHour > 0) {
                                        setExpiring(1);
                                    }
                                }
                            }
                        }
                        setLoading(false);
                    });
            }
        }

    }, [id]);

    const handleClickOpenRedeem = () => {
        setOpenRedeem(true);
    };

    const handleClickVerification = () => {
        history.push('/account/' + id + '/verification');
    };

    const handleClickOpenDelete = () => {
        if(account.isOwner){
            setOpenDelete(true);
        } else {
            setOpenLeft(true);
        }

    };

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    const handleCloseLeft = () => {
        setOpenLeft(false);
    };

    const handleTextChange = ({ target }) => {
        let value = target.value || '';
        let _lowerValue = value.toLowerCase();
        setConfirmDeleteText(_lowerValue);
    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + id;
        httpClient.delete(url)
            .then(res => {
                history.push('/accounts');
            });
    };

    const handleLeft = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + id +'/left';
        httpClient.delete(url)
            .then(res => {
                history.push('/accounts');
            });
    };

    const handleUpdateAccount = (data) => {
        if(data){
            setAccount({...account, plan: data.plan, expired: data.expired});
        }
    };

    const handleClosePaymentComplete = (value) => {
        if(value === 'complete'){
            if(id){
                const url = process.env.REACT_APP_API_BASE_URL + '/accounts/'+id;
                httpClient.get(url)
                    .then(res => {
                        if(res.data){
                            setAccount(res.data);

                            // expiring
                            if(res.data.expired){
                                if(res.data.plan !== 'FREE'){
                                    let remainingHour = dayjs(res.data.expired).diff(new Date(), 'hour');
                                    if(remainingHour < 1) {
                                        setExpiring(2);
                                    } else if(remainingHour < 96 && remainingHour > 0) {
                                        setExpiring(1);
                                    } else {
                                        // After redeem
                                        setExpiring(0);
                                    }
                                }
                            }
                        }
                        setLoading(false);
                    });
            }
        }
        setOpenRedeem(false);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Box display="flex" alignItems="center">
                    <Link to="/accounts" className={classes.link}>
                        <Typography variant="h6" align={"center"}>
                            {t('account.title')}
                        </Typography>
                    </Link>
                </Box>
            </Box>
            <Divider style={{'marginTop': '10px', 'marginBottom': '10px'}} />
            <Box m={1}>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {account._id &&
            <>
            {account.status === 'denied' &&
            <Box mt={2} mb={2}>
                <Alert severity="error">
                    {t('account.denied')}
                </Alert>
            </Box>
            }
            {account.isOwnerOrAdmin && account.status !== 'denied' && (account.verified === 0) &&
            <Box mt={2} mb={2}>
                <Alert icon={false} severity="warning" sx={{"& .MuiAlert-message":{width: '100%'}}}>
                    <Box>
                        {t('account.unverified')}
                    </Box>
                    <Box mt={2}>
                        <Button variant="outlined"
                                color="warning"
                                size="large"
                                fullWidth={true}
                                onClick={handleClickVerification}>
                            {t('common.message.verify')}
                        </Button>
                    </Box>
                </Alert>
            </Box>
            }
            {account.status !== 'denied'  && account.verified === 1 && expiring > 0 &&
            <Box mt={2} mb={2}>
                <Alert icon={false} severity={expiring === 1?'warning':'error'} sx={{"& .MuiAlert-message":{width: '100%'}}}>
                    <Box>
                        {t(expiring === 1?'common.message.expiring':'common.message.expired')}
                    </Box>
                    <Box mt={2}>
                        <Button variant="outlined" color={"warning"} fullWidth={true} onClick={handleClickOpenRedeem}>
                            {t('pricing.redeem')}
                        </Button>
                    </Box>
                </Alert>
            </Box>
            }
            <Card elevation={1} className={classes.card}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <MyCardMedia value={account} className={classes.media} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box>
                            <CardContent>
                                <Box mb={1}>
                                    <Typography variant="h6">
                                        {account.name.i18n.en}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography className={classes.title} color="inherit">
                                        {t('account.accountId')}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary">
                                        {account.cif}
                                    </Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography className={classes.title} color="inherit">
                                        {t('account.verify')}
                                    </Typography>
                                    {account.status !== 'denied' &&
                                        <Typography className={classes.title} color="textSecondary">
                                            {t(account.verified===0?'account.verifyFail':'account.verifyPass')}
                                        </Typography>
                                    }
                                    {account.status === 'denied' &&
                                        <Typography className={classes.title}
                                                    style={{color: red[500]}}>
                                            {t('common.denied')}
                                        </Typography>
                                    }
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography className={classes.title} color="inherit">
                                        {t('pricing.remainingDay')}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary"
                                    ><NumberFormat value={getRemainingDay(account.expired)} displayType={'text'} thousandSeparator={true} /> {t('pricing.remainingUnit')}</Typography>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography className={classes.title} color="inherit">
                                        {t('accountCredit.creditRemaining')}
                                    </Typography>
                                    <Typography className={classes.title} color="textSecondary"
                                    ><NumberFormat value={account.remaining} displayType={'text'} thousandSeparator={true} /> {t('accountCredit.credit')}</Typography>
                                </Box>
                                {account.isOwnerOrAdmin &&
                                    <AccountPlan data={account} onUpdate={handleUpdateAccount} />
                                }
                                <Box mt={2}>
                                    <Button variant="outlined"
                                            color="secondary"
                                            size="large"
                                            disableElevation
                                            fullWidth={true}
                                            startIcon={<DeleteIcon/>}
                                            onClick={(e) => handleClickOpenDelete(e)}>
                                        {account.isOwner && t('account.delete')}
                                        {!account.isOwner && t('account.leave')}
                                    </Button>
                                </Box>
                            </CardContent>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
            </>
            }
            </Box>
            <AccountDayDialog open={openRedeem} account={account} callback={handleClosePaymentComplete} />
            <Dialog
                fullWidth
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('account.confirmDelete')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('account.deleteMessage')}
                    </DialogContentText>
                    <Box>
                        <Typography variant="caption" color="inherit">
                            {t('account.deleteMessageConfirm')}
                        </Typography>
                        <TextField id="delete-account-confirm"
                                   variant="outlined"
                                   value={confirmDeleteText}
                                   onChange={ handleTextChange }
                                   size="small"
                                   fullWidth />
                    </Box>
                </DialogContent>
                <Box m={2}>
                    <Box mb={2}>
                        <Button variant="contained"
                                color="secondary"
                                size="large"
                                disableElevation
                                disabled={['permanently delete', 'ลบถาวร'].indexOf(confirmDeleteText) === -1}
                                fullWidth={true}
                                startIcon={<DeleteForeverIcon />}
                                onClick={(e) => handleDelete(e)}>
                            {t('common.delete')}
                        </Button>
                    </Box>
                    <Box>
                        <Button variant="contained"
                                color="grey"
                                onClick={handleCloseDelete}
                                fullWidth={true}
                                size="large"
                                disableElevation>
                            {t('common.cancel')}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
            <Dialog
                fullWidth
                open={openLeft}
                onClose={handleCloseLeft}
                aria-labelledby="alert-dialog-left-title"
                aria-describedby="alert-dialog-left-description"
            >
                <DialogTitle id="alert-dialog-left-title">{t('account.confirmLeft')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('account.leftMessage')}
                    </DialogContentText>
                </DialogContent>
                <Box m={2}>
                    <Box mb={2}>
                        <Button variant="contained"
                                color="secondary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                startIcon={<LogoutIcon />}
                                onClick={(e) => handleLeft(e)}>
                            {t('account.leftConfirm')}
                        </Button>
                    </Box>
                    <Box>
                        <Button variant="contained"
                                color="grey"
                                onClick={handleCloseLeft}
                                fullWidth={true}
                                size="large"
                                disableElevation>
                            {t('common.cancel')}
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </div>
    )
}