import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import {DateI18n} from "../DateI18n";
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {httpClient} from "../../core/HttpClient";
import {ListItemButton} from "@mui/material";
import dayjs from "dayjs";
import locale_th from 'dayjs/locale/th';
import NumberFormat from "react-number-format";
import ListItemIcon from "@mui/material/ListItemIcon";
import OrderImportantIcon from "../OrderImportantIcon";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:'',
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch) {
    console.log('fetchHits');
    dispatch({ type: "FETCH_START" });
    try {
        let dataTable = {};
        let results = [];
        let mode = 'all';

        if(query.search){
            mode = 'query';

            const signal = controller.signal;
            const url = process.env.REACT_APP_API_BASE_URL + '/transactions/search';
            const result = await httpClient(
                `${url}?q=${query.search}&c=${query.cursor}`,
                { signal }
            );

            if(result.data){
                result.data.forEach(item=>{
                    if(item.items){
                        item.items.forEach(txn=>{
                            let dateCode = dayjs(txn.orderDate).locale(locale_th).format('YYYYMMDD');
                            if(dataTable[dateCode]){
                                dataTable[dateCode]._id = txn._id;
                                dataTable[dateCode].list.push(txn);
                            }else{
                                dataTable[dateCode] = {
                                    _id: txn._id,
                                    date: txn.orderDate,
                                    id: txn._id,
                                    list: [txn]
                                }
                            }
                        });
                    }
                });
            }

            Object.keys(dataTable).forEach(key => {
                results.push(dataTable[key]);
            });

        } else {
            const url = process.env.REACT_APP_API_BASE_URL + '/transactions';
            const result = await httpClient(
                `${url}?c=${query.cursor}`,
                { signal: controller.signal }
            );

            if(result.data){
                result.data.forEach(item=>{
                    item.items.forEach(txn=>{
                        let dateCode = dayjs(txn.orderDate).locale(locale_th).format('YYYYMMDD');
                        if(dataTable[dateCode]){
                            dataTable[dateCode]._id = item._id;
                            dataTable[dateCode].list.push(txn);
                        }else{
                            dataTable[dateCode] = {
                                _id: item._id,
                                date: txn.orderDate,
                                id: `${txn._id}`,
                                list: [txn]
                            }
                        }
                    });

                });
            }
            Object.keys(dataTable).forEach(key => {
                results.push(dataTable[key]);
            });
        }

        results.sort((a,b) => (a.date < b.date) ? 1 : ((b.date < a.date) ? -1 : 0));
        dispatch({ type: "FETCH_SUCCESS", payload: results, cursor: query.cursor, mode: mode })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

let controller = null;

export default function Transactions() {
    const { t } = useTranslation();
    const [{ hits, hasNext, hasError, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null
    });
    const [query, setQuery] = useState({cursor: '', search: ''});
    const classes = useStyles();

    const debouncedFetchHits = useRef(
        _.debounce(
            (query) => fetchHits(query, dispatch),
            800
        )
    ).current;

    useEffect(() => {
        console.log('[Transactions]');
        controller = new AbortController();
        const timeOutId = setTimeout(() => debouncedFetchHits({cursor: '', search: ''}), 10);
        return () => clearTimeout(timeOutId);
    }, [debouncedFetchHits]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData', cursor);
        let newValue = {...query, cursor: cursor};
        setQuery(newValue);
        const cancelToken = httpClient.CancelToken.source();
        debouncedFetchHits(newValue, cancelToken.token).then(r=>{});
    };

    const handleQueryChange = (value) =>{
        setQuery({cursor: '', search: value});
        if(controller){
            controller.abort();
            controller = new AbortController();
        } else {
            controller = new AbortController();
        }
        debouncedFetchHits({cursor: '', search: value}).then(r => {});
    };

    return (
        <div className={classes.root}>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <Typography variant="h6">
                            {t('order.all')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={t('common.searchExample')}
                                fullWidth={true}
                                className={classes.inputInput}
                                style={{
                                    border: '1px solid gainsboro',
                                    borderRadius: '5px',
                                    paddingLeft: `calc(1em + 18px)`,
                                }}
                                value={query.search}
                                onChange={event => handleQueryChange(event.target.value)}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {hasError &&
            <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
            }
            {!isLoading && hits.length === 0 && !hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('common.notFound')}
                </Typography>
            </Box>
            }
            {hits.length > 0 &&
            <InfiniteScroll
                dataLength={hits.length} //This is important field to render the next data
                next={e=>handleFetchData(cursor)}
                hasMore={hasNext}
            >
            <Box style={{background: 'white'}}>
                <List className={classes.root} subheader={<li/>}>
                    {hits.map((result) => (
                        <li key={`section-${result.id}`} className={classes.listSection}>
                            <ul className={classes.ul}>
                                <ListSubheader>
                                    <Box display="flex" justifyContent="center" alignItems="center" style={{zIndex: 99, background: 'white',}}>
                                        <DateI18n value={result.date} format="DD MMM YYYY" />
                                    </Box>
                                </ListSubheader>
                                {result.list.map((item) => (
                                    <ListItemButton divider key={`item-${item._id}`} component={Link}
                                              to={`/orders/view/${item.uid}`}>
                                        <ListItemIcon>
                                            <OrderImportantIcon foodOrder={item} />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <div>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography noWrap={true}>
                                                        <span>
                                                            {item.transactionNo}
                                                        </span>
                                                    </Typography>
                                                    <div>
                                                        <span style={{color: ['cancel', 'reject'].indexOf(item.status) > -1?'red':'green', fontSize: '1rem'}}>
                                                            <NumberFormat value={item.grandTotal} displayType={'text'}
                                                                          thousandSeparator={true} prefix={'฿'}/>
                                                        </span>
                                                    </div>
                                                </Box>
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography noWrap={true}>
                                                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                                                            <DateI18n format="DD MMM YY, HH:mm" value={item.orderDate} />
                                                        </span>
                                                    </Typography>
                                                    <div>
                                                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                                                            {t(`transactionStatus.${item.status}`)}
                                                        </span>
                                                    </div>
                                                </Box>
                                            </div>
                                        </ListItemText>
                                    </ListItemButton>
                                ))}
                            </ul>
                        </li>
                    ))}
                </List>
            </Box>
            </InfiniteScroll>
            }
            {isLoading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
        </div>
    )
}