import axios from 'axios'

axios.interceptors.request.use(async (config)=> {

    const jwtToken = localStorage.getItem('token');
    if (jwtToken) {
        config.headers = { 'Authorization': `Bearer ${jwtToken}` }
    }
    return config;

});

// axios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         window.location.href = '/logout';
//     }
//     return error;
// });

export const httpClient = axios;