import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import VerifiedIcon from '@mui/icons-material/Verified';
import { red, orange, grey } from '@mui/material/colors';
import {httpClient} from "../../core/HttpClient";
import {getTextI18n} from "../TextI18n";
import dayjs from 'dayjs';
import {ListItemSecondaryAction} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

let MyAvatar = ({value}) => {
    let imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_photo.png`;
    let name = '';
    if(value && value.name) {
        name = getTextI18n(value.name);
    }
    if(value && value.image) {
        imageUrl = `${process.env.REACT_APP_CDN_BASE_URL}/${value.image.permission}/merchant/m/${value.image.name}`;
    }
    return (<img
        alt={name}
        src={imageUrl}
        style={{width:'60px', height: '60px'}}
    />)
};

let MySecondaryText = ({role, plan, expired}) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" ml={1} mr={1}>
            <Typography variant="body1">
                <span>{role}</span>
            </Typography>
        </Box>
    )
};

export default function Accounts() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);

    useEffect(() => {
        console.log('[Accounts]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAccounts(res.data.accounts);
                }
                setLoading(false);
            });
    }, []);

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('account.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
            {!loading &&
            <Box display="flex" justifyContent="center" mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth={true}
                    disableElevation
                    startIcon={<AddIcon />}
                    component={Link}
                    to="/accounts/new">
                    {t('shop.create')}
                </Button>
            </Box>
            }
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {accounts.length > 0 && !loading &&
            <Card>
                <List className={classes.root}>
                    {accounts.map((value, index) => (
                        <ListItem key={value.account._id} divider={index !== accounts.length - 1} button component={Link} to={`/account/${value.account._id}`}>
                            <ListItemAvatar>
                                <MyAvatar value={value.account} />
                            </ListItemAvatar>
                            <ListItemText primary={
                                                <Box display="flex" justifyContent="space-between" alignItems="center" ml={1} mr={1}>
                                                    <div>{value.account.name.i18n.en}</div>
                                                </Box>
                                            }
                                            secondaryTypographyProps={{ component: 'div' }}
                                            secondary={<MySecondaryText role={t(`user.roles.${value.roles[0]}`)}
                                                                      plan={value.plan}
                                                                      expired={value.account.expired} />} />
                            <ListItemSecondaryAction>
                                {value.account.status === 'denied' && <div><ErrorIcon style={{ color: red[500] }}/></div>}
                                {value.account.status !== 'denied' && (value.account.verified === 1) && dayjs(value.expired).diff(new Date(), 'hour') < 0 && <div><ErrorIcon style={{ color: red[500] }}/></div>}
                                {value.account.status !== 'denied' && (value.account.verified === 1) && dayjs(value.expired).diff(new Date(), 'hour') > 0 && dayjs(value.expired).diff(new Date(), 'hour') < 96 && <div><ErrorIcon style={{ color: orange[500] }}/></div>}
                                {value.account.status !== 'denied' && (value.account.verified === 1) && dayjs(value.expired).diff(new Date(), 'hour') > 96 && <div><VerifiedIcon color={"info"} /></div>}
                                {value.account.status !== 'denied' && (value.account.verified === 0) && <div><VerifiedIcon style={{ color: grey[400] }}/></div>}
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>
            </Card>
            }
            </Box>
        </div>
    )
}