import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";
import {Route, Redirect, useHistory} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import CssBaseline from "@mui/material/CssBaseline";
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import jwt_decode from "jwt-decode";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import app from "../../core/Firebase";
import SideMenu from "../../components/SideMenu";
import FooterMenu from "../../components/FooterMenu";
import {getTextI18n} from "../../components/TextI18n";
import {httpClient} from "../../core/HttpClient";
import { Context } from "../../core/Context";
import LineOAFriendship from "../../components/LineOAFriendship";
import Modal from "@mui/material/Modal";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 0,
    },
    leftMenuButton: {
        marginTop: '1rem',
    },
    title: {
        flexGrow: 1,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#efefef'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    showMenu: {
        display: 'inline'
    },
    hideMenu: {
        display: 'none'
    },
    content: {
        marginBottom: '75px',
    },
    select: {
        padding: '4px 16px 4px 16px!important',
        color: 'white!important',
        "& .MuiSvgIcon-root": {
            color: "white!important",
        },
        "& option": {
            color: "black"
        }
    },
    icon:{
        left:-5,
        color: 'white!important',
    },
    iconOpen:{
        transform:'none'
    },

}));

const modalStyle = {
    margin: '10px',
    border: '0px',
    position: 'absolute',
    top: '50%',
    transform: 'translate(-0%, -50%)',
    outline: 'none'
};

console.log("Firebase connected : "+app.name);

const Dashboard = ({role, children}) => {
    const { t } = useTranslation();
    const [context, setContext] = useState({userId: '', cid: '', displayName: '', role: role, accountType: 'RESTAURANT', plan: '', success: false, fail: false, loading: false});
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedAccountName, setSelectedAccountName] = useState('FoodKub');
    const [expiringReminder, setExpiringReminder] = useState(0);
    const [selectedAccountStatus, setSelectedAccountStatus] = useState('');
    const [verified, setVerified] = useState(1);
    const [accounts, setAccounts] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showFreeItems, setShowFreeItems] = useState([]);
    const history = useHistory();
    const classes = useStyles();
    const sideMenuRef = useRef();
    const open = Boolean(anchorEl);

    useEffect(() => {
        console.log('[Dashboard]');
        let token = localStorage.getItem('token');
        let ticketPasscode = localStorage.getItem('ticket');
        if(!token || !ticketPasscode){
            history.push('/signin');
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected';
        httpClient.get(url)
            .then(res => {
                if(res.data && res.data.state !== 9 && res.data.accounts){
                    if(res.data.accounts[0]){
                        let _accounts = [];
                        res.data.accounts.forEach(item=>{
                           let account = item.account;
                            _accounts.push({_id: account._id, name: account.name})
                        });
                        setAccounts(_accounts);

                        let user = {
                            userId: res.data._id,
                            role: role
                        };
                        if(res.data.selectedAccount){
                            user.cid = res.data.selectedAccount.cid;
                            user.displayName = getTextI18n(res.data.selectedAccount.name);
                            user.accountType = res.data.selectedAccount.type;
                            user.plan = res.data.selectedAccount.plan;
                            setSelectedAccount(res.data.selectedAccount._id);
                            setSelectedAccountName(res.data.selectedAccount.name.i18n.en);
                            setSelectedAccountStatus(res.data.selectedAccount.status);
                            setVerified(res.data.selectedAccount.verified);
                            if(res.data.selectedAccount.expired){
                                let remainingHour = dayjs(res.data.selectedAccount.expired).diff(new Date(), 'hour');
                                if(remainingHour < 1){
                                    setExpiringReminder(2);
                                } else if(remainingHour < 96 && remainingHour > 0){
                                    setExpiringReminder(1);
                                }

                                if (res.data.selectedAccount.freeItems) {
                                    let show = ["OWNER", "ADMIN"].indexOf(role) > -1 ? true : false;
                                    if (res.data.selectedAccount.freeItems.indexOf("F1") === -1) {
                                        const itemStr = localStorage.getItem("F1");
                                        // if the item doesn't exist, return null
                                        if (itemStr) {
                                            const item = JSON.parse(itemStr);
                                            const now = new Date();
                                            if (now.getTime() < item.expiry) {
                                                show = false;
                                            }
                                        }
                                    } else {
                                        show = false;
                                    }

                                    if (show) {
                                        if (remainingHour > 0) {
                                            localStorage.removeItem("F1")
                                            // not show for now
                                            // setShowFreeItems(["F1"]);
                                        }
                                    }
                                }
                            }
                        } else {
                            window.location.href = '/login';
                        }

                        try {
                            // verify passcode
                            let ticketDecoded = jwt_decode(ticketPasscode);
                            if(res.data._id !== ticketDecoded.userId){
                                history.push('/signin');
                            }

                            // refresh token process
                            let tokenDecoded = jwt_decode(token);
                            let remainingDays = dayjs(tokenDecoded.exp * 1000).diff(new Date(), 'days');
                            // if expired is 30 days that mean refresh token every day;
                            if(remainingDays < 29){
                                let refreshToken = localStorage.getItem('refreshToken');
                                handleRefreshToken(token, refreshToken).then(r => {
                                    // refresh token done.
                                }).catch(e=>{
                                    console.log('error', e);
                                });
                            }

                        } catch (e){
                            history.push('/signin');
                        }

                        setContext({ ...user, success: false, fail: false, loading: false });
                    } else {
                        history.push('/accounts');
                    }
                } else {
                    history.push('/signin');
                }
            }).catch(e=>{
                console.log('error', e);
            })
    }, [history, role]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event) => {
        localStorage.clear();
        window.location.href = '/login';
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setContext({ ...context, success: false, fail: false, loading: false });
    };

    const handleFreeItemsClose = (event) => {
        const now = new Date();
        // const ttl = 1000 * 60 * 1; // 1 minute
        const ttl = 1000 * 60 * 60 * 24; // 24 hours
        const item = {
            value: "F1",
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem("F1", JSON.stringify(item))
        setShowFreeItems([]);
    };

    const handleGotoCampaign = (productCode) => {
        handleFreeItemsClose();
        history.push(`/store/select/${productCode}`);
    };

    const handleGoToRoute = (path) => {
        history.push(path);
        handleMenuClose();
    };

    const handleChange = (event) => {
        setSelectedAccount(event.target.value);
        setContext({ ...context,loading: true });
        const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected';
        httpClient.put(url, {account: event.target.value})
            .then(res => {
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('refreshToken', res.data.refreshToken);
                window.location.href = '/home';
            }).catch(e=>{
                window.location.href = '/login';
            })
    };

    const handleRefreshToken = (token, refreshToken) => {
        console.log('handleRefreshToken');
        const url = process.env.REACT_APP_API_BASE_URL + '/auth/line/refresh-token';
        const payload = {
            token: token,
            refreshToken: refreshToken
        };
        return httpClient.post(url, payload).then(res => {
            let data = res.data;
            if(data && data.token && data.refreshToken){
                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken);
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
            }
        }).catch(e=>{
            console.log('e', e);
        });
    }

    return (
        <div className='page page-dashboard'>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{selectedAccountName}</title>
                </Helmet>
                {selectedAccountStatus === 'activated' && verified === 1 && expiringReminder === 1 &&
                <Alert onClose={() => {setExpiringReminder(false);}} severity="warning">
                    <div onClick={()=>{history.push(`/account/${selectedAccount}`)}}>{t('common.message.expiring')}</div>
                </Alert>
                }
                {selectedAccountStatus === 'activated' && verified === 1 && expiringReminder === 2 &&
                    <Alert severity="error">
                        <div onClick={()=>{history.push(`/account/${selectedAccount}`)}}>{t('common.message.expired')}</div>
                    </Alert>
                }
                <LineOAFriendship />
                <CssBaseline />
                <header>
                    <AppBar elevation={0} position="static">
                        <Toolbar>
                            {selectedAccountStatus === 'activated' && expiringReminder !== 2 && ['OWNER', 'ADMIN'].indexOf(role) > -1 &&
                            <IconButton edge="start"
                                        className={classes.menuButton}
                                        onClick={() => sideMenuRef.current.toggleDrawer(true)}
                                        color="inherit" aria-label="menu">
                                <MenuIcon/>
                            </IconButton>
                            }
                            <Typography variant="h6" noWrap={true} className={classes.title}>
                                {accounts.length === 1 &&
                                    <span>{context.displayName}</span>
                                }
                                {accounts.length > 1 &&
                                <FormControl>
                                    <NativeSelect
                                        value={selectedAccount}
                                        name="selectedAccount"
                                        onChange={handleChange}
                                        classes={{icon:classes.icon, iconOpen:classes.iconOpen,select:classes.select}}
                                        disableUnderline
                                        inputProps={{ 'aria-label': 'account' }}
                                    >
                                        {accounts.map(item=>{
                                            return (<option key={item._id} value={item._id}>{getTextI18n(item.name)}</option>)
                                        })}
                                    </NativeSelect>
                                </FormControl>
                                }
                            </Typography>
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <Avatar alt={context.displayName} src={context.userId?`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${context.userId}?dt=`+new Date().getTime(): `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.jpg`} sx={{ width: 32, height: 32 }} />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleMenuClose}
                            >
                                <MenuItem dense onClick={(e) => handleGoToRoute('/accounts')} >{t('account.title')}</MenuItem>
                                <MenuItem dense onClick={(e) => handleGoToRoute('/profile')} >{t('profile.title')}</MenuItem>
                                <MenuItem dense onClick={(e) => handleGoToRoute('/invoices')} >{t('invoice.title')}</MenuItem>
                                <MenuItem dense onClick={(e) => handleGoToRoute('/setting')}>{t('changeLanguage')}</MenuItem>
                                <Divider sx={{ my: 0.5 }} />
                                <MenuItem dense onClick={handleLogout}>{t('logout')}</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>
                </header>
                {!selectedAccountStatus &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
                }
                {selectedAccountStatus === 'denied' &&
                <Box p={2} mt={1}>
                    <Alert severity="error">
                        {t('account.denied')}
                    </Alert>
                </Box>
                }
                {selectedAccountStatus === 'activated' &&
                <Box>
                    <div>
                        <SideMenu role={role} plan={context.plan} ref={sideMenuRef}></SideMenu>
                    </div>
                    <div className={classes.content}>
                        <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
                    </div>
                    {context.cid && ['OWNER', 'ADMIN'].indexOf(role) > -1 &&
                    <FooterMenu role={role} cid={context.cid} plan={context.plan} />
                    }
                </Box>
                }
                <Modal
                    disableEnforceFocus
                    open={showFreeItems.indexOf("F1") > -1}
                    style={{overflow:'scroll'}}
                    onClose={handleFreeItemsClose}
                    aria-labelledby="free-item-title"
                    aria-describedby="free-item-description"
                >
                    <Box sx={modalStyle}>
                        <Box onClick={e=>handleGotoCampaign("F1")}>
                            <img style={{width: '100%'}} src={"https://cdn-stage.foodkub.com/public/assets/products/free/F1.png"} alt={"F1"} />
                        </Box>
                    </Box>
                </Modal>
                <Snackbar open={context.success}
                          anchorOrigin={{vertical:'top', horizontal:'center'}}
                          autoHideDuration={1500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {t('common.message.saveSuccess')}
                    </Alert>
                </Snackbar>
                <Snackbar open={context.fail}
                          anchorOrigin={{vertical:'top', horizontal:'center'}}
                          autoHideDuration={1500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error">
                        {t('common.message.saveFail')}
                    </Alert>
                </Snackbar>
                <Backdrop
                    style={{
                        zIndex: 999,
                        color: '#fff',
                    }}
                    open={context.loading}
                >
                    <CircularProgress color="inherit" size={20}/>
                </Backdrop>
            </HelmetProvider>
        </div>
    );
};

const DashboardLayoutRoute = ({ roles, component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const token = localStorage.getItem('token');
                if (token) {
                    let decoded = jwt_decode(token);
                    if(roles && roles.indexOf(decoded.role) < 0){
                        return <Redirect to={
                            {
                                pathname: '/application',
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }

                    if(decoded.account){
                        return <Dashboard role={decoded.role} ><Component {...props} /></Dashboard>
                    } else {
                        return <Redirect to={
                            {
                                pathname: '/init',
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }
                } else {
                    return <Redirect to={
                        {
                            pathname: '/signin',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }}
        />
    );
};

export default DashboardLayoutRoute;