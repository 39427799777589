import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import {createTheme} from '@mui/material/styles';
import {makeStyles, withStyles} from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddIcon from '@mui/icons-material/Add';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import {httpClient} from "../../../core/HttpClient";
import {Context} from "../../../core/Context";

import './style.css'
import ListItemIcon from "@mui/material/ListItemIcon";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import {SelectionRate} from "../../SelectionRate";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
import {ImagesUpload} from "../../ImagesUpload";
import List from "@mui/material/List";
import {ListItemButton, ListItemSecondaryAction} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import {TextI18n} from "../../TextI18n";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8',

    },
    tabPanels: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#00B300',
            dark: '#00C900',
            contrastText: '#ffffff',
        },
    },
});

const MemoizedSelectionRate = React.memo(({label, name, value, min, prefix='', onChange})=>{
    return(<SelectionRate label={label} name={name} value={value} onChange={ onChange } min={min} prefix={prefix} />)
});

const MemoizedImagesUpload = React.memo(({value, limit=0, onChange})=>{
    return(<ImagesUpload name="images" value={value} limit={limit} onChange={ onChange } />)
});

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix={props.prefix}
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

const GreenSwitch = withStyles({
    switchBase: {
        '&$checked': {
            color: '#44cc00',
        },
        '&$checked + $track': {
            backgroundColor: '#44cc00',
        },
    },
    checked: {},
    track: {},
})(Switch);

function RewardItemComponent(props) {
    return (
        <div>
            <ListItemButton divider={props.divider} onClick={e=>props.onClick(props.value)}  >
                <ListItemText
                    primary={
                        <Typography variant="h6" noWrap={true}>
                                <span style={{fontSize: '1.2rem'}}>
                                    <TextI18n value={props.value.name}></TextI18n>
                                </span>
                        </Typography>
                    }
                    secondary={
                        <Typography variant="caption">
                            {props.value.point} {props.t('reward.point')}
                        </Typography>
                    } />
                <ListItemSecondaryAction>
                    <FiberManualRecordIcon color={props.value.active?'primary':'disabled'} />
                </ListItemSecondaryAction>
            </ListItemButton>
        </div>
    );
}

export default function Reward() {
    const { t } = useTranslation();
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [plan, setPlan] = useState({reward: false});
    const [loading, setLoading] = useState({init: true, update: false});
    const [context, setContext] = useContext(Context);
    const [data, setData] = useState({
        active: false,
        rate: 1,
        online: false
    });
    const [errors, setErrors] = useState([]);
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [rewardItems, setRewardItems] = useState([]);
    const [rewardItem, setRewardItem] = useState({
        _id: '',
        name: {i18n: {en: '', th: ''}},
        description: {i18n: {en: '', th: ''}},
        point: 1,
        images: [],
        active: true
    });

    useEffect(() => {
        console.log('[Reward]');
        const url = process.env.REACT_APP_API_BASE_URL + '/rewards';
        const planUrl = process.env.REACT_APP_API_BASE_URL + '/accounts/me/limited';
        const rewardItemUrl = process.env.REACT_APP_API_BASE_URL + '/rewards/items';
        let tasks = [httpClient.get(url), httpClient.get(planUrl), httpClient.get(rewardItemUrl)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setData(rs[0].data);
                }

                if(rs[1] && rs[1].data){
                    let plan = rs[1].data;
                    setPlan(plan);
                }

                if(rs[2] && rs[2].data){
                    let rewardItems = rs[2].data;
                    setRewardItems(rewardItems);
                }

                setLoading({init: false, update: false});
            });
    }, []);

    const handleReloadItems = () => {
        setLoading({init: false, update: true});
        const url = process.env.REACT_APP_API_BASE_URL + '/rewards/items';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setRewardItems(res.data);
                }
            });
    };

    const handleSave = () => {
        let _data = {...data};
        setLoading({init: false, update: true});
        const url = process.env.REACT_APP_API_BASE_URL + '/rewards';
        httpClient.post(url, _data)
            .then(res => {
                setLoading({init: false, update: false});
                setContext({ ...context, success: true});
            });
    };

    const handleUpdateItems = (cb) => {
        let _data = {...rewardItem};
        setLoading({init: false, update: true});
        const url = process.env.REACT_APP_API_BASE_URL + '/rewards/items';
        httpClient.post(url, _data)
            .then(res => {
                cb(res);
            });
    };

    const handleSwitchItemEventChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        setData({...data, ...fieldValue});
    };

    const handleChange = ({ target }) => {
        setData({ ...data, [target.name]: target.value });
    };

    const handleAddItem = ({ target }) => {
        setRewardItem({
            _id: '',
            name: {i18n: {en: '', th: ''}},
            description: {i18n: {en: '', th: ''}},
            point: 1,
            images: [],
            active: true
        });
        setOpen(true);
    };

    const handleClose = ({ target }) => {
        setOpen(false);
    };
    const handleConfirmDeleteClose = ({ target }) => {
        setOpenDelete(false);
    };

    const handleRewardItemI18nChange = ({ target }) => {
        let value = {
            i18n: {th: target.value, en: target.value}
        };
        setRewardItem({ ...rewardItem, [target.name]: value });
    };

    const handleRewardItemChange = ({ target }) => {
        setRewardItem({ ...rewardItem, [target.name]: target.value });
    };

    const handleRewardItemCheck = (event) => {
        setRewardItem({ ...rewardItem, [event.target.name]: event.target.checked });
    };

    const validation = () => {
        let errors = [];
        if(!rewardItem.name.i18n.th){
            errors.push('name');
        }
        if(!rewardItem.point){
            errors.push('point');
        }
        if(rewardItem.point < 1){
            errors.push('point');
        }
        return errors;
    };

    const handleSaveItem = () => {
        let errors = validation();
        if (errors.length < 1) {
            setErrors(errors);

            handleUpdateItems(function (){
                handleReloadItems();
                setLoading({init: false, update: false});
                setOpen(false);
            });

        } else {
            setErrors(errors);
        }
    };

    const handleConfirmDelete = () => {
        setOpen(false);
        setOpenDelete(true);
    };

    const handleDeleteItem = () => {
        if(rewardItem && rewardItem._id){
            let _data = {...rewardItem};
            setLoading({init: false, update: true});
            const url = process.env.REACT_APP_API_BASE_URL + '/rewards/items/'+_data._id;
            httpClient.delete(url)
                .then(res => {
                    handleReloadItems();
                    setLoading({init: false, update: false});
                    setOpenDelete(false);
                });
        }
    };

    function handleClickItem(value){
        setRewardItem(value);
        setOpen(true);
    }

    return (
        <div className={classes.root}>
            <Box m={1} display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="h6">
                    {t('reward.title')}
                </Typography>
            </Box>
            {plan &&
            <Box m={1}>
                <Card className={classes.root} variant="outlined">
                    <CardContent>
                        <Box display="flex"
                             justifyContent="space-between"
                             alignItems="center">
                            <Box display="flex"
                                 alignItems="center">
                                <ListItemIcon>
                                    <CardMembershipIcon />
                                </ListItemIcon>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        {t('reward.open')}
                                    </Typography>
                                </Box>
                            </Box>
                            <GreenSwitch
                                name="active"
                                disabled={!plan.reward}
                                checked={ data.active }
                                onChange={ handleSwitchItemEventChange }
                                color="primary"
                                inputProps={{ 'aria-label': 'open' }}
                            />
                        </Box>
                        {plan.reward &&
                            <div>
                                <Collapse in={data.active}>
                                    <Divider style={{'marginBottom': '20px', 'marginTop': '20px'}}/>
                                    <Box>
                                        <MemoizedSelectionRate
                                            label={t('reward.pointRate')}
                                            name="rate"
                                            value={data.rate}
                                            min={1}
                                            prefix={"฿"}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                    {rewardItems.length > 0 &&
                                        <Box mt={2}>
                                            <Card variant="outlined">
                                                <List className="SortableList">
                                                    {rewardItems.map((value, index) => <RewardItemComponent
                                                        key={`item-${index}`}
                                                        id={value._id}
                                                        divider={index !== rewardItems.length - 1}
                                                        onClick={handleClickItem}
                                                        t={t}
                                                        index={index} value={value}/>)}
                                                </List>
                                            </Card>
                                        </Box>
                                    }
                                    <Box mt={2}>
                                        <Button variant="outlined"
                                                color="primary"
                                                size="large"
                                                disableElevation
                                                fullWidth={true}
                                                startIcon={<AddIcon/>}
                                                onClick={(e) => handleAddItem(e)}>
                                            {t('reward.addItem')}
                                        </Button>
                                    </Box>
                                </Collapse>
                            </div>
                        }
                    </CardContent>
                </Card>
                {plan.reward &&
                <Box display="flex" justifyContent="center" mt={2} pb={2}>
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            disabled={loading.update}
                            startIcon={<SaveIcon/>}
                            onClick={(e) => handleSave(e)}>
                        {t('common.save')}
                    </Button>
                </Box>
                }
                {!plan.reward &&
                    <Box mt={2}>
                        <Alert severity="info">
                            {t('reward.requirePlan')}
                        </Alert>
                    </Box>
                }
            </Box>
            }
            <Dialog open={open}
                    keepMounted
                    fullWidth
                    onClose={handleClose}>
                <DialogTitle>
                    {t('reward.rewardItem')}
                </DialogTitle>
                <DialogContent>
                    <Box>
                        <Box>
                            <Box mb={1} mt={1}>
                                <TextField label={t('reward.name')} variant="outlined" fullWidth={true}
                                           name="name" value={rewardItem.name.i18n['th']}
                                           onChange={handleRewardItemI18nChange} required
                                           error={errors.indexOf('name') > -1} />
                            </Box>
                            <Box mb={1}>
                                <TextField label={t('reward.description')} variant="outlined"
                                           fullWidth={true}
                                           name="description" value={rewardItem.description.i18n['th']}
                                           onChange={handleRewardItemI18nChange} />
                            </Box>
                            <Box mb={1}>
                                <Card variant={"outlined"}>
                                    <Box m={2}>
                                        <Typography id="reward-pictures">
                                            {t('reward.pictures')}
                                        </Typography>
                                    </Box>
                                    <Box mb={2}>
                                        <MemoizedImagesUpload value={rewardItem.images} limit={4} onChange={handleRewardItemChange}/>
                                    </Box>
                                </Card>
                            </Box>
                            <Box mb={1}>
                                <Box>
                                    <MemoizedSelectionRate
                                        label={t('reward.usePoint')}
                                        name="point"
                                        value={rewardItem.point}
                                        min={1}
                                        onChange={handleRewardItemChange}
                                    />
                                </Box>
                            </Box>
                            <Box mb={1}>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rewardItem.active}
                                                onChange={handleRewardItemCheck}
                                                name="active"
                                                color="primary"
                                            />
                                        }
                                        label={t('reward.active')}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Box mt={2}>
                                <Button variant="contained"
                                        color="primary"
                                        size="large"
                                        disableElevation
                                        fullWidth={matches}
                                        disabled={loading.update}
                                        startIcon={<SaveIcon/>}
                                        onClick={(e) => handleSaveItem(e)}>
                                    {t('common.save')}
                                </Button>
                            </Box>
                            {rewardItem._id &&
                                <Box mt={2}>
                                    <Button variant="contained"
                                            color="secondary"
                                            size="large"
                                            disableElevation
                                            fullWidth={matches}
                                            disabled={loading.update}
                                            startIcon={<DeleteIcon/>}
                                            onClick={(e) => handleConfirmDelete(e)}>
                                        {t('common.delete')}
                                    </Button>
                                </Box>
                            }
                            <Box mt={2}>
                                <Button variant="contained"
                                        size="large"
                                        disableElevation
                                        fullWidth={matches}
                                        disabled={loading.update}
                                        color={"grey"}
                                        onClick={(e) => handleClose(e)}>
                                    {t('common.cancel')}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                open={openDelete}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmDeleteClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('common.message.confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('common.message.confirmDelete')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            onClick={handleConfirmDeleteClose}
                            fullWidth={matches}
                            color="grey"
                            size="large"
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained"
                            color="secondary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            startIcon={<DeleteForeverIcon />}
                            onClick={(e) => handleDeleteItem(e)}>
                        {t('common.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}