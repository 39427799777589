import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import Card from "@mui/material/Card";
import {ListItem, Switch as SubSwitch} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import {Link, useHistory, useLocation} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import {httpClient} from "../../../core/HttpClient";
import {Context} from "../../../core/Context";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import {PROVINCES} from "../../../core/Static";
import {getProvinceI18n, getRemoteAreaI18n} from "../../TextI18n";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#00B300',
            dark: '#00C900',
            contrastText: '#ffffff',
        },
    },
});

let MyRemoteAreas = ({remoteAreas, t, i18n}) => {

    if(remoteAreas && remoteAreas.length < 1){
        return (<TableBody>
            <TableRow key={`item-notSpecify`}>
                <TableCell align={"center"} colSpan={3}>
                    <Typography variant="subtitle1">
                        {t('common.notSpecify')}
                    </Typography>
                </TableCell>
            </TableRow>
        </TableBody>)
    } else {
        return (<TableBody>
            {remoteAreas.map((value, index) => (
                <TableRow key={`item-${value._id}-${index}`}>
                    <TableCell>
                        <Typography variant="subtitle1">
                            {getRemoteAreaI18n(value,'province', i18n)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle1">
                            {getRemoteAreaI18n(value, 'district', i18n)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="subtitle1">
                            {value.zip_code}
                        </Typography>
                    </TableCell>
                </TableRow>
            ))}
        </TableBody>);
    }

    // if(remoteAreas.list && remoteAreas.list.length < 1){
    //     return (<List sx={{border: '1px solid #e0e0e0'}}>
    //         <ListItem key={`item-notSpecify`}>
    //             <ListItemText align={"center"}>
    //                 {t('common.notSpecify')}
    //             </ListItemText>
    //         </ListItem>
    //     </List>)
    // } else {
    //     return (<List sx={{border: '1px solid #e0e0e0'}}>
    //         {remoteAreas.list.map((value, index) => (
    //             <ListItem key={`item-${value._id}-${index}`} divider={index !== remoteAreas.list.length - 1}>
    //                 <ListItemText>
    //                     <Box>
    //                         <Box>{getRemoteAreaI18n(value,'province', i18n)}</Box>
    //                         <Typography variant="subtitle2" color="textSecondary">
    //                             {getRemoteAreaI18n(value, 'district', i18n)}
    //                         </Typography>
    //                     </Box>
    //                 </ListItemText>
    //                 <ListItemSecondaryAction align={"center"}>
    //                     <IconButton edge={"end"} onClick={()=>onDelete(value, index)}>
    //                         <DeleteIcon />
    //                     </IconButton>
    //                 </ListItemSecondaryAction>
    //             </ListItem>
    //         ))}
    //     </List>);
    // }
};

export default function Parcel() {
    const { t, i18n } = useTranslation();
    const search = useLocation().search;
    const orderingType = new URLSearchParams(search).get('orderingType');
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [context, setContext] = useContext(Context);
    const [remoteAreas, setRemoteAreas] = React.useState({
        "list": [],
        "include": [],
        "exclude": []
    });
    const [account, setAccount] = useState({
        parcel: false,
        parcelPrice: 0,
        parcelBooniesPrice: 0
    });
    const [address, setAddress] = useState({province: 'NONE', district: 'NONE'});
    const [errors, setErrors] = useState([]);
    const [district, setDistrict] = useState([]);

    console.log('Parcel');

    useEffect(() => {
        console.log('[Parcel]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me';
        const remoteAreasUrl = process.env.REACT_APP_API_BASE_URL + '/boonies';
        let tasks = [httpClient.get(url), httpClient.get(remoteAreasUrl)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setAccount(rs[0].data);
                }
                if(rs[1] && rs[1].data){
                    setRemoteAreas(rs[1].data);
                }
            });
    }, []);

    const _validate = () =>{
        let _errors = [];
        if(address.province === 'NONE'){
            _errors.push('province');
        }
        if(address.district === 'NONE'){
            _errors.push('district');
        }
        setErrors(_errors);
        return _errors.length === 0;
    };

    const handleAdd = () => {
        if(_validate()){
            let list = remoteAreas.list;
            let include = remoteAreas.include;
            list.push({...address});
            include.push(address.district);
            list = list.sort((a,b) => a.province === b.province? 0: a.district === b.district?0:-1);
            setRemoteAreas({...remoteAreas, list: list, include: include});

            setAddress({province: 'NONE', district: 'NONE'});
            setOpen2(false);
        }
    };

    const handleSave = () => {

        let _data = {
            _id: account._id,
            orderingType: orderingType,
            parcel: account.parcel,
            parcelPrice: account.parcelPrice,
            parcelBooniesPrice: account.parcelBooniesPrice
        };

        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id + '/parcel';
        httpClient.post(url, _data)
            .then(res => {
                setLoading(false);
                setContext({ ...context, success: true});
                history.push('/deliver');
            });
    };

    const handleNumberChange = ({ target }) => {
        if(target.value){
            let found = target.value.search(/^(\d*\.?\d*)$/);
            if(found > -1) {
                let fieldValue = {};
                fieldValue[target.name] = target.value;
                setAccount({...account, ...fieldValue});
            }
        }
    };

    const handleSwitchChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        setAccount({...account, ...fieldValue});
    };

    const handleOpen = (value, target) => {
        if(value === 1){
            setOpen1(target);
        } else if(value === 2){
            setOpen2(target);
        }
    };

    const handleProvinceChange = ({ target }) => {
        let provinces = PROVINCES.filter(obj => {
            return ''+obj.id === ''+target.value
        });
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/district/'+target.value;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setDistrict(res.data);
                }
            });
        setDistrict([]);
        setAddress({ ...address, [target.name]: target.value, provinceObj: provinces[0], district: 'NONE', subDistrict: 'NONE'});
    };

    const handleDistrictChange = ({ target }) => {
        let districts = district.filter(obj => {
            return ''+obj.id === ''+target.value
        });
        setAddress({ ...address, [target.name]: target.value, districtObj: districts[0], subDistrict: 'NONE'});

    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/deliver" className={classes.link}>
                        <Typography variant="h6">
                            {t('delivery.title')}
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                <Card className={classes.root} variant="outlined">
                    <List className={classes.root}>
                        <ListItem key="cash">
                            <ListItemIcon>
                                <LocalShippingIcon />
                            </ListItemIcon>
                            <ListItemText id='parcel'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('deliver.parcel')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('deliver.parcelDescription')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <SubSwitch
                                    checked={account.parcel}
                                    onChange={ (e) => handleSwitchChange(e) }
                                    color="primary"
                                    name="parcel"
                                    inputProps={{ 'aria-label': 'open' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <Collapse in={account.parcel}>
                            <Divider  style={{'marginRight': '20px', 'marginLeft': '20px'}} />
                            <Box m={2}>
                                <Box mt={2}>
                                    <Box mt={2}>
                                        <Typography id="parcel-price">
                                            {t('parcel.price')}
                                        </Typography>
                                        <Box key={`parcel-price`} mt={3}>
                                            <FormControl fullWidth className={classes.margin} variant="outlined">
                                                <TextField
                                                    key={`input-start`}
                                                    label={t('parcel.deliverPriceStart')}
                                                    variant="outlined"
                                                    type={"tel"}
                                                    name={'parcelPrice'}
                                                    value={account.parcelPrice}
                                                    onChange={handleNumberChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box mt={2}>
                                        <Box mt={3}>
                                            <FormControl fullWidth className={classes.margin} variant="outlined">
                                                <TextField
                                                    label={t('parcel.booniesPrice')}
                                                    variant="outlined"
                                                    name={'parcelBooniesPrice'}
                                                    type={"tel"}
                                                    value={account.parcelBooniesPrice}
                                                    onChange={handleNumberChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </FormControl>
                                        </Box>
                                        <Box mt={3}>
                                            <Button variant="outlined"
                                                    disableElevation
                                                    fullWidth={true}
                                                    size={"large"}
                                                    color={"default"}
                                                    startIcon={<ListAltIcon />}
                                                    disabled={loading.update}
                                                    onClick={() => handleOpen(1, true)}>
                                                {t('parcel.booniesList')}
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                </Collapse>
                </Card>
                <Box display="flex" justifyContent="center" mt={2} pb={2} m={1}>
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            disabled={loading.update}
                            startIcon={<SaveIcon/>}
                            onClick={(e) => handleSave(e)}>
                        {t('common.save')}
                    </Button>
                </Box>
            </Box>
            <Dialog
                onClose={ e=>handleOpen(1, false) }
                fullScreen={true}
                open={ open1 }>
                <AppBar elevation={1} color={"white"} sx={{ position: 'relative' }}>
                    <Toolbar variant="dense">
                        <Typography id="parcel-boonies-list" variant={"h6"} style={{flexGrow: 1}}>
                            {t('parcel.booniesList')}
                        </Typography>
                        <IconButton edge="end" color="default" aria-label="black" onClick={e=>handleOpen(1, false)}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent >
                <Box mb={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant={"h6"}>
                            {t('parcel.centre')}
                        </Typography>
                    </Box>
                    <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                        <TableHead>
                            <TableRow style={{background: 'gainsboro'}}>
                                <TableCell>{t('parcel.provinces')}</TableCell>
                                <TableCell>{t('parcel.district')}</TableCell>
                                <TableCell>{t('parcel.postalCode')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <MyRemoteAreas remoteAreas={remoteAreas.centre} t={t} i18n={i18n} />
                    </Table>
                </Box>
                <Box mb={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant={"h6"}>
                            {t('parcel.north')}
                        </Typography>
                    </Box>
                    <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                        <TableHead>
                            <TableRow style={{background: 'gainsboro'}}>
                                <TableCell>{t('parcel.provinces')}</TableCell>
                                <TableCell>{t('parcel.district')}</TableCell>
                                <TableCell>{t('parcel.postalCode')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <MyRemoteAreas remoteAreas={remoteAreas.north} t={t} i18n={i18n} />
                    </Table>
                </Box>
                <Box mb={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant={"h6"}>
                            {t('parcel.south')}
                        </Typography>
                    </Box>
                    <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                        <TableHead>
                            <TableRow style={{background: 'gainsboro'}}>
                                <TableCell>{t('parcel.provinces')}</TableCell>
                                <TableCell>{t('parcel.district')}</TableCell>
                                <TableCell>{t('parcel.postalCode')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <MyRemoteAreas remoteAreas={remoteAreas.south} t={t} i18n={i18n} />
                    </Table>
                </Box>
                <Box mb={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant={"h6"}>
                            {t('parcel.east')}
                        </Typography>
                    </Box>
                    <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                        <TableHead>
                            <TableRow style={{background: 'gainsboro'}}>
                                <TableCell>{t('parcel.provinces')}</TableCell>
                                <TableCell>{t('parcel.district')}</TableCell>
                                <TableCell>{t('parcel.postalCode')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <MyRemoteAreas remoteAreas={remoteAreas.east} t={t} i18n={i18n} />
                    </Table>
                </Box>
                <Box mb={2}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant={"h6"}>
                            {t('parcel.west')}
                        </Typography>
                    </Box>
                    <Table aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                        <TableHead>
                            <TableRow style={{background: 'gainsboro'}}>
                                <TableCell>{t('parcel.provinces')}</TableCell>
                                <TableCell>{t('parcel.district')}</TableCell>
                                <TableCell>{t('parcel.postalCode')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <MyRemoteAreas remoteAreas={remoteAreas.west} t={t} i18n={i18n} />
                    </Table>
                </Box>
                </DialogContent>
            </Dialog>
            <Dialog
                fullWidth={true}
                open={ open2 }>
                <DialogContent>
                    <Box mt={2}>
                        <Grid item xs={12} mb={2}>
                            <FormControl variant="outlined"
                                         fullWidth={true}
                                         error={errors.indexOf('province') > -1}>
                                <InputLabel id="province-label">{t('address.province')}</InputLabel>
                                <Select
                                    native
                                    labelId="province-label"
                                    label={t('address.province')}
                                    name="province"
                                    value={address.province}
                                    onChange={handleProvinceChange}>
                                    <>
                                        <option key={'NONE'} value={'NONE'}>
                                            {t('address.pleaseSelectProvince')}
                                        </option>
                                        {
                                            PROVINCES.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {getProvinceI18n(item, i18n)}
                                                </option>
                                            ))
                                        }
                                    </>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} mb={2}>
                            <FormControl variant="outlined"
                                         fullWidth={true}
                                         error={errors.indexOf('district') > -1}>
                                <InputLabel id="district-label">{t('address.district')}</InputLabel>
                                <Select
                                    native
                                    labelId="district-label"
                                    label={t('address.district')}
                                    name="district"
                                    value={address.district}
                                    onChange={handleDistrictChange}>
                                    <>
                                        <option key={'NONE'} value={'NONE'}>
                                            {t('address.pleaseSelectDistrict')}
                                        </option>
                                        {
                                            district.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {getProvinceI18n(item, i18n)}
                                                </option>
                                            ))
                                        }
                                    </>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Grid container >
                        <Grid item xs={12}>
                            <Box m={2}>
                                <Button fullWidth color='primary' variant="contained"
                                        disableElevation={true}
                                        onClick={handleAdd}
                                        size="large">
                                    {t('common.add')}
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box ml={2} mr={2} mb={2}>
                                <Button onClick={ e=>handleOpen(2, false) } fullWidth variant="contained"
                                        color={"grey"}
                                        disableElevation={true}
                                        size="large">
                                    {t('common.cancel')}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    )
}