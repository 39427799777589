
export function getUserName(user) {
    let name = '';
    if(user){
        if(user.firstName && user.firstName.length > 0){
            name += user.firstName;
            if(user.lastName && user.lastName.length > 0){
                if(name.length>0){
                    name += ' ';
                }
                name += user.lastName;
            }
        }

        if(name.length === 0){
            name = user.displayName;
        }
    }


    return name;
}