import React, {useCallback, useEffect} from 'react';
import Box from '@mui/material/Box';
import CircularProgress from "@mui/material/CircularProgress";
import {useHistory} from "react-router-dom";
import {httpClient} from "../../core/HttpClient";

export default function Refresh() {
    const history = useHistory();

    const handleRefreshToken = useCallback(() => {
        let token = localStorage.getItem('token');
        let refreshToken = localStorage.getItem('refreshToken');
        if(token && refreshToken){

            console.log('handleRefreshToken');
            const url = process.env.REACT_APP_API_BASE_URL + '/auth/line/refresh-token';
            const payload = {
                token: token,
                refreshToken: refreshToken
            };
            return httpClient.post(url, payload).then(res => {
                let data = res.data;
                if(data && data.token && data.refreshToken){
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('refreshToken', data.refreshToken);
                    history.push('/home');
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    window.location.href = '/login';
                }
            }).catch(e=>{
                console.log('e', e);
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
            });

        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            history.push('/login');
        }
    }, [history]);

    useEffect(() => {
        console.log('[Refresh]');
        handleRefreshToken().then(r => {});

    }, [handleRefreshToken]);

    return (
        <div>
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
        </div>
    )
}