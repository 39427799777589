import React, { useEffect, useReducer, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import {DateI18n} from "../../DateI18n";
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {httpClient} from "../../../core/HttpClient";
import {ListItem} from "@mui/material";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));


function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.payload.length > 0,
                cursor: action.nextCursor,
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(cursor, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        let results = [];

        const url = process.env.REACT_APP_API_BASE_URL + '/histories/credit';
        const result = await httpClient(
            `${url}?c=${cursor}`,
            {
                cancelToken,
            }
        );

        let nextCursor = null;
        if(result.data){
            result.data.forEach(item=>{
                nextCursor = item._id;
                item.items.forEach(item=>{
                    results.push(item);
                });
            });
        }

        dispatch({ type: "FETCH_SUCCESS", payload: results, cursor: cursor, nextCursor: nextCursor })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

export default function AccountCreditHistory() {
    const { t } = useTranslation();
    const [{ hits, hasNext, hasError, isLoading, cursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
    });
    const classes = useStyles();

    const debouncedFetchHits = useRef(
        _.debounce(
            (cursor, cancelToken) => fetchHits(cursor, dispatch, cancelToken),
            300
        )
    ).current;

    useEffect(() => {
        console.log('[AccountCreditHistory]');
        const { cancel, token } = httpClient.CancelToken.source();
        debouncedFetchHits('', token);
        return () => cancel("No longer latest query") || debouncedFetchHits.cancel()
    }, [debouncedFetchHits]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData');
        const cancelToken = httpClient.CancelToken.source();
        debouncedFetchHits(cursor, cancelToken.token).then(r=>{});
    };

    return (
        <div className={classes.root}>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={9}>
                        <Link to="/home" className={classes.link}>
                            <Typography variant="h6">
                                {t('usage.creditHistory')}
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
            </Box>
            }
            {!isLoading && hits.length === 0 && !hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Typography>
                    {t('common.notFound')}
                </Typography>
            </Box>
            }
            {hits.length > 0 &&
            <InfiniteScroll
                dataLength={hits.length} //This is important field to render the next data
                next={e=>handleFetchData(cursor)}
                hasMore={hasNext}
            >
            <Box style={{background: 'white'}}>
                <List className={classes.root} subheader={<li/>}>
                    {hits.map((item) => (
                        <ListItem divider key={`item-${item._id}`}>
                            <ListItemText>
                                <div>
                                    <Box display="flex" justifyContent="space-between">
                                        <Typography noWrap={true}>
                                            <span>
                                                {item.amount > -1?t('usage.earnCredit') :
                                                    item.ref1 === 'ONLINE_ORDERS'?t('usage.deductCreditOrderAccept') :
                                                    item.ref1 === 'MOBILE_ORDERS'?t('usage.deductCreditOrderAccept') :
                                                    item.ref1 === 'CANCEL_ORDER'?t('usage.deductCreditOrderCancel') : t('usage.deductCreditOrderReject')
                                                }
                                            </span>
                                            {item.refType === 'TRANSACTION' &&
                                            <span style={{marginLeft: '3px'}}>
                                                {item.ref2}
                                            </span>
                                            }
                                        </Typography>
                                        <div>
                                            <span style={{color: item.amount > -1 ?'green':'red', fontSize: '1rem'}}>
                                                <NumberFormat value={item.amount} displayType={'text'}
                                                              thousandSeparator={true} />
                                            </span>
                                        </div>
                                    </Box>
                                    <Box>
                                        <Typography noWrap={true}>
                                            <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                                                <DateI18n format="DD MMM YY, HH:mm" value={item.date} />
                                            </span>
                                        </Typography>
                                    </Box>
                                </div>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Box>
            </InfiniteScroll>
            }
            {isLoading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
        </div>
    )
}