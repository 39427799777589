import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import {httpClient} from "../../core/HttpClient";
import {Context} from "../../core/Context";

import './style.css'
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8',

    },
    tabPanels: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#00B300',
            dark: '#00C900',
            contrastText: '#ffffff',
        },
    },
});

export default function Ordering() {
    const { t } = useTranslation();
    const classes = useStyles();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const [context, setContext] = useContext(Context);
    const [account, setAccount] = useState({delivery: false, pickup: false,
        orderingAcceptation: 'accept',
        deliveryType: 'immediately',
        deliveryTimes: []
    });

    useEffect(() => {
        console.log('[Ordering]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me';
        let tasks = [httpClient.get(url)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setAccount(rs[0].data);
                }
            });
    }, []);

    const handleSave = () => {

        let _data = {_id: account._id, delivery: account.delivery, pickup: account.pickup,
            orderingAcceptation: account.orderingAcceptation,
            deliveryType: account.deliveryType,
            deliveryTimes: account.deliveryTimes
        };
        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id;
        httpClient.post(url, _data)
            .then(res => {
                setLoading(false);
                setContext({ ...context, success: true});
            });
    };

    const handleChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.value;
        setAccount({...account, ...fieldValue});
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('ordering.automateRequest')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1} mt={2}>
                <FormControl variant="outlined"
                             fullWidth={true}>
                    <InputLabel id="automateRequest-label">{t('ordering.automateRequest')}</InputLabel>
                    <Select
                        native
                        labelId="automateRequest-label"
                        label={t('ordering.automateRequest')}
                        name="orderingAcceptation"
                        value={account.orderingAcceptation}
                        onChange={handleChange}>
                        <option key="auto_accept" value="accept">
                            {t('ordering.orderingAcceptationAccept')}
                        </option>
                        <option key="auto_reject" value="reject">
                            {t('ordering.orderingAcceptationReject')}
                        </option>
                    </Select>
                </FormControl>
            </Box>
            <Box display="flex" justifyContent="center" m={1} mt={2} pb={2}>
                <Button variant="contained"
                        color="primary"
                        size="large"
                        disableElevation
                        fullWidth={matches}
                        disabled={loading.update}
                        startIcon={<SaveIcon/>}
                        onClick={(e) => handleSave(e)}>
                    {t('common.save')}
                </Button>
            </Box>
        </div>
    )
}