import React, { useState, useRef } from 'react';
import { useTranslation } from "react-i18next";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import {MapContainer, TileLayer, Marker, Circle, useMapEvents, useMap} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './style.css';
import {TransitionProps} from "@mui/material/transitions";
import L from "leaflet";
import MapUtil from "../../core/MapUtil";
import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LocationMarker = (props) => {
    const [position, setPosition] = useState(props.position);
    const [icon, setIcon] = useState(props.ood?blueIcon:redIcon);
    const markerRef = useRef(null);
    const map = useMap();

    useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend(e) {
            let center = map.getCenter();
            let ood = false;
            if(props.verified){
                ood = new MapUtil().isOutSideCircle(props.originPosition[0], props.originPosition[1], center.lat, center.lng, 1);
            }
            if(ood){
                setIcon(blueIcon);
            } else {
                setIcon(redIcon);
            }
            setPosition([center.lat, center.lng]);
            if(props.callback){
                props.callback(ood, center);
            }
        }
    });

    return position === null ? null : (
        <Marker icon={icon} position={position} ref={markerRef} ></Marker>

    )
};

const blueIcon = new L.Icon({
        iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-blue.png`,
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    }),
    redIcon = new L.Icon({
        iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-red.png`,
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

let CENTER = null;

export default function AddressOriginDialog({open, shop, onClose, onSave}) {
    const { t } = useTranslation();
    const [disabled, setDisabled] = useState(false);
    console.log('[AddressOriginDialog]');
    const handleSave = ()=>{
        onSave(CENTER);
    };

    const handleCallback = (odd, center)=>{
        CENTER = center;
        setDisabled(odd);
    };

    return (
        <Dialog fullScreen
                open={open}
                TransitionComponent={Transition}
                onClose={onClose}>
            <AppBar sx={{ position: 'relative' }} color="default" >
                <Toolbar>
                    <Typography variant="h6" noWrap={true} component="div" style={{flexGrow: 1}}>
                        {t('shop.editLocation')}
                    </Typography>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <MapContainer center={[shop.lat, shop.lng]}
                          zoom={shop.zoom}
                          doubleClickZoom={false}
                          scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationMarker effectOn={this} verified={shop.verified} originPosition={[shop.latOrigin, shop.lngOrigin]} position={[shop.lat, shop.lng]} callback={handleCallback} />
                {shop && shop.verified && shop.latOrigin && shop.lngOrigin &&
                <Circle
                    center={{lat: shop.latOrigin, lng:shop.lngOrigin}}
                    radius={1 * 1000}/>
                }
                <Box>
                    <Fab color={"primary"} variant="extended"
                         disabled={disabled}
                         sx={{
                            width: '100%',
                            bottom: '15px',
                            position: 'absolute'
                        }} onClick={handleSave}>
                        {t('common.save')}
                    </Fab>
                </Box>
            </MapContainer>
        </Dialog>
    )
};