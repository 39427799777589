import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Route, Redirect, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import {httpClient} from "../../core/HttpClient";
import { Context } from "../../core/Context";
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
        marginLeft: '10px',
    },
    menuButton: {
        padding: 0,
    },

}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const FoodKub = ({ children}) => {
    const { t, i18n } = useTranslation();
    const [context, setContext] = useState({displayName: '', userId: '', success: false, fail: false});
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const classes = useStyles();
    const open = Boolean(anchorEl);

    useEffect(() => {
        console.log('[FoodKub]');
        let token = localStorage.getItem('token');
        if(!token){
            history.push('/signin');
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/users/me';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    let user = {
                        userId: res.data._id,
                        displayName: res.data.displayName
                    };
                    setContext({ ...user, success: false, fail: false });
                } else {
                    history.push('/signin');
                }
            }).catch(e=>{
                history.push('/login');
            });
    }, [history]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setContext({ ...context, success: false, fail: false });
    };

    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        setAnchorEl(null);
    };

    return (
        <div className='page page-dashboard'>
            <CssBaseline />
            <header>
                <AppBar elevation={0} position="static">
                    <Toolbar>
                        <img alt={'FoodKub'} height="42" src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/foodkub_white.png`} sx={{ mr: 1,width: 32, height: 32 }} />
                        <Typography variant="h6" noWrap={true} className={classes.title}></Typography>
                        <IconButton edge="start"
                                    onClick={handleMenu}
                                    color="inherit" aria-label="menu">
                            <LanguageIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={(e) => handleChangeLanguage('en-US')}>{t('languages.english')}</MenuItem>
                            <MenuItem onClick={(e) => handleChangeLanguage('th-TH')}>{t('languages.thai')}</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </header>
            <div className={classes.content}>
                <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
            </div>
            <Snackbar open={context.success}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    This is a success message!
                </Alert>
            </Snackbar>
            <Snackbar open={context.fail}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    This is a error message!
                </Alert>
            </Snackbar>
        </div>
    );
};

const FoodKubLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (localStorage.getItem('token')) {
                    return <FoodKub><Component {...props} /></FoodKub>
                } else {
                    return <Redirect to={
                        {
                            pathname: '/signin',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }}
        />
    );
};

export default FoodKubLayoutRoute;