import React from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import LocalMallIcon from '@mui/icons-material/LocalMall';

export default function DeliverIcon({foodOrder, color}) {
    if(foodOrder.deliverType === 'DINE-IN'){
        return (<RestaurantIcon color={color} />);
    }  else if(foodOrder.deliverType === 'DELIVERY'){
        return (<DeliveryDiningIcon color={color} />);
    }  else if(foodOrder.deliverType === 'PICKUP'){
        return (<LocalMallIcon color={color} />);
    }  else if(foodOrder.deliverType === 'TAKEAWAY'){
        return (<ShoppingBagIcon color={color} />);
    }  else if(foodOrder.deliverType === 'PARCEL'){
        return (<LocalShippingIcon color={color} />);
    }  else {
        return (<ReceiptIcon color={color} />);
    }

};