import React, {useEffect, useRef, useState} from "react";
import QRCodeStyling from "qr-code-styling";
import Box from "@mui/material/Box";

const qrCode = new QRCodeStyling({
    "width": 300,
    "height": 300,
    "margin": 0,
    "qrOptions": {"typeNumber": "0", "mode": "Byte", "errorCorrectionLevel": "Q"},
    "imageOptions": {"hideBackgroundDots": true, "imageSize": 0.5, "margin": 0},
    "dotsOptions": {"type": "square", "color": "#000000"},
    "backgroundOptions": {"color": "#ffffff"},
    "image": `${process.env.PUBLIC_URL}/foodkub.svg`,
    "dotsOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#6a1a4c", "color2": "#6a1a4c", "rotation": "0"}
    },
    "cornersSquareOptions": {"type": "extra-rounded", "color": "#000000"},
    "cornersSquareOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}
    },
    "cornersDotOptions": {"type": "", "color": "#1abc9c"},
    "cornersDotOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}
    },
    "backgroundOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#ffffff", "color2": "#ffffff", "rotation": "0"}
    }
});

const CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_LIFF_URL;

export function QRCode({value,type}) {
    // const {t} = useTranslation();
    // const [fileExt] = useState("png");
    const [url] = useState(`${CLIENT_BASE_URL}/${type}/${value}`);
    const ref = useRef(null);

    useEffect(() => {
        console.log('[QRCode1]');
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        console.log('[QRCode2]');
        qrCode.update({
            data: url
        });
    }, [url]);

    return (<Box>
        <div ref={ref} />
    </Box>)
}