import React, {useEffect, useRef} from "react";
import QRCodeStyling from "qr-code-styling";
import './style.css';

const qrCode = new QRCodeStyling({
    "margin": 0,
    "image": "https://cdn-stage.foodkub.com/public/assets/thai_qr_payment_72.png",
    "imageOptions": {"hideBackgroundDots":false,"imageSize":0.7,"margin":0},
    "qrOptions": {"typeNumber": "0", "mode": "Alphanumeric", "errorCorrectionLevel": "L"}
});

export function QRCodePayment({value}) {
    const ref = useRef(null);

    useEffect(() => {
        console.log('[QRCodePayment]');
        qrCode.append(ref.current);
    }, []);

    useEffect(() => {
        console.log('[QRCodePayment]');
        qrCode.update({
            data: value
        });
    }, [value]);

    return (<div ref={ref} id={"qrCodePayment"} />)
}