import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {httpClient} from "../../core/HttpClient";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import {getDatabase, goOffline, goOnline} from "firebase/database";
import InfoIcon from '@mui/icons-material/Info';
import { useIdleTimer } from 'react-idle-timer';
import {getDatabaseInstance} from "../../core/Firebase";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import {useHistory} from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

export default function AccountOrder() {
    const { t } = useTranslation();
    const history = useHistory();
    const [accountLimit, setAccountLimit] = useState({orderLimit: 0, orderCurrent: 0, account: ''});
    const [openInfo, setOpenInfo] = useState(false);

    const onAction = (event) => {
        if(['visibilitychange','focus'].indexOf(event.type) > -1){
            if(document.visibilityState === 'hidden'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOffline(db);
            } else if(document.visibilityState === 'visible'){
                let app = getDatabaseInstance();
                const db = getDatabase(app);
                goOnline(db);
            }
        }
    }

    useIdleTimer({
        onAction,
        timeout: 10_000,
        throttle: 500
    });

    useEffect(() => {
        console.log('[AccountOrder]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/order/limited';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setAccountLimit({
                        orderCurrent: res.data.orderCurrent || 0,
                        orderLimit: res.data.order || 0,
                        account: res.data.account
                    });
                }
            });
    }, []);

    return (<Card variant="outlined">
        <Box m={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography color="textSecondary">
                    {t('account.orderPerMonth')}
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                {accountLimit.orderLimit >= 999999 &&
                    <Typography variant="h4" style={{color: 'grey'}}>
                        <NumberFormat value={accountLimit.orderCurrent} displayType={'text'} thousandSeparator={true} />
                    </Typography>
                }
                {accountLimit.orderLimit < 999999 &&
                    <>
                        <Typography variant="h4" style={{color: 'grey'}}>
                            <NumberFormat value={accountLimit.orderCurrent} displayType={'text'} thousandSeparator={true} />
                        </Typography>
                        <Typography variant="h4">
                            /
                        </Typography>
                        <Typography variant="h4">
                            <NumberFormat value={accountLimit.orderLimit} displayType={'text'} thousandSeparator={true} />
                        </Typography>
                    </>
                }
            </Box>
            <Box display="flex" justifyContent="center">
                <Typography>
                    {t('invoice.unit')}
                </Typography>
            </Box>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
            <Button variant="text"
                    size={"small"}
                    color={"default"}
                    onClick={e=>setOpenInfo(true)}
                    startIcon={<InfoIcon />}>
                <Typography variant={"caption"}>
                    {t('common.info')}
                </Typography>
            </Button>
        </Box>
        <Dialog
            fullScreen={true}
            open={openInfo}
            aria-labelledby="info-modal-title"
            aria-describedby="info-modal-description"
            onClose={e=>setOpenInfo(false)}
        >
            <AppBar position="static" color="transparent" elevation={0}>
                <Toolbar variant="dense">
                    <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
                        {t('common.info')}
                    </Typography>
                    <IconButton edge="start"color="primary" aria-label="black" onClick={e=>setOpenInfo(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Divider />
            <DialogContent>
                <Box>
                    <Typography>{t('usage.orderInfo.title')}</Typography>
                </Box>
                <Box mb={1}>
                    <Button variant="text" onClick={()=>{history.push(`/account/${accountLimit.account}`)}}>({t('usage.orderInfo.changePackage')})</Button>
                </Box>
                <Box>
                    <Typography>{t('usage.orderInfo.packageDetail')}</Typography>
                </Box>
                <TableContainer>
                    <Table id="package-table" aria-label="package table" sx={{border: '1px solid gainsboro'}}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('usage.orderInfo.package')}</TableCell>
                                <TableCell align="center">{t('usage.orderInfo.orderLimit')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">{t('pricing.free')}</TableCell>
                                <TableCell align="center">{t('pricing.package.free.order')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{t('pricing.start')}</TableCell>
                                <TableCell align="center">{t('pricing.package.start.order')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{t('pricing.basic')}</TableCell>
                                <TableCell align="center">{t('pricing.package.basic.order')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{t('pricing.standard')}</TableCell>
                                <TableCell align="center">{t('pricing.package.standard.order')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">{t('pricing.pro')}</TableCell>
                                <TableCell align="center">{t('pricing.package.pro.order')}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box mt={1}>
                    <Typography variant={"caption"}>{t('usage.orderInfo.remark')}</Typography>
                </Box>
            </DialogContent>
        </Dialog>
    </Card>
    )
}