import React, {useState, useEffect, useMemo} from 'react';
import { useTranslation } from "react-i18next";
import {useHistory} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import AddIcon from '@mui/icons-material/Add';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates, useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {httpClient} from "../../core/HttpClient";
import {TextI18n} from "../TextI18n";

import './style.css';
import Card from "@mui/material/Card";
import {ListItemButton} from "@mui/material";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToParentElement} from "@dnd-kit/modifiers";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging
    } = useSortable({id: props.id});

    const style = {
        position: "relative",
        zIndex: isDragging ? 1 : undefined,
        transform: CSS.Transform.toString(transform),
    };

    return (
        <div ref={setNodeRef} style={style}>
            <ListItemButton divider={props.divider} onClick={e=>props.onClick(props.value)}  >
                <ListItemText
                    primary={
                        <Typography variant="h6" noWrap={true}>
                                <span style={{fontSize: '1.2rem'}}>
                                    <TextI18n value={props.value.name}></TextI18n>
                                </span>
                        </Typography>
                    }/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="move" style={{touchAction: "none"}} {...attributes} {...listeners} ><OpenWithIcon style={{cursor: 'grab'}}></OpenWithIcon></IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
        </div>
    );
}

export default function Kitchens() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [showKitchenLimit, setShowKitchenLimit] = React.useState(false);
    const [planLimit, setPlanLimit] = React.useState({});
    const [kitchens, setKitchens] = useState([]);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const itemIds = useMemo(() => kitchens.map((item) => item._id), [kitchens]);

    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {

            const oldIndex = kitchens.findIndex(item => item._id === active.id);
            const newIndex = kitchens.findIndex(item => item._id === over.id);
            let newValue = arrayMove(kitchens, oldIndex, newIndex);
            setKitchens(newValue);

            const url = process.env.REACT_APP_API_BASE_URL + '/kitchens/sort';
            httpClient.put(url, newValue)
                .then(res => {
                    // done
                });
        }
    }

    function handleClick(value){
        history.push('/kitchen/' + value._id);
    }

    const handleChangePlan = (event, reason) => {
        history.push('/account/'+planLimit.account);
    };

    const handleKitchenLimitClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowKitchenLimit(false);
    };

    const handleClickNew = () => {
        if(!planLimit.kitchenLimit){
            history.push('/kitchen');
        } else {
            setShowKitchenLimit(true);
        }
    };

    useEffect(() => {
        console.log('[Kitchens]');
        const kitchenURL = process.env.REACT_APP_API_BASE_URL + '/kitchens';
        const accountLimitURL = process.env.REACT_APP_API_BASE_URL + '/accounts/me/limited';
        let tasks = [
            httpClient.get(kitchenURL),
            httpClient.get(accountLimitURL),
        ];
        Promise.all(tasks)
            .then(rs=> {
                if (rs[0].data) {
                    setKitchens(rs[0].data)
                }
                if (rs[1].data) {
                    setPlanLimit(rs[1].data)
                }

                setLoading(false);
            });
    }, []);

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('kitchen.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
                <Box m={1}>
                    <Box display="flex" justifyContent="center" pb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            disableElevation
                            startIcon={<AddIcon/>}
                            onClick={handleClickNew}>
                            {t('common.add')}
                        </Button>
                    </Box>
                    {!loading && kitchens.length === 0 &&
                        <Box display="flex" justifyContent="center" p={2} m={2}>
                            <Typography>
                                {t('common.notFound')}
                            </Typography>
                        </Box>
                    }
                    {kitchens.length > 0 &&
                    <Card variant="outlined">
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            modifiers={[restrictToParentElement]}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={itemIds}
                                strategy={verticalListSortingStrategy}
                            >
                                <List className="SortableList" >
                                {kitchens.map((value, index) => <SortableItem key={`item-${index}`}
                                                                                   id={value._id}
                                                                                   divider={index !== kitchens.length-1}
                                                                                   onClick={handleClick}
                                                                                   index={index} value={value} />)}
                                </List>
                            </SortableContext>
                        </DndContext>
                    </Card>
                    }
                </Box>
            }
            <Snackbar open={showKitchenLimit}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={5000} onClose={handleKitchenLimitClose}>
                <Alert onClose={handleKitchenLimitClose} severity="warning">
                    <Box>
                        <Button variant="outlined" color="primary" disableElevation onClick={handleChangePlan}>
                            <Box>
                                <div style={{marginBottom: '10px'}}>
                                    {planLimit.kitchen > 0 && t('kitchen.planLimited', {limit: planLimit.kitchen})}
                                    {planLimit.kitchen <= 0 && t('kitchen.notPermitted')}
                                </div>
                                <div>{t('common.message.changePlan')}</div>
                            </Box>
                        </Button>
                    </Box>
                </Alert>
            </Snackbar>
        </div>
    )
}