import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShareIcon from '@mui/icons-material/Share';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import {getTextI18n} from "../TextI18n";
import './style.css';
import Alert from "@mui/material/Alert";

const { Box } = require('@mui/material');


// const CONNECT_BASE_URL = process.env.REACT_APP_CONNECT_LIFF_URL;
const CONNECT_BASE_URL = process.env.REACT_APP_CLIENT_LIFF_URL;

export function ShopLink({name, cid, type, share=false}) {
    const { t } = useTranslation();
    const [state, setState] = useState({});
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        console.log('[ShopLink]');
        setState({cid: cid, name: name, type: type});

    },[name, cid, type]);

    const handleClose = () =>{
        setCopied(false);
    };

    const handleOpenLink = () => {
        let url = CONNECT_BASE_URL +'/'+state.type+'/'+state.cid;
        window.open(url, '_blank').focus();
    };

    const handleShare = async () => {
        const name = getTextI18n(state.name);
        let url = CONNECT_BASE_URL +'/'+state.type+'/'+state.cid;
        const shareData = {
            title: name,
            url: url
        };

        try {
            await navigator.share(shareData);
        } catch(err) {
            if(err && err.code === 20){
                // cancel
            } else {
                window.open('https://social-plugins.line.me/lineit/share?url='+CONNECT_BASE_URL+'/'+state.type+'/'+state.cid);
            }
        }
    };

    return(
        <div>
            {state.cid &&
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <div className="link-selection full-width">
                        <div className="link-content">
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center">
                                <Box display="flex" justifyContent="center" style={{width: '100%'}} onClick={(e) => handleOpenLink()} >
                                    <StorefrontIcon></StorefrontIcon>
                                    <Typography variant="body1" noWrap={true}>
                                        <span style={{'marginLeft': '5px'}}>
                                            {t('shop.viewStoreFront')}
                                        </span>
                                    </Typography>
                                </Box>
                                {share &&
                                    <Box style={{borderLeft: '1px solid #1abc9c'}}>
                                        <IconButton onClick={handleShare}>
                                            <ShareIcon sx={{color: '#1abc9c'}} />
                                        </IconButton>
                                    </Box>
                                }
                            </Box>
                        </div>
                    </div>
                </Grid>
            </Grid>
            }
            <Snackbar open={copied}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="info">
                    {t('common.message.copied')}
                </Alert>
            </Snackbar>
        </div>)
}