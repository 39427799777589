
const PLANS = {
    FREE: {
        distance: 2,
        menu: 3,
        branch: false,
        stepper: 1
    },
    START: {
        distance: 0,
        menu: 100,
        branch: false,
        stepper: 1
    },
    BASIC: {
        distance: 3,
        menu: 30,
        branch: true,
        stepper: 1
    },
    STANDARD: {
        distance: 6,
        menu: 100,
        branch: true,
        stepper: 1
    },
    PRO: {
        distance: 18,
        menu: 300,
        branch: true,
        stepper: 2
    }
};

const PRICES = {
    FREE: 0,
    START: 29,
    BASIC: 89,
    STANDARD: 189,
    PRO: 589
};

const PRODUCTS = {
    "P001": {
        code: 'P001',
        name: {i18n: {en: 'Tent Card Acrylic for Smart Menu size 6X9 cm.', th: 'ป้ายอะคริลิคขนาดเล็ก สแกนเพื่อดูเมนูอาหาร ขนาด 6X9 เซนติเมตร'}},
        price: 29,
        shippingFee: 60
    },
    "P002": {
        code: 'P002',
        name: {i18n: {en: 'Tent Card Acrylic for mobile orders size 10X14 cm.', th: 'ป้ายอะคริลิคขนาดใหญ่ สแกนเพื่อสั่งอาหารผ่านมือถือ ขนาด 10X14 เซนติเมตร'}},
        price: 189,
        shippingFee: 90
    },
    "P003": {
        code: 'P003',
        name: {i18n: {en: 'Waterproof sticker for Smart Menu size 6X9 cm.', th: 'สติกเกอร์ สแกนเพื่อดูเมนูอาหาร ขนาด 6X9 เซนติเมตร'}},
        price: 12,
        shippingFee: 60
    },
    "P004": {
        code: 'P004',
        name: {i18n: {en: 'Tent Card PP Board for mobile orders size 12X17 cm.', th: 'ป้ายเทนการ์ดพีพีบอร์ด สแกนเพื่อสั่งอาหารผ่านมือถือ พิมพ์ 2 หน้า ขนาด 12X17 เซนติเมตร'}},
        price: 119,
        shippingFee: 90
    },
    "P005": {
        code: 'P005',
        name: {i18n: {en: 'Scan for online orders size 5X2 cm. 120 unit.', th: 'สติกเกอร์ขนาดเล็ก สแกนเพื่อสั่งอาหารออนไลน์ ขนาด 5X2 เซนติเมตร จำนวน 120 ดวง'}},
        price: 60,
        shippingFee: 60
    },
    "P006": {
        code: 'P006',
        name: {i18n: {en: 'Waterproof sticker for mobile orders size 6X9 cm.', th: 'สติกเกอร์ สแกนเพื่อสั่งอาหารผ่านมือถือ ขนาด 6X9 เซนติเมตร'}},
        price: 12,
        shippingFee: 60
    },
    "P007": {
        code: 'P007',
        name: {i18n: {en: 'Tent Card Acrylic for mobile orders size 6X9 cm.', th: 'ป้ายอะคริลิคขนาดเล็ก สแกนเพื่อสั่งอาหารผ่านมือถือ ขนาด 6X9 เซนติเมตร'}},
        price: 29,
        shippingFee: 60
    },
    "S001": {
        code: 'S001',
        name: {i18n: {en: 'Shop promote Package + Standee Kin Tam Mee.', th: 'แพ็คเก็จโปรโหมดร้าน+สแตนดี้กินตามหมี'}},
        price: 1590,
        shippingFee: 300
    }
};

const PROVINCES = [{ id: '64', name_th: 'กระบี่', name_en: 'Krabi' },
    { id: '1', name_th: 'กรุงเทพมหานคร', name_en: 'Bangkok' },
    { id: '56', name_th: 'กาญจนบุรี', name_en: 'Kanchanaburi' },
    { id: '34', name_th: 'กาฬสินธุ์', name_en: 'Kalasin' },
    { id: '49', name_th: 'กำแพงเพชร', name_en: 'Kamphaeng Phet' },
    { id: '28', name_th: 'ขอนแก่น', name_en: 'Khon Kaen' },
    { id: '13', name_th: 'จันทบุรี', name_en: 'Chanthaburi' },
    { id: '15', name_th: 'ฉะเชิงเทรา', name_en: 'Chachoengsao' },
    { id: '11', name_th: 'ชลบุรี', name_en: 'Chon Buri' },
    { id: '9', name_th: 'ชัยนาท', name_en: 'Chai Nat' },
    { id: '25', name_th: 'ชัยภูมิ', name_en: 'Chaiyaphum' },
    { id: '69', name_th: 'ชุมพร', name_en: 'Chumphon' },
    { id: '72', name_th: 'ตรัง', name_en: 'Trang' },
    { id: '14', name_th: 'ตราด', name_en: 'Trat' },
    { id: '50', name_th: 'ตาก', name_en: 'Tak' },
    { id: '17', name_th: 'นครนายก', name_en: 'Nakhon Nayok' },
    { id: '58', name_th: 'นครปฐม', name_en: 'Nakhon Pathom' },
    { id: '36', name_th: 'นครพนม', name_en: 'Nakhon Phanom' },
    { id: '19', name_th: 'นครราชสีมา', name_en: 'Nakhon Ratchasima' },
    { id: '63', name_th: 'นครศรีธรรมราช', name_en: 'Nakhon Si Thammarat' },
    { id: '47', name_th: 'นครสวรรค์', name_en: 'Nakhon Sawan' },
    { id: '3', name_th: 'นนทบุรี', name_en: 'Nonthaburi' },
    { id: '76', name_th: 'นราธิวาส', name_en: 'Narathiwat' },
    { id: '43', name_th: 'น่าน', name_en: 'Nan' },
    { id: '77', name_th: 'บึงกาฬ', name_en: 'buogkan' },
    { id: '20', name_th: 'บุรีรัมย์', name_en: 'Buri Ram' },
    { id: '4', name_th: 'ปทุมธานี', name_en: 'Pathum Thani' },
    { id: '62', name_th: 'ประจวบคีรีขันธ์', name_en: 'Prachuap Khiri Khan' },
    { id: '16', name_th: 'ปราจีนบุรี', name_en: 'Prachin Buri' },
    { id: '74', name_th: 'ปัตตานี', name_en: 'Pattani' },
    { id: '5', name_th: 'พระนครศรีอยุธยา', name_en: 'Phra Nakhon Si Ayutthaya' },
    { id: '44', name_th: 'พะเยา', name_en: 'Phayao' },
    { id: '65', name_th: 'พังงา', name_en: 'Phangnga' },
    { id: '73', name_th: 'พัทลุง', name_en: 'Phatthalung' },
    { id: '53', name_th: 'พิจิตร', name_en: 'Phichit' },
    { id: '52', name_th: 'พิษณุโลก', name_en: 'Phitsanulok' },
    { id: '66', name_th: 'ภูเก็ต', name_en: 'Phuket' },
    { id: '32', name_th: 'มหาสารคาม', name_en: 'Maha Sarakham' },
    { id: '37', name_th: 'มุกดาหาร', name_en: 'Mukdahan' },
    { id: '75', name_th: 'ยะลา', name_en: 'Yala' },
    { id: '24', name_th: 'ยโสธร', name_en: 'Yasothon' },
    { id: '68', name_th: 'ระนอง', name_en: 'Ranong' },
    { id: '12', name_th: 'ระยอง', name_en: 'Rayong' },
    { id: '55', name_th: 'ราชบุรี', name_en: 'Ratchaburi' },
    { id: '33', name_th: 'ร้อยเอ็ด', name_en: 'Roi Et' },
    { id: '7', name_th: 'ลพบุรี', name_en: 'Loburi' },
    { id: '40', name_th: 'ลำปาง', name_en: 'Lampang' },
    { id: '39', name_th: 'ลำพูน', name_en: 'Lamphun' },
    { id: '22', name_th: 'ศรีสะเกษ', name_en: 'Si Sa Ket' },
    { id: '35', name_th: 'สกลนคร', name_en: 'Sakon Nakhon' },
    { id: '70', name_th: 'สงขลา', name_en: 'Songkhla' },
    { id: '71', name_th: 'สตูล', name_en: 'Satun' },
    { id: '2', name_th: 'สมุทรปราการ', name_en: 'Samut Prakan' },
    { id: '60', name_th: 'สมุทรสงคราม', name_en: 'Samut Songkhram' },
    { id: '59', name_th: 'สมุทรสาคร', name_en: 'Samut Sakhon' },
    { id: '10', name_th: 'สระบุรี', name_en: 'Saraburi' },
    { id: '18', name_th: 'สระแก้ว', name_en: 'Sa Kaeo' },
    { id: '8', name_th: 'สิงห์บุรี', name_en: 'Sing Buri' },
    { id: '57', name_th: 'สุพรรณบุรี', name_en: 'Suphan Buri' },
    { id: '67', name_th: 'สุราษฎร์ธานี', name_en: 'Surat Thani' },
    { id: '21', name_th: 'สุรินทร์', name_en: 'Surin' },
    { id: '51', name_th: 'สุโขทัย', name_en: 'Sukhothai' },
    { id: '31', name_th: 'หนองคาย', name_en: 'Nong Khai' },
    { id: '27', name_th: 'หนองบัวลำภู', name_en: 'Nong Bua Lam Phu' },
    { id: '26', name_th: 'อำนาจเจริญ', name_en: 'Amnat Charoen' },
    { id: '29', name_th: 'อุดรธานี', name_en: 'Udon Thani' },
    { id: '41', name_th: 'อุตรดิตถ์', name_en: 'Uttaradit' },
    { id: '48', name_th: 'อุทัยธานี', name_en: 'Uthai Thani' },
    { id: '23', name_th: 'อุบลราชธานี', name_en: 'Ubon Ratchathani' },
    { id: '6', name_th: 'อ่างทอง', name_en: 'Ang Thong' },
    { id: '45', name_th: 'เชียงราย', name_en: 'Chiang Rai' },
    { id: '38', name_th: 'เชียงใหม่', name_en: 'Chiang Mai' },
    { id: '61', name_th: 'เพชรบุรี', name_en: 'Phetchaburi' },
    { id: '54', name_th: 'เพชรบูรณ์', name_en: 'Phetchabun' },
    { id: '30', name_th: 'เลย', name_en: 'Loei' },
    { id: '42', name_th: 'แพร่', name_en: 'Phrae' },
    { id: '46', name_th: 'แม่ฮ่องสอน', name_en: 'Mae Hong Son' }];

exports.PLANS = PLANS;
exports.PRICES = PRICES;
exports.PROVINCES = PROVINCES;
exports.PRODUCTS = PRODUCTS;