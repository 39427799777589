import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import SaveIcon from '@mui/icons-material/Save';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {httpClient} from '../../core/HttpClient'
import {makeStyles} from "@mui/styles";
import {Switch as SubSwitch} from "@mui/material";
import {Context} from "../../core/Context";
import Autocomplete from '@mui/material/Autocomplete';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            format="###"
            isNumericString
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

export default function Table() {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const [context, setContext] = useContext(Context);
    const classes = useStyles();
    const [loading, setLoading] = useState({init: false, update: false});
    const [allTags, setAllTags] = useState(["AAA","BBB","CCC","DDD"]);
    const [table, setTable] = useState({
        _id: '',
        number: '',
        active: true,
        tags: []
    });

    console.log('Table');
    useEffect(() => {
        console.log('[Table]');
        const tableURL = process.env.REACT_APP_API_BASE_URL + '/tables/' + id;
        const tagURL = process.env.REACT_APP_API_BASE_URL + '/tags/table';
        let tasks = [httpClient.get(tableURL), httpClient.get(tagURL)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs && rs[0] && rs[0].data){
                    setTable(rs[0].data);
                }
                if(rs && rs[1] && rs[1].data){
                    setAllTags(rs[1].data);
                }
            })
            .catch(e=>{
                console.log('error', e);
            });
    }, [id]);

    const handleSwitchChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        setTable({...table, ...fieldValue});
    };
    const handleSave = () => {
        setLoading({update: true, init: false});
        const url = process.env.REACT_APP_API_BASE_URL + '/tables/'+id;
        httpClient.put(url, table)
            .then(res => {
                setContext({ ...context, success: true});
                setLoading({init: false, update: false});
                history.push('/tables');
            });
    };

    const handleCancel = () => {
        history.push('/tables');
    };

    return (
        <div>
            <form noValidate autoComplete="off">
                <Box m={1}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/tables" className={classes.link}>
                            <Typography variant="h6">
                                {t('table.title')}
                            </Typography>
                        </Link>
                    </Breadcrumbs>
                </Box>
                <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                <Box m={1}>
                    <Paper elevation={0}>
                        <Card elevation={1}>
                            <CardContent>
                                <Box mb={2}>
                                    <TextField label={t('table.number')} variant="outlined" fullWidth={true}
                                               name="name" value={table.number}
                                               disabled={true} />
                                </Box>
                                <Box mb={2}>
                                    <Autocomplete
                                        multiple
                                        id="tags"
                                        freeSolo
                                        autoSelect
                                        options={allTags}
                                        value={table.tags}
                                        clearOnBlur={true}
                                        onChange={(e, newTags, reason) => {
                                            console.log('onChange');
                                            setTable({...table, tags: newTags});
                                        }}
                                        getOptionLabel={(option) => option}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={t('table.tags')}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        )}
                                    />
                                    <Box>
                                        <Typography variant={"caption"}>
                                            {t('table.tagExample')}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="body1">
                                        {t('table.active')}
                                    </Typography>
                                    <SubSwitch
                                        checked={table.active}
                                        onChange={ (e) => handleSwitchChange(e) }
                                        color="primary"
                                        name="active"
                                        inputProps={{ 'aria-label': 'active' }}
                                    />
                                </Box>
                            </CardContent>
                        </Card>
                    </Paper>
                    <Box mt={2}>
                        <Box mb={2}>
                            <Button variant="contained"
                                    color="primary"
                                    size="large"
                                    disableElevation
                                    fullWidth={true}
                                    disabled={loading.update}
                                    startIcon={<SaveIcon />}
                                    onClick={(e) => handleSave(e)}>
                                {t('common.save')}
                            </Button>
                        </Box>
                        <Box mb={2}>
                            <Button variant="contained"
                                    size="large"
                                    disableElevation
                                    fullWidth={true}
                                    disabled={loading.update}
                                    color={"grey"}
                                    onClick={handleCancel}>
                                {t('common.cancel')}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </form>
        </div>
    )
}