import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Typography from "@mui/material/Typography";
import AddBoxIcon from "@mui/icons-material/AddBox";
const { Box } = require('@mui/material');

let LAST_CLICKED = Date.now();
let INCREMENTAL = 1;
let INCREMENTAL_START = 4;

const useStyles = makeStyles((theme) => ({
    fieldSet: {
        '&:hover': {
            borderColor: "rgba(0, 0, 0, 0.87)"
        }
    }
}));

export function SelectionRate({name, label, value, min=0 ,max=Number.MAX_VALUE , prefix='', minLabel='', onChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState(0);

    useEffect(() => {
        console.log('[SelectionRate]');
        setState(value);
    },[value]);

    const handleAdditional = (value) => {
        let diff = Date.now()-LAST_CLICKED;
        if(diff < 300){
            INCREMENTAL++;
        } else {
            INCREMENTAL = 1;
        }

        LAST_CLICKED = Date.now();
        if(state < INCREMENTAL){
            INCREMENTAL = 1;
        }

        let _incremental = (INCREMENTAL > INCREMENTAL_START)?INCREMENTAL:1;
        let _value = (state || 0) + (value*_incremental);
        let _min = min || 0;
        let _max = max || Number.MAX_VALUE;
        if(_value >= _min && _value <= _max){
            setState(_value);
            onChange({target: {name: name, value: _value}});
        }
    };

    return(
        <Box>
            <FormControl fullWidth className={classes.margin} variant="outlined">
                <fieldset style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                    paddingTop: '0px',
                    borderRadius: '4px',
                    fontSize: '12px',
                    color: 'rgba(0, 0, 0, 0.6)'
                }}>
                    <legend>{label}</legend>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <IconButton aria-label="decrement" disabled={state <= min} onClick={e=>handleAdditional(-1)}>
                            <IndeterminateCheckBoxIcon fontSize="large" />
                        </IconButton>
                        <Box>
                            {minLabel === '' &&
                                <Typography variant={"h6"}>
                                    {`${prefix}${state}`}
                                </Typography>
                            }
                            {minLabel &&
                            <Typography variant={"h6"}>
                                {state > min && `${prefix}${state}`}
                                {state <= min && t(minLabel)}
                            </Typography>
                            }
                        </Box>
                        <IconButton aria-label="increment" disabled={state >= max} onClick={e=>handleAdditional(1)}>
                            <AddBoxIcon fontSize="large" />
                        </IconButton>
                    </Box>
                </fieldset>
            </FormControl>
        </Box>)
}