import React, {useState, useEffect} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import {useHistory, useLocation} from "react-router-dom";
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Switch from '@mui/material/Switch';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Avatar from '@mui/material/Avatar';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NumberFormat from 'react-number-format';
import {getProvinceI18n} from "../../TextI18n";
import {PROVINCES} from "../../../core/Static";
import {httpClient} from "../../../core/HttpClient";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);


let confirmDeleteId = '';

export default function PartnerPayment() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const search = useLocation().search;
    const ads = new URLSearchParams(search).get('ads');
    const [selectAddress, setSelectAddress] = useState(null);
    const [address, setAddress] = useState({state: '', _id: '', name: '', phone: '', addressNumber: '',postalCode: '', province: 'NONE', district: 'NONE', subDistrict: 'NONE', used: false});
    const [addressList, setAddressList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [errors, setErrors] = useState([]);
    const [district, setDistrict] = useState([]);
    const [subDistrict, setSubDistrict] = useState([]);

    useEffect(() => {
        console.log('[PartnerPayment]');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/address';
        httpClient.get(url)
            .then(res => {
                let addressList = res.data;
                if(Array.isArray(addressList)){
                    setAddressList(addressList);
                    let defaultAddress = addressList.find((obj, index) => {
                        obj.index = index;
                        return obj.used;
                    });
                    if(ads !== null){
                        let defaultAddress = addressList[ads];
                        setSelectAddress(defaultAddress);
                    } else if(defaultAddress){
                        setSelectAddress(defaultAddress);
                    } else {
                        if(addressList.length>0){
                            setSelectAddress(addressList[0]);
                            setSelectAddress({...addressList[0], index: 0});
                        }
                    }
                }
                setLoading(false);
            });
    }, [ads]);

    const handleBack = () => {
        history.push(`/partners/1`);
    };

    const handleAddMoreAddress = () => {
        setOpen(true);
    };

    const handleSelectAddress = (item, index) => {
        setSelectAddress({...item, index: index});
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        setAddress({state: '', _id: '', name: '', phone: '', addressNumber: '',postalCode: '', province: 'NONE', district: 'NONE', subDistrict: 'NONE', used: false});
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const handleOpenConfirm = (item) => {
        confirmDeleteId = item._id;
        setOpenConfirm(true);
    };

    const handleEditAddress = (item) => {
        let _editData = {state: 'edit', _id: item._id, name: item.name, phone: item.phone, addressNumber: item.addressNumber,postalCode: item.postalCode,
            province: item.province.id, district: item.district.id, subDistrict: item.subDistrict.id, used: item.used};

        const urlDistrict = process.env.REACT_APP_API_BASE_URL + '/publish/region/district/'+item.province.id;
        const urlSubDistrict = process.env.REACT_APP_API_BASE_URL + '/publish/region/subdistrict/'+item.district.id;
        let tasks = [httpClient.get(urlDistrict), httpClient.get(urlSubDistrict)];

        Promise.all(tasks)
            .then(rs=>{
                if(rs[0].data){
                    setDistrict(rs[0].data);
                }
                if(rs[1].data){
                    setSubDistrict(rs[1].data);
                }

                setAddress(_editData);
            })
            .catch(e=>{
                console.log('error', e);
            });
    };

    const handleBackToAddress = () => {
        setAddress({state: '', _id: '', name: '', phone: '', addressNumber: '',postalCode: '', province: 'NONE', district: 'NONE', subDistrict: 'NONE', used: false});
    };

    const handleDeleteAddress = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/users/address/' + confirmDeleteId;
        httpClient.delete(url)
            .then(res => {
                // reload address
                setLoading(true);
                const url = process.env.REACT_APP_API_BASE_URL + '/users/address';
                httpClient.get(url)
                    .then(res => {
                        let addressList = res.data;
                        if(Array.isArray(addressList)){
                            setAddressList(addressList);
                        }
                        setLoading(false);
                        setOpenConfirm(false);
                    });
            });

    };

    const handleNext = () => {
        if(selectAddress){
            // selected Address
            history.push(`/partners/1/payment/confirm?type=service&ads=${selectAddress.index}`);
        } else {
            // new Address
            if(_validate()){
                setLoading(true);
                let p = PROVINCES.find(obj => {
                    return ''+obj.id === address.province
                });
                let d = district.find(obj => {
                    return ''+obj.id === address.district
                });
                let sd = subDistrict.find(obj => {
                    return ''+obj.id === address.subDistrict
                });
                let data = {
                    name: address.name,
                    phone: address.phone,
                    addressNumber: address.addressNumber,
                    postalCode: address.postalCode,
                    province: p,
                    district: d,
                    subDistrict: sd,
                    used: true
                };
                const url = process.env.REACT_APP_API_BASE_URL + '/users/address';
                httpClient.post(url, data)
                    .then(res => {
                        history.push(`/partners/1/payment/confirm?type=service&ads=0`);
                    });
            }
        }
    };

    const handleSwitchChange = (e) => {
        setAddress({ ...address, [e.target.name]: e.target.checked});
    };

    const _validate = () =>{
        let _errors = [];
        if(!address.name){
            _errors.push('name');
        }
        if(!address.phone){
            _errors.push('phone');
        }
        if(!address.addressNumber){
            _errors.push('addressNumber');
        }
        if(address.province === 'NONE'){
            _errors.push('province');
        }
        if(address.district === 'NONE'){
            _errors.push('district');
        }
        if(address.subDistrict === 'NONE'){
            _errors.push('subDistrict');
        }
        if(!address.postalCode){
            _errors.push('postalCode');
        }
        setErrors(_errors);
        return _errors.length === 0;
    };

    const handleCreateNewAddress = () =>{
        setAddress({state: 'create', _id: '', name: '', phone: '', addressNumber: '',postalCode: '', province: 'NONE', district: 'NONE', subDistrict: 'NONE', used: false});
    };

    const handleAddEditAddress = () => {
        if(_validate()){
            let p = PROVINCES.find(obj => {
                return ''+obj.id === address.province
            });
            let d = district.find(obj => {
                return ''+obj.id === address.district
            });
            let sd = subDistrict.find(obj => {
                return ''+obj.id === address.subDistrict
            });
            let data = {
                _id: address._id,
                name: address.name,
                phone: address.phone,
                addressNumber: address.addressNumber,
                postalCode: address.postalCode,
                province: p,
                district: d,
                subDistrict: sd,
                used: address.used
            };
            const url = process.env.REACT_APP_API_BASE_URL + '/users/address';
            httpClient.post(url, data)
                .then(res => {
                    // reload address
                    setLoading(true);
                    const url = process.env.REACT_APP_API_BASE_URL + '/users/address';
                    httpClient.get(url)
                        .then(res => {
                            let addressList = res.data;
                            if(Array.isArray(addressList)){
                                setAddressList(addressList);
                            }
                            setLoading(false);
                        });
                    handleBackToAddress();
                });
        }
    };

    const handleChange = ({ target }) => {
        setAddress({ ...address, [target.name]: target.value });
    };

    const handleProvinceChange = ({ target }) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/district/'+target.value;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setDistrict(res.data);
                }
            });
        setDistrict([]);
        setSubDistrict([]);
        setAddress({ ...address, [target.name]: target.value, district: 'NONE', subDistrict: 'NONE'});
    };

    const handleDistrictChange = ({ target }) => {
        const url = process.env.REACT_APP_API_BASE_URL + '/publish/region/subdistrict/'+target.value;
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setSubDistrict(res.data);
                }
            });
        setSubDistrict([]);
        setAddress({ ...address, [target.name]: target.value, subDistrict: 'NONE'});

    };

    const handleSubDistrictChange = ({ target }) => {
        let sd = subDistrict.find(obj => {
            return ''+obj.id === target.value
        });
        let pc = '';
        if(sd){
            pc = sd.zip_code;
        }
        setAddress({ ...address, [target.name]: target.value, postalCode: pc });
    };

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" m={1}>
                <IconButton edge="start" color="primary" aria-label="black" onClick={handleBack}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6">
                    {t('store.confirmPayment')}
                </Typography>
            </Box>
            <Divider style={{'marginTop': '10px'}} />
            <Box m={1}>
            <Box>
                <List>
                    <ListItem key={`item-1`}>
                        <ListItemAvatar><Avatar
                            variant="square"
                            alt='product'
                            src={`https://mui.com/static/images/cards/live-from-space.jpg`}></Avatar></ListItemAvatar>
                        <ListItemText>
                            <div>
                                <Box>
                                    <Typography variant="body1">
                                        <strong>{t(`partner.partners.1.product.name`)}</strong>
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography variant="body1">
                                        <span>{t(`partner.partners.1.product.description`)}</span>
                                    </Typography>
                                </Box>
                            </div>
                        </ListItemText>
                    </ListItem>
                </List>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="body1">
                        <span>{t('store.price')}</span>
                    </Typography>
                    <Typography noWrap={true}>
                        {t(`partner.partners.1.product.price`)}
                    </Typography>
                </Box>
                <Divider style={{'marginTop': '10px', 'marginBottom': '10px'}} />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography variant="body1">
                    <span>{t('store.address')}</span>
                </Typography>
                {!loading && addressList.length > 0 &&
                <Button
                    color="primary"
                    size="small"
                    disableElevation
                    onClick={handleAddMoreAddress}>
                    {t('address.changeDeliveryAddress')}
                </Button>
                }
            </Box>
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {selectAddress !== null && addressList.length !== 0 &&
            <Box border={1} borderRadius={2} borderColor={'primary.main'} sx={{ width: '100%'}} p={1} mb={1}>
                <Box >
                    <Box><b>{selectAddress.name}</b></Box>
                    <Box>
                        <div style={{'lineHeight': 0.75}} ><Typography variant="caption" style={{'lineHeight': 1}} >{selectAddress.addressNumber}</Typography></div>
                        <div><Typography variant="caption">{getProvinceI18n(selectAddress.subDistrict, i18n)} {getProvinceI18n(selectAddress.district, i18n)} {getProvinceI18n(selectAddress.province, i18n)} {selectAddress.postalCode}</Typography></div>
                        <div><Typography variant="caption" >{t('customer.tel')} <NumberFormat value={selectAddress.phone} displayType={'text'} format="###-###-####" /></Typography></div>
                    </Box>
                </Box>
            </Box>
            }
            {!loading && addressList.length === 0 &&
            <Box mt={2}>
                <form className={classes.root} noValidate autoComplete="off">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField label={t('address.name')} variant="outlined" fullWidth={true}
                                       name="name" value={address.name}
                                       onChange={ handleChange } required
                                       error={errors.indexOf('name') > -1} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField id="user--address-phone"
                                       label={t('address.phone')}
                                       variant="outlined"
                                       fullWidth={true}
                                       name="phone" type="tel"
                                       value={address.phone}
                                       error={errors.indexOf('phone') > -1}
                                       onChange={ handleChange }
                                       InputProps={{
                                           inputComponent: PhoneFormatCustom,
                                       }}
                                       required />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined"
                                         fullWidth={true}
                                         error={errors.indexOf('province') > -1}>
                                <InputLabel id="province-label">{t('address.province')}</InputLabel>
                                <Select
                                    native
                                    labelId="province-label"
                                    label={t('address.province')}
                                    name="province"
                                    value={address.province}
                                    onChange={handleProvinceChange}>
                                    <>
                                        <option key={'NONE'} value={'NONE'}>
                                            {t('address.pleaseSelectProvince')}
                                        </option>
                                        {
                                            PROVINCES.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {getProvinceI18n(item, i18n)}
                                                </option>
                                            ))
                                        }
                                    </>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined"
                                         fullWidth={true}
                                         error={errors.indexOf('district') > -1}>
                                <InputLabel id="district-label">{t('address.district')}</InputLabel>
                                <Select
                                    native
                                    labelId="district-label"
                                    label={t('address.district')}
                                    name="district"
                                    value={address.district}
                                    onChange={handleDistrictChange}>
                                    <>
                                        <option key={'NONE'} value={'NONE'}>
                                            {t('address.pleaseSelectDistrict')}
                                        </option>
                                        {
                                            district.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {getProvinceI18n(item, i18n)}
                                                </option>
                                            ))
                                        }
                                    </>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined"
                                         fullWidth={true}
                                         error={errors.indexOf('subDistrict') > -1}>
                                <InputLabel id="subDistrict-label">{t('address.subDistrict')}</InputLabel>
                                <Select
                                    native
                                    labelId="subDistrict-label"
                                    label={t('address.subDistrict')}
                                    name="subDistrict"
                                    value={address.subDistrict}
                                    onChange={handleSubDistrictChange}>
                                    <>
                                        <option key={'NONE'} value={'NONE'}>
                                            {t('address.pleaseSelectSubDistrict')}
                                        </option>
                                        {
                                            subDistrict.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {getProvinceI18n(item, i18n)}
                                                </option>
                                            ))
                                        }
                                    </>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={t('address.postalCode')} variant="outlined" fullWidth={true}
                                       name="postalCode" value={address.postalCode}
                                       onChange={ handleChange } required
                                       error={errors.indexOf('postalCode') > -1} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={t('address.addressNumber')} variant="outlined" fullWidth={true}
                                       name="addressNumber" value={address.addressNumber}
                                       onChange={ handleChange } required
                                       error={errors.indexOf('addressNumber') > -1} />
                        </Grid>
                    </Grid>
                </form>
            </Box>
            }
            <Box mt={2} pb={2} style={{width: '100%'}}>
                <Button variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        disableElevation
                        disabled={loading}
                        onClick={handleNext} >
                    {t('store.confirmPayment')}
                </Button>
            </Box>
            </Box>
            {open &&
            <Dialog
                onClose={ handleClose }
                keepMounted
                fullScreen={true}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                open={ open }>
                <AppBar sx={{ position: 'relative' }} color="default">
                    {address.state !== '' &&
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleBackToAddress}
                            aria-label="close"
                        >
                            <ArrowBackIcon/>
                        </IconButton>
                        <Typography ml="2" sx={{flex: 1}} variant="h6" component="div">
                            {address.state === 'create' && t(`address.addNewDeliveryAddress`)}
                            {address.state === 'edit' && t(`address.editDeliveryAddress`)}
                        </Typography>
                    </Toolbar>
                    }
                    {address.state === '' &&
                    <Toolbar>
                        <Typography sx={{flex: 1}} variant="h6" component="div">
                            {t(`address.selectDeliveryAddress`)}
                        </Typography>
                        {address.state === '' &&
                            <IconButton
                                edge="end"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                        }
                    </Toolbar>
                    }
                </AppBar>
                {address.state === '' &&
                <Box mt={1}>
                    <Box p={2}>
                        <Button onClick={ handleCreateNewAddress }
                                fullWidth
                                disableElevation
                                variant="outlined"
                                color="primary">
                            {t('address.addNewDeliveryAddress')}
                        </Button>
                    </Box>
                    <List>
                        {
                            addressList.map((item, index) => (
                                <ListItem key={`address-item-${index}`} divider={true} button onClick={e=>handleSelectAddress(item, index)}>
                                    <ListItemText>
                                        <div>
                                            <Box display="flex">
                                                <Typography variant="body1">
                                                    <strong>{item.name}</strong>
                                                </Typography>
                                                {item.used && <Box ml={1}><Chip size="small" label={t('common.default')} /></Box>}

                                            </Box>
                                            <Box>
                                                <Typography variant="caption" >{item.addressNumber}</Typography>
                                            </Box>
                                            <Box>
                                                <div><Typography variant="caption">{getProvinceI18n(item.subDistrict, i18n)} {getProvinceI18n(item.district, i18n)} {getProvinceI18n(item.province, i18n)} {item.postalCode}</Typography></div>
                                            </Box>
                                            <Box>
                                                <Typography variant="caption" >{t('customer.tel')} <NumberFormat value={item.phone} displayType={'text'} format="###-###-####" /></Typography>
                                            </Box>
                                        </div>
                                    </ListItemText>
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="edit" onClick={e=>handleEditAddress(item)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={e=>handleOpenConfirm(item)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                    </List>
                </Box>
                }
                {address.state !== '' &&
                <DialogContent>
                    <Box mt={1}>
                        <form className={classes.root} noValidate autoComplete="off">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField label={t('address.name')} variant="outlined" fullWidth={true}
                                               name="name" value={address.name}
                                               onChange={handleChange} required
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               error={errors.indexOf('name') > -1}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="outlined-basic"
                                               label={t('address.phone')}
                                               variant="outlined"
                                               fullWidth={true}
                                               name="phone" type="tel"
                                               value={address.phone}
                                               error={errors.indexOf('phone') > -1}
                                               onChange={handleChange}
                                               InputProps={{
                                                   inputComponent: PhoneFormatCustom,
                                               }}
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               required/>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}
                                                 error={errors.indexOf('province') > -1}>
                                        <InputLabel id="province-label">{t('address.province')}</InputLabel>
                                        <Select
                                            native
                                            labelId="province-label"
                                            label={t('address.province')}
                                            name="province"
                                            value={address.province}
                                            onChange={handleProvinceChange}>
                                            <>
                                                <option key={'NONE'} value={'NONE'}>
                                                    {t('address.pleaseSelectProvince')}
                                                </option>
                                                {
                                                    PROVINCES.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {getProvinceI18n(item, i18n)}
                                                        </option>
                                                    ))
                                                }
                                            </>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}
                                                 error={errors.indexOf('district') > -1}>
                                        <InputLabel id="district-label">{t('address.district')}</InputLabel>
                                        <Select
                                            native
                                            labelId="district-label"
                                            label={t('address.district')}
                                            name="district"
                                            value={address.district}
                                            onChange={handleDistrictChange}>
                                            <>
                                                <option key={'NONE'} value={'NONE'}>
                                                    {t('address.pleaseSelectDistrict')}
                                                </option>
                                                {
                                                    district.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {getProvinceI18n(item, i18n)}
                                                        </option>
                                                    ))
                                                }
                                            </>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined"
                                                 fullWidth={true}
                                                 error={errors.indexOf('subDistrict') > -1}>
                                        <InputLabel id="subDistrict-label">{t('address.subDistrict')}</InputLabel>
                                        <Select
                                            native
                                            labelId="subDistrict-label"
                                            label={t('address.subDistrict')}
                                            name="subDistrict"
                                            value={address.subDistrict}
                                            onChange={handleSubDistrictChange}>
                                            <>
                                                <option key={'NONE'} value={'NONE'}>
                                                    {t('address.pleaseSelectSubDistrict')}
                                                </option>
                                                {
                                                    subDistrict.map((item) => (
                                                        <option key={item.id} value={item.id}>
                                                            {getProvinceI18n(item, i18n)}
                                                        </option>
                                                    ))
                                                }
                                            </>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('address.postalCode')} variant="outlined" fullWidth={true}
                                               name="postalCode" value={address.postalCode}
                                               onChange={handleChange} required
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               error={errors.indexOf('postalCode') > -1}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label={t('address.addressNumber')} variant="outlined" fullWidth={true}
                                               name="addressNumber" value={address.addressNumber}
                                               onChange={handleChange} required
                                               InputLabelProps={{
                                                   shrink: true,
                                               }}
                                               error={errors.indexOf('addressNumber') > -1}/>
                                </Grid>
                                <Grid item xs={12} mt={2}>
                                    <Box display="flex" justifyContent="center">
                                        <Typography variant="body2">
                                            {t('common.defaultSetting')}
                                        </Typography>
                                    </Box>
                                    <Box display="flex" justifyContent="center">
                                        <Switch
                                            checked={address.used}
                                            onChange={(e) => handleSwitchChange(e)}
                                            color="primary"
                                            name='used'
                                            inputProps={{'aria-label': 'used'}}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>

                        </form>
                        <Box mt={2} mb={2}>
                            <Button onClick={handleAddEditAddress}
                                    fullWidth
                                    disableElevation
                                    variant="contained"
                                    color="primary"
                                    size="large">
                                {t('common.ok')}
                            </Button>
                        </Box>
                    </Box>
                </DialogContent>
                }
                {openConfirm &&
                <Dialog
                    open={open > 0}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleCloseConfirm}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">{t('common.message.confirm')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            {t('common.message.confirmDelete')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained"
                                onClick={handleCloseConfirm}
                                fullWidth={true}
                                size="large"
                                disableElevation>
                            {t('common.cancel')}
                        </Button>
                        <Button variant="contained"
                                color="secondary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                startIcon={<DeleteForeverIcon/>}
                                onClick={(e) => handleDeleteAddress(e)}>
                            {t('common.delete')}
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </Dialog>
            }
        </div>
    )
}