import React from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {ListItemSecondaryAction} from "@mui/material";
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from "@mui/material/IconButton";

export default function ContactInfo({value}) {
    const { t } = useTranslation();
    let customer = value.customer || {};
    let contact = null;
    if(value.location && value.location.contact){
        contact = value.location.contact;
    }

    const handleTel = () => {
        let phone = '';
        if(value.contactProfile && value.contactProfile.mobile){
            phone = value.contactProfile.mobile
        } else if(customer.mobile){
            phone = customer.mobile;
        }

        if(phone){
            const link = document.createElement('a');
            link.id = 'phone';
            link.href = 'tel:'+phone;
            link.click();
        }

    }

    return (
        <Box>
            <List>
                <ListItem>
                    {customer && customer._id &&
                    <ListItemAvatar>
                        <Avatar src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${customer._id}`} />
                    </ListItemAvatar>
                    }
                    <ListItemText primary={
                        value.contactProfile?value.contactProfile.name:''
                    } secondary={
                        <span style={value.customerLevel === ''?{background: 'red', padding: '4px', color: 'white', borderRadius: '4px'}
                            : {background: 'green', padding: '4px', color: 'white', borderRadius: '4px'}}>
                            {value.customerLevel === ''?t("customer.newCustomer"):t("customer.oldCustomer")}
                        </span>
                    } />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="phone" onClick={handleTel}>
                            <Avatar sx={{background: "#1abc9c", width: 32, height: 32 }}>
                                <PhoneIcon fontSize={"small"} />
                            </Avatar>
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>
            {contact && contact.address &&
            <Box ml={2} mr={2} mb={2}>
                <Typography variant="body2" component="div" style={{flexGrow: 1}}>
                    {t('order.address')} : {contact.address}
                </Typography>
            </Box>
            }
        </Box>
    );
}
