import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import {Route, Redirect, useHistory} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import CssBaseline from "@mui/material/CssBaseline";
import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import jwt_decode from "jwt-decode";
import {getTextI18n} from "../../components/TextI18n";
import {httpClient} from "../../core/HttpClient";
import { Context } from "../../core/Context";
import Box from "@mui/material/Box";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 0,
    },
    leftMenuButton: {
        marginTop: '1rem',
    },
    title: {
        flexGrow: 1,
    },
    stickToBottom: {
        width: '100%',
        position: 'fixed',
        bottom: 0,
        background: '#efefef'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    showMenu: {
        display: 'inline'
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3)
    },
    hideMenu: {
        display: 'none'
    },
    content: {
        marginBottom: '75px',
    },
    select: {
        paddingRight: '0!important',
        paddingLeft: '20px',
        color: 'white',
        "& .MuiSvgIcon-root": {
            color: "white",
        },
        "& option": {
            color: "black"
        }
    },
    icon:{
        left:-5,
        color: 'white',
    },
    iconOpen:{
        transform:'none'
    },

}));

const Console = ({role, children}) => {
    const { t } = useTranslation();
    const [context, setContext] = useState({userId: '', cid: '', displayName: '', success: false, fail: false, loading: false});
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const [noAccountAvailable, setNoAccountAvailable] = useState(false);

    useEffect(() => {
        console.log('[Console]');
        let token = localStorage.getItem('token');
        let ticketPasscode = localStorage.getItem('ticket');
        if(!token || !ticketPasscode){
            history.push('/signin');
        }

        const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected';
        httpClient.get(url)
            .then(res => {
                if(res.data && res.data.accounts){
                    if(res.data.accounts[0]){

                        let user = {
                            userId: res.data._id
                        };
                        if(res.data.selectedAccount){
                            user.cid = res.data.selectedAccount.cid;
                            user.displayName = getTextI18n(res.data.selectedAccount.name);
                        } else {
                            window.location.href = '/login';
                        }

                        setContext({ ...user, success: false, fail: false, loading: false });
                    } else {
                        setNoAccountAvailable(true);
                    }

                }

                try {
                    // verify passcode
                    let ticketDecoded = jwt_decode(ticketPasscode);
                    if(res.data._id !== ticketDecoded.userId){
                        history.push('/signin');
                    }

                    // refresh token process
                    let tokenDecoded = jwt_decode(token);
                    let remainingDays = dayjs(tokenDecoded.exp * 1000).diff(new Date(), 'days');
                    // if expired is 30 days that mean refresh token every day;
                    if(remainingDays < 29){
                        let refreshToken = localStorage.getItem('refreshToken');
                        handleRefreshToken(token, refreshToken).then(r => {
                            // refresh token done.
                        }).catch(e=>{
                            console.log('error', e);
                        });
                    }

                } catch (e){
                    history.push('/signin');
                }
            }).catch(e=>{
                console.log('error', e);
            })
    }, [history]);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = (event) => {
        localStorage.clear();
        window.location.href = '/login';
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setContext({ ...context, success: false, fail: false, loading: false });
    };

    const handleGoToRoute = (path) => {
        history.push(path);
        handleMenuClose();
    };

    const handleDashboard = () => {
        window.location.href = '/home';
    };

    const handleRefreshToken = (token, refreshToken) => {
        console.log('handleRefreshToken');
        const url = process.env.REACT_APP_API_BASE_URL + '/auth/line/refresh-token';
        const payload = {
            token: token,
            refreshToken: refreshToken
        };
        return httpClient.post(url, payload).then(res => {
            let data = res.data;
            if(data && data.token && data.refreshToken){
                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken);
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
            }
        }).catch(e=>{
            console.log('e', e);
        });
    }

    return (
        <div className='page page-dashboard'>
            <CssBaseline />
            <header>
                <AppBar elevation={0} position="static">
                    <Toolbar>
                        {!noAccountAvailable &&
                        <>
                            <IconButton edge="start"
                                        className={classes.menuButton}
                                        onClick={handleDashboard}
                                        color="inherit" aria-label="menu">
                                <ArrowBackIosNewIcon/>
                            </IconButton>
                            <Typography variant="h6" noWrap={true} className={classes.title}>
                                <span onClick={handleDashboard}>{t('common.goHome')}</span>
                            </Typography>
                        </>
                        }
                        {noAccountAvailable && <Typography variant="h6" noWrap={true} className={classes.title}></Typography>}
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <Avatar alt={context.displayName} src={context.userId?`${process.env.REACT_APP_CDN_BASE_URL}/public/user/${context.userId}?dt=`+new Date().getTime(): `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty_user.jpg`} sx={{ width: 32, height: 32 }} />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={open}
                            onClose={handleMenuClose}
                        >
                            <MenuItem dense onClick={(e) => handleGoToRoute('/accounts')} >{t('account.title')}</MenuItem>
                            <MenuItem dense onClick={(e) => handleGoToRoute('/profile')} >{t('profile.title')}</MenuItem>
                            <MenuItem dense onClick={(e) => handleGoToRoute('/invoices')} >{t('invoice.title')}</MenuItem>
                            <MenuItem dense onClick={(e) => handleGoToRoute('/setting')}>{t('changeLanguage')}</MenuItem>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem dense onClick={handleLogout}>{t('logout')}</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
            </header>
            <Box>
                <div className={classes.content}>
                    <Context.Provider value={[context, setContext]}>{children}</Context.Provider>
                </div>
            </Box>
            <Snackbar open={context.success}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {t('common.message.saveSuccess')}
                </Alert>
            </Snackbar>
            <Snackbar open={context.fail}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {t('common.message.saveFail')}
                </Alert>
            </Snackbar>
            <Backdrop
                style={{
                    zIndex: 999,
                    color: '#fff',
                }}
                open={context.loading}
            >
                <CircularProgress color="inherit" size={20}/>
            </Backdrop>
        </div>
    );
};

const ConsoleLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                const token = localStorage.getItem('token');
                if (token) {
                    var decoded = jwt_decode(token);
                    if(decoded.account){
                        return <Console role={decoded.role} ><Component {...props} /></Console>
                    }else{
                        return <Redirect to={
                            {
                                pathname: '/init',
                                state: {
                                    from: props.location
                                }
                            }
                        } />
                    }
                } else {
                    return <Redirect to={
                        {
                            pathname: '/signin',
                            state: {
                                from: props.location
                            }
                        }
                    } />
                }
            }}
        />
    );
};

export default ConsoleLayoutRoute;