import React, {useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {isDesktop} from "react-device-detect";
import ImageGallery from "react-image-gallery";
import {Link, useHistory} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {makeStyles} from "@mui/styles";
import Button from "@mui/material/Button";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    blockquote: {
        background: "#f9f9f9",
        borderLeft: "10px solid #ccc",
        margin: "1.5em 10px",
        padding: "0.5em 10px"
    },
}));

export default function PartnerInfo() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [images] = useState([
        {
            original: `https://mui.com/static/images/cards/live-from-space.jpg`,
            originalClass: 'my-image-gallery-image'
        },
        {
            original: `https://mui.com/static/images/cards/live-from-space.jpg`,
            originalClass: 'my-image-gallery-image'
        },
        {
            original: `https://mui.com/static/images/cards/live-from-space.jpg`,
            originalClass: 'my-image-gallery-image'
        }
    ]);

    const handleClick = () => {
        history.push('/partners/1/payment');
    };

    return (
        <div>
            <Box m={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/partners" className={classes.link}>
                        <Typography variant="h6">
                            {t('partner.title')}
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box>
                <ImageGallery items={images}
                              infinite={false}
                              showNav={isDesktop && images.length > 1}
                              showThumbnails={false}
                              showFullscreenButton={false}
                              useBrowserFullscreen={false}
                              showBullets={images.length > 1}
                              showPlayButton={false} />
            </Box>
            <Box m={2} mb={1}>
                <Typography variant={"h5"}>{t('partner.partners.1.name')}</Typography>
            </Box>
            <Box ml={2} mr={2} mb={2}>
                <Typography className={classes.blockquote} variant={"body1"}>{t('partner.partners.1.detail')}</Typography>
            </Box>
            <Box ml={2} mr={2} mb={2}>
                <Typography variant={"h6"}>
                    {t('partner.partners.1.offers.list')}
                </Typography>
                <Typography variant={"body1"}>
                    {t('partner.partners.1.offers.1')}
                </Typography>
                <Typography variant={"body1"}>
                    {t('partner.partners.1.offers.2')}
                </Typography>
                <Typography variant={"body1"}>
                    {t('partner.partners.1.offers.3')}
                </Typography>
            </Box>
            <Box m={2}>
                <Button variant="outlined"
                        color="success"
                        fullWidth={true}
                        onClick={handleClick}
                        size={"large"}>
                    <b>{t('partner.partners.1.buyNow')}</b>
                </Button>
            </Box>
            <Box style={{height: '1rem'}}></Box>
        </div>
    )
}