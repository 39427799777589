import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {httpClient} from "../../core/HttpClient";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import SaveIcon from "@mui/icons-material/Save";
import {Context} from "../../core/Context";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import {QRCode} from "../QRCode";
import Switch from "@mui/material/Switch";
import {styled} from "@mui/material/styles";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

const CONNECT_BASE_URL = process.env.REACT_APP_CLIENT_LIFF_URL;
const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8',

    },
    tabPanels: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
}));

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    '#fff',
                )}" d="M17 1.01L7 1c-1.1 0-2 .9-2 2v18c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-1.99-2-1.99zM17 19H7V5h10v14z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: '#44cc00',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                '#fff',
            )}" d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}));


export default function QRCodeList() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [context, setContext] = useContext(Context);
    const [account, setAccount] = useState({
        cid: '',
    });
    const [qrcode, setQrcode] = useState({
        type: 'v',
    });

    console.log('QRCodeList');

    useEffect(() => {
        console.log('[QRCodeList]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me';
        const qrcodeUrl = process.env.REACT_APP_API_BASE_URL + '/qrcode/1';
        let tasks = [httpClient.get(url), httpClient.get(qrcodeUrl)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setAccount(rs[0].data);
                }
                if(rs[1] && rs[1].data){
                    setQrcode(rs[1].data);
                }
                setLoading(false);
            });
    }, []);

    const handleSwitch = (e) => {
        let fieldValue = {};
        if(e.target.checked){
            fieldValue[e.target.name] = 'm';
        } else {
            fieldValue[e.target.name] = 'v';
        }
        setQrcode({...qrcode, ...fieldValue});
    };

    const handleClick = (value) => {
        let fieldValue = {
            type: value
        };
        setQrcode({...qrcode, ...fieldValue});
    };

    const handleOpenLink = (cid) => {
        let url = CONNECT_BASE_URL +'/1/'+cid;
        window.open(url, '_blank').focus();
    };

    const handleSave = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/qrcode/1';
        setUpdating(true);
        httpClient.put(url, qrcode)
            .then(res => {
                setUpdating(false);
                setContext({ ...context, success: true});
            });
    };

    return (
        <div className={classes.root}>
            <Box m={1}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h6">
                        {t('qr.mobile')}
                    </Typography>
                </Box>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <>
                    <Box m={1}>
                        <Paper className={classes.paper} elevation={0}>
                            <Card className={classes.root} variant="outlined">
                                <CardContent>
                                    <Box display="flex" justifyContent="center" flexDirection="column" >
                                        <Box display="flex" justifyContent="center">
                                            {account.cid &&
                                                <QRCode name="qrcode" value={account.cid} type="1" />
                                            }
                                        </Box>
                                        <Box display="flex" justifyContent="center" mb={2} style={{width: '100%'}} >
                                            <Button variant="outlined"
                                                    sx={{width: '300px'}}
                                                    fullWidth={true}
                                                    disableElevation
                                                    disabled={updating}
                                                    startIcon={<QrCodeScannerIcon/>}
                                                    onClick={(e) => handleOpenLink(account.cid)}>
                                                {t('mobileOrdering.viewMenu')}
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2}>
                                        <FormControlLabel
                                            control={<MaterialUISwitch sx={{ m: 1 }}
                                                                       name={"type"}
                                                                       onChange={handleSwitch}
                                                                       checked={qrcode.type === 'm'} />}
                                        />
                                    </Box>
                                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={2} mb={2}>
                                        <Box display={"flex"} onClick={e=>handleClick('v')} mr={4}>
                                            {qrcode.type !== 'm' && <CheckCircleOutlineIcon />}
                                            {qrcode.type === 'm' && <RadioButtonUncheckedIcon color="disabled" />}
                                            <Typography ml={1} style={{color: qrcode.type === 'm'?"#8f8f8f":"black"}}>
                                                {t('qrcode.type.view')}
                                            </Typography>
                                        </Box>
                                        <Box display={"flex"} onClick={e=>handleClick('m')} >
                                            {qrcode.type === 'm' && <CheckCircleOutlineIcon />}
                                            {qrcode.type !== 'm' && <RadioButtonUncheckedIcon color="disabled" />}
                                            <Typography ml={1} style={{color: qrcode.type === 'm'?"black":"#8f8f8f"}}>
                                                {t('qrcode.type.mobile')}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Paper>
                    </Box>
                    <Box display="flex" justifyContent="center" m={1} mt={2} pb={2}>
                        <Button variant="contained"
                                color="primary"
                                size="large"
                                disableElevation
                                fullWidth={true}
                                disabled={updating}
                                startIcon={<SaveIcon/>}
                                onClick={(e) => handleSave(e)}>
                            {t('common.save')}
                        </Button>
                    </Box>
                </>
            }
        </div>
    )
}