import React, { useState, useRef, useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { createTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import SaveIcon from '@mui/icons-material/Save';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import L from 'leaflet';
import {MapContainer, TileLayer, Marker} from 'react-leaflet'
import "leaflet-editable";
import ReactLeafletEditable from "react-leaflet-editable";
import {httpClient} from "../../../core/HttpClient";
import {Context} from "../../../core/Context";

import Card from "@mui/material/Card";
import {ListItem, Switch as SubSwitch} from "@mui/material";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import Collapse from "@mui/material/Collapse";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ListItemText from "@mui/material/ListItemText";
import NumberFormat from "react-number-format";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Dialog from "@mui/material/Dialog";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {ClockPicker} from "@mui/x-date-pickers/ClockPicker";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import dateFormat from "dateformat";

import 'leaflet/dist/leaflet.css'
import {isDesktop} from "react-device-detect";
import {Link, useHistory, useLocation} from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import {area, polygon, kinks} from "@turf/turf";
import ButtonGroup from "@mui/material/ButtonGroup";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const markerIcon = new L.Icon({
    iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark.png`,
    iconRetinaUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/fkmark2.png`,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: [30, 44],
    iconAnchor: [15, 44],
    className: 'foodkub-leaflet-div-icon'
});

export { markerIcon };

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: isDesktop
};

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
}));

const theme = createTheme({
    palette: {
        primary: {
            main: '#00B300',
            dark: '#00C900',
            contrastText: '#ffffff',
        },
    },
});

const MemoizedMapContainer = React.memo(({setMap, map, deliveryAreas, maxAreas, editRef, position, zoom, onMapChanged})=>{
    console.log('MyMapContainer');

    useEffect(() => {
        console.log('[MyMapContainer]');
        if(map){
            if(deliveryAreas && deliveryAreas[0]){
                let line2 = L.polygon(deliveryAreas[0]).addTo(map);
                line2.enableEdit();
            }
        }
    }, [deliveryAreas, map]);

    const handleEnable = (e) => {
        e.layer.setStyle({color: '#1abc9c'});
    };

    const handleDrawingMove = (e) => {
        let deliveryArea = [];
        if(e.vertex.latlngs){
            e.vertex.latlngs.forEach(latLng=>{
                deliveryArea.push([latLng.lat, latLng.lng]);
            });
        }

        let sm = area(e.layer.toGeoJSON());
        let skm = Math.floor(sm/1000000 * 100) / 100;

        let _areas = [];
        deliveryArea.forEach(e=>{
            _areas.push([e[1],e[0]]);
        });
        _areas.push(_areas[0]);
        let _polygon = polygon([_areas]);
        let _kinks = kinks(_polygon);

        if(skm > maxAreas || _kinks.features.length > 0){
            e.layer.setStyle({color: '#ff0000'});
        } else {
            e.layer.setStyle({color: '#1abc9c'});
        }

    };

    const handleVertexDeleted = (e) => {
        console.log('handleVertexDeleted');
        let deliveryArea = [];
        if(e.vertex.latlngs){
            e.vertex.latlngs.forEach(latLng=>{
                deliveryArea.push([latLng.lat, latLng.lng]);
            });
        }

        let _areas = [];
        deliveryAreas.forEach(e=>{
            _areas.push([e[1],e[0]]);
        });
        _areas.push(_areas[0]);
        let _polygon = polygon([_areas]);
        let _kinks = kinks(_polygon);

        let sm = area(e.layer.toGeoJSON());
        let skm = Math.floor(sm/1000000 * 100) / 100;

        onMapChanged(deliveryArea, skm, _kinks.features.length);
    };

    const handleVertexMarkerDragEnd = (e) => {
        console.log('handleVertexMarkerDragEnd');
        let deliveryAreas = [];
        if(e.vertex.latlngs){
            e.vertex.latlngs.forEach(latLng=>{
                    deliveryAreas.push([latLng.lat, latLng.lng]);
            });
        }

        let _areas = [];
        deliveryAreas.forEach(e=>{
            _areas.push([e[1],e[0]]);
        });
        _areas.push(_areas[0]);
        let _polygon = polygon([_areas]);
        let _kinks = kinks(_polygon);

        let sm = area(e.layer.toGeoJSON());
        let skm = Math.floor(sm/1000000 * 100) / 100;
        onMapChanged(deliveryAreas, skm, _kinks.features.length);
    };

    return(
        <ReactLeafletEditable ref={editRef} map={map}
                              onEnable={handleEnable}
                              onDrawingMove={handleDrawingMove}
                              onVertexMarkerDragEnd={handleVertexMarkerDragEnd}
                              onVertexDeleted={handleVertexDeleted}>
            {position &&
                <MapContainer center={position}
                              zoom={zoom}
                              {...interactionOptions}
                              ref={setMap}
                              editable={true}
                              scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <LocationMarker effectOn={this} position={position} />
                </MapContainer>
            }
        </ReactLeafletEditable>
    );
});

const LocationMarker = (props) => {
    console.log('[LocationMarker]');
    const [position] = useState(props.position);
    const markerRef = useRef(null);

    return position === null ? null : (
        <>
            <Marker icon={markerIcon} position={position} ref={markerRef} ></Marker>
        </>
    )
};

let TIME_INDEX = -1;

export default function Delivery() {
    const { t } = useTranslation();
    const search = useLocation().search;
    const orderingType = new URLSearchParams(search).get('orderingType');
    const classes = useStyles();
    const history = useHistory();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(true);
    const [context, setContext] = useContext(Context);
    const [account, setAccount] = useState({zoom: 15,
        deliveryStartPrice: 0,
        deliveryAdditionalPrice: 0,
        deliveryStartAt: 1,
        deliveryAdditionalAt: 1,
        delivery: false,
        pickup: false,
        orderingAcceptation: 'accept',
        deliveryType: 'immediately',
        deliveryDays: [],
        deliveryTimes: [],
        deliveryAreasSize: 0
    });
    const [deliveryPrices, setDeliveryPrices] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [time, setTime] = useState(new Date(new Date().setHours(0, 0)));
    const [errors, setErrors] = useState({times: false});
    const [deliveryTimesError, setDeliveryTimesError] = useState(false);
    const [position, setPosition] = useState(null);
    const [deliveryAreas, setDeliveryAreas] = useState([]);
    const [maxAreas, setMaxAreas] = useState(0);
    const [map, setMap] = useState(null);
    const editRef = useRef();

    console.log('Deliver');

    useEffect(() => {
        console.log('[Delivery]');
        const fields = "lat,lng,zoom,deliveryStartPrice,deliveryAdditionalPrice,deliveryStartAt,deliveryAdditionalAt," +
            "delivery,pickup,orderingAcceptation,deliveryType,deliveryDays,deliveryTimes,deliveryAreas,deliveryAreasSize";
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me?fields='+fields;
        let tasks = [
            httpClient.get(url),
            httpClient.get(process.env.REACT_APP_API_BASE_URL + '/accounts/me/limited')
        ];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setAccount(rs[0].data);
                    let _distancePrices = [];
                    setPosition([rs[0].data.lat, rs[0].data.lng]);
                    setDeliveryAreas(rs[0].data.deliveryAreas);
                    setDeliveryPrices(_distancePrices);
                } else {
                    setPosition([13.721163782, 100.523664572]); // Default Bangkok
                }

                if(rs[1] && rs[1].data){
                    setMaxAreas(rs[1].data.areas);
                }
            });
    }, []);

    const handleSave = () => {
        if(account.deliveryType === 'round'){
            if(account.deliveryTimes.length === 0){
                setContext({ ...context, fail: true});
                setErrors({...errors, times: true});
                return;
            }
        }

        let _data = {
            _id: account._id,
            orderingType: orderingType,
            delivery: account.delivery,
            orderingAcceptation: account.orderingAcceptation,
            deliveryType: account.deliveryType,
            deliveryDays: account.deliveryDays,
            deliveryTimes: account.deliveryTimes,
            deliveryAreas: account.deliveryAreas,
            deliveryAreasSize: account.deliveryAreasSize,
            deliveryPrices: deliveryPrices,
            deliveryStartPrice: account.deliveryStartPrice,
            deliveryAdditionalPrice: account.deliveryAdditionalPrice,
            deliveryStartAt: account.deliveryStartAt,
            deliveryAdditionalAt: account.deliveryAdditionalAt,
            deliverPriceCalculation: account.deliverPriceCalculation
        };

        if(map){
            let zoom = map.getZoom();
            _data.zoom = zoom;
        }

        setLoading(true);
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/' + account._id+'/delivery';
        httpClient.post(url, _data)
            .then(res => {
                setLoading(false);
                if(res.status === 200){
                    setContext({ ...context, success: true});
                    history.push('/deliver');
                } else {
                    setContext({ ...context, fail: true});
                }

            });
    };

    const handleMapChange = (deliveryArea, skm, kinks) => {
        console.log('skm = ', skm);
        console.log('kinks = ', kinks);
        setAccount({...account, deliveryAreas: [deliveryArea], deliveryAreasSize: skm});
    };

    const handleChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.value;
        setAccount({...account, ...fieldValue});
    };

    const handleNumberChange = ({ target }) => {
        if(target.value){
            let found = target.value.search(/^(\d*\.?\d*)$/);
            if(found > -1) {
                let fieldValue = {};
                fieldValue[target.name] = target.value;
                setAccount({...account, ...fieldValue});
            }
        }
    };

    const handleSwitchChange = (e) => {
        let fieldValue = {};
        fieldValue[e.target.name] = e.target.checked;
        setAccount({...account, ...fieldValue});
    };

    const handleDeliveryStart = (value) => {
        let _value = (account.deliveryStartAt || 0) + value;
        if(_value > 0){
            let _additionalAt = account.deliveryAdditionalAt += value;
            setAccount({...account, deliveryStartAt: _value, deliveryAdditionalAt: _additionalAt});
        }
    };

    const handleDeliveryAdditional = (value) => {
        let _value = (account.deliveryAdditionalAt || 0) + value;
        if(_value > account.deliveryStartAt){
            setAccount({...account, deliveryAdditionalAt: _value});
        }
    };

    const handleAddRound = (e) => {
        console.log('handleAddRound');
        const data = {hour: time.getHours(), minute: time.getMinutes()};
        // validate duplicate
        let duplicateResult = false;
        let duplicateIndex = -1;
        let _hourStr = ''+data.hour;
        let _minuteStr = ''+data.minute;
        account.deliveryTimes.forEach((dt,index)=>{
            if(''+dt.hour === _hourStr && ''+dt.minute === _minuteStr){
                duplicateResult = true;
                duplicateIndex = index;
            }
        });
        if(TIME_INDEX < 0){
            // add
            if(duplicateResult){
                setDeliveryTimesError(true);
            } else {
                account.deliveryTimes.push(data);
            }

        } else {
            // edit
            if(duplicateResult && TIME_INDEX !== duplicateIndex){
                setDeliveryTimesError(true);
            } else {
                account.deliveryTimes[TIME_INDEX] = data;
            }

        }
        let _deliveryTimes = account.deliveryTimes.sort((a, b) => ((parseInt(a.hour)*60)+parseInt(a.minute)) > ((parseInt(b.hour)*60)+parseInt(b.minute)) ? 1 : -1);
        setAccount({...account, deliveryTimes: _deliveryTimes});
        setErrors({...errors, times: false});
        setOpen(false);
    };

    const handleWeekdays = (day) => {
        let index = account.deliveryDays.indexOf(day);
        if(index > -1){
            account.deliveryDays.splice(index, 1);
        } else {
            account.deliveryDays.push(day);
        }
        setAccount({ ...account });
    };

    const handleEditTime = (index) => {
        let _time = new Date(new Date().setHours(account.deliveryTimes[index].hour, account.deliveryTimes[index].minute));
        setTime(_time);
        TIME_INDEX = index;
        setOpen(true);
    };

    const handleDeleteTime = (index) => {
        account.deliveryTimes.splice(index, 1);
        setAccount({...account});
    };

    const handleOpen = () => {
        TIME_INDEX = -1;
        setTime(new Date(new Date().setHours(0, 0)));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDeliveryTimeError = () => {
        setDeliveryTimesError(false);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="/deliver" className={classes.link}>
                        <Typography variant="h6">
                            {t('delivery.title')}
                        </Typography>
                    </Link>
                </Breadcrumbs>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                <Card className={classes.root} variant="outlined">
                    <List className={classes.root}>
                        <ListItem key="cash">
                            <ListItemIcon>
                                <TwoWheelerIcon/>
                            </ListItemIcon>
                            <ListItemText id='delivery'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('deliver.delivery')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('deliver.deliveryDescription')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <SubSwitch
                                    checked={account.delivery}
                                    onChange={ (e) => handleSwitchChange(e) }
                                    color="primary"
                                    name="delivery"
                                    inputProps={{ 'aria-label': 'open' }}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                    <Collapse in={account.delivery}>
                        <Divider  style={{'marginRight': '20px', 'marginLeft': '20px'}} />
                        <Box m={2}>
                            <Box mt={2}>
                                <Box mb={2}>
                                    <Typography>
                                        {t('deliver.method')}
                                    </Typography>
                                </Box>
                                <FormControl variant="outlined"
                                             fullWidth={true}>
                                    <InputLabel id="delivery-type-label">{t('deliver.type')}</InputLabel>
                                    <Select
                                        native
                                        labelId="delivery-type-label"
                                        label={t('deliver.type')}
                                        name="deliveryType"
                                        value={''+account.deliveryType}
                                        onChange={handleChange}>
                                        <option key="auto_accept" value="immediately">
                                            {t('ordering.deliveryNow')}
                                        </option>
                                        <option key="auto_reject" value="round">
                                            {t('ordering.deliveryRound')}
                                        </option>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Collapse in={account.deliveryType === 'round'}>
                                <Box mt={2} m1={2}>
                                    <ButtonGroup disableElevation size="small" fullWidth aria-label="Business days">
                                        <Button color={account.deliveryDays.indexOf(1)>-1?'primary':'inherit'}
                                                onClick={(e) => handleWeekdays(1)}
                                                variant="contained">{t('common.weekdays.mon')}</Button>
                                        <Button color={account.deliveryDays.indexOf(2)>-1?'primary':'inherit'}
                                                onClick={(e) => handleWeekdays(2)}
                                                variant="contained">{t('common.weekdays.tue')}</Button>
                                        <Button color={account.deliveryDays.indexOf(3)>-1?'primary':'inherit'}
                                                onClick={(e) => handleWeekdays(3)}
                                                variant="contained">{t('common.weekdays.wed')}</Button>
                                        <Button color={account.deliveryDays.indexOf(4)>-1?'primary':'inherit'}
                                                onClick={(e) => handleWeekdays(4)}
                                                variant="contained">{t('common.weekdays.thu')}</Button>
                                        <Button color={account.deliveryDays.indexOf(5)>-1?'primary':'inherit'}
                                                onClick={(e) => handleWeekdays(5)}
                                                variant="contained">{t('common.weekdays.fri')}</Button>
                                        <Button color={account.deliveryDays.indexOf(6)>-1?'primary':'inherit'}
                                                onClick={(e) => handleWeekdays(6)}
                                                variant="contained">{t('common.weekdays.sat')}</Button>
                                        <Button color={account.deliveryDays.indexOf(0)>-1?'primary':'inherit'}
                                                onClick={(e) => handleWeekdays(0)}
                                                variant="contained">{t('common.weekdays.sun')}</Button>
                                    </ButtonGroup>
                                </Box>
                                <List>
                                    {account.deliveryTimes.map((item, index) => (
                                        <Box key={`times-${index}`}>
                                            <ListItem dense key={item.bank} role={undefined} onClick={()=>handleEditTime(index)}  sx={{ border: '1px solid gainsboro', marginTop: '10px' }}>
                                                <ListItemIcon sx={{minWidth: '35px'}}>
                                                    <AccessTimeIcon />
                                                </ListItemIcon>
                                                <ListItemText id={`times-${index}`}>
                                                    <Typography variant="h6" noWrap={true}>
                                                        <NumberFormat value={item.hour} displayType={'text'} format={(val)=>{return val<10?'0'+val:val}} />
                                                        <span>:</span>
                                                        <NumberFormat value={item.minute} displayType={'text'} format={(val)=>{return val<10?'0'+val:val}} />
                                                    </Typography>
                                                </ListItemText>
                                                {index !== 0 &&
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="add" onClick={(e) => handleDeleteTime(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        </Box>
                                    ))
                                    }
                                </List>
                                <Box mt={1}>
                                    {errors.times &&
                                        <Typography variant="caption" color="secondary">
                                            *{t('ordering.errorTimes')}
                                        </Typography>
                                    }
                                    <Button variant="outlined"
                                            disableElevation
                                            fullWidth={true}
                                            disabled={loading.update}
                                            style={{justifyContent: "center"}}
                                            onClick={() => handleOpen()}>
                                        <AddCircleOutlineIcon /><Box ml={1}>{t('ordering.addDeliveryRounds')}</Box>
                                    </Button>
                                </Box>
                            </Collapse>
                            <Box mt={2}>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography>
                                        {t('delivery.specify')}
                                    </Typography>
                                    <Typography variant={"caption"}>{account.deliveryAreasSize} {t('delivery.areaUnit')}</Typography>
                                </Box>
                                <Box mt={1} style={{height: `300px`}}>
                                    <MemoizedMapContainer
                                        map={map}
                                        setMap={setMap}
                                        deliveryAreas={deliveryAreas}
                                        maxAreas={maxAreas}
                                        editRef={editRef}
                                        position={position}
                                        zoom={account.zoom}
                                        onMapChanged={handleMapChange}
                                    />
                                </Box>
                                <Box mt={2}>
                                    <Typography id="continuous-slider">
                                        {t('delivery.startPrice')}
                                    </Typography>
                                    <Box key={`item-start`} mt={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <TextField
                                                key={`input-start`}
                                                label={t('delivery.deliverPriceStart')}
                                                variant="outlined"
                                                type={"tel"}
                                                name={'deliveryStartPrice'}
                                                value={account.deliveryStartPrice}
                                                onChange={handleNumberChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box mt={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <fieldset style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                                                paddingTop: '0px',
                                                borderRadius: '4px',
                                                fontSize: '12px',
                                                color: 'rgba(0, 0, 0, 0.6)'
                                            }}>
                                                <legend>{t('delivery.startPriceCalculate')}</legend>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <IconButton aria-label="decrement" onClick={e=>handleDeliveryStart(-1)}>
                                                        <IndeterminateCheckBoxIcon fontSize="large" />
                                                    </IconButton>
                                                    <Box>
                                                        <Typography variant={"h6"}>
                                                            {account.deliveryStartAt}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton aria-label="increment" onClick={e=>handleDeliveryStart(1)}>
                                                        <AddBoxIcon fontSize="large" />
                                                    </IconButton>
                                                </Box>
                                            </fieldset>
                                        </FormControl>
                                    </Box>
                                </Box>
                                <Box mt={2}>
                                    <Typography id="continuous-slider">
                                        {t('delivery.additionalPrice')}
                                    </Typography>
                                    <Box mt={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <TextField
                                                label={t('delivery.additionalPriceUnit')}
                                                variant="outlined"
                                                name={'deliveryAdditionalPrice'}
                                                type={"tel"}
                                                value={account.deliveryAdditionalPrice}
                                                onChange={handleNumberChange}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </FormControl>
                                    </Box>
                                    <Box mt={3}>
                                        <FormControl fullWidth variant="outlined">
                                            <fieldset style={{border: '1px solid rgba(0, 0, 0, 0.23)',
                                                paddingTop: '0px',
                                                borderRadius: '4px',
                                                fontSize: '12px',
                                                color: 'rgba(0, 0, 0, 0.6)'
                                            }}>
                                                <legend>{t('delivery.additionalPriceCalculate')}</legend>
                                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                                    <IconButton aria-label="decrement" onClick={e=>handleDeliveryAdditional(-1)}>
                                                        <IndeterminateCheckBoxIcon fontSize="large" />
                                                    </IconButton>
                                                    <Box>
                                                        <Typography variant={"h6"}>
                                                            {account.deliveryAdditionalAt}
                                                        </Typography>
                                                    </Box>
                                                    <IconButton aria-label="increment" onClick={e=>handleDeliveryAdditional(1)}>
                                                        <AddBoxIcon fontSize="large" />
                                                    </IconButton>
                                                </Box>
                                            </fieldset>
                                        </FormControl>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                </Card>
                <Box display="flex" justifyContent="center" mt={2} pb={2}>
                    <Button variant="contained"
                            color="primary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            disabled={loading.update}
                            startIcon={<SaveIcon/>}
                            onClick={(e) => handleSave(e)}>
                        {t('common.save')}
                    </Button>
                </Box>
            </Box>
            <Dialog
                onClose={ handleClose }
                maxWidth='xs'
                scroll="body"
                PaperProps={{
                    style: {
                        margin: 0,
                        maxWidth: 'none',
                    },
                }}
                open={ open }>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ClockPicker date={time} onChange={(newDate) => setTime(newDate)} />
                </LocalizationProvider>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button onClick={ handleAddRound } fullWidth color='primary' variant="outlined"
                                    size="large">
                                { dateFormat(time, "HH:MM")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={ handleClose } fullWidth variant="outlined"
                                    size="large">
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            <Snackbar open={deliveryTimesError}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={3000} onClose={handleCloseDeliveryTimeError}>
                <Alert onClose={handleCloseDeliveryTimeError} severity="warning">
                    {t('deliver.deliveryTimesDuplicate')}
                </Alert>
            </Snackbar>
        </div>
    )
}