import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';
import Card from "@mui/material/Card";
import { makeStyles } from '@mui/styles';
import {useHistory} from "react-router-dom";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import {httpClient} from "../../core/HttpClient";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default function Discounts() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [discount, setDiscount] = useState({
        delivery: 'none',
        food: 'none'
    });

    useEffect(() => {
        console.log('[Discounts]');
        const url = process.env.REACT_APP_API_BASE_URL + '/discounts';
        let tasks = [
            httpClient.get(url),
        ];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setDiscount(rs[0].data);
                }
            });
    }, []);

    const handleClick = (key) => () => {
        // window.open(process.env.REACT_APP_CLIENT_LIFF_URL +'/sm/'+account.cid);
        history.push('/discounts/'+key);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('discount.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                <Card className={classes.root} variant="outlined">
                    <List className={classes.root}>
                        <ListItem divider key="food" role={undefined} onClick={handleClick('food')}>
                            <ListItemIcon>
                                <FastfoodIcon />
                            </ListItemIcon>
                            <ListItemText id='cash'>
                                <Typography variant="h6">
                                    <div>
                                        {t('discount.food')}
                                    </div>
                                </Typography>
                            </ListItemText>
                            <ListItemSecondaryAction onClick={handleClick('food')}>
                                <FiberManualRecordIcon color={discount.food === 'none'?'disabled':'primary'} />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem key="delivery" role={undefined} onClick={handleClick('deliver')}>
                            <ListItemIcon>
                                <TwoWheelerIcon />
                            </ListItemIcon>
                            <ListItemText id='online'>
                                <Typography variant="h6">
                                    <div>
                                        {t('discount.delivery')}
                                    </div>
                                </Typography>
                            </ListItemText>
                            <ListItemSecondaryAction onClick={handleClick('deliver')}>
                                <FiberManualRecordIcon color={discount.delivery === 'none'?'disabled':'primary'} />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Card>
            </Box>
            <Box m={2}>
                <Typography variant={"caption"}>*{t('discount.remark')}</Typography>
            </Box>
        </div>
    )
}