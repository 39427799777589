import React, {useState, useEffect, useReducer} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import {DateI18n} from '../DateI18n';
import {TextI18n} from "../TextI18n";
import {httpClient} from "../../core/HttpClient";
import BankInfo from "../BankInfo";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InfiniteScroll from "react-infinite-scroll-component";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import {ListItemSecondaryAction} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from "@mui/material/IconButton";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import NumberFormat from "react-number-format";
import Divider from "@mui/material/Divider";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
    },
    card: {
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
    },
    tabs: {
        borderBottom: '1px solid #e8e8e8',

    },
    tabPanels: {
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    paper: {
        position: 'absolute',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        border: '0px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    noSelected: {
        color: "#8c8c8c"
    }
}));

function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasNext: action.payload.length > 0,
                nextCursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:null,
                hits: action.nextCursor === null?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
            };
        default:
            throw new Error()
    }
}

function TabPanel(props) {
    const {children, value, index, classes, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}

function InvoiceItem({item, t, onCopy}){
    return (<ListItem divider={true}>
        <ListItemText>
            <div>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                             {t('invoice.product')}
                        </span>
                    </Typography>
                    <Typography>
                        <strong><TextI18n value={item.pname}></TextI18n></strong>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                             {t('invoice.pcode')}
                        </span>
                    </Typography>
                    <Typography style={{color: '#7d7d7d', fontSize: '1rem'}}>
                        {item.pcode}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                             {t('invoice.accountTitle')}
                        </span>
                    </Typography>
                    <Typography style={{color: '#7d7d7d', fontSize: '1rem'}}>
                        <TextI18n value={item.aname}></TextI18n>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                             {t('invoice.ref1')}
                        </span>
                    </Typography>
                    <div>
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            {item.paymentInfo.billPaymentRef1}
                        </span>
                    </div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                             {t('invoice.qty')}
                        </span>
                    </Typography>
                    <div>
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            {item.qty} {t('invoice.unit')}
                        </span>
                    </div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                             {t('invoice.amount')}
                        </span>
                    </Typography>
                    <div>
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            <NumberFormat value={item.paymentInfo.amount } displayType={"text"} thousandSeparator={true} prefix={"฿"} />
                        </span>
                    </div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                            {t('invoice.payer')}
                        </span>
                    </Typography>
                    <div>
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            {item.paymentInfo.payerName}
                        </span>
                    </div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                            {t('invoice.bank')}
                        </span>
                    </Typography>
                    <div>
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            <BankInfo code={item.paymentInfo.sendingBankCode} />
                        </span>
                    </div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                            {t('invoice.date')}
                        </span>
                    </Typography>
                    <div>
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            <DateI18n value={item.paymentInfo.transactionDateandTime} format="DD MMM YYYY HH:mm" />
                        </span>
                    </div>
                </Box>
                <Box display="flex" justifyContent="space-between">
                    <Typography noWrap={true}>
                        <span>
                            {t('invoice.status')}
                        </span>
                    </Typography>
                    <div>
                        {item.state === 'progress' &&
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            {t('invoice.progress')}
                        </span>
                        }
                        {item.state === 'completed' &&
                        <span style={{color: '#7d7d7d', fontSize: '1rem'}}>
                            {(item.status === 'success')?t('common.success'):t('common.cancel')}
                        </span>
                        }
                    </div>
                </Box>
                {item.address &&
                    <Box border={1} borderRadius={2}
                         sx={{width: '100%'}} mb={1} mt={2}>
                        <List dense={true}>
                            <ListItem>
                                <ListItemText primary={t('invoice.address')} />
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    <Box>
                                        <Box>{t('invoice.customerTitle')} {item.address.name}</Box>
                                        <Box>{`${item.address.addressNumber} ${item.address.subDistrict['name_th']} ${item.address.district['name_th']}
                                            ${item.address.province['name_th']} ${item.address.postalCode}`}</Box>
                                        <Box>{t('invoice.customerTel')} {item.address.phone}</Box>
                                    </Box>
                                </ListItemText>
                            </ListItem>
                            {item.deliveryExpress && item.state === 'completed' &&
                                <>
                                    <Divider/>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar alt={item.deliveryExpress}
                                                    variant={"square"}
                                                    src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/express/${item.deliveryExpress}.png`}/>
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Box>{t('invoice.trackingNumber')}</Box>
                                            <Box>
                                                <Typography variant={"body1"} style={{wordBreak: 'break-all'}}>
                                                    {item.trackingNumber}
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant={"caption"} style={{color: '#7d7d7d'}}>
                                                    <DateI18n value={item.deliveryDate} format="DD MMM YYYY HH:mm"/>
                                                </Typography>
                                            </Box>
                                        </ListItemText>
                                        <ListItemSecondaryAction>
                                            <CopyToClipboard key={item.bank} text={item.trackingNumber || ''}
                                                             onCopy={e => onCopy()}>
                                                <IconButton edge="end" aria-label="copy">
                                                    <ContentCopyIcon/>
                                                </IconButton>
                                            </CopyToClipboard>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </>
                            }
                        </List>
                    </Box>
                }
            </div>
        </ListItemText>
    </ListItem>);
}

async function fetchHits(nextCursor, dispatch, cancelToken) {
    dispatch({ type: "FETCH_START" });
    try {
        const url = process.env.REACT_APP_API_BASE_URL + '/invoices/complete';
        const result = await httpClient(
            `${url}?c=${nextCursor}`,
            {
                cancelToken,
            }
        );

        dispatch({ type: "FETCH_SUCCESS", payload: result.data, nextCursor: nextCursor })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

function getCounter(results){
    if(!results) return 0;

    let count = 0;
    results.forEach(result=>{
        count += result.items.length;
    });
    return count;
}

export default function Invoice() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [tabIndex, setTabIndex] = useState(1);
    const [copied, setCopied] = useState(false);
    let [progressInvoices, setProgressInvoices] = useState([]);
    const [{ hits, hasNext, isLoading, nextCursor }, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        nextCursor: null
    });
    const fetchNext = (cursor)=>{
        console.log('cursor', cursor);
        const { token } = httpClient.CancelToken.source();
        fetchHits(cursor, dispatch, token);
    };

    // const debouncedChangeHandler = useCallback(
    //     _.debounce((e) => {
    //         console.log('eventHandler',e.target.value);
    //     }, 800)
    //     , []);

    useEffect(() => {
        console.log('[Invoice]');
        const url = process.env.REACT_APP_API_BASE_URL + '/invoices/progress';
        let tasks = [httpClient.get(url)];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    setProgressInvoices(rs[0].data);
                    if(rs[0].data.length > 0){
                        setTabIndex(0);
                    }
                }
            });

        const { token } = httpClient.CancelToken.source();
        fetchHits(null, dispatch, token);
        // return () => {
        //     debouncedChangeHandler.cancel();
        // }
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleFetchData = (cursor) =>{
        console.log('handleFetchData', cursor);
        fetchNext(cursor);
    };

    const handleCopy = () => {
        setCopied(true);
    };

    const handleCloseCopied = () => {
        setCopied(false);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" alignItems="center" m={1} mb={0}>
                <Typography variant="h6">
                    {t('invoice.title')}
                </Typography>
            </Box>
            {/*<TextField*/}
            {/*    onChange={debouncedChangeHandler}*/}
            {/*    placeholder="Type a query..."*/}
            {/*/>*/}
            <Box>
                <form className={classes.root} noValidate autoComplete="off">
                    <Tabs value={tabIndex}
                          className={classes.tabs}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          onChange={handleTabChange}
                          aria-label="info tab">
                        <Tab label={
                            <Box display={"flex"}>
                                <Typography>{t('invoice.progress')}</Typography>
                                {!isLoading && progressInvoices.length > 0 &&
                                    <Box ml={1}><Typography>({getCounter(progressInvoices)})</Typography></Box>
                                }
                            </Box>
                        } />
                        <Tab label={t('invoice.complete')} />
                    </Tabs>
                    <TabPanel className={classes.tabPanels} value={tabIndex} index={0}>
                        {!isLoading && progressInvoices.length === 0 &&
                            <Box display="flex" justifyContent="center" p={2} m={2} mt={4}>
                                <Typography>
                                    {t('common.notFound')}
                                </Typography>
                            </Box>
                        }
                        {progressInvoices.length > 0 &&
                            <Box style={{background: 'white'}}>
                                <List className={classes.root} subheader={<li/>}>
                                    {progressInvoices.map((hit) => (
                                        hit.items.map((item) => (
                                            <InvoiceItem key={`invoice-progress-${item._id}`} item={item} t={t} onCopy={handleCopy} />
                                        ))
                                    ))}
                                </List>
                            </Box>
                        }
                        {isLoading &&
                            <Box display="flex" justifyContent="center" mt={4}>
                                <CircularProgress size={20}/>
                            </Box>
                        }
                    </TabPanel>

                    <TabPanel className={classes.tabPanels} value={tabIndex} index={1}>
                        {!isLoading && hits.length === 0 &&
                            <Box display="flex" justifyContent="center" p={2} m={2} mt={4}>
                                <Typography>
                                    {t('common.notFound')}
                                </Typography>
                            </Box>
                        }
                        {hits.length > 0 &&
                            <InfiniteScroll
                                dataLength={hits.length} //This is important field to render the next data
                                next={e=>handleFetchData(nextCursor)}
                                hasMore={hasNext}
                            >
                                <Box style={{background: 'white'}}>
                                    <List className={classes.root} subheader={<li/>}>
                                        {hits.map((hit) => (
                                            hit.items.map((item) => (
                                                <InvoiceItem key={`invoice-complete-${item._id}`} item={item} t={t} onCopy={handleCopy} />
                                            ))
                                        ))}
                                    </List>
                                </Box>
                            </InfiniteScroll>
                        }
                        {isLoading &&
                            <Box display="flex" justifyContent="center" mt={4}>
                                <CircularProgress size={20}/>
                            </Box>
                        }
                    </TabPanel>
                </form>
            </Box>
            <Snackbar open={copied}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={1500} onClose={handleCloseCopied}>
                <Alert icon={<ContentCopyIcon />} variant="filled" onClose={handleCloseCopied} severity="info">
                    {t('invoice.copyTrackingNumber')}
                </Alert>
            </Snackbar>
        </div>
    )
}