import React, {useState, useEffect, useContext, useMemo} from 'react';
import { useTranslation } from "react-i18next";
import {Link, useHistory} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import AddIcon from '@mui/icons-material/Add';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates, useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {httpClient} from "../../core/HttpClient";
import {TextI18n} from "../TextI18n";

import './style.css';
import Card from "@mui/material/Card";
import {ListItemButton} from "@mui/material";
import {Context} from "../../core/Context";
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToParentElement} from "@dnd-kit/modifiers";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging
    } = useSortable({id: props.id});

    const style = {
        position: "relative",
        zIndex: isDragging ? 1 : undefined,
        transform: CSS.Transform.toString(transform),
    };

    return (
        <div ref={setNodeRef} style={style}>
            <ListItemButton divider={props.divider} onClick={e=>props.onClick(props.value)}  >
                <ListItemText
                    primary={
                        <Typography variant="h6" noWrap={true}>
                                <span style={{fontSize: '1.2rem'}}>
                                    <TextI18n value={props.value.name}></TextI18n>
                                </span>
                        </Typography>
                    }/>
                <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="move" style={{touchAction: "none"}} {...attributes} {...listeners} ><OpenWithIcon style={{cursor: 'grab'}}></OpenWithIcon></IconButton>
                </ListItemSecondaryAction>
            </ListItemButton>
        </div>
    );
}

export default function ProductGroups() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [productGroups, setProductGroups] = useState([]);
    const [context] = useContext(Context);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );
    const itemIds = useMemo(() => productGroups.map((item) => item._id), [productGroups]);

    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {

            const oldIndex = productGroups.findIndex(item => item._id === active.id);
            const newIndex = productGroups.findIndex(item => item._id === over.id);
            let newValue = arrayMove(productGroups, oldIndex, newIndex);
            setProductGroups(newValue);

            const url = process.env.REACT_APP_API_BASE_URL + '/groups/sort';
            httpClient.put(url, newValue)
                .then(res => {
                    // done
                });
        }
    }

    function handleClick(value){
        history.push('/category/' + value._id);
    }

    useEffect(() => {
        console.log('[ProductGroups]');
        const url = process.env.REACT_APP_API_BASE_URL + '/groups';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setProductGroups(res.data)
                }
                setLoading(false);
            });
    }, []);

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {context.accountType==='RESTAURANT'?t('productCategory.title'):t('productCategory.shopTitle')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {loading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading &&
                <Box m={1}>
                    <Box display="flex" justifyContent="center" pb={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth={true}
                            disableElevation
                            startIcon={<AddIcon/>}
                            component={Link}
                            to="/category">
                            {t('common.add')}
                        </Button>
                    </Box>
                    {!loading && productGroups.length === 0 &&
                        <Box display="flex" justifyContent="center" p={2} m={2}>
                            <Typography>
                                {t('common.notFound')}
                            </Typography>
                        </Box>
                    }
                    {productGroups.length > 0 &&
                    <Card variant="outlined">
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            modifiers={[restrictToParentElement]}
                            onDragEnd={handleDragEnd}
                        >
                            <SortableContext
                                items={itemIds}
                                strategy={verticalListSortingStrategy}
                            >
                                <List className="SortableList" >
                                {productGroups.map((value, index) => <SortableItem key={`item-${index}`}
                                                                                   id={value._id}
                                                                                   divider={index !== productGroups.length-1}
                                                                                   onClick={handleClick}
                                                                                   index={index} value={value} />)}
                                </List>
                            </SortableContext>
                        </DndContext>
                    </Card>
                    }
                </Box>
            }
        </div>
    )
}