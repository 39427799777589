import React from 'react';
import { useTranslation } from "react-i18next";
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NumberFormat from 'react-number-format';
import {TextI18n} from "../TextI18n";

const shapeStyles = { border: '1px solid grey', padding: '0 8px 0 8px', fontSize: '0.8rem' };

const MyProductItemOptional = ((optional, i) => {
    let productOptions = [];
    if(optional && optional.options){
        optional.options.forEach(option=>{
            productOptions.push(option);
        });
    }

    return (
        <Box key={i} mb={1} ml={2} mr={2}>
            {productOptions.map((opt, j) => (
            <Box key={j}>
                {opt.checkedItems && opt.checkedItems.map((checkedItem, k) => (
                    <Box key={`${i}_${j}_${k}`}
                         display="flex"
                         alignItems="center"
                         justifyContent="space-between">
                        <Box display="flex">
                            <CheckBoxIcon style={{'fontSize': '1.2rem', 'marginRight': '3px'}}/>
                            <Typography color="textSecondary" style={{fontSize: '0.85rem'}}>
                                <TextI18n value={checkedItem.name}></TextI18n>
                            </Typography>
                        </Box>
                        {checkedItem.price > 0 &&
                            <Typography color="textSecondary">
                                <NumberFormat value={checkedItem.price} thousandSeparator={true} displayType={'text'} prefix={"฿"}></NumberFormat>
                            </Typography>
                        }
                    </Box>
                ))}
            </Box>
            ))}
        </Box>
    )
});

const MyProductItem = (({item,v, i, t}) => {
    return (
        <Box key={`key-${i}-${i}`}>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"} ml={2} mr={2} >
                <Typography component={'span'} variant="body1"  style={{wordWrap: 'break-word'}} fontWeight="fontWeightBold">
                    <TextI18n value={item.product.name} ></TextI18n>
                </Typography>
                <Box display={"flex"} justifyContent={"flex-end"} style={{minWidth: '80px'}}>
                    <Typography variant="subtitle2">
                        <NumberFormat value={item.price * item.qty} displayType={'text'} thousandSeparator={true} prefix={'฿'} />
                    </Typography>
                </Box>
            </Box>
            {item.optional &&
            MyProductItemOptional(item.optional, i)
            }
            {item.remark &&
            <Box style={{wordWrap: 'break-word'}} ml={2} mr={2}>
                <Typography variant="body1" color="textSecondary" sx={{fontSize: '0.85rem', color: 'red'}}>* {item.remark}</Typography>
            </Box>
            }
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} ml={2} mr={2}>
                <div style={{textAlign: 'right'}}>{t('order.qty')}</div>
                <div>
                    <Box sx={shapeStyles}>x{item.qty}</Box>
                    {/*<Typography variant="subtitle2" sx={{'marginLeft': '10px'}}>*/}
                    {/*    <NumberFormat value={item.price * item.qty} displayType={'text'} thousandSeparator={true} prefix={'฿'} />*/}
                    {/*</Typography>*/}
                </div>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
        </Box>
    )
});

export default function OrderItems({value}) {
    const { t } = useTranslation();

    return (
        <Card>
            <CardHeader style={{paddingBottom: '0', textAlign: 'center'}} title={<Typography variant="h6">
                {t('order.foodOrders')}
            </Typography>} />
            {value &&
            <>
                {value.foodOrders.map((fo, i) => (
                    <Box key={i}>
                        {fo.items.map((item, j) => (
                            <MyProductItem key={`item-${i}-${j}`} item={item} v={value._id} i={j} t={t} />
                        ))}
                    </Box>
                ))}

            </>
            }

            {value.remark &&
                <Box m={2} mt={2} p={2} style={{border: '1px solid red'}}>
                    <Typography variant="body1" style={{color: 'red'}}>
                        {value.remark}
                    </Typography>
                </Box>
            }
            <CardContent>
                <Box display="flex" justifyContent="space-between" alignItems="center"
                     style={{width: '100%'}}>
                    <div>{t('order.total')}</div>
                    <div><NumberFormat value={value.total} displayType={'text'}
                                       thousandSeparator={true} prefix={'฿'}/></div>
                </Box>
                {value.deliverType === 'DELIVERY'&&
                    <>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <Box display="flex" justifyContent="start" alignItems="center">
                                <Box mr={1}>{t('order.delivery')}</Box>
                                <Typography variant="subtitle2" color="textSecondary">({value.deliveryDistance} {t('order.distanceUnit')})</Typography>
                            </Box>
                            <div><NumberFormat value={value.deliveryPrice} displayType={'text'}
                                               thousandSeparator={true} prefix={'฿'}/></div>
                        </Box>
                    </>
                }
                {value.discount && value.discount.total === 0 &&
                    <>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <div>
                                <span>{t('discount.title')}</span>
                            </div>
                            <div><NumberFormat value={0} displayType={'text'}
                                               thousandSeparator={true} prefix={'฿'}/></div>
                        </Box>
                    </>
                }
                {value.additionalPrice > 0 &&
                    <>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%'}}>
                            <Box display="flex" justifyContent="start" alignItems="center">
                                <Box mr={1}>{t('order.additionalPrice')}</Box>
                            </Box>
                            <div><NumberFormat value={value.additionalPrice} displayType={'text'}
                                               thousandSeparator={true} prefix={'฿'}/></div>
                        </Box>
                    </>
                }
                {value.discount && value.discount.food > 0 &&
                    <>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%', color: 'green'}}>
                            <Box display="flex" justifyContent="start" alignItems="center">
                                <Box mr={1}>{t('discount.food')}</Box>
                            </Box>
                            <div><NumberFormat value={value.discount.food} displayType={'text'}
                                               thousandSeparator={true} prefix={'-฿'}/></div>
                        </Box>
                    </>
                }
                {value.discount && value.discount.delivery > 0 &&
                    <>
                        <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                        <Box display="flex" justifyContent="space-between" alignItems="center"
                             style={{width: '100%', color: 'green'}}>
                            <Box display="flex" justifyContent="start" alignItems="center">
                                <Box mr={1}>{t('discount.delivery')}</Box>
                            </Box>
                            <div><NumberFormat value={value.discount.delivery} displayType={'text'}
                                               thousandSeparator={true} prefix={'-฿'}/></div>
                        </Box>
                    </>
                }
                <Divider style={{'marginBottom': '5px', 'marginTop': '5px', height: '0'}} />
                <Box display="flex" justifyContent="space-between" alignItems="center"
                     fontWeight="fontWeightBold"
                     style={{width: '100%'}}>
                    <div>{t('common.grandTotal')}</div>
                    <div><NumberFormat value={value.grandTotal} displayType={'text'}
                                       thousandSeparator={true} prefix={'฿'}/></div>
                </Box>
            </CardContent>
        </Card>
    );
}
