import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import {DateI18n} from "../DateI18n";
import {httpClient} from "../../core/HttpClient";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function Notifications() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        console.log('[Notifications]');
        const url = process.env.REACT_APP_API_BASE_URL + '/notifications';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setNotifications(res.data);
                }
            });
    }, []);

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('notification.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
            <Card>
                {notifications.length === 0 &&
                <Box display="flex" justifyContent="center" p={2} m={2}>
                    <Typography>
                        {t('common.notFound')}
                    </Typography>
                </Box>
                }
                <List className={classes.root}>
                    {notifications.map((value, index) => (
                    <ListItem key={value._id} divider={index !== notifications.length - 1} button component={Link} to={`/notifications/${value._id}`}>
                        <ListItemAvatar>
                            <Avatar
                                alt={value.rider}
                                src={`${process.env.REACT_APP_CDN_BASE_URL}/public/rider/${value.rider}`}
                            />
                        </ListItemAvatar>
                        <ListItemText primary={t('riders.register')} secondary={<DateI18n value={value.created} format="DD MMM YYYY HH:mm" />} />
                    </ListItem>
                    ))}
                </List>
            </Card>
            </Box>
        </div>
    )
}