import React, {useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import ReactMarkdown from "react-markdown";

export default function Terms() {
    const [content, setContent] = useState("");

    useEffect(() => {

        let url = `${process.env.REACT_APP_BASE_URL}/terms.md`;
        fetch(url)
            .then((res) => res.text())
            .then((text) => setContent(text));
    }, []);

    return(
        <Box>
            <ReactMarkdown children={content} />
        </Box>
    )
}