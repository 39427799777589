import React, {useState, useEffect, useCallback, useMemo} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {isDesktop} from 'react-device-detect';
import Slide from '@mui/material/Slide';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageGallery from 'react-image-gallery';
import arrayMove from 'array-move';
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from "@dnd-kit/core";
import {restrictToParentElement} from "@dnd-kit/modifiers";
import {
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {DateI18n} from "../DateI18n";
import {httpClient} from '../../core/HttpClient'

import './style.css';
import "react-image-gallery/styles/css/image-gallery.css";
import {TransitionProps} from "@mui/material/transitions";
import Cropper from "react-easy-crop";
import Grid from "@mui/material/Grid";
import getCroppedImg from "../../core/CropImage";
import Divider from "@mui/material/Divider";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inputBrowse: {
        display: 'none',
    },
    square: {
        width: '50px',
        height: '50px',
    },
    avatar: {
        backgroundColor: '#fff'
    },
    rounded: {
        color: '#fff',
        backgroundColor: '#999'
    },
}));


function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        isDragging,
    } = useSortable({id: props.id});

    const style = {
        position: "relative",
        zIndex: isDragging ? 1 : undefined,
        transform: CSS.Transform.toString(transform),
        backgroundColor: 'white'
    };

    return (<div ref={setNodeRef} style={style}>
                <ListItem divider={props.divider}>
                <ListItemAvatar>
                    <img src={`${process.env.REACT_APP_CDN_BASE_URL}/${props.value.permission}/merchant/m/${props.value.name}`}
                         alt={props.value.originName}
                         style={{'width': '100px'}}/>
                </ListItemAvatar>
                <ListItemText>
                    <Box ml={2} mr={2}>
                        <Typography variant="body1" noWrap={true}>
                            <DateI18n value={props.value.created} format="DD MMM YYYY HH:mm"/>
                        </Typography>
                    </Box>
                </ListItemText>
                <ListItemSecondaryAction>
                    <IconButton edge="start" aria-label="move" style={{touchAction: "none"}} {...attributes} {...listeners}><OpenWithIcon></OpenWithIcon></IconButton>
                    <IconButton edge="end" aria-label="delete"
                                onClick={() => props.onRemove(props.sortIndex)}>
                        <DeleteIcon/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        </div>);
}

const MySortableList = ({items, onRemove}) => {
    const itemIds = useMemo(() => items.map((item) => item.name), [items]);
    return (
        <SortableContext
            items={itemIds}
            strategy={verticalListSortingStrategy}
        >
            <List>
                {items.map((value, index) => (
                    <SortableItem key={`item-${value.name}`}
                                  id={value.name}
                                  index={index}
                                  value={value}
                                  divider={index < items.length-1}
                                  onRemove={ onRemove }
                                  sortIndex={index} />
                ))}
            </List>
        </SortableContext>
    );
};

export function ImagesUploadSlideShow({name, value, max, label, onChange}) {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [imagesView, setImagesView] = useState([]);
    const [confirm, setConfirm] = React.useState({open: false});
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropDialog, setCropDialog] = useState(false);
    const [cropImage, setCropImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        console.log('[ImagesUploadSlideShow]');
        setImages(value);
        if(value && value.length>0){
            let views = [];
            value.forEach(v=>{
                views.push({
                    original: `${process.env.REACT_APP_CDN_BASE_URL}/${v.permission}/merchant/o/${v.name}`,
                    originalClass: 'my-image-gallery-image'
                });
            });
            setImagesView(views);
        }
    }, [value]);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                cropImage,
                croppedAreaPixels,
                0
            )
            setCropDialog(false);
            setLoading(true);
            const data = new FormData();
            data.append('file', croppedImage);
            const url = process.env.REACT_APP_API_BASE_URL + '/upload/image';
            httpClient.post(url, data)
                .then(res => {
                    if(res.data){
                        const newList = images.concat(res.data);
                        setImages(newList);
                        let myTarget = {
                            target: {
                                name: name,
                                value: newList
                            }
                        };
                        onChange(myTarget);
                    }
                    setLoading(false);
                });
        } catch (e) {
            console.error(e);
        }
    }, [images, cropImage, name, croppedAreaPixels, onChange]);

    const handleUploadSlide = ({ target }) => {
        if(target.files[0]){
            const reader = new FileReader();
            reader.onloadend = () => {
                setCropImage(reader.result);
            };
            reader.readAsDataURL(target.files[0]);
            target.value = ''; // fix same file select
            setCropDialog(true);
        }
    };

    const onRemove = (index) => {
        setConfirm({open: true, index: index});
    };

    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {
            const oldIndex = images.findIndex(item => item.name === active.id);
            const newIndex = images.findIndex(item => item.name === over.id);
            let newList = arrayMove(images, oldIndex, newIndex);
            setImages(newList);
            let myTarget = {
                target: {
                    name: 'images',
                    value: newList
                }
            };
            onChange(myTarget);
        }
    }

    const handleRemove = () => {
        let index = confirm.index;
        images.splice(index, 1);
        let newList = [].concat(images);
        setImages(newList);
        setConfirm({open: false});
        let myTarget = {
            target: {
                name: 'images',
                value: newList
            }
        };
        onChange(myTarget);
    };

    const handleClose = () => {
        setConfirm({open: false});
    };

    const handleCropClose = () =>{
        setCropDialog(false);
    };

    return(
        <div>
            {!loading && images.length === 0 &&
                <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} alt={"profile"}  style={{width: '100%'}} />
            }
            {images.length > 0 &&
            <>
                <ImageGallery items={imagesView}
                              infinite={false}
                              showNav={isDesktop && imagesView.length > 1}
                              showThumbnails={false}
                              showFullscreenButton={false}
                              useBrowserFullscreen={false}
                              showBullets={imagesView.length > 1}
                              showPlayButton={false} />
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    modifiers={[restrictToParentElement]}
                    onDragEnd={handleDragEnd}
                >
                    <MySortableList items={images}
                                    onRemove={onRemove} />
                </DndContext>
            </>
            }
            {loading &&
            <Box display="flex" justifyContent="center" mt={4} mb={4}>
                <CircularProgress size={20}/>
            </Box>
            }
            {!loading && imagesView.length < max &&
            <Box display="flex" justifyContent="center" mt={2}>
                <input
                    accept="image/*"
                    className={classes.inputBrowse}
                    id="contained-button-file-slide-show"
                    name="contained-button-file-slide-show"
                    onChange={handleUploadSlide}
                    type="file"
                />
                <label htmlFor="contained-button-file-slide-show" style={{width: '100%'}}>
                    <Button
                        variant="outlined"
                        disableElevation
                        color="default"
                        component="span"
                        fullWidth={true}
                        size={"large"}
                        startIcon={<CloudUploadIcon/>}>
                        {label}
                    </Button>
                </label>
            </Box>
            }
            <Divider />
            <Dialog
                open={confirm.open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{t('common.message.confirm')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {t('common.message.confirmDelete')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                            onClick={handleClose}
                            fullWidth={matches}
                            size="large"
                            color="grey"
                            disableElevation>
                        {t('common.cancel')}
                    </Button>
                    <Button variant="contained"
                            color="secondary"
                            size="large"
                            disableElevation
                            fullWidth={matches}
                            onClick={ handleRemove }
                            startIcon={<DeleteIcon />}>
                        {t('common.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
            {cropDialog &&
                <Dialog
                    onClose={ handleCropClose }
                    fullScreen={true}
                    open={ cropDialog }>
                    <DialogContent>
                        <Cropper
                            image={cropImage}
                            crop={crop}
                            zoom={zoom}
                            aspect={1.91/1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button color='primary'
                                        variant="contained"
                                        onClick={showCroppedImage}
                                        fullWidth={true}
                                        size="large"
                                        disableElevation>
                                    {t('common.ok')}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained"
                                        onClick={handleCropClose}
                                        fullWidth={true}
                                        size="large"
                                        color={"grey"}
                                        disableElevation>
                                    {t('common.cancel')}
                                </Button>
                            </Grid>
                        </Grid>


                    </DialogActions>
                </Dialog>
            }
        </div>
    )
};