import React from 'react';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ReceiptIcon from '@mui/icons-material/Receipt';
import UpdateIcon from "@mui/icons-material/Update";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LocalMallIcon from '@mui/icons-material/LocalMall';

export default function OrderImportantIcon({foodOrder, color}) {
    if(foodOrder.preorder){
        return <UpdateIcon />;
    }

    if(foodOrder.tableNumber > 0){
        return (<TableRestaurantIcon color={color} />);
    } else {
        if(foodOrder.deliverType === 'DINE-IN'){
            return (<RestaurantIcon color={color} />);
        }  else if(foodOrder.deliverType === 'DELIVERY'){
            return (<DeliveryDiningIcon color={color} />);
        }  else if(foodOrder.deliverType === 'PICKUP'){
            return (<LocalMallIcon color={color} />);
        }  else if(foodOrder.deliverType === 'TAKEAWAY'){
            return (<ShoppingBagIcon color={color} />);
        }  else if(foodOrder.deliverType === 'PARCEL'){
            return (<LocalShippingIcon color={color} />);
        }  else {
            return (<ReceiptIcon color={color} />);
        }
    }

};