import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../../core/CropImage'
import {httpClient} from "../../../core/HttpClient";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import {styled} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    inputBrowse: {
        display: 'none',
    },
    media: {
        height: 0,
        paddingTop: '52.35%', // 1.91:1 Facebook OG Image
        cursor: 'pointer'
    },
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 48,
    height: 48,
    border: `2px solid ${theme.palette.background.paper}`,
}));

export function ProfileUpload({name, value, aspect, button}) {
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const classes = useStyles();
    const [profileImageUrl, setProfileImageUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropDialog, setCropDialog] = useState(false);
    const [cropImage, setCropImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {
        console.log('[ProfileUpload]');
        if(value){
            let url = `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${value._id}?dt=`+new Date().getTime();
            setProfileImageUrl(url);
        }
    },[value]);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                cropImage,
                croppedAreaPixels,
                0
            )
            setCropDialog(false);
            setLoading(true);
            const data = new FormData();
            data.append('file', croppedImage);
            const url = process.env.REACT_APP_API_BASE_URL + '/upload/profile';
            httpClient.post(url, data).then(res => {
                if(res.data){
                    let url = `${process.env.REACT_APP_CDN_BASE_URL}/public/user/${value._id}?dt=`+new Date().getTime();
                    setProfileImageUrl(url);
                }
                setLoading(false);
            });
        } catch (e) {
            console.error(e);
        }
    }, [cropImage, croppedAreaPixels, value]);

    const handleUpload = ({ target }) => {
        if(target.files[0]){
            const reader = new FileReader();
            reader.onloadend = () => {
                setCropImage(reader.result);
            };
            reader.readAsDataURL(target.files[0]);
            target.value = ''; // fix same file select
            setCropDialog(true);
        }
    };

    const handleClose = () =>{
      setCropDialog(false);
    };

    const handleUploadClick = () => {
        // 👇️ open file input box on click of other element
        inputRef.current.click();
    };

    return(
        <>
            <label htmlFor="contained-button-file">
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={loading?<SmallAvatar alt="Upload"><CircularProgress size={20} color={"grey"} /></SmallAvatar> : <SmallAvatar alt="Upload"><PhotoCameraIcon /></SmallAvatar>}
                >
                    <Avatar sx={{ width: 168, height: 168 }}
                            alt="Profile"
                            src={loading?`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/loading2.gif`:profileImageUrl !== ''?profileImageUrl : `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/empty.png`} />
                </Badge>
            </label>
            <input
                ref={inputRef}
                accept="image/*"
                className={classes.inputBrowse}
                id="contained-button-file"
                name="contained-button-file"
                onChange={handleUpload}
                type="file"
            />
            {button &&
            <Box mt={1}>
                <Button variant="outlined" fullWidth={true} color="default" onClick={handleUploadClick}>
                    {t('common.upload')}
                </Button>
            </Box>
            }
            {cropDialog &&
            <Dialog
                onClose={ handleClose }
                fullScreen={true}
                open={ cropDialog }>
                <DialogContent>
                    <Cropper
                        image={cropImage}
                        crop={crop}
                        zoom={zoom}
                        aspect={aspect}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Button color='primary'
                                    variant="contained"
                                    onClick={showCroppedImage}
                                    fullWidth={true}
                                    size="large"
                                    disableElevation>
                                {t('common.ok')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained"
                                    onClick={handleClose}
                                    fullWidth={true}
                                    size="large"
                                    color={"grey"}
                                    disableElevation>
                                {t('common.cancel')}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
            }
        </>
    )
}