import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import { useParams, useHistory, useLocation } from "react-router-dom";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import NumberFormat from "react-number-format";
import {httpClient} from "../../../core/HttpClient";
import CircularProgress from "@mui/material/CircularProgress";
import {getTextI18n} from "../../TextI18n";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Link} from "@mui/material";
import AccountDayDialog from "../../AccountDay/AccountDayDialog";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
    root: {},
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none'
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function SelectProduct() {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const search = useLocation().search;
    const { id } = useParams();
    const sqty = new URLSearchParams(search).get('qty');
    const stype = new URLSearchParams(search).get('type');
    let _qty = 1;
    if(sqty){
        _qty = parseInt(sqty);
    }
    let _type = '';
    if(stype){
        _type = stype;
    }
    const [qty, setQty] = useState(_qty);
    const [type] = useState(_type);
    const [open, setOpen] = useState(false);
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState({});
    const [accountCampaignCondition, setAccountCampaignCondition] = useState({verified: 0, redemptionDayCounter: 0});

    useEffect(() => {
        console.log('[Store]');
        const productsUrl = process.env.REACT_APP_API_BASE_URL + '/stores/products';
        const productUrl = process.env.REACT_APP_API_BASE_URL + '/stores/products/'+id;
        const accountCampaignConditionUrl = process.env.REACT_APP_API_BASE_URL + '/stores/free/condition';
        let tasks = [
            httpClient.get(productsUrl),
            httpClient.get(productUrl),
            httpClient.get(accountCampaignConditionUrl)
        ];
        Promise.all(tasks)
            .then(rs=>{
                if(rs[0] && rs[0].data){
                    let tmp = rs[0].data.filter(obj => {
                        return obj.code === id;
                    });
                    if(!tmp[0]){
                        setInvalid(true);
                    }
                }
                if(rs[1] && rs[1].data){
                    setProduct(rs[1].data);
                }
                if(rs[2] && rs[2].data){
                    setAccountCampaignCondition(rs[2].data);
                }
                setLoading(false);
            })
            .catch(e=>{
                console.log('error', e);
            });
    }, [id]);

    const handleBack = () => {
        history.push('/plugins');
    };

    const handleQty = (value) => {
        let _qty = qty+value;
        if(_qty>0){
            setQty(_qty);
        }
    };

    const handleNext = () => {
        history.push(`/store/address/${id}?qty=${qty}&type=${type}`);
    };

    const handleClosePaymentComplete = (value) => {
        if(value === 'complete'){
            const url = process.env.REACT_APP_API_BASE_URL + '/stores/free/condition';
            httpClient.get(url)
                .then(res => {
                    if(res.data){
                        setAccountCampaignCondition(res.data);
                    }
                });
        }
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <>
                    {invalid &&
                        <Box m={2}>
                            <Alert severity="error">
                                {t('store.invalidProduct')}
                            </Alert>
                        </Box>
                    }
                    {!invalid &&
                        <>
                            <Box display="flex" alignItems="center" m={1}>
                                <IconButton edge="start" color="primary" aria-label="black" onClick={handleBack}>
                                    <ArrowBackIcon/>
                                </IconButton>
                                <Typography variant="h6">
                                    {getTextI18n(product.name)}
                                </Typography>
                            </Box>
                            <Divider style={{'marginTop': '10px'}}/>
                            <Box m={1}>
                                <Box>
                                    <Box mb={2}>
                                        <img
                                            src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/products/${product.code}.jpg`}
                                            alt="product" style={{width: '100%'}}/>
                                    </Box>
                                    <Box>
                                        <Typography variant="body1">
                                            <strong>{getTextI18n(product.name)}</strong>
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography variant="body1">
                                            <span>{getTextI18n(product.description)}</span>
                                        </Typography>
                                    </Box>
                                    {product.price > 0 &&
                                        <>
                                            {['1', '3'].indexOf(id) > -1 &&
                                                <Box>
                                                    <Typography variant="caption" color={"secondary"}>
                                                        {t(`store.menuRemark`)}
                                                    </Typography>
                                                </Box>
                                            }
                                            <Box display="flex" justifyContent="space-between" mt={2}>
                                                <Typography variant="body1">
                                                    <span>{t('store.code')}</span>
                                                </Typography>
                                                <Typography noWrap={true}>
                                                    <span>{product.code}</span>
                                                </Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" mt={1}>
                                                <Typography variant="body1">
                                                    <span>{t('store.price')}</span>
                                                </Typography>
                                                <Typography noWrap={true}>
                                                    <NumberFormat value={product.price} displayType={'text'}
                                                                  thousandSeparator={true}/> {t('pricing.baht')}
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                </Box>
                                {product.price > 0 &&
                                    <>
                                        <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body1">
                                                <span>{t('store.qty')}</span>
                                            </Typography>
                                            <ButtonGroup variant="outlined" aria-label="outlined button group" color="default">
                                                <Button onClick={e => handleQty(-1)}><RemoveIcon sx={{fontSize: 15}}/></Button>
                                                <Box display="flex" justifyContent="center" alignItems="center" sx={{
                                                    borderTop: '1px solid #c7c7c7',
                                                    borderBottom: '1px solid #c7c7c7',
                                                    borderLeft: '1px solid #c7c7c7',
                                                    width: '50px'
                                                }}>{qty}</Box>
                                                <Button onClick={e => handleQty(1)}><AddIcon sx={{fontSize: 15}}/></Button>
                                            </ButtonGroup>
                                        </Box>
                                        <Box mt={4} pb={2} style={{width: '100%'}}>
                                            <Button variant="contained" color="primary"
                                                    size="large" fullWidth={true}
                                                    disableElevation
                                                    onClick={handleNext}>
                                                {t('common.ok')}
                                            </Button>
                                        </Box>
                                    </>
                                }
                                {product.price <= 0 &&
                                    <Box>
                                        {accountCampaignCondition.freeItems && accountCampaignCondition.freeItems.indexOf("F1") > -1 &&
                                            <Box mt={1}>
                                                <Alert severity="success">
                                                    {t("store.alreadyEarn")}
                                                </Alert>
                                            </Box>
                                        }
                                        {accountCampaignCondition.freeItems && accountCampaignCondition.freeItems.indexOf("F1") === -1 &&
                                            <>
                                                <Box mt={1}>
                                                    <Typography variant="body1" style={{color: "#44cc00"}}>
                                                        <strong>{t('store.campaign.condition.title')}</strong>
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    {accountCampaignCondition.verified === 1 &&
                                                        <CheckCircleIcon fontSize={"small"} color={"success"}/>
                                                    }
                                                    {accountCampaignCondition.verified !== 1 &&
                                                        <CheckCircleOutlineIcon fontSize={"small"} color={"default"}/>
                                                    }
                                                    <Typography variant="caption" style={{marginLeft: '3px'}}>
                                                        <span>1. {t('store.campaign.condition.1')}</span>
                                                        {accountCampaignCondition.verified !== 1 &&
                                                            <Link href={`/account/${accountCampaignCondition._id}/verification`}
                                                                  style={{marginLeft: '5px'}}>
                                                                <Typography variant="caption">
                                                                    <span>({t('verification.verification')})</span>
                                                                </Typography>
                                                            </Link>
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" alignItems="center">
                                                    {accountCampaignCondition.redemptionDayCounter > 0 &&
                                                        <CheckCircleIcon fontSize={"small"} color={"success"}/>
                                                    }
                                                    {!accountCampaignCondition.redemptionDayCounter > 0 &&
                                                        <CheckCircleOutlineIcon fontSize={"small"} color={"default"}/>
                                                    }
                                                    <Typography variant="caption" style={{marginLeft: '3px'}}>
                                                        <span>2. {t('store.campaign.condition.2')}</span>
                                                        {!accountCampaignCondition.redemptionDayCounter > 0 &&
                                                            <>
                                                                <Link component="button" style={{marginLeft: '5px'}}
                                                                      onClick={() => {
                                                                          setOpen(true);
                                                                      }}>
                                                                    <Typography variant="caption">
                                                                        <span>({t('account.redemption')})</span>
                                                                    </Typography>
                                                                </Link>
                                                                <AccountDayDialog open={open} account={{_id: accountCampaignCondition._id, plan: accountCampaignCondition.plan, planOption: accountCampaignCondition.planOption}} callback={handleClosePaymentComplete} />
                                                            </>
                                                        }
                                                    </Typography>
                                                </Box>
                                                <Box mt={4} pb={2} style={{width: '100%'}}>
                                                    <Button variant="contained" color="primary"
                                                            size="large" fullWidth={true}
                                                            disabled={!(product.price > 0 || (accountCampaignCondition.verified === 1 && accountCampaignCondition.redemptionDayCounter > 0)) }
                                                            disableElevation
                                                            onClick={handleNext}>
                                                        {t('common.ok')}
                                                    </Button>
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                }
                            </Box>
                        </>
                    }
                </>
            }
        </div>
    )
}