import dayjs from "dayjs";

export function getRemainingDay(data) {
    if(data){
        let remainingDay = 0;
        let remainingHours = dayjs(data).diff(new Date(), 'hours');
        if(remainingHours > 0){
            remainingDay = Math.ceil(remainingHours / 24);
        }
        remainingDay = remainingDay.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return remainingDay;
    } else {
        return 0;
    }
}