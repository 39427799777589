import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {httpClient} from "../../core/HttpClient";
import {ListItemButton} from "@mui/material";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import SportsMotorsportsIcon from '@mui/icons-material/SportsMotorsports';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

export default function Users() {
    const { t } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [counter, setCounter] = useState({admin: 0, staff: 0, rider: 0});

    useEffect(() => {
        console.log('[Users]');
        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/users/count';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setCounter(res.data);
                }
                setLoading(false);
            });
    }, []);

    const handleClick = (key) => () => {
        history.push('/users/' + key);
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('user.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
                {!loading &&
                <Card variant="outlined">
                    <List className={classes.root}>
                        <ListItemButton divider onClick={handleClick('admin')}>
                            <ListItemIcon>
                                <ManageAccountsIcon />
                            </ListItemIcon>
                            <ListItemText id='promptPay'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('user.roles.ADMIN')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('user.roles.ADMINDescription')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Typography variant={"h5"}>{counter.admin}</Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <ListItemButton divider onClick={handleClick('staff')}>
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText id='promptPay'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('user.roles.STAFF')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('user.roles.STAFFDescription')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Typography variant={"h5"}>{counter.staff}</Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <ListItemButton onClick={handleClick('rider')}>
                            <ListItemIcon>
                                <SportsMotorsportsIcon />
                            </ListItemIcon>
                            <ListItemText id='promptPay'>
                                <Box mr={1}>
                                    <Typography variant="h6">
                                        <div>
                                            {t('user.roles.RIDER')}
                                        </div>
                                        <div style={{fontSize: '1rem'}}>
                                            {t('user.roles.RIDERDescription')}
                                        </div>
                                    </Typography>
                                </Box>
                            </ListItemText>
                            <ListItemSecondaryAction>
                                <Typography variant={"h5"}>{counter.rider}</Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    </List>
                </Card>
                }
            </Box>
        </div>
    )
}