import React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';

export function TableNumber(props) {
    return(
        <Box display="flex"
             justifyContent="center"
             alignItems="center"
             sx={{ border: 1, borderRadius: 1 , flexDirection: 'column', minWidth: '50px'  }} >
            <TableRestaurantIcon />
            <Typography variant={"body1"} sx={{marginTop: '-5px'}}>
                <strong>{props.value}</strong>
            </Typography>
        </Box>
    )
}