import React, { useState, useRef, useEffect } from 'react';
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";
import {styled, useTheme} from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import NumberFormat from 'react-number-format';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet'
import {isDesktop} from 'react-device-detect';
import ShopConfirm from "../ShopConfirm";
import {DateI18n} from "../DateI18n";
import {httpClient} from "../../core/HttpClient";
import 'leaflet/dist/leaflet.css';
import './style.css'
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import {NativeSelect} from "@mui/material";
import Terms from "../Terms";
import Snackbar from "@mui/material/Snackbar";
import jwt_decode from "jwt-decode";
import {getDatabase, onValue, ref} from "firebase/database";
import {getDatabaseInstance} from "../../core/Firebase";
import {getAuth, signInAnonymously} from "firebase/auth";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const interactionOptions = {
    zoomControl: isDesktop,
    doubleClickZoom: false,
    closePopupOnClick: true,
    dragging: true,
    zoomSnap: true,
    zoomDelta: true,
    trackResize: true,
    touchZoom: true,
    scrollWheelZoom: false,
};

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const blueIcon = new L.Icon({
        iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-blue.png`,
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    }),
    redIcon = new L.Icon({
        iconUrl: `${process.env.REACT_APP_CDN_BASE_URL}/public/assets/pin-red.png`,
        iconSize: [21, 34],
        iconAnchor: [10, 34]
    });

const LocationMarker = (props) => {
    const markerRef = useRef(null);
    const [icon, setIcon] = useState(props.ood?blueIcon:redIcon);

    const map = useMapEvents({
        move() {
            const marker = markerRef.current;
            if (marker != null) {
                marker.setLatLng(map.getCenter());
            }
        },
        moveend(e) {
            const marker = markerRef.current;
            if (marker != null) {
                let center = map.getCenter();
                let zoom = map.getZoom();
                setIcon(redIcon);
                if(props.callback){
                    props.callback(center.lat, center.lng, zoom);
                }
            }
        }
    });

    return props.initial === null ? null : (
        <>
            <Marker icon={icon} draggable={false} position={props.position} ref={markerRef} ></Marker>
        </>
    )
};

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

function selectAccountHandle(data){
    console.log('selectAccountHandle', data);
    if(data){

        if(data.selectedAccount){
            window.location.href = '/home';
        } else {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login';
        }
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
    }
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.grey["300"],
        color: theme.palette.common.black,
        fontWeight: 'bold',
        padding: 8
    },
}));

const DATES = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31];
const MONTHS = [1,2,3,4,5,6,7,8,9,10,11,12];
const YEARS = [];

let currentYear = new Date().getFullYear() + 543;
currentYear -= 18;
for(let i = currentYear; i>currentYear-118;i--){
    YEARS.push(i);
}

function renewAccountToken(){

    console.log('renewAccountToken');
    let token = localStorage.getItem('token');
    let refreshToken = localStorage.getItem('refreshToken');
    if(token && refreshToken){
        const url = process.env.REACT_APP_API_BASE_URL + '/auth/line/refresh-token';
        const payload = {
            token: token,
            refreshToken: refreshToken,
            renew: true
        };
        return httpClient.post(url, payload).then(res => {
            let data = res.data;
            if(data && data.token && data.refreshToken){
                localStorage.setItem('token', data.token);
                localStorage.setItem('refreshToken', data.refreshToken);
                window.location.href = '/home';
            } else {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                window.location.href = '/login';
            }
        }).catch(e=>{
            console.log('e', e);
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            window.location.href = '/login';
        });
    } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
    }
}

export default function Initial(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const tick = useRef();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({step:-1, acceptAgreement: false});
    const [temp, setTemp] = useState({name: '', phone: '', address: '', type: 'RESTAURANT'});
    const [position, setPosition] = useState([13.721163782, 100.523664572]); // Default Bangkok
    const [map, setMap] = useState(null);
    const [showMap, setShowMap] = useState(false);
    const [openAgreement, setOpenAgreement] = useState(false);
    const [mapGeoLocationError, setMapGeoLocationError] = useState(false);
    const [errors, setErrors] = useState([]);
    const [qrCodeData, setQrCodeData] = useState(null);
    const [payment, setPayment] = useState({date: new Date(), price: 0});
    const [profile, setProfile] = useState({displayName: '', firstName: '', lastName: '', birthday: '', mobile: ''});
    const [birthday, setBirthday] = useState({day: '', month: '', year: ''});
    const [paymentComplete, setPaymentComplete] = useState(false);

    useEffect(() => {
        console.log('[Initial]');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/me';
        httpClient.get(url)
            .then(res => {
                let data = res.data;
                if(data){

                    if (!data.acceptAgreement) {
                        setOpenAgreement(true);
                    }

                    if(data.accounts && data.accounts[0]) {
                        if(data.firstName && data.firstName.length > 0 && data.lastName && data.lastName.length > 0){
                            let token = localStorage.getItem('token');
                            let decoded = jwt_decode(token);
                            if(decoded.account){
                                const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected';
                                httpClient.get(url)
                                    .then(res => {
                                        selectAccountHandle(res.data);
                                    });
                            } else {
                                history.push('/refresh');
                            }
                        } else {
                            // create profile
                            setState({step: 4, acceptAgreement: true});
                        }

                    } else if(!data.temp) {
                        // create new store
                        setState({step: 0, acceptAgreement: true});
                    } else if(data.temp) {
                        setTemp(data.temp);

                        if(data.birthday){
                            // Setup Profile
                            let _profile = {displayName: data.displayName || ''
                                , firstName: data.firstName || ''
                                , lastName: data.lastName || ''
                                , birthday: data.birthday || ''
                                , mobile: data.mobile || ''};

                            setProfile(_profile);
                            if(data.birthday.length === 8){
                                let day = parseInt(data.birthday.substring(0,2));
                                let month = parseInt(data.birthday.substring(2,4));
                                let year = parseInt(data.birthday.substring(4,8));
                                setBirthday({day: day, month: month, year: year});
                            }
                            setState({step: 2, acceptAgreement: true});
                        } else if(data.temp.plan){
                            // Setup Plan
                            setState({step: 2, acceptAgreement: true});
                        } else if(data.temp.coordinates){
                            // select plan
                            setState({step: 2, acceptAgreement: true});
                        } else {
                            // setup coordinates
                            if(!data.temp.coordinates){
                                // get client location
                                try{
                                    let processTimer = 0;
                                    let processTimout = false;
                                    tick.current = setInterval(() => {
                                        processTimer++;
                                        if(processTimer === 9){
                                            console.log('process timout');
                                            processTimout = true;
                                            setShowMap(true);
                                            setMapGeoLocationError(true);
                                            clearInterval(tick.current);
                                        }
                                        if(processTimer === 1){
                                            navigator.geolocation.getCurrentPosition(result=>{
                                                const coordinates = result.coords;
                                                if(!processTimout){
                                                    setTemp({...data.temp, zoom: 15, coordinates: {lat: coordinates.latitude, lng: coordinates.longitude}});
                                                    setPosition([coordinates.latitude, coordinates.longitude]);
                                                    setShowMap(true);
                                                }
                                                clearInterval(tick.current);
                                            }, function(positionError) {
                                                if(!processTimout){
                                                    setShowMap(true);
                                                    setMapGeoLocationError(true);
                                                }
                                                clearInterval(tick.current);
                                            }, {enableHighAccuracy: true, maximumAge: 10000, timeout: 8000});
                                        }

                                    }, 1000);

                                } catch(err){
                                    console.log(err);
                                    setShowMap(true);
                                    // done
                                }
                            }
                            setState({step: 1, acceptAgreement: true});
                        }
                    } else {
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshToken');
                        history.push('/login');
                    }
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('refreshToken');
                    history.push('/login');
                }
            });
    }, [history]);

    const handleProfileChange = ({ target }) => {
        setProfile({...profile, [target.name]: target.value});
    };

    const handleTempChange = ({ target }) => {
        setTemp({...temp, [target.name]: target.value});
    };

    const handleMapChange = (lat, lng, zoom) => {
        if(map){
            setTemp({...temp,zoom: zoom, coordinates: {lat: lat, lng: lng}});
        }
    };

    const handleBirthdayChange = ({ target }) => {
        setBirthday({...birthday, [target.name]: target.value});
    };

    const handlePrevious = (previous) => {
        setState({step: previous, acceptAgreement: true});
        if(temp && temp.coordinates && map){
            setMap({lat: temp.coordinates[0], lng: temp.coordinates[1]});
        }
        setShowMap(true);
    };

    const handleAcceptAgreement = () => {
        const url = process.env.REACT_APP_API_BASE_URL + '/users/me/accept';
        setLoading(true);
        httpClient.post(url, {})
            .then(res => {
                setLoading(false);
                setOpenAgreement(false);
            });
    };

    const handleSelectPlan = (plan) => {
        setTemp({...temp, plan: plan});
        // let element = document.getElementById("pricing-table");
        // if(["FREE","START"].indexOf(plan) > -1){
        //     element.scrollIntoView({ behavior: "smooth", inline: "start" });
        // } else if(["BASIC","STANDARD","PRO"].indexOf(plan) > -1){
        //     element.scrollIntoView({ behavior: "smooth", inline: "end" });
        // }

        // window.scrollTo({
        //     top: document.documentElement.scrollHeight,
        //     behavior: 'auto'
        //     /* you can also use 'auto' behaviour
        //        in place of 'smooth' */
        // });

    };

    const handleSaveTemp = (next) => {
        if(_validation()){
            setErrors([]);
            const url = process.env.REACT_APP_API_BASE_URL + '/users/me/temp';
            setLoading(true);
            httpClient.post(url, temp)
                .then(res => {
                    if(next===1){
                        const url = process.env.REACT_APP_API_BASE_URL + '/users/profile';
                        let d = ("0" + birthday.day).slice(-2);
                        let m = ("0" + birthday.month).slice(-2);
                        let y = birthday.year;
                        let bd = d+m+y;
                        setProfile({...profile, birthday: d+m+y});
                        httpClient.put(url, {firstName: profile.firstName, lastName: profile.lastName, mobile: profile.mobile, birthday: bd})
                            .then(res => {
                            if(!temp.coordinates){
                                // get client location
                                try{
                                    let processTimer = 0;
                                    let processTimout = false;
                                    tick.current = setInterval(() => {
                                        if(processTimer === 9){
                                            console.log('process timout');
                                            processTimout = true;
                                            setShowMap(true);
                                            setLoading(false);
                                            setMapGeoLocationError(true);
                                            clearInterval(tick.current);
                                        }
                                        navigator.geolocation.getCurrentPosition(result=>{
                                            if(!processTimout){
                                                const coordinates = result.coords;
                                                setTemp({...temp, zoom: 15, coordinates: {lat: coordinates.latitude, lng: coordinates.longitude}});
                                                setPosition([coordinates.latitude, coordinates.longitude]);
                                                setShowMap(true);
                                                setLoading(false);
                                            }
                                            clearInterval(tick.current);
                                        }, function(positionError) {
                                            if(!processTimout){
                                                setShowMap(true);
                                                setMapGeoLocationError(true);
                                                setLoading(false);
                                            }
                                            clearInterval(tick.current);
                                        }, {enableHighAccuracy: true, maximumAge: 10000, timeout: 8000});
                                    }, 1000);
                                } catch(err){
                                    console.log(err);
                                    setShowMap(true);
                                    setLoading(false);
                                    // done
                                }
                            } else {
                                setShowMap(true);
                                setLoading(false);
                                setState({step: next, acceptAgreement: true});
                            }
                        });

                    }

                    if(next === 4){
                        let newUser = res.data.newUser;
                        handleConfirm(newUser);
                    } else {
                        setLoading(false);
                        setState({step: next, acceptAgreement: true});
                    }
                });
        }
    };

    const handleConfirm = (newUser) => {
        if(newUser && temp.plan === 'FREE'){
            handleSubmit();
        } else {
            setState({step: 4, acceptAgreement: true});
            setLoading(false);
            setPayment({date: new Date()});
            setQrCodeData(null);
            let app = getDatabaseInstance();
            const db = getDatabase(app);
            const auth = getAuth(app);
            signInAnonymously(auth)
                .then(() => {
                    const url = process.env.REACT_APP_API_BASE_URL + '/users/me/temp/qr';
                    return httpClient.post(url, {plan: temp.plan});
                }).then(res => {
                    if(res.data.data){
                        setQrCodeData({orderNo: res.data.orderNo, amount: res.data.amount, qrcodeId: res.data.data.qrcodeId,  base64:res.data.data.qrImage});

                        let key = res.data.uid;
                        const statusRef = ref(db, 'payments/' + key);
                        onValue(statusRef, (snapshot) => {
                            const data = snapshot.val();
                            if(data && data.status === 'success'){
                                setPaymentComplete(true);
                                renewAccountToken().catch(e=>{
                                    console.log('e', e);
                                });
                            }
                        });
                    }
                });
        }
    };

    const handleSubmit = () => {
        if(temp.plan && temp.coordinates){
            const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/temp';
            httpClient.post(url, temp).then(res => {
                if(res.status !== 200) {
                    return Promise.reject(res);
                }

                const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected';
                return httpClient.get(url);
            }).then(res => {
                selectAccountHandle(res.data);
            });
        } else {
            const url = process.env.REACT_APP_API_BASE_URL + '/users/account/selected';
            httpClient.get(url).then(res=>{
                selectAccountHandle(res.data);
            });
        }
    };

    function _validation() {
        let _errors = [];
        if(!temp.name || temp.name.length === 0){
            _errors.push('name');
        }
        if(!temp.address || temp.address.length === 0){
            _errors.push('address');
        }

        let _phone = null;
        if(temp.phone){
            _phone = temp.phone.replace(/\D/g,'');
        }
        if(!_phone ||
            _phone.length !== 10 ||
            !_phone.startsWith('0')
        ){
            _errors.push('phone');
        }

        if(!profile.firstName ||
            profile.firstName.length === 0){
            _errors.push('firstName');
        }
        if(!profile.lastName ||
            profile.lastName.length === 0){
            _errors.push('lastName');
        }
        if(!profile.lastName ||
            profile.lastName.length === 0){
            _errors.push('lastName');
        }

        let _mobile = null;
        if(profile.mobile){
            _mobile = profile.mobile.replace(/\D/g,'');
        }
        if(!_mobile ||
            _mobile.length !== 10 ||
            !_mobile.startsWith('0')
        ){
            _errors.push('mobile');
        }

        if(birthday.day === ''
            || birthday.month === ''
            || birthday.year === ''){
            _errors.push('birthday');
        }

        setErrors(_errors);

        return _errors.length === 0;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setMapGeoLocationError(false);
    };

    return (
        <div>
            {state.step === -1 &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {state.step === 0 &&
                <Box p={2}>
                    <form noValidate autoComplete="off">
                        <Box>
                            <Typography variant="h6">
                                {t('shop.create')}
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                        <Box>
                            <Typography variant="body1">
                                {t('shop.information')}
                            </Typography>
                        </Box>
                        <Box mt={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField id="account-name" label={t('shop.name')} variant="outlined" fullWidth={true}
                                               name="name" value={ temp.name } error={errors.indexOf('name') > -1} onChange={ handleTempChange } required />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="account-address" label={t('shop.address')} variant="outlined" fullWidth={true}
                                               name="address" value={ temp.address } error={errors.indexOf('address') > -1} multiline rows={2} onChange={ handleTempChange } required />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField id="account-mobile"
                                               label={t('shop.mobile')}
                                               variant="outlined"
                                               fullWidth={true}
                                               name="phone" type="tel"
                                               value={temp.phone}
                                               error={errors.indexOf('phone') > -1}
                                               onChange={ handleTempChange }
                                               InputProps={{
                                                   inputComponent: PhoneFormatCustom,
                                               }}
                                               required />
                                </Grid>
                            </Grid>
                            <Box mt={3}>
                                <Typography variant="body1">
                                    {t('shop.register')}
                                </Typography>
                            </Box>
                            <Box mt={2}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <TextField id="firstName" label={t('profile.firstName')} variant="outlined" fullWidth={true}
                                               name="firstName" value={ profile.firstName } onChange={ handleProfileChange } required />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <TextField id="lastName" label={t('profile.lastName')} variant="outlined" fullWidth={true}
                                               name="lastName" value={ profile.lastName } onChange={ handleProfileChange } required />
                                </Box>
                            </Box>
                            <Box mt={2}>
                                <TextField id="outlined-basic"
                                           label={t('profile.mobile')}
                                           variant="outlined"
                                           fullWidth={true}
                                           name="mobile" type="tel"
                                           value={profile.mobile}
                                           error={errors.indexOf('mobile') > -1}
                                           onChange={ handleProfileChange }
                                           InputProps={{
                                               inputComponent: PhoneFormatCustom,
                                           }}
                                           required />
                            </Box>
                            <Box mt={3}>
                                <Box mb={1}>
                                    <Typography>
                                        {t('profile.birthday')}*
                                    </Typography>
                                </Box>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}>
                                            <NativeSelect
                                                name="day"
                                                value={ birthday.day }
                                                onChange={ handleBirthdayChange }
                                                input={<BootstrapInput />}>
                                                {birthday.day === '' && <option value="">{t('common.duration.day')}</option>}
                                                {DATES.map((value, i) => {
                                                    return (<option value={value} key={`d-${i}`}>{value}</option>)
                                                })}
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}>
                                            <NativeSelect
                                                name="month"
                                                value={ birthday.month }
                                                onChange={ handleBirthdayChange }
                                                input={<BootstrapInput />}>
                                                {birthday.month === '' && <option value="">{t('common.duration.month')}</option>}
                                                {MONTHS.map((value, i) => {
                                                    return (<option value={value} key={`m-${i}`}>{t(`common.months.${value}.name`)}</option>)
                                                })}
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl variant="outlined"
                                                     fullWidth={true}>
                                            <NativeSelect
                                                name="year"
                                                value={ birthday.year }
                                                onChange={ handleBirthdayChange }
                                                input={<BootstrapInput />}>
                                                {birthday.year === '' && <option value="">{t('common.duration.year')}</option>}
                                                {YEARS.map((value, i) => {
                                                    return (<option value={value} key={`y-${i}`}>{value}</option>)
                                                })}
                                            </NativeSelect>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box mt={1}>
                                <Typography variant={"caption"}>
                                    *{t('initial.ageAgreement')}
                                </Typography>
                            </Box>
                        </Box>
                    </form>
                    <div style={{'marginTop': '20px'}}>
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained"
                                    color="primary"
                                    disableElevation
                                    size="large"
                                    fullWidth={matches}
                                    onClick={(e) => handleSaveTemp(1)}
                                    disabled={ temp.name.trim().length === 0
                                        || temp.address.trim().length === 0
                                        || temp.phone.length === 0
                                        || temp.phone.replace(/\D/g,'').length !== 10
                                        || profile.firstName.trim().length === 0
                                        || profile.lastName.trim().length === 0
                                        || profile.mobile.replace(/\D/g,'').length !== 10
                                        || birthday.day === ''
                                        || birthday.month === ''
                                        || birthday.year === ''
                                        || loading}>
                                {t('common.next')}
                            </Button>
                        </Box>
                    </div>
                </Box>
            }
            {state.step === 1 &&
                <Box p={2}>
                    <form noValidate autoComplete="off">
                        <Box>
                            <Typography variant="h6">
                                {t('shop.location')}
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                        <Box mt={2} style={{height: `300px`}}>
                            {!showMap &&
                                <Box display="flex" justifyContent="center" mt={4}>
                                    <CircularProgress size={20}/>
                                </Box>
                            }
                            {showMap &&
                                <MapContainer center={temp.coordinates ? temp.coordinates : position}
                                              zoom={temp.zoom ? temp.zoom : 15}
                                              {...interactionOptions}
                                              ref={setMap}
                                              scrollWheelZoom={false}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    {position[0] &&
                                        <LocationMarker effectOn={this}
                                                        ood={temp.coordinates ? false : true}
                                                        position={temp.coordinates ? temp.coordinates : position}
                                                        callback={handleMapChange}/>
                                    }
                                </MapContainer>
                            }
                        </Box>
                        <Box mt={1}>
                            <Typography variant="caption">
                                {t('shop.locationMessage')}
                            </Typography>
                        </Box>
                    </form>
                    <div style={{'marginTop': '20px'}}>
                        <Box display="flex" justifyContent="space-between">
                            <Button variant="contained"
                                    disableElevation
                                    size="large"
                                    fullWidth={matches}
                                    color={"grey"}
                                    onClick={(e) => handlePrevious(0)}>
                                {t('common.previous')}
                            </Button>
                            <div>&nbsp;</div>
                            <Button variant="contained"
                                    color="primary"
                                    disableElevation
                                    size="large"
                                    fullWidth={matches}
                                    onClick={(e) => handleSaveTemp(2)}
                                    disabled={!temp.coordinates || loading}>
                                {t('common.next')}
                            </Button>
                        </Box>
                    </div>
                </Box>
            }
            {state.step === 2 &&
                <Box p={2}>
                    <form noValidate autoComplete="off">
                        <Box>
                            <Typography variant="h6">
                                {t('shop.confirm')}
                            </Typography>
                        </Box>
                        <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                        <Box mt={2}>
                            <ShopConfirm temp={temp} profile={profile} />
                        </Box>
                    </form>
                    <Box mt={4}>
                        <div style={{'marginTop': '20px'}}>
                            <Box display="flex" justifyContent="space-between">
                                <Button variant="contained"
                                        disableElevation
                                        size="large"
                                        fullWidth={matches}
                                        color={"grey"}
                                        onClick={(e) => handlePrevious(1)}>
                                    {t('common.previous')}
                                </Button>
                                <div>&nbsp;</div>
                                <Button variant="contained"
                                        color="primary"
                                        disableElevation
                                        size="large"
                                        fullWidth={matches}
                                        onClick={(e) => handleSaveTemp(3)}
                                        disabled={!temp.coordinates || loading}>
                                    {t('common.next')}
                                </Button>
                            </Box>
                        </div>
                    </Box>
                </Box>
            }
            {state.step === 3 &&
                <Box p={2}>
                    <Box>
                        <Typography variant="h6">
                            {t('pricing.selectPlan')}
                        </Typography>
                    </Box>
                    <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                    <TableContainer>
                        <Table id="pricing-table" aria-label="simple table" sx={{border: '1px solid gainsboro'}}>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell
                                        align="center" style={{
                                        borderRight: '2px solid white',
                                        fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                    }} onClick={(e) => handleSelectPlan('FREE')}>{t('pricing.free')}</StyledTableCell>
                                    <StyledTableCell
                                        align="center" style={{
                                        borderRight: '2px solid white',
                                        fontWeight: temp.plan === 'START'?'bold': 'normal'
                                    }} onClick={(e) => handleSelectPlan('START')}>{t('pricing.start')}</StyledTableCell>
                                    <StyledTableCell
                                        align="center" style={{
                                        borderRight: '2px solid white',
                                        fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                    }} onClick={(e) => handleSelectPlan('BASIC')}>{t('pricing.basic')}</StyledTableCell>
                                    <StyledTableCell align="center" style={{
                                        borderLeft: '2px solid white',
                                        fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                    }} onClick={(e) => handleSelectPlan('STANDARD')}>{t('pricing.standard')}</StyledTableCell>
                                    <StyledTableCell align="center" style={{
                                        borderLeft: '2px solid white',
                                        fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                    }} onClick={(e) => handleSelectPlan('PRO')}>{t('pricing.pro')}</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={5} align="center">{t('pricing.fee')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{padding: '10px 0 10px 0'}}
                                               align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                        }}>{t('pricing.price.free')}</Box>
                                    </TableCell>
                                    <TableCell style={{padding: '10px 0 10px 0'}}
                                               align="center">
                                        <Box sx={{borderRight: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.start')}</Box>
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.price.start')}</Box>
                                    </TableCell>
                                    <TableCell style={{padding: '10px 0 10px 0'}}
                                               align="center">
                                        <Box sx={{borderRight: '1px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.basic')}</Box>
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.price.basic')}</Box>
                                    </TableCell>
                                    <TableCell style={{padding: '10px 0 10px 0'}} align="center" >
                                        <Box sx={{borderLeft: '0px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.standard')}</Box>
                                        <Box sx={{
                                            borderLeft: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.price.standard')}</Box>
                                    </TableCell>
                                    <TableCell style={{padding: '10px 0 10px 0'}} align="center" >
                                        <Box sx={{borderLeft: '0px solid gainsboro',textDecoration:'line-through', color: 'gray'}}>{t('pricing.normalPrice.pro')}</Box>
                                        <Box sx={{
                                            borderLeft: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }}>{t('pricing.price.pro')}</Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={5} align="center">
                                        {temp.type==='RESTAURANT'?t('pricing.menu'):t('pricing.product')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                        }}>{t('pricing.package.free.menu')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.menu')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.menu')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.menu')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }}>{t('pricing.package.pro.menu')}</Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={5} align="center">
                                        {t('pricing.order')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                        }}>{t('pricing.package.free.order')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.order')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.order')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.order')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }}>{t('pricing.package.pro.order')}</Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={5} align="center">
                                        {temp.type==='RESTAURANT'?t('pricing.table'):t('pricing.table')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                        }}>{t('pricing.package.free.table')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.table')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.table')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.table')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }}>{t('pricing.package.pro.table')}</Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={5} align="center">
                                        {temp.type==='RESTAURANT'?t('pricing.kitchen'):t('pricing.kitchen')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                        }}>{t('pricing.package.free.kitchen')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.kitchen')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.kitchen')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.kitchen')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }}>{t('pricing.package.pro.kitchen')}</Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={5} align="center">
                                        {t('pricing.user')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                        }}>{t('pricing.package.free.user')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.user')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.user')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.user')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }}>{t('pricing.package.pro.user')}</Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{borderBottom: '0px', padding: '10px 0 0 0'}} colSpan={5} align="center">
                                        {t('pricing.delivery')}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'FREE'?'bold': 'normal'
                                        }}>{t('pricing.package.free.area')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'START'?'bold': 'normal'
                                        }}>{t('pricing.package.start.area')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderRight: '0px solid gainsboro',
                                            fontWeight: temp.plan === 'BASIC'?'bold': 'normal'
                                        }}>{t('pricing.package.basic.area')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'STANDARD'?'bold': 'normal'
                                        }}>{t('pricing.package.standard.area')}</Box>
                                    </TableCell>
                                    <TableCell style={{
                                        padding: '10px 0 10px 0'
                                    }} align="center">
                                        <Box sx={{
                                            borderLeft: '1px solid gainsboro',
                                            fontWeight: temp.plan === 'PRO'?'bold': 'normal'
                                        }}>{t('pricing.package.pro.area')}</Box>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">
                                        <Button fullWidth={true} variant="contained"
                                                disableElevation
                                                style={{paddingLeft: 0, paddingRight: 0,  fontWeight: temp.plan === 'FREE'?'bold':'normal' }}
                                                onClick={(e) => handleSelectPlan('FREE')}
                                                color={temp.plan === 'FREE'?'orange':'grey'}>
                                            {t('pricing.price.free')}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button fullWidth={true} variant="contained"
                                                disableElevation
                                                style={{paddingLeft: 0, paddingRight: 0,  fontWeight: temp.plan === 'START'?'bold':'normal' }}
                                                onClick={(e) => handleSelectPlan('START')}
                                                color={temp.plan === 'START'?'orange':'grey'}>
                                            {t('pricing.price.start')}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button fullWidth={true} variant="contained"
                                                disableElevation
                                                style={{paddingLeft: 0, paddingRight: 0,   fontWeight: temp.plan === 'BASIC'?'bold':'normal' }}
                                                onClick={(e) => handleSelectPlan('BASIC')}
                                                color={temp.plan === 'BASIC'?'orange':'grey'}>
                                            {t('pricing.price.basic')}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button fullWidth={true} variant="contained"
                                                disableElevation
                                                onClick={(e) => handleSelectPlan('STANDARD')}
                                                style={{paddingLeft: 0, paddingRight: 0,   fontWeight: temp.plan === 'STANDARD'?'bold':'normal' }}
                                                color={temp.plan === 'STANDARD'?'orange':'grey'}>
                                            {t('pricing.price.standard')}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button fullWidth={true} variant="contained"
                                                disableElevation
                                                onClick={(e) => handleSelectPlan('PRO')}
                                                style={{paddingLeft: 0, paddingRight: 0,   fontWeight: temp.plan === 'PRO'?'bold':'normal' }}
                                                color={temp.plan === 'PRO'?'orange':'grey'}>
                                            {t('pricing.price.pro')}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/*{trial &&*/}
                    {/*    <Box mb={2} mt={2}>*/}
                    {/*        <Alert icon={<CardGiftcardIcon sx={{ fontSize: 40 }} />} severity="success">*/}
                    {/*            {t('shop.trial.message')}*/}
                    {/*        </Alert>*/}
                    {/*    </Box>*/}
                    {/*}*/}
                    <Box mt={2}>
                        <Typography variant={"caption"}>
                            *{t('common.message.selectPlanMessage')}
                        </Typography>
                    </Box>
                    <div style={{'marginTop': '10px'}}>
                        <Box display="flex" justifyContent="space-between">
                            <Button variant="contained"
                                    disableElevation
                                    size="large"
                                    fullWidth={matches}
                                    color={"grey"}
                                    onClick={(e) => handlePrevious(2)}>
                                {t('common.previous')}
                            </Button>
                            <div>&nbsp;</div>
                            <Button variant="contained"
                                    color="primary"
                                    style={{ fontWeight: (temp.plan)?'bold':'normal' }}
                                    disableElevation
                                    size="large"
                                    fullWidth={matches}
                                    onClick={(e) => handleSaveTemp(4)}
                                    disabled={!temp.plan || loading}>
                                {t('common.next')}
                            </Button>
                        </Box>
                    </div>
                </Box>
            }
            {state.step === 4 &&
                <Box p={2}>
                    <Box>
                        <Typography variant="h6">
                            {t('initial.payment')}
                        </Typography>
                    </Box>
                    <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
                    <div>
                        {paymentComplete &&
                            <div>
                                <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                                    <CheckCircleOutlineIcon color={"success"} sx={{ fontSize: 60 }} />
                                </Box>
                                <Alert icon={false} severity="success">{t('payment.complete')}</Alert>
                            </div>
                        }
                        {!paymentComplete &&
                            <Box mt={2}>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <img src={`${process.env.REACT_APP_CDN_BASE_URL}/public/assets/promptpay_visa_master.png`} alt="payment network"
                                         style={{width: '100%', maxWidth: '200px'}}/>
                                </Box>
                                {!qrCodeData &&
                                    <Box display="flex" justifyContent="center" mt={3} mb={4}>
                                        <CircularProgress size={20}/>
                                    </Box>
                                }
                                {qrCodeData &&
                                    <>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
                                            <img src={`data:image/png;base64,${qrCodeData.base64}`} alt="pay"
                                                 style={{width: '100%', maxWidth: '250px'}}/>
                                        </Box>
                                        <Box display="flex" justifyContent="center" alignItems="center" mb={4}>
                                            <Typography variant="body1" color="inherit">{qrCodeData.qrcodeId}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body1"
                                                        color="inherit">{t('pricing.totalAmount')}</Typography>
                                            <Typography variant="body1"
                                                        color="inherit"><NumberFormat value={qrCodeData.amount} displayType={'text'} thousandSeparator={true} /> {t('pricing.baht')}</Typography>
                                        </Box>
                                        <hr/>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body1" color="inherit">{t(`pricing.ref1`)}</Typography>
                                            <div>{qrCodeData && <span>{qrCodeData.orderNo}</span>}</div>
                                        </Box>
                                        <hr/>
                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                            <Typography variant="body1" color="inherit">{t(`pricing.paymentDate`)}</Typography>
                                            <div><DateI18n value={payment.date} format="DD MMM YYYY HH:mm" /></div>
                                        </Box>
                                    </>
                                }
                                <Box mt={2}>
                                    <Button color="grey" variant="contained" fullWidth={true} disableElevation
                                            onClick={(e) => handlePrevious(3)}>
                                        {t('common.cancel')}
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </div>
                </Box>
            }
            <Dialog
                open={openAgreement}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">{t('initial.agreement')}</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        component="div"
                        tabIndex={-1}
                    >
                        <Terms />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  variant="contained"
                             color="primary"
                             size="large"
                             disableElevation
                             fullWidth={true}
                             disabled={loading}
                             onClick={(e) => handleAcceptAgreement(e)}>
                        {t('initial.agree')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={mapGeoLocationError}
                      anchorOrigin={{vertical:'top', horizontal:'center'}}
                      autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {t('common.message.geoLocationFail')}
                </Alert>
            </Snackbar>
        </div>
    )
}