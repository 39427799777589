import React, {useState, useEffect, useContext} from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import {httpClient} from "../../core/HttpClient";
import CardContent from "@mui/material/CardContent";
import {ProfileUpload} from "./ProfileUpload";
import TextField from "@mui/material/TextField";
import NumberFormat from "react-number-format";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import {Context} from "../../core/Context";
import {InputAdornment} from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import Alert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
}));

const MemoizedImageAvatar = React.memo(({name, value})=>{
    return(<ProfileUpload name={name} value={ value } aspect={1/1} />)
});

const PhoneFormatCustom = React.forwardRef((props, ref) =>
    <NumberFormat
        {...props}
        getInputRef={ref}
        type="tel"
        format="###-###-####"
    />);

export default function Profile() {
    const { t } = useTranslation();
    const classes = useStyles();
    const [context, setContext] = useContext(Context);
    const [error, setError] = useState({firstName: false, lastName: false, mobile: false});
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState({});

    useEffect(() => {
        console.log('[Profile]');
        const url = process.env.REACT_APP_API_BASE_URL + '/users/me';
        httpClient.get(url)
            .then(res => {
                if(res.data){
                    setProfile(res.data);
                }
                setLoading(false);
            });
    }, []);

    const handleChange = ({ target }) => {
        setProfile({...profile,  [target.name]: target.value});
    };

    function _validation() {
        let result = true;
        let error = {firstName: false, lastName: false, displayName: false, mobile: false};
        let _mobile = null;
        if(profile.mobile){
            _mobile = profile.mobile.replace(/\D/g,'');
        }
        if(!_mobile ||
            _mobile.length !== 10 ||
            !_mobile.startsWith('0')
        ){
            result = false;
            error.mobile = true;
        }

        if(!profile.firstName ||
            profile.firstName.length === 0){
            result = false;
            error.firstName = true;
        }

        if(!profile.lastName ||
            profile.lastName.length === 0){
            result = false;
            error.lastName = true;
        }

        if(!result){
            setError(error);
        }

        return result;
    }

    const handleSave = () => {
        if(_validation()) {
            setError({phone: false});
            const url = process.env.REACT_APP_API_BASE_URL + '/users/profile';
            setLoading(true);
            httpClient.put(url, {firstName: profile.firstName, lastName: profile.lastName, mobile: profile.mobile})
                .then(res => {
                    setContext({ ...context, success: true});
                    setLoading(false);
                });
        }
    };

    return (
        <div className={classes.root}>
            <Box display="flex" justifyContent="space-between" alignItems="center" m={1}>
                <Typography variant="h6">
                    {t('profile.title')}
                </Typography>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            <Box m={1}>
            {loading &&
                <Box display="flex" justifyContent="center" mt={4}>
                    <CircularProgress size={20}/>
                </Box>
            }
            {!loading &&
                <>
                    <Card>
                        <CardContent>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <MemoizedImageAvatar name="image" value={ profile } />
                            </Box>
                            <Box mt={4}>
                                <TextField id="outlined-basic"
                                           label={t('profile.firstName')}
                                           variant="outlined" fullWidth={true}
                                           name="firstName" value={ profile.firstName }
                                           error={error.firstName}
                                           InputProps={{
                                               readOnly: profile.status !== 'unverified',
                                               endAdornment: profile.status !== 'unverified'?(
                                                   <InputAdornment position="end">
                                                       <VerifiedIcon color={profile.status === 'verified'?"info":"default"} />
                                                   </InputAdornment>
                                               ):null,
                                           }}
                                           onChange={ handleChange } required />
                            </Box>
                            <Box mt={2}>
                                <TextField id="outlined-basic"
                                           label={t('profile.lastName')}
                                           variant="outlined" fullWidth={true}
                                           name="lastName" value={ profile.lastName }
                                           error={error.lastName}
                                           InputProps={{
                                               readOnly: profile.status !== 'unverified',
                                               endAdornment: profile.status !== 'unverified'?(
                                                   <InputAdornment position="end">
                                                       <VerifiedIcon color={profile.status === 'verified'?"info":"default"} />
                                                   </InputAdornment>
                                               ):null,
                                           }}
                                           onChange={ handleChange } required />
                            </Box>
                            <Box mt={2}>
                                <TextField id="outlined-basic"
                                           label={t('profile.mobile')}
                                           variant="outlined"
                                           fullWidth={true}
                                           name="mobile" type="tel"
                                           value={profile.mobile}
                                           error={error.mobile}
                                           onChange={ handleChange }
                                           InputProps={{
                                               inputComponent: PhoneFormatCustom,
                                               readOnly: profile.status !== 'unverified',
                                               endAdornment: profile.status !== 'unverified'?(
                                                   <InputAdornment position="end">
                                                       <VerifiedIcon color={profile.status === 'verified'?"info":"default"} />
                                                   </InputAdornment>
                                               ):null,
                                           }}
                                           required />
                            </Box>
                            {profile.status !== 'unverified' &&
                                <Box mt={2}>
                                    <Alert severity="info">{t('profile.changeProfileRemark')}</Alert>
                                </Box>
                            }
                        </CardContent>
                    </Card>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <Button variant="contained"
                                color="primary"
                                disableElevation
                                size="large"
                                fullWidth={true}
                                onClick={(e) => handleSave()}
                                disabled={ loading.init || loading.update}>
                            {t('common.save')}
                        </Button>
                    </Box>
                </>
            }
            </Box>
        </div>
    )
}