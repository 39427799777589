import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useTranslation } from "react-i18next";
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import _ from 'lodash';
import InfiniteScroll from 'react-infinite-scroll-component';
import {httpClient} from "../../core/HttpClient";
import {DateI18n} from "../DateI18n";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        paddingTop: 0
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        border: '1px solid gainsboro',
        borderRadius: '5px'
    },
}));


function fetchReducer(state, action) {
    switch (action.type) {
        case "FETCH_START":
            return {
                ...state,
                isLoading: true,
                hasError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                hasError: false,
                hasNext: action.mode==='query'?false:action.payload.length > 0,
                cursor: action.payload.length > 0?action.payload[action.payload.length - 1]._id:null,
                hits: action.cursor === ''?action.payload:state.hits.concat(action.payload),
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                hasError: true
            };
        default:
            throw new Error()
    }
}

async function fetchHits(query, dispatch) {
    dispatch({ type: "FETCH_START" });
    try {
        let results = [];
        let mode = 'all';

        const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/customers';
        // const url = process.env.REACT_APP_API_BASE_URL + '/accounts/me/reaches';
        const result = await httpClient(
            `${url}?c=${query.cursor}`
        );

        if(result.data && result.data.results){
            results = result.data.results;
        }
        dispatch({ type: "FETCH_SUCCESS", payload: results, cursor: query.cursor, mode: mode })
    } catch (err) {
        httpClient.isCancel(err) || dispatch({ type: "FETCH_FAILURE" })
    }
}

export default function Customers() {
    const { t } = useTranslation();
    const history = useHistory();
    const [{ hits, hasNext, hasError, isLoading, cursor}, dispatch] = useReducer(fetchReducer, {
        hits: [],
        isLoading: true,
        hasNext: true,
        hasError: false,
        cursor: null
    });
    const [query, setQuery] = useState({cursor: '', search: ''});
    const classes = useStyles();

    const debouncedFetchHits = useRef(
        _.debounce(
            (query, cursor) => fetchHits(query, dispatch),
            200
        )
    ).current;

    useEffect(() => {
        console.log('[Customers]');
        const timeOutId = setTimeout(() => debouncedFetchHits({cursor: '', query: ''}), 10);
        return () => clearTimeout(timeOutId);
    }, [debouncedFetchHits]);


    const handleFetchData = (cursor) =>{
        console.log('handleFetchData', cursor);
        let newValue = {...query, cursor: cursor};
        setQuery(newValue);
        const cancelToken = httpClient.CancelToken.source();
        debouncedFetchHits(newValue, cancelToken.token).then(r=>{});
    };

    return (
        <div className={classes.root}>
            <Box m={1}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            {t('customer.title')}
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Divider style={{'marginBottom': '10px', 'marginTop': '10px'}} />
            {hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2}>
                <Alert severity="error">{t('common.message.somethingWrong')}</Alert>
            </Box>
            }
            {!isLoading && hits.length === 0 && !hasError &&
            <Box display="flex" justifyContent="center" p={2} m={2} mt={4}>
                <Typography>
                    {t('common.notFound')}
                </Typography>
            </Box>
            }
            {hits.length > 0 &&
            <Box m={1}>
                <InfiniteScroll
                    dataLength={hits.length} //This is important field to render the next data
                    next={e=>handleFetchData(cursor)}
                    hasMore={hasNext}
                >
                <Card variant="outlined">
                    <List className={classes.root} subheader={<li/>}>
                        {hits.map((hit) => (
                            hit.items.map((item, index) => {
                                if (!item.customer) {
                                    return <ListItem key={`item-${item._id}`}
                                                     divider={index !== hit.items.length - 1}></ListItem>
                                }
                                return (
                                    <ListItem key={`item-${item._id}`} divider={index !== hit.items.length - 1}
                                              onClick={() => {
                                                  history.push(`/customers/${item.customer._id}`)
                                              }}>
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={item.customer.displayName}
                                                src={`${process.env.REACT_APP_CDN_BASE_URL}/public/customer/${item.customer._id}`}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText>
                                            <Box display="flex" justifyContent="space-between">
                                                <Typography>
                                                    {item.customer.displayName}
                                                </Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" >
                                                <Typography variant="caption">
                                                    {t('common.lastTime')} <DateI18n value={item.date}
                                                                                     format="DD MMM YY, HH:mm" />
                                                </Typography>
                                            </Box>
                                        </ListItemText>
                                    </ListItem>
                                );
                            })
                        ))}
                    </List>
                </Card>
                </InfiniteScroll>
            </Box>
            }
            {isLoading &&
            <Box display="flex" justifyContent="center" mt={4}>
                <CircularProgress size={20}/>
            </Box>
            }
        </div>
    )
}